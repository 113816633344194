import {
	axiosTravelInstance,
	axiosSalesforceInstance,
	axiosWCApiInstance,
} from '../configuration/axios/axiosConfiguration';
import { ApiError } from '../utils/ApiError';
import { appInsights } from '../AppInsights';
import { dateInUrlFormat } from '../utils/helpers';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
/**
 * Gets upcoming assignments from salesforce
 */
async function getAssignments(externalId, assignmentStatus) {
	let response;
	try {
		response = await axiosWCApiInstance.get('Clinicians/' + externalId + '/Assignments', {
			params: {
				assignmentStatus,
			},
		});
	} catch (error) {
		console.log('GetClinicianAssignment error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'getAssignments', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'GetClinicianAssignment'
			);
		}
		//return error.message;
	}
	return response.data;
}

/**
 * Gets upcoming assignments from salesforce
 */
async function getAssignmentTeam(externalId, assignmentId, assignmentType) {
	let response;
	try {
		response = await axiosWCApiInstance.get(
			'clinicians/' + externalId + '/Assignments/' + assignmentId + '/team'
		);
	} catch (error) {
		console.log('getAssignmentTeam error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'getAssignmentTeam', severityLevel: SeverityLevel.Error },
		});
		response = {
			data: [],
		};
	}

	var filterTeamData;
	if (assignmentType === 'active') {
		filterTeamData = response.data.filter(function (member) {
			return member.role === 'Recruiter' || member.role === 'Clinician Deployment Specialist' || member.role === 'Client Liaison';
		});

		response.data = filterTeamData;
	} else if (assignmentType === 'upcoming') {

		filterTeamData = response.data.filter(function (member) {
			return (
				member.role === 'Recruiter' ||
				member.role === 'Client Liaison' ||
				member.role === 'Clinician Deployment Specialist' ||
				member.role === 'Credentialing/Privileging Specialist' ||
				member.role === 'Licensing Specialist'
			);
		});

		response.data = filterTeamData;
	} else {
		response.data = [];
	}

	return response.data;
}

async function getTravelData(assignmentId, filter) {
	let response;
	try {
		response = await axiosTravelInstance.get('GetBookingReservation', {
			params: {
				booking: assignmentId,
				filter: filter,
			},
		});
	} catch (error) {
		console.log('GetBookingReservation error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'getTravelData', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'GetBookingReservation'
			);
		}
	}
	return response.data;
}

async function getTravelDataWC(externalId, assignmentId, assignmentStatus) {
	let response;
	try {
		if (assignmentStatus) {
			response = await axiosWCApiInstance.get(
				'clinicians/' + externalId + '/Assignments/' + assignmentId + '/Travel',
				{
					params: {
						assignmentStatus,
					},
				}
			);
		} else {
			response = await axiosWCApiInstance.get(
				'clinicians/' + externalId + '/Assignments/' + assignmentId + '/Travel');
		}
	} catch (error) {
		console.log('GetProviderAssignmentTravelData error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'getTravelDataWC', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && error.response.status === 404) {
			console.log('getTravelDataWC status 404 =: ' + error.response.status);
			//Return empty on 404
			response = {
				data: [],
			};
		} else if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'GetProviderAssignmentTravelData'
			);
		}
	}
	return response.data;
}

async function getTravelItinerary(externalId, assignmentId, recordLocator) {
	try {
		const response = await axiosWCApiInstance.get(
			`clinicians/${externalId}/Assignments/${assignmentId}/Itinerary/${recordLocator}`,
			{ responseType: 'blob' } // Ensure response is treated as a binary Blob
		);

		// Extract filename from Content-Disposition header, if available
		const contentDisposition = response.headers['content-disposition'];
		let fileName = 'itinerary.pdf'; // Default filename
		if (contentDisposition) {
			const matches = contentDisposition.match(/filename="([^"]+)"/);
			if (matches && matches[1]) {
				fileName = matches[1];
			}
		}

		// Create a Blob and download the file
		const blob = new Blob([response.data], { type: 'application/pdf' });
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = fileName;
		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
	} catch (error) {
		console.log('getTravelItinerary error=', error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'getTravelItinerary', severityLevel: SeverityLevel.Error },
		});

		if (error.response && error.response.data) {
			console.log('throwing ApiError:', JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (error.message) {
			console.log('throwing ApiError:', error.message);
			throw new ApiError(error.message);
		} else {
			throw new ApiError(
				'Server Error',
				'There was a problem processing that request.',
				'getTravelItinerary'
			);
		}
	}
}

function cleanContactForUpload(contact) {
	contact.displayName = `${contact.firstName} ${contact.lastName}`;
	contact.initials = '';
	if (contact.firstName) contact.initials += contact.firstName[0];
	if (contact.lastName) contact.initials += contact.lastName[0];
	contact.initials = contact.initials.toUpperCase();
	delete contact.id;
	return contact;
}

/**
 * Creates a contact for the given facility in the user's address book
 */
async function createContact(facilityId, contact) {
	let response;
	try {
		response = await axiosSalesforceInstance.post(
			'CreateContact',
			cleanContactForUpload(contact),
			{
				params: {
					facilityId,
				},
			}
		);
	} catch (error) {
		console.log('CreateContact error=' + error);
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'CreateContact'
			);
		}
	}
	return response.status === 200;
}

/**
 * Deletes a contact in the user's address book for the given facility
 */
async function deleteContact(contactId) {
	let response;
	try {
		response = await axiosSalesforceInstance.delete('DeleteContact', {
			params: {
				contactId,
			},
		});
	} catch (error) {
		console.log('DeleteContact error=' + error);
		appInsights.trackException({
			exception: error,
			properties: { method: 'deleteContact', severityLevel: SeverityLevel.Error },
		});
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'DeleteContact'
			);
		}
	}
	return response.status === 200 || response.status === 204;
}

/**
 * Gets all shifts for an assignment from salesforce
 */
async function getAssignmentShifts(bookingId) {
	let response;
	try {
		response = await axiosWCApiInstance.get('/assignments/' + bookingId + '/shifts', {
			params: {
				StartTime: dateInUrlFormat(new Date()),
				STRel: 'GT',
			},
		});
	} catch (error) {
		console.log('getAssignmentShifts error=' + error);
		if (!!error.response && error.response.status === 404) {
			console.log('getAssignmentShifts status 404 =: ' + error.response.status);
			//Return Empty List on 404
			response = {
				data: [],
			};
		} else if (!!error.response && !!error.response.data) {
			appInsights.trackException({
				exception: error,
				properties: { method: 'getAssignmentShifts', severityLevel: SeverityLevel.Error },
			});
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getAssignmentShifts'
			);
		}
	}
	return response.data;
}

export {
	getAssignments,
	createContact,
	deleteContact,
	getAssignmentShifts,
	getTravelData,
	getTravelDataWC,
	getAssignmentTeam,
	getTravelItinerary
};
