import React, { useRef, useState, useEffect } from "react";
import Box from '@material-ui/core/Box';
import "./TimesheetEntryTransitionOverlay.css";

const TimesheetEntryTransitionOverlay = (props) => {

    const { children } = props;
    const [transitionState, setTransitionState] = useState(props.doTransition[0]);
    const storedTransition = useRef(transitionState);
    const focusRef = useRef(null);

    useEffect(() => {
        if (transitionState === true && storedTransition.current === true) {
            focusRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            focusRef.current.focus();
            const timeoutId = setTimeout(() => {
                storedTransition.current = false;
                setTransitionState(false);
            }, 2000); // Change state after 2 seconds (Should be same as value in CSS animation-duration property)
            return () => clearTimeout(timeoutId); // Cleanup on unmount
        }

  }, []); // Empty dependency array ensures this runs only once on mount

  return (
      <Box ref={focusRef} display="flex" style={{ width: '100%' }} className={transitionState && storedTransition.current ? 'highlighted-row' : ''}>
          {children}
    </Box>
  );
};

export default TimesheetEntryTransitionOverlay;