import React, { Component } from 'react';
import { Popover,Button,withStyles, Grid, Paper } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import FlightsV3 from './Flights/FlightsV3';
import LodgingV3 from './Lodging/LodgingV3';
import CarRentalV3 from './CarRental/CarRentalV3';
import { COLORS } from '../../../utils/Application_Constants';
import { getTravelDataWC } from '../../../services/AssignmentsService';
import DateFnsUtils from '@date-io/date-fns';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { Alert } from '@material-ui/lab';
import { withRouter } from 'react-router-dom';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const moment = extendMoment(Moment);
const styles = (theme) => ({
	header: {
		fontWeight: '700',
		fontSize: '20px',
		color: COLORS.RAVEN,
		margin: 0,
		textAlign: 'left',
	},
	noDocuments: {
		padding: '8px 20px',
		opacity: '0.3',
	},
	headerGridRoot: {
		paddingLeft: '25px',
	},
	helpNumber: {
		margin: 0,
		paddingRight: '25px',
		textAlign: 'right',
	},
	dateFilter: {
		margin: 0,
		textAlign: 'center',
	},
	dateWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	filterButton: {
		fontSize: '16px',
		marginLeft: '8px',
		marginBottom: '3px',
		color: COLORS.TRUE_BLUE,
		'&:hover': {
			background: COLORS.LT_JOURNEY10,
			color: COLORS.LT_JOURNEY,
			borderColor: COLORS.LT_JOURNEY,
		},
	},
	summaryHeaderGridRoot: {
		color: COLORS.RAVEN,
		padding: '25px 15px 0px 15px',
		textAlign: 'left',
	},
	dashboardCard: {
		width: '100%',
		marginTop: '20px',
		paddingTop: '20px',
		paddingBottom: '20px',
	},
	noDocumentsCard: {
		marginTop: '8px',
		marginLeft: '8px',
		marginRight: '8px',
		marginBottom: '8px',
	},
	travelCard: {
		border: `1px solid ${COLORS.WHITE_LILAC}`,
		borderRadius: '10px',
		marginTop: '8px',
		marginLeft: '8px',
		marginRight: '8px',
		marginBottom: '8px',
	},
	panelHeader: {
		color: COLORS.RAVEN,
	},
	expansionPanelSummaryRoot: {
		padding: '8px 20px',
	},
	chevronRoot: {
		width: '25px',
		right: '0',
		color: COLORS.TRUE_BLUE,
	},
	clickableLink: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		cursor: 'pointer',
		borderBottom: `1px dashed ${COLORS.TRUE_BLUE}`,
	},
	alignLeft: {
		width: '50%',
		float: 'left',
		textAlign: 'left',
	},
	iconClass: {
		verticalAlign: 'middle',
		paddingRight: '6px',
		color: COLORS.TRUE_BLUE,
	},
	alert: {
		textAlign: 'center',
		paddingRight: '20px'
	},
});

class TravelV3 extends Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef();
		const { assignment } = props;
		var query_string = window.location.hash.split('?')[1];
  		const searchParams = new URLSearchParams(query_string);
		const OriginatedDate = searchParams.get('OriginatedDate');
		const CompletedDate = searchParams.get('CompletedDate');

		let defaultStartDate = moment(new Date()).startOf('day').isBefore(moment.utc(assignment.startDate).startOf('day').add(-7, 'days')) ? moment.utc(assignment.startDate).add(-7, 'days').format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD')
		let defaultEndDate = moment(new Date()).startOf('day').add(6, 'days').isAfter(moment.utc(assignment.endDate).startOf('day').add(7, 'days')) ? moment.utc(assignment.endDate).add(7, 'days').format('YYYY-MM-DD') : moment(new Date()).add(6, 'days').format('YYYY-MM-DD')
		let displayStartDate = moment(new Date()).startOf('day').isBefore(moment.utc(assignment.startDate).startOf('day').add(-7, 'days')) ? moment.utc(assignment.startDate).add(-7, 'days').format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');
		let displayEndDate = moment(new Date()).startOf('day').add(6, 'days').isAfter(moment.utc(assignment.endDate).startOf('day').add(7, 'days')) ? moment.utc(assignment.endDate).add(7, 'days').format('YYYY-MM-DD') : moment(new Date()).add(6, 'days').format('YYYY-MM-DD');
		let minDate = moment.utc(assignment.startDate).add(-7, 'days').format('YYYY-MM-DD');
		let maxDate = moment.utc(assignment.endDate).add(7, 'days').format('YYYY-MM-DD');
		
		let dateOriginatedDate =  moment.utc(OriginatedDate).startOf('day');
		let dateCompletedDate =  moment.utc(CompletedDate).startOf('day');

		//Override StartDate display and default with Query Param values when entering with Notification
		if (OriginatedDate){
			if (dateOriginatedDate.isSameOrAfter(moment.utc(assignment.startDate).add(-7, 'days'))){
				displayStartDate = dateOriginatedDate.format('YYYY-MM-DD');
			}else{
				displayStartDate = minDate;
			}
			defaultStartDate = displayStartDate;
		}
		//Override EndDate display and default with Query Param values when entering with Notification
		if (CompletedDate){
			if (dateCompletedDate.isSameOrBefore(moment.utc(assignment.endDate).add(7, 'days'))){
				displayEndDate = dateCompletedDate.format('YYYY-MM-DD');
			}else{
				displayEndDate = maxDate;
			}
			defaultEndDate = displayEndDate;
		}
		this.state = {
			travelInfo: [],
			displayTravelInfo: [],
			defaultStartDate: defaultStartDate,
			defaultEndDate:  defaultEndDate,
			displayStartDate: displayStartDate,
			displayEndDate: displayEndDate,
			minDate: minDate,
			maxDate: maxDate,
			anchorEl: null,
			isLoading: true,
			originatedDate: OriginatedDate,
			completedDate: CompletedDate,
			scrollIntoView: false
		};
	}
	componentDidUpdate(prevProps) {
		const { assignment } = prevProps;
		const state = { ...this.state };
		//Check to see if Notification was clicked while on the Travel Page
		//if so it needs to update the dates based upon the item that was clicked
		var query_string = window.location.hash.split('?')[1];
  		let aSearchParams = new URLSearchParams(query_string);
		let aOriginatedDate = aSearchParams.get('OriginatedDate');
		let aCompletedDate = aSearchParams.get('CompletedDate');
		if (assignment != null && state.travelInfo && (state.originatedDate != aOriginatedDate || state.completedDate != aCompletedDate)){
			let defaultStartDate = moment(new Date()).startOf('day').isBefore(moment.utc(assignment.startDate).startOf('day').add(-7, 'days')) ? moment.utc(assignment.startDate).add(-7, 'days').format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD')
			let defaultEndDate = moment(new Date()).startOf('day').add(6, 'days').isAfter(moment.utc(assignment.endDate).startOf('day').add(7, 'days')) ? moment.utc(assignment.endDate).add(7, 'days').format('YYYY-MM-DD') : moment(new Date()).add(6, 'days').format('YYYY-MM-DD')
			let displayStartDate = moment(new Date()).startOf('day').isBefore(moment.utc(assignment.startDate).startOf('day').add(-7, 'days')) ? moment.utc(assignment.startDate).add(-7, 'days').format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');
			let displayEndDate = moment(new Date()).startOf('day').add(6, 'days').isAfter(moment.utc(assignment.endDate).startOf('day').add(7, 'days')) ? moment.utc(assignment.endDate).add(7, 'days').format('YYYY-MM-DD') : moment(new Date()).add(6, 'days').format('YYYY-MM-DD');
			let minDate = moment.utc(assignment.startDate).add(-7, 'days').format('YYYY-MM-DD');
			let maxDate = moment.utc(assignment.endDate).add(7, 'days').format('YYYY-MM-DD');
			
			let dateOriginatedDate =  moment.utc(aOriginatedDate).startOf('day');
			let dateCompletedDate =  moment.utc(aCompletedDate).startOf('day');
	
			//Override StartDate display and default with Query Param values when entering with Notification
			if (aOriginatedDate){
				if (dateOriginatedDate.isSameOrAfter(moment.utc(assignment.startDate).add(-7, 'days'))){
					displayStartDate = dateOriginatedDate.format('YYYY-MM-DD');
				}else{
					displayStartDate = minDate;
				}
				defaultStartDate = displayStartDate;
			}
			//Override EndDate display and default with Query Param values when entering with Notification
			if (aCompletedDate){
				if (dateCompletedDate.isSameOrBefore(moment.utc(assignment.endDate).add(7, 'days'))){
					displayEndDate = dateCompletedDate.format('YYYY-MM-DD');
				}else{
					displayEndDate = maxDate;
				}
				defaultEndDate = displayEndDate;
			}

			let aDisplayRange = moment.range(displayStartDate, displayEndDate);
			let aTravelInfo = state.travelInfo.filter(segment => aDisplayRange.overlaps(moment.range(moment.utc(segment.originatedDate).format('YYYY-MM-DD'), moment.utc(segment.completedDate).format('YYYY-MM-DD'))));
			this.setState({
				displayTravelInfo: aTravelInfo,
				defaultStartDate: defaultStartDate,
				defaultEndDate:  defaultEndDate,
				displayStartDate: displayStartDate,
				displayEndDate: displayEndDate,
				minDate: minDate,
				maxDate: maxDate,
				originatedDate: aOriginatedDate,
				completedDate: aCompletedDate,
				anchorEl: null, 
				scrollIntoView: true
			});
		}
		setTimeout(() => {
			// Adding a slight delay to allow the page to load before scrolling to the element
			this.scrollToMyElement();
		  }, 2000); // 2 seconds delay
		
	}

	async componentDidMount() {
		const { assignment, externalId } = this.props;
		let assignmentId = assignment.name.slice(4);
		const state = { ...this.state };
		try {
			let aTravelInfo = await getTravelDataWC(externalId, assignmentId, null);
			state.travelInfo = aTravelInfo;
			const displayRange = moment.range(state.displayStartDate, state.displayEndDate);
			state.displayTravelInfo = aTravelInfo.filter(segment => displayRange.overlaps(moment.range(moment.utc(segment.originatedDate).format('YYYY-MM-DD'), moment.utc(segment.completedDate).format('YYYY-MM-DD'))));
		} catch (error) {
			const { status } = error.response || { status: undefined };
			if (status === 404) {
				window.location.href = '#/404';
			} else if (status === 503) {
				window.location.href = '#/503';
			} else if (status === 500) {
				window.location.href = '#/500';
			} else {
				// default to 403
				window.location.href = '#/403';
			}
		}
		var query_string = window.location.hash.split('?')[1];
  		let aSearchParams = new URLSearchParams(query_string);
		let aOriginatedDate = aSearchParams.get('OriginatedDate');
		let aCompletedDate = aSearchParams.get('CompletedDate');
		if (aOriginatedDate && aCompletedDate){
			state.scrollIntoView = true;
		}
		state.isLoading = false;
		this.setState({ ...state });
	}

	handleClick = (event) => {
		const state = { ...this.state };
		state.anchorEl = event.currentTarget;
		this.setState({ ...state });
	};

	setDisplayStartDate = (date) => {
		const state = { ...this.state };
		state.displayStartDate = date;
		this.setState({ ...state });
	};

	setDisplayEndDate = (date) => {
		const state = { ...this.state };
		state.displayEndDate = date;
		this.setState({ ...state });
	};

	clear = () => {
		const state = { ...this.state };
		state.displayStartDate = state.defaultStartDate;
		state.displayEndDate = state.defaultEndDate;
		this.setState({ ...state });
	};

	formatWeekEndingDate = (date) => {
		if (!date) {
			return null;
		}
		return moment(date).format('YYYY-MM-DD');
	};

	handleClose = () => {
		const state = { ...this.state };
		state.anchorEl = null;
		this.setState({ ...state });
	};
	
	renderNoDocuments() {
		const { classes, t } = this.props;
		return (
			<Grid item xs={12} classes={{ root: classes.noDocumentsCard }}>
				<Paper className={classes.noDocumentsCard}>
					<Grid container alignItems="center">
						<Grid item>
							<Alert severity="info" icon={false} style={{ backgroundColor: 'white' }}>
								<InfoOutlinedIcon fontSize="large" style={{ color: COLORS.RAVEN, backgroundColor: 'white' }} />
							</Alert>
						</Grid>
						<Grid item>
							<p>
								There is currently no travel booked during these dates. If you need assistance, contact your Clinician Relations Specialist.
							</p>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		);
	}

	handleFilterApply = async () => {
		const { assignment } = this.props;
		const state = { ...this.state };
		if (!this.state.isLoading) {
			this.setState({ isLoading: true });
			try {
				const displayRange = moment.range(moment.utc(state.displayStartDate).startOf('day'), moment.utc(state.displayEndDate).endOf('day'));
				let aTravelInfo = state.travelInfo.filter(segment => displayRange.overlaps(moment.range(moment.utc(segment.originatedDate).startOf('day'), moment.utc(segment.completedDate).endOf('day'))));
				this.setState({ displayTravelInfo: aTravelInfo, anchorEl: null, isLoading: false });
			} catch (error) {
				const { status } = error.response || { status: undefined };
				if (status === 404) {
					window.location.href = '#/404';
				} else if (status === 503) {
					window.location.href = '#/503';
				} else if (status === 500) {
					window.location.href = '#/500';
				} else {
					// default to 403
					window.location.href = '#/403';
				}
			}
		}
	};

	renderDateFilter = () => {
		const state = { ...this.state };
		const {t,classes } = this.props;
		return (
			<Grid item>
				<div ref={this.myRef} className={classes.dateWrapper}>
					{moment(state.displayStartDate).format('ddd, MMM D YYYY')} - {moment(state.displayEndDate).format('ddd, MMM D YYYY')}
					<CalendarTodayIcon
						aria-controls='travel-filter'
						aria-haspopup='true'
						onClick={(event) => this.handleClick(event)}
						id='travel-filter'
						classes={{ root: classes.filterButton}}
					>
					</CalendarTodayIcon>
				</div>
				<Popover
					id='travel-filter-menu'
					anchorEl={state.anchorEl}
					keepMounted
					open={Boolean(state.anchorEl?.id === 'travel-filter')}
					onClose={this.handleClose}
					PaperProps={{ className: classes.menuPaperRoot }}
				>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<Grid
							container
							direction='row'
							justifyContent='space-between'
							alignItems='center'
							spacing={2}
						>
							<Grid item md={6}>
								<KeyboardDatePicker
									disableToolbar
									variant='static'
									format='MM/dd/yyyy'
									id='start-picker-inline'
									label='Start'
									value={moment(state.displayStartDate)}
									minDate={moment(state.minDate)}
									maxDate={moment(state.displayEndDate)}
									onChange={(e) => {
										this.setDisplayStartDate(this.formatWeekEndingDate(e));
									}}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
								/>
							</Grid>
							<Grid item md={6}>
								<KeyboardDatePicker
									disableToolbar
									minDate={moment(state.displayStartDate)}
									maxDate={moment(state.maxDate)}
									variant='static'
									format='MM/dd/yyyy'
									id='end-picker-inline'
									label='End'
									value={moment(state.displayEndDate)}
									onChange={(e) => {
										this.setDisplayEndDate(this.formatWeekEndingDate(e));
									}}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
								/>
							</Grid>
						</Grid>
					</MuiPickersUtilsProvider>

					<Grid container justifyContent='flex-end' spacing={1}>
						<Grid item>
							<Button
								color='primary'
								onClick={() => {
									this.clear();
								}}
							>
								Clear
							</Button>
						</Grid>
						<Grid item>
							<Button
								variant='contained'
								color='primary'
								onClick={() => {
									this.handleFilterApply()
								}}
								disabled={this.state.isLoading}
							>
								Apply
							</Button>
						</Grid>
					</Grid>
				</Popover>
			</Grid>
		);
	};

	scrollToMyElement = () => {
		const state = { ...this.state };
		if (state.scrollIntoView){
			this.myRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' }); 
			state.scrollIntoView = false;
			this.setState({ ...state });
		}
	}

	render = () => {
		const state = { ...this.state };
		const { t, classes, externalId, assignment } = this.props;
		let assignmentId = assignment.name.slice(4);

		let cards;
		if (state.displayTravelInfo.length === 0) {
			cards = this.renderNoDocuments();
		} else{
			cards = state.displayTravelInfo.map((item,index) => 
				{
					switch(item.segmentType) {
						case 'H':   return <Grid item xs={12} classes={{ root: classes.travelCard }}><LodgingV3 key={index} item={item} externalId={externalId} assignmentId={assignmentId} /></Grid>;
						case 'C':   return <Grid item xs={12} classes={{ root: classes.travelCard }}><CarRentalV3 key={index} item={item} externalId={externalId} assignmentId={assignmentId} /></Grid>;
						case 'A': 	return <Grid item xs={12} classes={{ root: classes.travelCard }}><FlightsV3 key={index} item={item} externalId={externalId} assignmentId={assignmentId} /></Grid>;
						default:	return null
					}
				}
			)
		}

		return (
			<Paper classes={{ root: classes.dashboardCard }}>
				<Grid container>
					<Grid item xs={12} classes={{ root: classes.headerGridRoot }}>
						<Grid container>
							<Grid item xs={12} md={4} classes={{ root: classes.header }}>
								{t('assignments:TRAVEL.TITLEV3')}
							</Grid>
							<Grid item xs={12} md={4} classes={{ root: classes.dateFilter }}>
								{this.renderDateFilter()}
							</Grid>
							<Grid item xs={12} md={4} classes={{ root: classes.helpNumber }}>
									{t('assignments:TRAVEL.AFTER_HOURS')}{' '}
								<a
									href={t('common:INFORMATION.HELP_NUMBER_LINK')}
									className={classes.clickableLink}
								>
									{t('common:INFORMATION.HELP_NUMBER')}
								</a>
							</Grid>
						</Grid>
						{cards.length > 0 &&
							<Grid container>
								<Grid item xs={12} classes={{ root: classes.alert }}>
									<Alert severity='warning'>
										For the most current information about your travel, please visit the vendor’s website.
									</Alert>
								</Grid>
							</Grid>
						}
					</Grid>
					{cards}
				</Grid>
			</Paper>
		);
	};
}
export default withTranslation()(withRouter(withStyles(styles)(TravelV3)));