import React from 'react';
import { withStyles, Grid, Container, Typography, MenuList, Paper } from '@material-ui/core';
import { COLORS } from '../../utils/Application_Constants';
import BannerLogo from '../../assets/logo/locumtenens_com_logo_mark--white.svg';
import Logo from '../../assets/logo/locumtenens_com_logo.svg';
import { withTranslation } from 'react-i18next';
import { ltdomain } from '../../utils/helpers';
import Footer from '../../components/FooterComponents/Footer/Footer';

const styles = (theme) => ({
	root: {
		width: '100%',
		height: 80,
		position: 'relative',
		color: 'white',
		textAlign: 'left',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		backgroundColor: COLORS.DENIM,
		backgroundImage: `url(${BannerLogo})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'right center',
		backgroundSize: '285px 250px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '0',
			verticalAlign: 'center',
			textAlign: 'center',
		},
	},
	logoWrapper: {
		maxWidth: '270px',
	},
	navbarWrapper: {
		padding: '0 15px',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		color: `${COLORS.DENIM}`,
	},
	body: {
		textAlign: 'center',
		paddingTop: '20px',
		paddingBottom: '20px'
	},
	bodyFont: {
		color: COLORS.DENIM
	},
	supportNavNumber: {
		fontWeight: '700',
		color: COLORS.TRUE_BLUE,
		fontSize: '14px',
		cursor: 'pointer',
		padding: '12px 10px',
		transition: '0.3s all',
		'&:hover': {
			background: 'none',
			color: '#336195',
		},
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '10px 0px 18px 0px',
		marginTop: '15px',
		flexGrow: '1',
	},
	paper: {
		padding: '2rem',
		textAlign: 'center',
		width: 'auto',
	},
});

const MaintenancePage = (props) => {
	const { classes, t} = props;
	return (
		<div>
			<div>
				<Grid
					container
					justifyContent='flex-start'
					alignContent='center'
					className={classes.navbarWrapper}
				>
					<Grid item classes={{ root: classes.logoWrapper }} md={3} xs={2}>
						<a href='/'>
							<img alt='LocumTenens Logo' src={Logo} />
						</a>
					</Grid>
					<Grid item md='auto' classes={{ root: classes.buttonWrapper }}>
						<MenuList classes={{ root: classes.supportNumberMenuListRoot }}>
							<a href={t('common:INFORMATION.HELP_NUMBER_LINK')} className={classes.supportNavNumber}>
								{' '}
								{t('common:INFORMATION.HELP_NUMBER')}
							</a>
						</MenuList>
					</Grid>
				</Grid>
			</div>
			<div className={classes.root} />
			<div className={classes.body}>
				<Paper classes={{ root: classes.paper }}>
					<Grid item className={classes.bodyFont}>
						<h1 className={classes.bodyFont}>Maintenance In Progress</h1>
						<div>Please be patient as we improve this website.</div>
						<br />
						<div>The site should be functional again soon.</div>
					</Grid>
				</Paper>
			</div>
			<Footer />
		</div>
    );
};

export default withStyles(styles)(withTranslation()(MaintenancePage));