import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MailOutline, Phone, PhoneIphone, InfoRounded } from '@material-ui/icons';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { COLORS } from '../../../utils/Application_Constants';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { withTranslation } from 'react-i18next';
import { formatPhoneNumber } from '../../../utils/helpers';
import Tooltip from '@material-ui/core/Tooltip';

const ToolTipComponent = withStyles({
	tooltip: {
		color: COLORS.WHITE,
		backgroundColor: COLORS.TRUE_BLUE,
		fontSize: '12px',
		borderRadius: 0,
		width: '200px',
		padding: '10px',
	},
})(Tooltip);

const styles = (theme) => ({
	media: {
		marginTop: 25,
		marginLeft: 85,
		width: 100,
		height: 100,
		borderRadius: '50%',
		objectFit: 'cover',
	},
	contactWrapper: {
		display: 'flex',
		alignItems: 'center',
		paddingBottom: 25,
		paddingTop: 0,
	},
	contactInformationWrapper: {
		padding: 0,
	},
	contactIcon: {
		fontSize: '18px',
		marginRight: '0px',
	},
	typo: {
		flexGrow: 1,
		textAlign: 'center',
	},
	displayName: {
		color: COLORS.RAVEN,
		fontWeight: '900',
		fontSize: '16px',
		paddingTop: '5px',
	},
	title: {
		color: COLORS.RAVEN,
		fontWeight: '500',
		fontSize: '.78em',
		fontStyle: 'italic',
		paddingLeft: '0px',
	},
	infoIcon: {
		height: '.75em',
		paddingLeft: '-10px',
	},
	infoText: {
		color: '#ffffff',
		fontWeight: '500',
		fontSize: '10px',
		display: 'block',
		maxWidth: '200px',
		maxHeight: '350px',
		overflowX: 'auto',
		whiteSpace: 'auto',
		paddingTop: '5px',
		paddingRight: '5px',
		paddingBottom: '5px',
		paddingLeft: '5px',
	},
	commButton: {
		fontWeight: '500',
		color: COLORS.RAVEN,
		fontSize: '12px',
		cursor: 'pointer',
	},
});

class YourTeamCard extends React.Component {
	render() {
		const { classes, contact, t } = this.props;

		let displayCall =
			contact.phones && contact.phones.telephone ? contact.phones.telephone : null;
		let displaySms = contact.phones && contact.phones.mobile ? contact.phones.mobile : null;
		let displayEmail = contact.emails && contact.emails.primary ? contact.emails.primary : null;
		let displayResponsibility;

		switch (contact.role) {
			case 'Recruiter':
				displayResponsibility = t('YOURTEAM.RESPONSIBILITY_REC', { ns: 'assignments' });
				break;
			case 'Client Liaison':
				displayResponsibility = t('YOURTEAM.RESPONSIBILITY_CLI', { ns: 'assignments' });
				break;
			case 'Clinician Deployment Specialist':
				displayResponsibility = t('YOURTEAM.RESPONSIBILITY_CDS', { ns: 'assignments' });
				break;
			case 'Customer Care Specialist':
				displayResponsibility = t('YOURTEAM.RESPONSIBILITY_CCS', { ns: 'assignments' });
				break;
			case 'Credentialing/Privileging Specialist':
				displayResponsibility = t('YOURTEAM.RESPONSIBILITY_CPS', { ns: 'assignments' });
				break;
			case 'Licensing Specialist':
				displayResponsibility = t('YOURTEAM.RESPONSIBILITY_LS', { ns: 'assignments' });
				break;
			default:
				displayResponsibility = '';
				console.log(`Title not recognized`);
		}

		return (
			<Card className='YourTeamGridCard'>
				<CardMedia component='img' className={classes.media} image={contact.image} />
				<div className={classes.contactWrapper}>
					<Typography noWrap component='p' className={classes.typo}>
						<div className={classes.displayName}>{contact.name}</div>
						<div className='YourTeamGridTitle'>
							<Grid
								container
								direction='row'
								justifyContent='center'
								alignItems='center'
							>
								<div className={classes.title}>{contact.role === 'Clinician Deployment Specialist' ? 'Clinician Relations Specialist' : contact.role}</div>
								<ToolTipComponent
									leaveTouchDelay={20000}
									enterTouchDelay={50}
									interactive
									placement='right'
									title={<>{displayResponsibility}</>}
								>
									<InfoRounded classes={{ root: classes.infoIcon }} />
								</ToolTipComponent>
							</Grid>
						</div>
					</Typography>
				</div>
				<CardContent className={classes.contactInformationWrapper}>
					<Typography noWrap component='p' className={classes.typo}>
						<Grid container direction='row' justifyContent='center' alignItems='center'>
							{displayCall && (
								<Grid item>
									<PopupState variant='popover' popupId='popup-popover'>
										{(popupState) => (
											<div>
												<Button
													classes={{ root: classes.commButton }}
													{...bindTrigger(popupState)}
												>
													<Grid item>
														<Grid
															container
															direction='column'
															alignItems='center'
														>
															<Grid item>
																<Phone
																	classes={{
																		root: classes.contactIcon,
																	}}
																	htmlColor='#6D707E'
																/>
															</Grid>
															<Grid item>
																<span>Call</span>
															</Grid>
														</Grid>
													</Grid>
												</Button>
												<Popover
													PaperProps={{
														style: {
															maxHeight: '500px',
															maxWidth: '500px',
															boxShadow: 'none',
															border: '1px solid #61666E',
															borderRadius: '10px',
															backgroundColor: '#61666E',
														},
													}}
													{...bindPopover(popupState)}
													anchorOrigin={{
														vertical: 'top',
														horizontal: 'right',
													}}
													transformOrigin={{
														vertical: 'top',
														horizontal: 'left',
													}}
												>
													<div className={classes.infoText}>
														<Button
															onClick={() => {
																window.open(
																	`tel:${displayCall}`,
																	'_blank'
																);
															}}
														>
															Call: {formatPhoneNumber(displayCall)}
														</Button>
													</div>
												</Popover>
											</div>
										)}
									</PopupState>
								</Grid>
							)}

							{displaySms && (
								<Grid item>
									<PopupState variant='popover' popupId='popup-popover'>
										{(popupState) => (
											<div>
												<Button
													classes={{ root: classes.commButton }}
													{...bindTrigger(popupState)}
												>
													<Grid item>
														<Grid
															container
															direction='column'
															alignItems='center'
														>
															<Grid item>
																<PhoneIphone
																	classes={{
																		root: classes.contactIcon,
																	}}
																	htmlColor='#6D707E'
																/>
															</Grid>
															<Grid item>
																<span>Text</span>
															</Grid>
														</Grid>
													</Grid>
												</Button>
												<Popover
													PaperProps={{
														style: {
															maxHeight: '500px',
															maxWidth: '500px',
															boxShadow: 'none',
															border: '1px solid #61666E',
															borderRadius: '10px',
															backgroundColor: '#61666E',
														},
													}}
													{...bindPopover(popupState)}
													anchorOrigin={{
														vertical: 'top',
														horizontal: 'right',
													}}
													transformOrigin={{
														vertical: 'top',
														horizontal: 'left',
													}}
												>
													<div className={classes.infoText}>
														<Button
															onClick={() => {
																window.open(
																	`sms:${displaySms}`,
																	'_blank'
																);
															}}
														>
															Text: {formatPhoneNumber(displaySms)}
														</Button>
													</div>
												</Popover>
											</div>
										)}
									</PopupState>
								</Grid>
							)}

							{displayEmail && (
								<Grid item>
									<Button
										classes={{ root: classes.commButton }}
										onClick={() => {
											window.open(`mailto:${displayEmail}`, '_blank');
										}}
									>
										<Grid item>
											<Grid container direction='column' alignItems='center'>
												<Grid item>
													<MailOutline
														classes={{ root: classes.contactIcon }}
														htmlColor='#6D707E'
													/>
												</Grid>
												<Grid item>
													<span>Email</span>
												</Grid>
											</Grid>
										</Grid>
									</Button>
								</Grid>
							)}
						</Grid>
					</Typography>
				</CardContent>
			</Card>
		);
	}
}

YourTeamCard.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles)(YourTeamCard));
