import React, { useState } from 'react';
import {
    Grid,
    withStyles,
    Hidden,
    Chip,
    Typography,
    CircularProgress,
    Box
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { COLORS } from '../../../utils/Application_Constants';
import { ExpenseStatusEnum } from './ExpenseStatus.enum';
import { PreCodedStatusEnum } from './PreCodedStatus.enum';
import moment from 'moment';
import { ExpenseBillTypeEnum } from './BillType.enum';
import { ExpenseSourceEnum } from './ExpenseSource.enum';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useFormContext } from 'react-hook-form';
import FilterAltIcon from '../../../assets/icons/FilterAltIcon.js';
import ErrorIcon from '@material-ui/icons/Error';
import SplitIcon from '@material-ui/icons/CallSplit';
import Tooltip from '../../../components/GlobalComponents/ToolTipComponent.js';

const styles = (theme) => ({
    stickyHeaderCell: {
        position: 'sticky',
        borderRadius: '8px',
        zIndex: 1,
        width: 150,
        backgroundColor: COLORS.LT_MIDNIGHTBG1,
        display: 'flex',
        alignItems: 'center',
        color: COLORS.LT_MIDNIGHT50,
        fontSize: '12px',
        lineHeight: '18px',
        '& span:hover': {
            cursor: 'pointer',
            background: COLORS.LT_MIDNIGHTBG1,
        },
        whiteSpace: 'nowrap',
        padding: '0px 16px',
        flex: '0 0 150px',
        '&:nth-child(1)': {
            left: 0,
        },
        '&:nth-child(2)': {
            left: 150,
        },
        '&:nth-child(3)': {
            left: 300,
        },
    },
    stickyBodyCell: {
        position: 'sticky',
        flex: '0 0 150px',
        width: 150,
        borderCollapse: 'separate',
        borderLeft: `1px solid ${COLORS.LT_MIDNIGHT5}`,
        '&:nth-child(1)': {
            left: 0,
            zIndex: 3,
        },
        '&:nth-child(2)': {
            left: 150,
            zIndex: 2,
        },
        '&:nth-child(3)': {
            left: 300,
            zIndex: 1,
            overflow: 'hidden',
            '& $tableCellContent': {
                boxShadow: 'rgba(0, 0, 0, 0.12) 2px 0px 8px 0px',
                marginRight: 8,
            },
        },
        '& .MuiTypography-body2': {
            maxWidth: '100%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            textWrap: 'nowrap',
            lineHeight: '32px',
        },
    },
    scrollableHeaders: {
        overflowX: 'auto',
        borderRadius: '8px',
    },
    paragraph3: {
        display: 'flex',
        alignItems: 'center',
        color: COLORS.LT_MIDNIGHT50,
        fontSize: '12px',
        lineHeight: '18px',
        '& span:hover': {
            cursor: 'pointer',
        },
        whiteSpace: 'nowrap',
    },
    sortIcon: {
        transition: '.15s',
        '&.desc': {
            transform: 'rotate(180deg)',
        },
    },
    sortIcons: {
        '& $sortIcon': {
            margin: '-5px 0',
        },
    },
    headerRow: {
        display: 'flex',
        width: 'auto',
        backgroundColor: COLORS.LT_MIDNIGHTBG1,
        borderRadius: '8px',
    },
    tableRow: {
        '&:first-child $stickyBodyCell:first-child': {
            borderTopLeftRadius: 8,
        },
        '&:last-child $stickyBodyCell:first-child': {
            borderBottomLeftRadius: 8,
        },
        '&:first-child': {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
        },
        '&:last-child': {
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
        },
        '&:not(:last-child)': {
            borderBottom: `1px solid ${COLORS.LT_MIDNIGHT5}`,
        },
        '&:hover': {
            cursor: 'pointer',
            background: COLORS.LT_MIDNIGHTBG1,
        },
    },
    chipRoot: {
        textAlign: 'center',
        width: '9em',

        [theme.breakpoints.up('lg')]: {
            minWidth: '100px',
        },
    },
    chipOnHoldMatchPending: {
        backgroundColor: COLORS.LT_SUNFLOWER25,
        color: '#AD7829',
        borderColor: '#AD7829',
    },
    chipOnHoldPendingAirfare: {
        backgroundColor: COLORS.LT_SUNFLOWER25,
        color: '#AD7829',
        borderColor: '#AD7829',
    },
    chipOnHoldAirlineCreditOnFile: {
        backgroundColor: COLORS.LT_SUNFLOWER25,
        color: '#AD7829',
        borderColor: '#AD7829',
    },
    chipOnHoldDisputed: {
        backgroundColor: COLORS.LT_SUNFLOWER25,
        color: '#AD7829',
        borderColor: '#AD7829',
    },
    chipOnHoldAdvancedDeposit: {
        backgroundColor: COLORS.LT_SUNFLOWER25,
        color: '#AD7829',
        borderColor: '#AD7829',
    },
    chipOnHoldReimbursement: {
        backgroundColor: COLORS.LT_SUNFLOWER25,
        color: '#AD7829',
        borderColor: '#AD7829',
    },
    chipReadyForReview: {
        backgroundColor: COLORS.LT_JOURNEY10,
        color: COLORS.LT_JOURNEY,
        borderColor: COLORS.LT_JOURNEY,
    },
    chipInProcess: {
        backgroundColor: COLORS.LT_JOURNEY10,
        color: COLORS.LT_JOURNEY,
        borderColor: COLORS.LT_JOURNEY,
    },
    chipNew: {
        backgroundColor: COLORS.LT_JOURNEY10,
        color: COLORS.LT_JOURNEY,
        borderColor: COLORS.LT_JOURNEY,
    },
    chipApproved: {
        backgroundColor: COLORS.LT_EMERALD10,
        color: COLORS.LT_EMERALD,
        borderColor: COLORS.LT_EMERALD,
    },
    chipMatched: {
        backgroundColor: COLORS.LT_EMERALD10,
        color: COLORS.LT_EMERALD,
        borderColor: COLORS.LT_EMERALD,
    },
    chipAbandoned: {
        backgroundColor: COLORS.LT_EMERALD10,
        color: COLORS.LT_EMERALD,
        borderColor: COLORS.LT_EMERALD,
    },
    chipPosted: {
        backgroundColor: COLORS.LT_EMERALD10,
        color: COLORS.LT_EMERALD,
        borderColor: COLORS.LT_EMERALD,
    },
    chipMissingInformation: {
        backgroundColor: COLORS.LT_PEPPER10,
        color: COLORS.LT_PEPPER,
        borderColor: COLORS.LT_PEPPER,
    },
    chipRejected: {
        backgroundColor: COLORS.LT_PEPPER10,
        color: COLORS.LT_PEPPER,
        borderColor: COLORS.LT_PEPPER,
    },
    chipNonReimbursable: {
        backgroundColor: COLORS.LT_PEPPER10,
        color: COLORS.LT_PEPPER,
        borderColor: COLORS.LT_PEPPER,
    },
    chipAssigned: {
        backgroundColor: COLORS.LT_EMERALD10,
        color: COLORS.LT_EMERALD,
        borderColor: COLORS.LT_EMERALD,
    },
    chipUnassigned: {
        backgroundColor: COLORS.LT_JOURNEY10,
        color: COLORS.LT_JOURNEY,
        borderColor: COLORS.LT_JOURNEY,
    },
    chipTcApproved: {
        backgroundColor: COLORS.LT_JOURNEY10,
        color: COLORS.LT_JOURNEY,
        borderColor: COLORS.LT_JOURNEY,
    },
    chipNeedMoreInformation: {
        backgroundColor: COLORS.LT_SUNFLOWER25,
        color: '#AD7829',
        borderColor: '#AD7829',
    },
    chipReturnedByAccounting: {
        backgroundColor: COLORS.LT_SUNFLOWER25,
        color: '#AD7829',
        borderColor: '#AD7829',
    },
    chipPendingSubmission: {
        backgroundColor: COLORS.LT_JOURNEY10,
        color: COLORS.LT_JOURNEY,
        borderColor: COLORS.LT_JOURNEY,
    },
    chipSubmitted: {
        backgroundColor: COLORS.LT_JOURNEY10,
        color: COLORS.LT_JOURNEY,
        borderColor: COLORS.LT_JOURNEY,
    },
    tableCell: {
        width: 200,
        '&:first-child': {
            paddingLeft: '24px',
        },
        '& .MuiTypography-body2': {
            maxWidth: '100%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            textWrap: 'nowrap',
            lineHeight: '32px',
        },
    },
    tableBody: {
        position: 'relative',
        marginTop: '2em',
        borderRadius: '8px',
        borderTop: `1px solid ${COLORS.LT_MIDNIGHT5}`,
        borderBottom: `1px solid ${COLORS.LT_MIDNIGHT5}`,
        borderRight: `1px solid ${COLORS.LT_MIDNIGHT5}`,
    },
    tableCellContent: {
        padding: 16,
    },
    headerCell: {
        width: 200,
        padding: '12px 16px',
        display: 'flex',
        alignItems: 'center',
        '&:first-child': {
            paddingLeft: '24px',
        },
    },
    loader: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 400,
    },
    statusCell: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    filterIcon: {
        color: COLORS.LT_JOURNEY,
    },
});

const ExpenseTable = (props) => {
    const { classes, sortBy, sortDirection, config, expenses, isLoading, handleSort, t, pendingCCReview } = props;
    const history = useHistory();

    const handleNavigation = (expense) => {

        if (expense.source != null) {
            history.push(`/expense-redirect/${expense.id}`);
        }
        else if (pendingCCReview) {
            history.push(`/expenses/pending-cc/${expense.id}`);
        }
    };
    const { watch, getValues } = useFormContext();
    const formValues = watch(getValues());
    const statusEnum = pendingCCReview ? PreCodedStatusEnum : ExpenseStatusEnum;
    const renderStatusChip = (expense, index) => {

    let statusKey = Object.keys(statusEnum).find((key) => statusEnum[key].value === expense.status);

    let statusTitle = !!statusEnum[statusKey].UI
        ? statusEnum[statusKey].UI
        : 'Unknown';
    const status = !!statusEnum[statusKey].value
        ? statusEnum[statusKey].value
        : 'Unknown';
    statusTitle = statusTitle.replace(/ /g, '');

    return (
        <Grid
            item
            className={`${classes.statusCell} ${index === 0 || index === 1 || index === 2
                ? classes.stickyBodyCell
                : classes.tableCell
                }`}
            style={{
                backgroundColor: isHovered === expense ? COLORS.LT_MIDNIGHTBG1 : COLORS.WHITE,
            }}
            xs={2}
            key={`${expense}-status-${index}`}
        >
            <Chip
                label={statusTitle}
                className={classes[`chip${status}`]}
                classes={{ root: classes.chipRoot }}
                title={statusTitle}
            />
        </Grid>
    );
};

    const renderSortableArrow = () => (
        <Grid container direction='column' className={classes.sortIcons}>
            <ArrowDropUpIcon className={`${classes.sortIcon} asc`} fontSize='small' />
            <ArrowDropUpIcon className={`${classes.sortIcon} desc`} fontSize='small' />
        </Grid>
    );
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = (index) => {
        setIsHovered(index);
    };

    const handleMouseLeave = () => {
        setIsHovered(null);
    };
    const checkIfFilterApplied = (status) => {
        const plural = status + 's';
        const esplural = status + 'es';
        for (var key in formValues) {
            if ((formValues.hasOwnProperty(key) && key == plural) || key == esplural) {
                var value = formValues[key];
                if (value !== null && !(Array.isArray(value) && value.length === 0) && !!value) {
                    return true;
                }
            }
        }
        return false;
    };

    const renderTimesheetListHeader = () => {
        const columns = pendingCCReview ? config.pendingCCTableColumns : config.tableColumns;

        return (
            <Hidden only={['xs', 'sm']}>
                <Grid container wrap='nowrap' className={classes.headerRow}>
                    {columns.map(({ name, sortKey, size }, index) => {
                        const sorted = sortBy === sortKey;
                        return (
                            <Grid
                                item
                                xs={size}
                                key={name}
                                className={
                                    index === 0 || index === 1 || index === 2
                                        ? classes.stickyHeaderCell
                                        : classes.headerCell
                                }
                            >
                                {checkIfFilterApplied(name) && (
                                    <FilterAltIcon className={classes.filterIcon} />
                                )}
                                <div
                                    className={classes.paragraph3}
                                    style={{ fontWeight: sorted ? 700 : 'initial' }}
                                >
                                    {sortKey ? (
                                        <span onClick={() => handleSort(sortKey)}>{name}</span>
                                    ) : (
                                        name
                                    )}
                                    {sorted && (
                                        <ArrowDropUpIcon
                                            className={`${classes.sortIcon} ${sortDirection}`}
                                            fontSize='small'
                                        />
                                    )}
                                    {!sorted && sortKey && renderSortableArrow()}
                                </div>
                            </Grid>
                        );
                    })}
                </Grid>
            </Hidden>
        );
    };

    const renderTimesheetListGrid = () => {
        return (
            <div className={classes.tableBody} >
                {expenses.map((expense, index) => (
                    <React.Fragment key={`expense-${expense.id}-row-${index}`}>
                        {renderTimesheetListGridRow(expense)}
                    </React.Fragment>
                ))}
            </div>
        );
    };

    const renderTimesheetListGridRow = (expense) => {
        const columns = pendingCCReview ? config.pendingCCTableColumns : config.tableColumns;

        return (
            <Grid
                container
                wrap='nowrap'
                className={classes.tableRow}
                onMouseEnter={() => handleMouseEnter(expense)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleNavigation(expense)}
            >
                {columns.map(({ key, size, name }, index) => {
                    const isStatus = key === 'status';
                    const isName = key === 'clinicianName';
                    const hasName = expense.clinicianFirstName && expense.clinicianLastName;
                    const isSource = key === 'source';
                    const isCreditCard = key === 'creditCardNumber';
                    const isBillType = key === 'billType';
                    const isAmount = key === 'amount';
                    const showAbbreviation =
                        expense.currency === 'CAD' ||
                        expense.currency === 'MXN' ||
                        expense.currency === 'COP';
                    const isDate =
                        key === 'transactionDate' ||
                        key === 'endDate' ||
                        key === 'postDate' ||
                        key === 'periodEndDate' ||
                        key === 'startDate';
                    const errorCount = expense.errorCount;
                    let hasError = false;
                    if (errorCount > 0) { hasError = true; }
                    const isSplitChild = !!expense.primaryExpenseId;
                    const formattedDate = isDate && moment(expense[key]).format('MM/DD/YYYY');
                    const clinicianFullName = `${expense.clinicianFirstName} ${expense.clinicianLastName}`;
                    let value = !!expense[key] ? expense[key] : '-';
                    if (isName && hasName) value = clinicianFullName;
                    if (isDate && expense[key]) value = formattedDate;
                    if (isStatus) return renderStatusChip(expense, index);
                    if (isCreditCard && expense.creditCardName && expense.creditCardNumber)
                        value = `${expense.creditCardNumber} - ${expense.creditCardName}`;
                    if (isBillType && ExpenseBillTypeEnum[expense[key]])
                        value = ExpenseBillTypeEnum[expense[key]];
                    if (isSource && ExpenseSourceEnum[expense[key]])
                        value = ExpenseSourceEnum[expense[key]];
                    if (isAmount && expense.currency)
                        value = `${t(`currency:${expense.currency}`)}${expense.amount} ${showAbbreviation ? expense.currency : ''
                            }`;
                    return (
                        <Grid
                            item
                            key={`${expense}-${key}-${index}`}
                            xs={size}
                            className={
                                index === 0 || index === 1 || index === 2
                                    ? classes.stickyBodyCell
                                    : classes.tableCell
                            }
                            style={{
                                backgroundColor:
                                    isHovered === expense ? COLORS.LT_MIDNIGHTBG1 : COLORS.WHITE,
                            }}
                        >
                            <div className={classes.tableCellContent} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography variant='body2' title={value}>
                                    {value}
                                </Typography>
                                {hasError && isName && (
                                    <Tooltip title="One or more needs review reasons applied to this transaction">
                                        <ErrorIcon fontSize='small' style={{ marginLeft: 5, color: COLORS.LT_PEPPER }} />
                                    </Tooltip>
                                )}
                                {isName && isSplitChild && (
                                    <Tooltip title="This transaction includes one or more split. Click to see related transactions">
                                        <SplitIcon fontSize='small' style={{ marginLeft: 5, color: COLORS.LT_MIDNIGHT25 }} />
                                    </Tooltip>
                                )}
                                {isName && expense.isParent && (
                                    <Tooltip title="This transaction includes one or more split. Click to see related transactions">
                                        <SplitIcon fontSize='small' style={{ marginLeft: 5, color: COLORS.LT_MIDNIGHT75 }} />
                                    </Tooltip>
                                )}
                            </div>
                        </Grid>
                    );
                })}
            </Grid>
        );
    };

    return (
        <Hidden only={['xs', 'sm']}>
            <Grid container className={classes.scrollableHeaders}>
                {renderTimesheetListHeader()}
                {isLoading && (
                    <div className={classes.loader}>
                        <CircularProgress color='primary' />
                    </div>
                )}
                {!isLoading && expenses.length > 0 && renderTimesheetListGrid()}
                {!isLoading && expenses.length === 0 && (
                    <Box mt={3}>
                        <Typography variant='body2'>No results found</Typography>
                    </Box>
                )}
            </Grid>
        </Hidden>
    );
};

export default withTranslation()(withStyles(styles)(ExpenseTable));
