import { AppConfigurationClient } from '@azure/app-configuration';
import { backOff } from 'exponential-backoff';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { getLocalStorageWithExpiry, setLocalStorageWithExpiry } from '../utils/helpers';
import { appInsights } from '../AppInsights';

/**
 * Gets App Configuration from Azure
 */
async function getConfigurationSettingImpl(name, isFeatureFlag = false) {
	let featureName = name;

	if (isFeatureFlag) {
		featureName = '.appconfig.featureflag/' + featureName;
	}

	const client = new AppConfigurationClient(process.env.REACT_APP_APP_CONFIG);

	let responseValue = null;

	if (isFeatureFlag) {
		responseValue = getLocalStorageWithExpiry(featureName, true);
	} else {
		// Use .appconfig prefix for all appconfig value caching
		responseValue = getLocalStorageWithExpiry('.appconfig.'+featureName, true);
	}
	// If the value does not exist in localstorage or if localstorage is expired
	// attempt to lookup from server failures will be thrown and retried according to
	// exponential-backoff settings
	if (responseValue === null || responseValue.expired === true) {
		const response = await client.getConfigurationSetting(
				{ key: featureName },
				{ requestOptions: { customHeaders: { 'cache-control': 'no-cache' } } }
			);
		// process response
		responseValue = JSON.parse(response.value);
		if (isFeatureFlag) {
			setLocalStorageWithExpiry(featureName, responseValue, 300000);
		} else {
			// Use .appconfig prefix for all appconfig value caching
			setLocalStorageWithExpiry('.appconfig.'+ featureName, responseValue, 300000);
		}
	} else {
		responseValue = responseValue.value;
	}

	if (isFeatureFlag) {
		return responseValue.enabled;
	}
	return responseValue;
}

// Wrapper function used for retrying failures
async function getConfigurationSetting(name, isFeatureFlag = false, fallbackValue = null) {
	const backOffOptions = {
		delayFirstAttempt: false, // Decides whether the startingDelay should be applied before the first call.
		jitter: 'full', // Adding a random factor to slightly separate the calls
		retryDelay: 1000, // Initial retry delay in milliseconds
		maxDelay: 3000, // The maximum delay, in milliseconds, between two consecutive attempts.
		numOfAttempts: 3, // The maximum number of times to attempt the function.
		startingDelay: 100, // The delay, in milliseconds, before executing the function for the first time.
		timeMultiple: 2, // The startingDelay is multiplied by the timeMultiple to increase the delay between reattempt.
		retry: (e, attemptNumber) => {
			if (e.statusCode === 404) {
				const error = new Error(`AppConfigService.getConfigurationSetting 404 will abort after 1 attempt. 
					Name: ${name} 
					IsFeatureFlag: ${isFeatureFlag} 
					FallbackValue: ${fallbackValue}
					Request: ${JSON.stringify(e.request)} 
					Response: ${JSON.stringify(e.response)}`);
				error.value = e;
				throw error;
			}
			if (attemptNumber === 3) {
				const error = new Error(`AppConfigService.getConfigurationSetting maximum retries 3 reached. 
					Name: ${name} 
					IsFeatureFlag: ${isFeatureFlag} 
					FallbackValue: ${fallbackValue}
					Request: ${JSON.stringify(e.request)} 
					Response: ${JSON.stringify(e.response)}`);
				error.value = e;
				throw error;
			}
			return true;
		}
	};

	// Added the Retry logic at a higher level to leverage any successful caching performed
	// prior to the retry happening.
	try {
		return await backOff(() => getConfigurationSettingImpl(name, isFeatureFlag), backOffOptions);
	} catch (error) {
		appInsights.trackException({
			exception: error,
			properties: { method: 'getConfigurationSetting', severityLevel: SeverityLevel.Error },
		});

		// Default the return value to the provided fallbackValue
		let aReturn = fallbackValue;
		if (isFeatureFlag && aReturn === null) {
			aReturn = false;
		}

		// If a version in LocalStorage exists set the return to it
		if (isFeatureFlag) {
			// Use .appconfig.featureflag/ prefix for Feature Flag appconfig value caching
			const responseValue = getLocalStorageWithExpiry('.appconfig.featureflag/'+name, true);
			if (responseValue && responseValue.value) {
				aReturn = responseValue.value.enabled;
			}
		} else {
			// Use .appconfig. prefix for Non Feature Flag appconfig value caching
			const responseValue = getLocalStorageWithExpiry('.appconfig.'+name, true);
			if (responseValue) {
				aReturn = responseValue.value;
			}
		}

		return aReturn;
	}
}

export { getConfigurationSetting };
