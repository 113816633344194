import DateFnsAdapter from '@date-io/date-fns';
import { Chip } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Menu from '@material-ui/core/Menu';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AssignmentIcon from '@material-ui/icons/Assignment';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import CheckIcon from '@material-ui/icons/Check';
import LaunchIcon from '@material-ui/icons/Launch';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SnoozeIcon from '@material-ui/icons/Snooze';
import moment from 'moment';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import WithContexts from '../../../contexts/withContexts';
import { getAssignments } from '../../../services/AssignmentsService';
import { getAdobeSignDocumentUrl } from '../../../services/DocumentService';
import {
	dismissNotification,
	getNotificationsByReceiptId,
} from '../../../services/NotificationsService';
import { getSamlResponse } from '../../../services/peoplenetSsoService';
import { COLORS, DOCUMENT_TYPES } from '../../../utils/Application_Constants';
import AssignmentViewAndSignDocumentModel from '../../ContentComponents/AssignmentDocumentComponent/AssignmentViewAndSignDocumentModel';
const styles = (theme) => ({
	menuListRoot: {
		display: 'flex',
	},
	paper: {
		transformOrigin: 'top right',
	},
	list: {
		width: '400px',
		maxHeight: '500px',
		overflow: 'auto',
	},
	listItem: {
		display: 'flex',
		flexDirection: 'column',
		paddingTop: '10px',
	},
	divider: {
		margin: '1px',
	},
	btnGroup1: {
		/*background-color: #4CAF50; */
		borderLeft: `0.5px solid #a5a5a5`,
		borderTop: '0.5px solid #a5a5a5',
		borderBottom: '0.5px solid #a5a5a5',
		color: 'blue',
		padding: '10px 5px',
		float: 'left',
		width: '120px',
		fontSize: '11px',
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			padding: '10px 0px',
		},
	},
	btnGroup2: {
		/*background-color: #4CAF50;  */
		border: '0.5px solid #a5a5a5',
		color: 'blue',
		padding: '10px 5px',
		float: 'left',
		width: '90px',
		textAlign: 'center',
		fontSize: '11px',
		[theme.breakpoints.down('sm')]: {
			padding: '10px 0px',
			width: '70px',
		},
	},
	btnGroup3: {
		borderTop: '0.5px solid #a5a5a5',
		borderRight: '0.5px solid #a5a5a5',
		borderBottom: '0.5px solid #a5a5a5',
		color: 'blue',
		padding: '10px 5px',
		float: 'left',
		width: '90px',
		textAlign: 'center',
		fontSize: '11px',
		[theme.breakpoints.down('sm')]: {
			padding: '10px 0px',
			width: '70px',
		},
	},
	buttonAction: {
		cursor: 'pointer',
	},
	notificationsTitle: {
		fontSize: '12px',
		fontWeight: '700',
		color: `${COLORS.WATERLOO}`,
		marginLeft: '10px',
	},
	notificationsMessage: {
		fontSize: '12px',
		color: `${COLORS.WATERLOO}`,
		fontWeight: '700',
		display: 'flex',
		alignItems: 'center',
		marginBottom: '8px',
	},
	dateSpan: {
		paddingBottom: '10px',
		marginLeft: '24px',
		fontSize: '12px',
		color: `${COLORS.MILANO_RED}`,
		fontWeight: '500',
	},
	documentTypeSpan: {
		paddingBottom: '10px',
		marginLeft: '24px',
		fontSize: '12px',
		color: `${COLORS.WATERLOO}`,
		fontWeight: '500',
	},
	notificationMessagePadding: {
		paddingLeft: '4px',
		marginBottom: 0,
	},
	notificationChipRoot: {
		height: 'auto',
		textAlign: 'left',
		marginBottom: '8px',
		color: COLORS.LT_PEPPER,
		border: `1px solid ${COLORS.LT_PEPPER}`,
		background: COLORS.LT_PEPPER10,
	},
	notificationChipLabel: {
		overflow: 'auto',
		whiteSpace: 'wrap',
		textTransform: 'none',
		fontSize: '12px',
	},
});
class Notifications extends Component {
	constructor(props, context) {
		super(props);

		this.state = {
			anchorEl: null,
			notificationsList: [],
			signDocument: false,
			signDocumentURL: '',
			signDocumentError: '',
			snoozeData: localStorage.getItem('snoozeNotification')
				? JSON.parse(localStorage.getItem('snoozeNotification'))
				: [],
			extSystemId: '',
			pastDueWarnings: [],
		};
	}
	async componentDidMount() {
		const { refreshNotifications } = this.props;
		const { snoozeData } = this.state;

		if (refreshNotifications) {
			let notificationsList = refreshNotifications.slice();
			if (snoozeData !== null && snoozeData !== undefined) {
				if (snoozeData.length > 0)
					snoozeData.forEach(function (item, index) {
						for (let [i, notification] of notificationsList.entries()) {
							if (notification.externalSystemId === item) {
								notificationsList.splice(i, 1);
							}
						}
					});
				this.setState({ notificationsList: notificationsList });
			}
		}
	}

	componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		if (this.props.refreshNotifications !== prevProps.refreshNotifications) {
			const { refreshNotifications } = this.props;
			if (refreshNotifications) {
				let notificationsList = refreshNotifications.slice();
				const { snoozeData } = this.state;
				if (snoozeData !== null && snoozeData !== undefined) {
					if (snoozeData.length > 0)
						snoozeData.forEach(function (item, index) {
							for (let [i, notification] of notificationsList.entries()) {
								if (notification.externalSystemId === item) {
									notificationsList.splice(i, 1);
								}
							}
						});
					this.setState({ notificationsList: notificationsList });
				}
			}
		}
	}

	handleManageTimesheet = (notification, index) => {
		const { history } = this.props;
		const hasPayload = !!notification.payload && notification.payload !== '';
		if (hasPayload) {
			const url = `/timesheet/${this.getTimesheetIdFromPayload(
				notification.payload
			)}/edit?weekEnding=${this.getWeekEndingFromPayload(notification.payload)}`;
			const payload = JSON.parse(notification.payload);
			const duration = moment(new Date()).diff(moment(payload.WeekEnding), 'days');
			if (duration >= 28) {
				const pastDueWarnings = [...this.state.pastDueWarnings, index];
				this.setState({ pastDueWarnings });
			} else {
				history.push(url);
			}
		} else {
			getSamlResponse();
		}
	};

	handleViewTravel = (notification, index) => {
		const { history } = this.props;
		const { externalId } = this.props.UserContext;
		const hasPayload = !!notification.payload && notification.payload !== '';
		if (hasPayload) {
			const notificationPayload = JSON.parse(notification.payload);
			let url = null;
			Promise.all([
				getAssignments(externalId, 'active'),
				getAssignments(externalId, 'upcoming'),
			])
				.then(([active, upcoming]) => {
					let foundAssignment = null;
					if ((active && active.length > 0) || (upcoming && upcoming.length > 0)) {
						foundAssignment = active.find(assignment => assignment.id == notificationPayload.BookingId);
					}
					if (foundAssignment) {
						url = `/assignments/active/${foundAssignment.id}?OriginatedDate=${notificationPayload.OriginatedDate}&CompletedDate=${notificationPayload.CompletedDate}`;
					} else {
						foundAssignment = upcoming.find(assignment => assignment.id == notificationPayload.BookingId);
						if (foundAssignment) {
							url = `/assignments/upcoming/${foundAssignment.id}?OriginatedDate=${notificationPayload.OriginatedDate}&CompletedDate=${notificationPayload.CompletedDate}`;
						}
					}
					if (!!url) {
						//Dismiss Update Notification on View
						if (notification.notificationType === 'ClinicianTravelUpdated'){
							this.dismissNotifications(
								notification.externalSystemId
							)
						}
						history.push(url);
					}
				})
				.catch((e) => {
					console.log(e);
				});		
		}
	};

	handleToggle = (event) => {
		this.setState({ anchorEl: event.currentTarget });
	};
	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	dismissNotifications = async (externalSystemId) => {
		const { externalId } = this.props.UserContext;
		const response = await dismissNotification(externalSystemId);
		const { snoozeData } = this.state;
		this.setState({ pastDueWarnings: [] });
		if (response === 1) {
			const notifications = await getNotificationsByReceiptId(externalId);
			if (snoozeData !== null && snoozeData !== undefined) {
				if (snoozeData.length > 0)
					snoozeData.forEach(function (item, index) {
						for (let [i, notification] of notifications.entries()) {
							if (notification.externalSystemId === item) {
								notifications.splice(i, 1);
							}
						}
					});
				this.setState({ notificationsList: notifications });
			}
		}
	};

	snoozeNotifications = async (notification) => {
		const externalSystemId = notification.externalSystemId;
		const { notificationsList, snoozeData } = this.state;
		snoozeData.push(notification.externalSystemId);
		localStorage.setItem('snoozeNotification', JSON.stringify(snoozeData));
		this.setState({ pastDueWarnings: [] });
		for (let [i, notification] of notificationsList.entries()) {
			if (notification.externalSystemId === externalSystemId) {
				notificationsList.splice(i, 1);
			}
		}
		this.setState({ notificationsList: notificationsList });
		this.setState({ snoozeData: snoozeData });
	};

	getDocumentTypeFromPayload = (notification) => {
		const payload = JSON.parse(notification.payload);
		if (payload.docType === DOCUMENT_TYPES.ADDENDUM) {
			return DOCUMENT_TYPES.ADDENDUM;
		} else if (payload.docType === DOCUMENT_TYPES.W9 || payload.docType === DOCUMENT_TYPES.DD) {
			return payload.docType;
		} else {
			return DOCUMENT_TYPES.AGREEMENT;
		}
	};

	getTimesheetIdFromPayload = (aPayload) => {
		let aReturn = '';
		if (aPayload) {
			try {
				const payload = JSON.parse(aPayload);
				if (payload && payload.TimesheetId) {
					aReturn = payload.TimesheetId;
				}
			} catch {}
		}
		return aReturn;
	};

	getWeekEndingFromPayload = (aPayload) => {
		let aReturn = '';
		if (aPayload) {
			try {
				const payload = JSON.parse(aPayload);
				if (payload && payload.WeekEnding) {
					const dateParts = payload.WeekEnding.split('/'); //12/24/2023  month/day/year
					if (dateParts.length == 3) {
						const dateFns = new DateFnsAdapter();
						const aDate = new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
						aReturn = dateFns.format(aDate, 'yyyy-MM-dd');
					}
				}
			} catch {}
		}
		return aReturn;
	};

	signDocumentHandler = async (notification) => {
		this.setState({
			signDocument: !this.state.signDocument,
			signDocumentError: '',
			signDocumentURL: '',
		});
		const data = JSON.parse(notification.payload);

		if (data.clinicianExternalId && data.agreementId && data.senderEmail) {
			let adobeSignUrl = await getAdobeSignDocumentUrl(
				data.clinicianExternalId,
				data.agreementId,
				data.senderEmail
			);
			if (adobeSignUrl) {
				this.setState({
					signDocumentURL: adobeSignUrl,
					extSystemId: notification.externalSystemId,
				});
			} else {
				this.setState({ signDocumentError: 'error', extSystemId: '' });
			}
		} else {
			this.setState({ signDocumentError: 'error', extSystemId: '' });
		}
	};

	toggleSignDocumentModal = async (isViewable) => {
		this.setState({ signDocument: isViewable });
		const { extSystemId, notificationsList } = this.state;
		if (extSystemId) {
			for (let [i, notification] of notificationsList.entries()) {
				if (notification.externalSystemId === extSystemId) {
					notificationsList.splice(i, 1);
				}
			}
			this.setState({ notificationsList: notificationsList });
		}
	};
	adobeSignDocumentModal() {
		const { signDocument, signDocumentURL, signDocumentError } = this.state;
		if (signDocument === true) {
			return (
				<AssignmentViewAndSignDocumentModel
					credentialingSigningUrl={signDocumentURL}
					toggleHandler={this.toggleSignDocumentModal}
					openDialog={signDocument}
					responseTimeout={signDocumentError}
				/>
			);
		}

		return null;
	}

	renderDocumentSignBtn = (notification) => {
		const { classes, t, history } = this.props;
		var aDocType = this.getDocumentTypeFromPayload(notification);

		if (aDocType === DOCUMENT_TYPES.W9 || aDocType === DOCUMENT_TYPES.DD) {
			return (
				<>
					<span className={classes.btnGroup1}>
						<a
							onClick={() => {
								history.push('/profile/paymentElections');
							}}
							className={classes.buttonAction}
						>
							<LaunchIcon style={{ fontSize: 10, color: '#0474c8' }} />{' '}
							{t('assignments:NOTIFICATIONS.PAYMENT_PREFERENCE')}
						</a>
					</span>
					<span className={classes.btnGroup2}>
						<a
							onClick={() => this.snoozeNotifications(notification)}
							className={classes.buttonAction}
						>
							<SnoozeIcon style={{ fontSize: 10, color: '#0474c8' }} />{' '}
							{t('assignments:NOTIFICATIONS.SNOOZE')}
						</a>
					</span>
				</>
			);
		} else {
			return (
				<>
					<span className={classes.btnGroup1}>
						<a
							onClick={() => this.signDocumentHandler(notification)}
							className={classes.buttonAction}
						>
							<LaunchIcon style={{ fontSize: 10, color: '#0474c8' }} />{' '}
							{t('assignments:NOTIFICATIONS.SIGN_DOCUMENT')}
						</a>
					</span>
					<span className={classes.btnGroup2}>
						<a
							onClick={() => this.dismissNotifications(notification.externalSystemId)}
							className={classes.buttonAction}
						>
							<CheckIcon style={{ fontSize: 10, color: '#0474c8' }} />{' '}
							{t('assignments:NOTIFICATIONS.DISMISS_BTN')}
						</a>
					</span>
					<span className={classes.btnGroup3}>
						<a
							onClick={() => this.snoozeNotifications(notification)}
							className={classes.buttonAction}
						>
							<SnoozeIcon style={{ fontSize: 10, color: '#0474c8' }} />{' '}
							{t('assignments:NOTIFICATIONS.SNOOZE')}
						</a>
					</span>
				</>
			);
		}
	};

	render() {
		const id = Boolean(this.state.anchorEl) ? 'simple-popover' : undefined;
		const { classes, t, device } = this.props;
		const { notificationsList, pastDueWarnings } = this.state;
		const { snoozeData } = this.state;
		if (snoozeData !== null && snoozeData !== undefined) {
			if (snoozeData.length > 0)
				snoozeData.forEach(function (item, index) {
					for (let [i, notification] of notificationsList.entries()) {
						if (notification.externalSystemId === item) {
							notificationsList.splice(i, 1);
						}
					}
				});
		}
		return (
			<>
				<div>
					<IconButton
						aria-label='notifications'
						color='inherit'
						onClick={this.handleToggle}
						aria-describedby={id}
					>
						<Badge
							badgeContent={notificationsList ? notificationsList.length : 0}
							color='secondary'
							showZero
							overlap='rectangular'
						>
							{device === 'desktop' ? (
								<NotificationsIcon />
							) : (
								<NotificationsIcon style={{ fontSize: 30, color: '#0474c8' }} />
							)}
						</Badge>
					</IconButton>
					<Menu
						id='simple-menu'
						anchorEl={this.state.anchorEl}
						keepMounted
						open={Boolean(this.state.anchorEl)}
						onClose={this.handleClose}
						getContentAnchorEl={null}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
					>
						<Paper className={classes.paper} elevation={0}>
							<List className={classes.list}>
								<span className={classes.notificationsTitle}>
									{t('assignments:NOTIFICATIONS.TITLE_NOTIFICATIONS')}
								</span>

								{notificationsList && notificationsList.length > 0 ? (
									notificationsList
										.sort((a, b) => {
											var dateA = new Date(a.createdOn),
												dateB = new Date(b.createdOn);
											return dateA - dateB;
										})
										.map((notification, index) => (
											<React.Fragment key={notification.notificationId}>
												{notification.notificationType ===
													'MissingTimesheet' && (
													<ListItem
														alignItems='flex-start'
														className={classes.listItem}
													>
														<div
															className={classes.notificationsMessage}
														>
															<AccessTimeIcon
																style={{
																	fontSize: 18,
																	color: `${COLORS.WATERLOO}`,
																}}
															/>{' '}
															<p
																className={
																	classes.notificationMessagePadding
																}
															>
																{notification.notificationDetail.substring(
																	0,
																	notification.notificationDetail.indexOf(
																		'.'
																	)
																)}
															</p>
														</div>
														{pastDueWarnings.includes(index) && (
															<Chip
																classes={{
																	root: classes.notificationChipRoot,
																	label: classes.notificationChipLabel,
																}}
																label='This timesheet is past the 4 week
															deadline for online entry, please reach
															out to your recruiter to assist with
															submittal.'
															/>
														)}
														<span
															className={classes.dateSpan}
															dangerouslySetInnerHTML={{
																__html: notification.notificationDetail.substr(
																	notification.notificationDetail.indexOf(
																		'.'
																	) + 1
																),
															}}
														></span>
														<div>
															<span className={classes.btnGroup1}>
																<a
																	onClick={() =>
																		this.handleManageTimesheet(
																			notification,
																			index
																		)
																	}
																	className={classes.buttonAction}
																>
																	<LaunchIcon
																		style={{
																			fontSize: 10,
																			color: '#0474c8',
																		}}
																	/>{' '}
																	{t(
																		'assignments:NOTIFICATIONS.MANAGE_TIMESHEET_BTN'
																	)}
																</a>
															</span>
															<span className={classes.btnGroup2}>
																<a
																	onClick={() =>
																		this.dismissNotifications(
																			notification.externalSystemId
																		)
																	}
																	className={classes.buttonAction}
																>
																	<CheckIcon
																		style={{
																			fontSize: 10,
																			color: '#0474c8',
																		}}
																	/>{' '}
																	{t(
																		'assignments:NOTIFICATIONS.DISMISS_BTN'
																	)}
																</a>
															</span>
															<span className={classes.btnGroup3}>
																<a
																	onClick={() =>
																		this.snoozeNotifications(
																			notification
																		)
																	}
																	className={classes.buttonAction}
																>
																	<SnoozeIcon
																		style={{
																			fontSize: 10,
																			color: '#0474c8',
																		}}
																	/>{' '}
																	{t(
																		'assignments:NOTIFICATIONS.SNOOZE'
																	)}
																</a>
															</span>
														</div>
													</ListItem>
												)}
												{(notification.notificationType ===
													'ClinicianTravelCreated' || notification.notificationType ===
													'ClinicianTravelUpdated') && (
													<ListItem
														alignItems='flex-start'
														className={classes.listItem}
													>
														<div
															className={classes.notificationsMessage}
														>
															<InfoOutlined
																style={{
																	fontSize: 18,
																	color: `${COLORS.WATERLOO}`,
																}}
															/>{' '}
															<p
																className={
																	classes.notificationMessagePadding
																}
															>
																{notification.notificationDetail}
															</p>
														</div>
														<div>
															<span className={classes.btnGroup1}>
																<a
																	onClick={() =>
																		this.handleViewTravel(
																			notification,
																			index
																		)
																	}
																	className={classes.buttonAction}
																>
																	<LaunchIcon
																		style={{
																			fontSize: 10,
																			color: '#0474c8',
																		}}
																	/>{' '}
																	{t(
																		'assignments:NOTIFICATIONS.VIEW_TRAVEL'
																	)}
																</a>
															</span>
															<span className={classes.btnGroup2}>
																<a
																	onClick={() =>
																		this.dismissNotifications(
																			notification.externalSystemId
																		)
																	}
																	className={classes.buttonAction}
																>
																	<CheckIcon
																		style={{
																			fontSize: 10,
																			color: '#0474c8',
																		}}
																	/>{' '}
																	{t(
																		'assignments:NOTIFICATIONS.DISMISS_BTN'
																	)}
																</a>
															</span>
															<span className={classes.btnGroup3}>
																<a
																	onClick={() =>
																		this.snoozeNotifications(
																			notification
																		)
																	}
																	className={classes.buttonAction}
																>
																	<SnoozeIcon
																		style={{
																			fontSize: 10,
																			color: '#0474c8',
																		}}
																	/>{' '}
																	{t(
																		'assignments:NOTIFICATIONS.SNOOZE'
																	)}
																</a>
															</span>
														</div>
													</ListItem>
												)}
												{(notification.notificationType ===
													'Document Sign' ||
													notification.notificationType ===
														'Document Attention Needed') && (
													<ListItem
														alignItems='flex-start'
														className={classes.listItem}
													>
														<div
															className={classes.notificationsMessage}
														>
															<AssignmentIcon
																style={{
																	fontSize: 18,
																	color: `${COLORS.WATERLOO}`,
																}}
															/>{' '}
															<p
																className={
																	classes.notificationMessagePadding
																}
															>
																{notification.notificationDetail}
															</p>
														</div>
														<span className={classes.documentTypeSpan}>
															Document:{' '}
															{this.getDocumentTypeFromPayload(
																notification
															)}
														</span>
														<div>
															{this.renderDocumentSignBtn(
																notification
															)}
														</div>
													</ListItem>
												)}

												{/* {(notification.notificationType === 'Document Sign' && this.getDocmentTypeFromPayload(notification) === DOCUMENT_TYPES.AGREEMENT) && (<ListItem alignItems="flex-start" className={classes.listItem}>
                                                    <div className={classes.notificationsMessage}>
                                                        <AssignmentIcon style={{ fontSize: 18, color: `${COLORS.WATERLOO}` }} />{' '}
                                                        <p className={classes.notificationMessagePadding}>{notification.notificationDetail}</p>
                                                    </div>
                                                    <div>
                                                        <span className={classes.btnGroup1}>
                                                            <a onClick={() => this.signDocumentHandler(notification)} className={classes.buttonAction}><LaunchIcon style={{ fontSize: 10, color: '#0474c8' }} /> {t('assignments:NOTIFICATIONS.SIGN_DOCUMENT')}
                                                            </a>
                                                        </span >
                                                        <span className={classes.btnGroup2}>
                                                            <a onClick={() => this.snoozeNotifications(notification)} className={classes.buttonAction}><CheckIcon style={{ fontSize: 10, color: '#0474c8' }} /> {t('assignments:NOTIFICATIONS.SNOOZE')}
                                                            </a>

                                                        </span>
                                                    </div>

                                                </ListItem>)
                                                } */}

												{index < notificationsList.length - 1 ? (
													<Divider className={classes.divider} />
												) : null}
											</React.Fragment>
										))
								) : (
									<ListItem>
										<span className={classes.notificationsMessage}>
											No Notifications{' '}
										</span>
									</ListItem>
								)}
							</List>
						</Paper>
					</Menu>
				</div>
				{this.adobeSignDocumentModal()}
			</>
		);
	}
}

export default withRouter(WithContexts(withTranslation()(withStyles(styles)(Notifications))));
