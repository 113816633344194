import React, { useState, useEffect } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { Controller, useFormContext} from 'react-hook-form';
import ExpenseTextField from './ExpenseTextField';
import { billTypes } from '../../Configurations/ExpenseEntryConfig';
import { ExpenseStatusValuesEnum } from './ExpenseStatus.enum';

const ExpenseBillType = (props) => {
    const { index, expense, isClinicianReimbursement, mode, isSGA, isPosted, isPendingSubmission, isViewOnly} = props;
    const { control, errors, setValue } = useFormContext();
    const [required, setRequired] = useState({ required: true });

    useEffect(() => {
        setRequired({ required: (mode !== 'cc' || expense?.status === ExpenseStatusValuesEnum.Approved) });
    }, [expense?.status]);

    return (
        <Grid item xs={12} md={6}>
            <Controller
                control={control}
                name={`expenseEntries[${index}].billType`}
                defaultValue={!expense.billType ? '' : expense.billType}
                rules={{
                    required: required.required ? 'Bill type is required' : false,
                }}
                render={({ onChange, value }) => (
                    <ExpenseTextField
                        id={'bill-type'}
                        select
                        onChange={(e) => {
                            if(isSGA[index]){
                                setValue(`expenseEntries[${index}].sgaBillType`, e.target.value);
                            }else{
                                setValue(`expenseEntries[${index}].bookingBillType`, e.target.value);
                            }
                            onChange(e);
                        }}
                        value={!value ? '' : value}
                        label={'Bill type'}
                        {...required}
                        disabled={isViewOnly || isPosted || isPendingSubmission}
                        error={Boolean(errors.expenseEntries?.[index]?.billType)}
                        helperText={errors.expenseEntries?.[index]?.billType?.message}
                    >
                        <MenuItem value='' disabled>
                            Select a bill type
                        </MenuItem>
                        {isClinicianReimbursement ? billTypes.filter((type) => type.value !== 'ClinicianDeduction').map((option, index) => (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                                id={`bill-type-menu-option-${index}`}
                            >
                                {option.type}
                            </MenuItem>
                        )) : ((isSGA[index]) ? 
                        
                        billTypes.filter((type) => type.value === 'NonBillTeam' || type.value === 'NonBillProducer').map((option, index) => (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                                id={`bill-type-menu-option-${index}`}
                            >
                                {option.type}
                            </MenuItem>
                        ))

                        :
                        
                        
                        
                        billTypes.map((option, index) => (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                                id={`bill-type-menu-option-${index}`}
                            >
                                {option.type}
                            </MenuItem>
                        ))
                    )
                        }
                    </ExpenseTextField>
                )}
            />
        </Grid>
    );
};

export default ExpenseBillType;
