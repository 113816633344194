import { Box, Collapse, Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { COLORS } from '../../../utils/Application_Constants';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
	formHeader: {
		borderBottom: `1px solid ${COLORS.LT_MIDNIGHT25}`,
		paddingBottom: 8,
		marginBottom: 16,
		display: 'flex',
		justifyContent: 'space-between',
		cursor: 'pointer',
	},
}));

const ImportedExpenseDetails = ({ expense }) => {
	const { importedData } = expense;
	const sortedKeys = Object.keys(importedData).sort();
	return (
		<Box mb={4}>
			<div>
				{sortedKeys.map((key) => (
					<Typography key={key} variant="body2">
						{key}: {importedData[key].length > 1 ? importedData[key].join(", ") : importedData[key][0]}
					</Typography>
				))}
			</div>
		</Box>
	);
};

const AdditionalExpenseDetails = (props) => {
	const { index, expense, isViewOnly } = props;
	const classes = useStyles();
	const [expanded, setExpanded] = useState(false);

	return (
		<>
			<div
				className={classes.formHeader}
				onClick={() => setExpanded((prevState) => !prevState)}
			>
				<Typography variant='h5' component='h5'>
					Additional Expense Details
				</Typography>
				<ExpandMoreIcon
					color='primary'
					style={{
						transition: 'transform .25s',
						transform: `${expanded ? 'rotate(180deg)' : 'rotate(0)'}`,
					}}
				/>
			</div>
			<Collapse in={expanded}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						{expense.importedData &&
							<ImportedExpenseDetails expense={expense} />
						}
					</Grid>
				</Grid>
			</Collapse>
		</>
	);
};

export default AdditionalExpenseDetails;
