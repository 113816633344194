/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import {
	IconButton,
	makeStyles,
	Grid,
	Hidden,
	TextField,
	MenuItem,
	Menu,
	Typography,
	InputLabel,
	Divider,
	Button,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import WithContexts from '../../../contexts/withContexts';
import { totalCount } from '../../../utils/helpers';
import { MoreVert, ExpandMore, DeleteOutline, MoreHoriz } from '@material-ui/icons';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import PropTypes from 'prop-types';
import RateTypeCategoryEnum from './RateTypeCategories.enum';
import CopyADayMenuItem from './CopyADayMenuItem';
import CopyADayMenu from './CopyADayMenu';
import moment from 'moment';
import { AdminAuthContext } from '../../../Admin/Contexts/AdminAuthContext';
import TimesheetBreak from './TimesheetBreak';
import { useFieldArray, useForm } from 'react-hook-form';
import RateDisplayNameEnum from '../../ContentComponents/Timesheets/RateDisplayName.enum';
import TimesheetEntryStylesV1 from './TimesheetEntryStylesV1.js';
import TimesheetEntryStylesV2 from './TimesheetEntryStylesV2.js';
import { WarningController } from './WarningController';
import TimesheetEntryTransitionOverlay from './TimesheetEntryTransitionOverlay.js';

const Placeholder = ({ label }) => <span className='select-placeholder'>{label}</span>;

function TimesheetEntry(props) {
	const { key, index, dateLookup, timesheet, timesheetEntry, onRemove, isPaidApproved, timesheetInsert, triggerCallRowLockCheck, setTotalTimesheetHours, isLastCallBackEntry, isMobile, isMobileUiV2Enabled, isShowWarning, generateNextId } =
		props;

	const getStylesheet = () => {
		const styles = isMobileUiV2Enabled
			? TimesheetEntryStylesV2
			: TimesheetEntryStylesV1;

		const useStyles = makeStyles(styles);
		return useStyles();
	};

	const classes = getStylesheet();

	const { errors, setValue, watch, getValues, clearErrors, reset } = useFormContext();
	const [rateTypeCategory, setRateTypeCategory] = useState(RateTypeCategoryEnum.Hours);
	const [rateTypeDisplayName, setRateTypeDisplayName] = useState(null);
	const [isCallBackEntry, setIsCallBackEntry] = useState(timesheetEntry.relatedEntryId !== '');
	const [isCallWithCallBack, setIsCallWithCallBack] = useState(() => {
		const hasRelatedEntryId = timesheet.timesheetEntries.some(entry =>
			entry.relatedEntryId === timesheetEntry.id
		);
		return hasRelatedEntryId;
	});
	const [modifiedDateLookup, setModifiedDateLookup] = useState(dateLookup);
	const [hasBreaks, setHasBreaks] = useState(false);
	const [entryHours, setEntryHours] = useState('');
	const [showCopyADayMenu, setShowCopyADayMenu] = useState(false);
	const watchRateTypeId = watch(`timesheetEntries[${index}].rateTypeId`);
	const watchTransactionDate = watch(`timesheetEntries[${index}].transactionDate`);
	const watchComment = watch(`timesheetEntries[${index}].comment`);
	const watchStartTime = watch(`timesheetEntries[${index}].startTime`);
	const watchEndTime = watch(`timesheetEntries[${index}].endTime`);
	const readOnlyLocations =
		timesheet.timesheetReviews?.map((review) =>
			review.status !== 'NotStarted' &&
				review.status !== 'PendingSubmission' &&
				review.status !== 'Rejected' &&
				review.status !== 'DidNotWork'
				? review.locationId
				: null
		) || [];
	const adminAuth = useContext(AdminAuthContext);
	const isReadOnly =
		readOnlyLocations.indexOf(timesheetEntry.locationId) > -1 ||
		isPaidApproved ||
		adminAuth?.isLTAssociateUser;
	const [anchorEl, setAnchorEl] = useState(null);
	const isFirstRender = useRef(true);
	const getAvailableLocation = () => {
		return (
			timesheet.timesheetReviews?.find(
				(review) => !readOnlyLocations.includes(review.locationId)
			)?.locationId || null
		);
	};
	const avaliableLocationId = getAvailableLocation();
	const defaultLocationId =
		timesheet.primaryWorkLocationId &&
			!readOnlyLocations.includes(timesheet.primaryWorkLocationId)
			? timesheet.primaryWorkLocationId
			: avaliableLocationId;

	const getTransactionDateLabel = (transactionDate) => {
		return modifiedDateLookup.filter(date => date.value === transactionDate)[0]?.label;
	};
	const [transactionDateLabel, setTransactionDateLabel] = useState(getTransactionDateLabel(timesheetEntry.transactionDate));

	const getLocationDisplayName = (locationId) => {
		const existingOrDefaultLocationId = locationId || defaultLocationId;
		return timesheet.booking.locations.filter(location => location.id === existingOrDefaultLocationId)[0]?.displayName;
	};
	const [locationDisplayName, setLocationDisplayName] = useState(getLocationDisplayName(timesheetEntry.locationId));

	const [isUnitShift, setIsUnitShift] = useState(false);

	const doTransition = useState(timesheetEntry.isAddedEntry);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const methods = useForm({ defaultValues: {} });

	const {
		formState: { isDirty },
	} = methods;

	const {
		fields: timesheetBreaks,
		insert: breakInsert,
		remove: breakRemove,
	} = useFieldArray({
		control: methods.control,
		name: `timesheetBreaks`,
		keyName: 'timesheetBreakId',
	});

	useEffect(() => {
		if (timesheetEntry) {
			methods.reset(setDefaultValues());
		}
	}, [methods.reset, timesheetEntry]);

	const setDefaultValues = () => {
		let timesheetBreaks = [];

		timesheetBreaks = timesheetEntry.timesheetBreaks;

		return { timesheetBreaks };
	};

	useEffect(() => {
		const rateType = timesheet.booking.availableRates.find(
			(rate) => rate.id === watchRateTypeId
		);
		if (rateType) {
			if (
				rateType.category === RateTypeCategoryEnum.Units ||
				rateType.category === RateTypeCategoryEnum.TeleUnits
			) {
				const units = timesheetEntry.units ? timesheetEntry.units : '';
				setValue(`timesheetEntries[${index}].units`, units);
				setValue(`timesheetEntries[${index}].startTime`, '');
				setValue(`timesheetEntries[${index}].endTime`, '');
				timesheetEntry.units = '';
				setIsUnitShift(true);
			} else if (
				rateType.category === RateTypeCategoryEnum.SingleUnit ||
				rateType.category === RateTypeCategoryEnum.TeleSingleUnit
			) {
				setValue(`timesheetEntries[${index}].units`, '1');
				setValue(`timesheetEntries[${index}].startTime`, '');
				setValue(`timesheetEntries[${index}].endTime`, '');
				setIsUnitShift(true);
			} else {
				setValue(`timesheetEntries[${index}].units`, '');
				setIsUnitShift(false);
			}
			if (rateType && rateType.displayName.includes("Call-Callback")) {
				setIsCallBackEntry(true);
			} else {
				setIsCallBackEntry(false);
			}
			setRateTypeCategory(rateType.category);
			setRateTypeDisplayName(rateType.displayName);
		}
	}, [watchRateTypeId]);

	useEffect(() => {
		if (!isFirstRender.current) {
			const hasRelatedEntryId = getValues().timesheetEntries.some(entry =>
				entry.relatedEntryId === timesheetEntry.id
			);
			setIsCallWithCallBack(hasRelatedEntryId);
		} else {
			isFirstRender.current = false;
		}
	}, [triggerCallRowLockCheck, getValues().timesheetEntries, timesheetEntry.id])

	useEffect(() => {
		const relatedTimesheetEntry = getValues().timesheetEntries.find(entry => entry.id === timesheetEntry.relatedEntryId);
		if (isCallBackEntry && relatedTimesheetEntry) {
			const modifiedDates = [
				{ value: moment(relatedTimesheetEntry.transactionDate).format('YYYY-MM-DDTHH:mm:ss'), label: moment(relatedTimesheetEntry.transactionDate).format('ddd M/D') },
				{ value: moment(relatedTimesheetEntry.transactionDate).add(1, 'day').format('YYYY-MM-DDTHH:mm:ss'), label: moment(relatedTimesheetEntry.transactionDate).add(1, 'day').format('ddd M/D') }
			];
			setModifiedDateLookup(modifiedDates);
		}
	}, [reset]);

	useEffect(() => {
		calculateEntryHours();
	}, [watchStartTime, watchEndTime, watchRateTypeId, timesheetBreaks]);

	useEffect(() => {
			setHasBreaks(timesheetBreaks && timesheetBreaks.length > 0);
	}, [timesheetBreaks]);

	const calculateTotalTimesheetHours = () => {
		const timesheetEntries = getValues().timesheetEntries;
		let totalTimesheetHours = 0;
		for (var i = 0; i < timesheetEntries.length; i++) {
			if (timesheetEntries[i].totalTimeInHours) {
				totalTimesheetHours += timesheetEntries[i].totalTimeInHours
			}
		}

		setTotalTimesheetHours(Number(totalTimesheetHours.toFixed(2)));
	};

	const validateUnits = (value) => {
		let regex;
		const hasDecimal = value.indexOf('.') > -1;
		if (hasDecimal) {
			regex = /^\d{0,2}(?:[.]\d{0,2})/;
		} else {
			regex = /^\d{0,2}/;
		}
		const match = value.match(regex);

		if (match) {
			//In-House Call Rate Types have an additional restriction of 26 or less
			if (
				!!!!rateTypeDisplayName &&
				rateTypeDisplayName.toUpperCase().includes('IN-HOUSE CALL') &&
				!(value <= 26)
			) {
				return null;
			}
			return match[0];
		} else {
			return null;
		}
	};

	const calculateEntryHours = () => {
		if (!watchEndTime || !watchStartTime) {
			setValue(`timesheetEntries[${index}].totalTimeInHours`, null);
			setEntryHours('');
			calculateTotalTimesheetHours();
			return;
		}
		const startTime = moment(watchStartTime, 'HH:mm');
		const endTime = moment(watchEndTime, 'HH:mm');
		let hours = moment.duration(endTime.diff(startTime)).asHours();

		if (hours <= 0)
			hours += 24;

		let breakHours = calculateTotalEntryBreakHours();
		if (breakHours)
			hours -= breakHours;

		if (hours < 0)
			hours = 0;

		hours = Number(hours.toFixed(2))
		setValue(`timesheetEntries[${index}].totalTimeInHours`, hours);
		setEntryHours(hours);
		calculateTotalTimesheetHours();
	};

	const calculateTotalEntryBreakHours = () => {
		let entries = getValues().timesheetEntries;

		if (!entries[index].timesheetBreaks)
			return 0;

		return totalCount(entries[index].timesheetBreaks, 'totalTimeInHours');
	}

	const getAssociatedRateType = (rateTypeId) => {
		const rate = timesheet.booking.availableRates.find(rate => rate.id === rateTypeId);
		return rate ? rate.associatedRateType : null;
	};

	const renderCallBackMenuItem = () => {
		const rateExists = watchRateTypeId !== null;
		const associatedRateExists = timesheet.booking.availableRates.some(rate => rate.id === watchRateTypeId
			&& rate.associatedRateType !== null && !rate.displayName.includes("Call-Callback"));

		if ((rateExists && associatedRateExists) || (isMobile && isMobileUiV2Enabled && isLastCallBackEntry)) {
			return (
				<MenuItem
					onClick={() => {
						const entries = getValues().timesheetEntries;
						timesheetInsert((getNextCallbackIndex(entries)), {
							id: generateNextId(),
							transactionDate: entries[index].transactionDate,
							locationId: entries[index].locationId,
							rateTypeId: isLastCallBackEntry ? watchRateTypeId : getAssociatedRateType(watchRateTypeId),
							relatedEntryId: isLastCallBackEntry ? entries[index].relatedEntryId : entries[index].id,
							units: '',
							startTime: '',
							endTime: '',
							comment: '',
						});

						if (!isLastCallBackEntry)
							setIsCallWithCallBack(true);

						handleClose();
					}}
					id={`timesheet-entry--${index}--menu--list-item--callback`}
				>
					<Grid container alignItems='center' spacing={2}>
						<Grid item className={classes.menuIcon}>
							<SettingsBackupRestoreIcon
								fontSize='small'
								className={classes.menuIconCallBack}
							/>
						</Grid>
						<Grid item>
							<Typography variant='body2'>Add Call-Back</Typography>
						</Grid>
					</Grid>
				</MenuItem>
			);
		}
	};

	const getNextCallbackIndex = (entries) => {
		if (isLastCallBackEntry)
			return index + 1;

		let lastChildIndex = entries.findLastIndex(element =>
			element.relatedEntryId === entries[index].id
		);

		return (lastChildIndex > -1) ? lastChildIndex + 1 : index + 1;
	};
	
	const checkBreakRateDisplayName = (rateDisplayName) => {
		const names = [
			RateDisplayNameEnum.Regular,
			RateDisplayNameEnum.RegularOnsite,
			RateDisplayNameEnum.RegularTele,
			RateDisplayNameEnum.DayShift, 
			RateDisplayNameEnum.DayShiftOnsite,
			RateDisplayNameEnum.DayShiftTele,
			RateDisplayNameEnum.NightShift,
			RateDisplayNameEnum.NightShiftOnsite,
			RateDisplayNameEnum.NightShiftTele,
			RateDisplayNameEnum.Orientation,
			RateDisplayNameEnum.OrientationOnsite,
			RateDisplayNameEnum.OrientationTele,
		];
		
		return names.indexOf(rateDisplayName) !== -1;
	};

	const renderBreakMenuItem = () => {
		const rateExists = watchRateTypeId !== null;
		const timeRateExists = timesheet.booking.availableRates.some(rate => rate.id === watchRateTypeId
			&& checkBreakRateDisplayName(rate.displayName));

		if (rateExists && timeRateExists) {
			return (
					<MenuItem
						onClick={() => {
							const entries = getValues().timesheetEntries;

							let lastChildIndex = entries[index].timesheetBreaks?.findLastIndex(element =>
								element.timesheetEntryId === entries[index].id
							);
							const breakIndex = (lastChildIndex > -1) ? lastChildIndex + 1 : 0

							breakInsert((breakIndex), {
								id: 0,
								timesheetEntryId: entries[index].id,
								startTime: '',
								endTime: ''
							});

							handleClose();
						}}
						id={`timesheet-entry--${index}--menu--list-item--break`}
					>
						<Grid container alignItems='center' spacing={2}>
							<Grid item className={classes.menuIcon}>
								<AccessTimeOutlinedIcon
									fontSize='small'
									className={classes.menuIconBreak}
								/>
							</Grid>
							<Grid item>
								<Typography variant='body2'>Add a Break</Typography>
							</Grid>
						</Grid>
					</MenuItem>
			);
		} else {
			return null; // or you can render an alternative message or component
		}
	};

	const renderCopyADayMenuItem = () => {
		return (
			<CopyADayMenuItem
				index={index}
				entryType={'timesheet-entry'}
				setAnchorEl={setAnchorEl}
				setShowCopyADayMenu={setShowCopyADayMenu}
				isDisabled={!watchRateTypeId}
				isMobile={isMobile}
				isMobileUiV2Enabled={isMobileUiV2Enabled}
			/>
		);
	};

	const renderCopyADayMenu = () => {
		return (
			<CopyADayMenu
				index={index}
				timesheet={timesheet}
				dateLookup={modifiedDateLookup}
				entryType={'timesheet-entry'}
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
				showCopyADayMenu={showCopyADayMenu}
				setShowCopyADayMenu={setShowCopyADayMenu}
				isMobile={isMobile}
				isMobileUiV2Enabled={isMobileUiV2Enabled}
				generateNextId={generateNextId}
			/>
		);
	};

	const renderBreaks = () => {
		if (!hasBreaks) {
			return null;
		}

		return <Grid item>
			<Grid container direction='column' spacing={1}>
				{timesheetBreaks?.map((timesheetBreak, breakIndex) => (
					<Grid item key={timesheetBreak.timesheetBreakId + '-' + index} >
						<TimesheetBreak
							entryIndex={index}
							index={breakIndex}
							timesheetBreak={timesheetBreak}
							isPaidApproved={isPaidApproved}
							calculateEntryHours={calculateEntryHours}
							breakRemove={breakRemove}
							isReadOnly={isReadOnly}
							isLastBreakInEntry={breakIndex === timesheetBreaks.length - 1}
							isMobile={isMobile}
							isMobileUiV2Enabled={isMobileUiV2Enabled}
						/>
					</Grid>
				))}
			</Grid>
		</Grid>
	};

	const renderRemoveMenuItem = () => {
		return (
			<MenuItem
				onClick={() => {
					onRemove(getValues().timesheetEntries[index], index);
					handleClose();
				}}
				id={`timesheet-entry--${index}--menu--list-item--remove`}
				className={classes.actionRemoveShiftButton}
			>
				<Grid container alignItems='center' spacing={2}>
					<Grid item className={classes.menuIcon}>
						<DeleteOutline
							fontSize='small'
							className={classes.menuIconRemove}
						/>
					</Grid>
					<Grid item>
						<Typography variant='body2'>Remove</Typography>
					</Grid>
				</Grid>
			</MenuItem>
		)
	};

	const renderCallBackConnector = () => {
		if (!isCallBackEntry)
			return null;

		return <Grid item className={classes.connectorGrid}>
			<div className={classes.midCallBackConnectorContainer}>
				<div className={classes.midCallBackConnector}></div>
			</div>
			{isLastCallBackEntry ? null : <div className={classes.endCallBackConnectorContainer}><div className={classes.endCallBackConnector}></div></div>}
		</Grid>
	};

	const getEntryCssClass = () => {
		if (isCallWithCallBack)
			return classes.callWithCallbackEntry;

		if (isCallBackEntry)
			return isLastCallBackEntry ? classes.lastCallbackEntry : classes.callbackEntry;

		return null;
	};

	const getEntryContainerCssClass = () => {
		if (isCallWithCallBack)
			return classes.callWithCallbackEntryContainer;

		if (isCallBackEntry)
			return isLastCallBackEntry ? classes.lastCallbackEntryContainer : classes.callbackEntryContainer;

		return classes.entryContainer;
	};

	const renderMobileEntryHeader = () => {
		if (isCallBackEntry)
			return (
				<Grid className={classes.callbackMobileHeader} item xs={12}>
					<Divider />
					<h4 className={classes.callbackMobileHeaderText}>Callback</h4>
				</Grid>
			);

		return (<h4 className={classes.shiftMobileHeader}>{transactionDateLabel} - {locationDisplayName}</h4>);
	};

	return (
		<Grid
			className={getEntryCssClass()}
			xs={12}
			item
			key={key}>
			<Hidden
				only={
					isPaidApproved || adminAuth?.isLTAssociateUser || isMobileUiV2Enabled
						? ['xs', 'sm', 'md', 'lg', 'xl']
						: ['md', 'lg', 'xl']
				}
			>
				<div className={classes.mobileMenuButton}>
					<IconButton
						id={`timesheet-entry--${index}--menu`}
						color='default'
						aria-label='More options'
						onClick={handleClick}
						disabled={isReadOnly ? true : false}
					>
						<MoreHoriz />
					</IconButton>
					<Menu
						id='timesheetEntry-menu'
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						{renderBreakMenuItem()}
						{renderCopyADayMenuItem()}
						{renderRemoveMenuItem()}
						{renderCallBackMenuItem()}
					</Menu>
					{renderCopyADayMenu()}
				</div>
			</Hidden>
			<Grid
				container
				direction='column'
				spacing={1}
				className={getEntryContainerCssClass()}
			>
				<Grid item>
					<Grid container
						className={classes.entryRow}
						id={`timesheet-entry--${index}`}>
						{renderMobileEntryHeader()}
						<Controller
							name={`timesheetEntries[${index}].id`}
							defaultValue={timesheetEntry.id}
						/>
						<Controller
							name={`timesheetEntries[${index}].relatedEntryId`}
							defaultValue={timesheetEntry.relatedEntryId}
						/>
						
							<Grid
								className={classes.entryCell}
								item
								xs={isMobileUiV2Enabled ? 12 : 4}
								md={2}
								lg={1}>
								<InputLabel shrink classes={{ root: classes.inputLabel }}>
									Date
							</InputLabel>
							<TimesheetEntryTransitionOverlay id={`timesheet-Transition--${index}`} doTransition={isMobileUiV2Enabled && doTransition}>
								<Controller
									name={`timesheetEntries[${index}].transactionDate`}
									defaultValue={timesheetEntry.transactionDate}
									rules={{
										required: watchRateTypeId || watchComment ? 'Date is required' : false,
									}}
									render={({ onChange, value, ref }) => (
										<TextField
											id={`timesheet-entry--${index}--date`}
											onChange={(e) => {
												setTransactionDateLabel(getTransactionDateLabel(e.target.value));
												clearErrors(`timesheetEntries[${index}].transactionDate`);
												const startTimeError = errors?.timesheetEntries?.[index]?.startTime?.message;
												const endTimeError = errors?.timesheetEntries?.[index]?.endTime?.message;

												if (startTimeError === 'Timesheets cannot be submitted with shift or mileage dates in the future.') {
													clearErrors(`timesheetEntries[${index}].startTime`);
												}

												if (endTimeError === 'Timesheets cannot be submitted with shift or mileage dates in the future.') {
													clearErrors(`timesheetEntries[${index}].endTime`);
												}
												onChange(e);
											}}
											select
											value={value}
											className={`${classes.formControl} timesheet-entry--cell`}
											ref={ref}
											InputProps={{
												classes: {
													root: classes.inputRoot,
													disabled: classes.disabled,
													notchedOutline: classes.notchedOutline,
												},
											}}
											SelectProps={{
												classes: {
													icon: classes.expandMore,
													outlined: classes.selectOutlined,
													disabled: classes.disabled,
												},
												IconComponent: ExpandMore,
												displayEmpty: true,
												renderValue:
													value !== '' ? null : () => <Placeholder label='Date' />,
											}}
											variant='outlined'
											disabled={isReadOnly || isCallWithCallBack}
											inputProps={{ tabIndex: index === 0 ? '-1' : '0' }}
											error={
												errors['timesheetEntries']?.[index]?.transactionDate
													? true
													: false
											}
											helperText={
												errors['timesheetEntries']?.[index]?.transactionDate?.message
											}
										>
											{modifiedDateLookup.map((date, dateIndex) => (
												<MenuItem
													key={date.value}
													value={date.value}
													id={`timesheet-entry--${index}--date--list-item--${dateIndex}`}
												>
													{date.label}
												</MenuItem>
											))}
										</TextField>
									)}
								/>
							</TimesheetEntryTransitionOverlay>
							</Grid>
							<Grid className={classes.entryCell} item xs={isMobileUiV2Enabled ? 12 : 4} md={2} lg={3}>
								<InputLabel shrink classes={{ root: classes.inputLabel }}>
									Location
							</InputLabel>
								<TimesheetEntryTransitionOverlay id={`timesheet-Transition--${index}`} doTransition={isMobileUiV2Enabled && doTransition}>
								<Controller
									name={`timesheetEntries[${index}].locationId`}
									defaultValue={
										timesheetEntry.locationId
											? timesheetEntry.locationId
											: defaultLocationId
									}
									rules={{
										required:
											watchRateTypeId || watchComment
												? 'Work Location is required'
												: false,
									}}
									render={({ onChange, value, ref }) => (
										<TextField
											id={`timesheet-entry--${index}--work-location`}
											onChange={(e) => {
												setLocationDisplayName(getLocationDisplayName(e.target.value));
												clearErrors(`timesheetEntries[${index}].locationId`);
												onChange(e);
											}}
											select
											value={value}
											className={`${classes.formControl} timesheet-entry--cell`}
											ref={ref}
											InputProps={{
												classes: {
													root: classes.inputRoot,
													disabled: classes.disabled,
													notchedOutline: classes.notchedOutline,
												},
											}}
											SelectProps={{
												classes: {
													icon: classes.expandMore,
													outlined: classes.selectOutlined,
													disabled: classes.disabled,
												},
												IconComponent: ExpandMore,
												displayEmpty: true,
												renderValue:
													value !== ''
														? null
														: () => <Placeholder label='Location' />,
											}}
											variant='outlined'
											disabled={isReadOnly || isCallBackEntry || isCallWithCallBack}
											error={
												errors['timesheetEntries']?.[index]?.locationId ? true : false
											}
											helperText={
												errors['timesheetEntries']?.[index]?.locationId?.message
											}
										>
											{timesheet.booking.locations.map((location, locationIndex) => (
												<MenuItem
													key={location.id}
													value={location.id}
													disabled={readOnlyLocations.indexOf(location.id) > -1}
													id={`timesheet-entry--${index}--work-location--list-item--${locationIndex}`}
												>
													{location.displayName}
												</MenuItem>
											))}
										</TextField>
									)}
								/>
								</TimesheetEntryTransitionOverlay>
							</Grid>
							<Grid className={classes.entryCell} item xs={isMobileUiV2Enabled ? 12 : 4} md={2} lg={1}>
								<InputLabel shrink classes={{ root: classes.inputLabel }}>
									Shift type
							</InputLabel>
								<TimesheetEntryTransitionOverlay id={`timesheet-Transition--${index}`} doTransition={isMobileUiV2Enabled && doTransition}>
								<Controller
									name={`timesheetEntries[${index}].rateTypeId`}
									defaultValue={timesheetEntry.rateTypeId}
									rules={{
										required: watchComment ? 'Shift type is required' : false,
									}}
									render={({ onChange, value, ref }) => (
										<TextField
											id={`timesheet-entry--${index}--shift-type`}
											onChange={(e) => {
												clearErrors(`timesheetEntries[${index}].rateTypeId`);
												onChange(e);
											}}
											select
											value={value}
											className={`${classes.formControl} timesheet-entry--cell`}
											ref={ref}
											InputProps={{
												classes: {
													root: classes.inputRoot,
													disabled: classes.disabled,
													notchedOutline: classes.notchedOutline,
												},
											}}
											SelectProps={{
												classes: {
													icon: classes.expandMore,
													outlined: classes.selectOutlined,
													disabled: classes.disabled,
												},
												IconComponent: ExpandMore,
												displayEmpty: true,
												renderValue:
													value !== ''
														? null
														: () => <Placeholder label='Shift Type' />,
											}}
											variant='outlined'
											disabled={isReadOnly || isCallBackEntry || isCallWithCallBack || hasBreaks}
											error={
												errors['timesheetEntries']?.[index]?.rateTypeId ? true : false
											}
											helperText={
												errors['timesheetEntries']?.[index]?.rateTypeId?.message
											}
										>
											{timesheet.booking.availableRates
												.filter(item =>
													item.startDate <= moment(watchTransactionDate).format('YYYY-MM-DD') &&
													item.endDate >= moment(watchTransactionDate).format('YYYY-MM-DD') &&
													(isCallBackEntry ? item.displayName.includes("Call-Callback") : !item.displayName.includes("Call-Callback"))
												)
												.map((item, shiftTypeIndex) => (
													<MenuItem
														key={item.id}
														value={item.id}
														id={`timesheet-entry--${index}--shift-type--list-item--${shiftTypeIndex}`}
													>
														{item.displayName}
													</MenuItem>
												))}
											{!watchTransactionDate && (
												<MenuItem disabled>Please select a date</MenuItem>
											)}
										</TextField>
									)}
								/>
								</TimesheetEntryTransitionOverlay>
							</Grid>
							<Grid
								className={classes.entryCell}
								item
								xs={isMobileUiV2Enabled ? 12 : 4}
								md={1}
								hidden={isMobile && isMobileUiV2Enabled && !isUnitShift}>
								<InputLabel shrink classes={{ root: classes.inputLabel }}>
									Units
							</InputLabel>
								<TimesheetEntryTransitionOverlay id={`timesheet-Transition--${index}`} doTransition={isMobileUiV2Enabled && doTransition}>
								<Controller
									name={`timesheetEntries[${index}].units`}
									defaultValue={timesheetEntry.units}
									rules={{
										required:
											rateTypeCategory === RateTypeCategoryEnum.Units ||
												rateTypeCategory === RateTypeCategoryEnum.TeleUnits
												? 'Units are required'
												: false,
									}}
									render={({ onChange, value, ref }) => (
										<TextField
											id={`timesheet-entry--${index}--units`}
											className={`${classes.formControl} timesheet-entry--cell`}
											value={value}
											inputRef={ref}
											disabled={
												(rateTypeCategory !== RateTypeCategoryEnum.Units &&
													rateTypeCategory !== RateTypeCategoryEnum.TeleUnits) ||
												isReadOnly || hasBreaks
											}
											onChange={(e) => {
												clearErrors(`timesheetEntries[${index}].units`);
												const newValue = validateUnits(e.target.value);
												if (newValue || newValue === '') {
													onChange(newValue);
												}
											}}
											InputProps={{
												classes: {
													input: classes.input,
													root: classes.inputRoot,
													disabled: classes.disabled,
													notchedOutline: classes.notchedOutline,
												},
											}}
											variant='outlined'
											error={errors['timesheetEntries']?.[index]?.units ? true : false}
											helperText={errors['timesheetEntries']?.[index]?.units?.message}
											placeholder='Units'
										/>
									)}
								/>
								</TimesheetEntryTransitionOverlay>
							</Grid>
							<Grid
								className={classes.entryCell}
								item
								xs={isMobileUiV2Enabled ? 6 : 4}
								md={1}
								hidden={isMobile && isMobileUiV2Enabled && isUnitShift}>
								<InputLabel shrink classes={{ root: classes.inputLabel }}>
									Start
							</InputLabel>
								<TimesheetEntryTransitionOverlay id={`timesheet-Transition--${index}`} doTransition={isMobileUiV2Enabled && doTransition}>
								<Controller
									name={`timesheetEntries[${index}].startTime`}
									defaultValue={timesheetEntry.startTime}
									rules={{
										required:
											watchRateTypeId &&
												(rateTypeCategory === RateTypeCategoryEnum.Hours ||
													rateTypeCategory === RateTypeCategoryEnum.TeleHours)
												? 'Start time is required'
												: false,
									}}
									render={({ onChange, value, ref }) => (
										<TextField
											id={`timesheet-entry--${index}--start-time`}
											type='time'
											className={`${classes.formControl} timesheet-entry--cell`}
											value={value}
											disabled={
												!watchRateTypeId ||
												(rateTypeCategory !== RateTypeCategoryEnum.Hours &&
													rateTypeCategory !== RateTypeCategoryEnum.TeleHours) ||
												isReadOnly
											}
											onChange={(e) => {
												clearErrors(`timesheetEntries[${index}].startTime`);
												onChange(e);
											}}
											onKeyDown = {(e) => {
												if (e.code === 'Space') e.preventDefault()
											}}
											inputRef={ref}
											InputProps={{
												classes: {
													input: classes.input,
													root: classes.inputRoot,
													disabled: classes.disabled,
													notchedOutline: classes.notchedOutline,
												},
											}}
											inputProps={{
												step: 300, // 5 min
											}}
											variant='outlined'
											error={
												errors['timesheetEntries']?.[index]?.startTime ? true : false
											}
											helperText={errors['timesheetEntries']?.[index]?.startTime?.message}
										/>
									)}
								/>
								</TimesheetEntryTransitionOverlay>
							</Grid>
							<Grid
								className={classes.entryCell}
								item
								xs={isMobileUiV2Enabled ? 6 : 4}
								md={1}
								hidden={isMobile && isMobileUiV2Enabled && isUnitShift}>
								<InputLabel shrink classes={{ root: classes.inputLabel }}>
									End
							</InputLabel>
								<TimesheetEntryTransitionOverlay id={`timesheet-Transition--${index}`} doTransition={isMobileUiV2Enabled && doTransition}>
								<Controller
									name={`timesheetEntries[${index}].endTime`}
									defaultValue={timesheetEntry.endTime}
									rules={{
										required:
											watchRateTypeId &&
												(rateTypeCategory === RateTypeCategoryEnum.Hours ||
													rateTypeCategory === RateTypeCategoryEnum.TeleHours)
												? 'End time is required'
												: false,
									}}
									render={({ onChange, value, ref }) => (
										<TextField
											id={`timesheet-entry--${index}--end-time`}
											type='time'
											className={`${classes.formControl} timesheet-entry--cell`}
											value={value}
											inputRef={ref}
											disabled={
												!watchRateTypeId ||
												(rateTypeCategory !== RateTypeCategoryEnum.Hours &&
													rateTypeCategory !== RateTypeCategoryEnum.TeleHours) ||
												isReadOnly
											}
											onChange={(e) => {
												clearErrors(`timesheetEntries[${index}].endTime`);
												onChange(e);
											}}
											onKeyDown = {(e) => {
												if (e.code === 'Space') e.preventDefault()
											}}
											InputProps={{
												classes: {
													input: classes.input,
													root: classes.inputRoot,
													disabled: classes.disabled,
													notchedOutline: classes.notchedOutline,
												},
											}}
											inputProps={{
												step: 300, // 5 min
											}}
											variant='outlined'
											error={errors['timesheetEntries']?.[index]?.endTime ? true : false}
											helperText={errors['timesheetEntries']?.[index]?.endTime?.message}
										/>
									)}
								/>
								</TimesheetEntryTransitionOverlay>
							</Grid>
							<Grid
								className={classes.entryCell}
								item
								xs={isMobileUiV2Enabled ? 12 : 4}
								md={1}
								hidden={isMobile && isMobileUiV2Enabled && isUnitShift}>
								<InputLabel shrink classes={{ root: classes.inputLabel }}>
									Hours
							</InputLabel>
								<TimesheetEntryTransitionOverlay id={`timesheet-Transition--${index}`} doTransition={isMobileUiV2Enabled && doTransition}>
								<Controller
									name={`timesheetEntries[${index}].totalTimeInHours`}
									defaultValue={timesheetEntry.totalTimeInHours ?? ""}
									render={({ ref }) => (
										<TextField
											id={`timesheet-entry--${index}--totalTimeInHours`}
											className={`${classes.formControl} timesheet-entry--cell`}
											value={entryHours}
											inputRef={ref}
											disabled={true}
											InputProps={{
												classes: {
													input: classes.input,
													root: classes.inputRoot,
													disabled: classes.disabled,
													notchedOutline: classes.notchedOutline,
												},
											}}
											variant='outlined'
											error={errors['timesheetEntries']?.[index]?.totalTimeInHours ? true : false}
											helperText={errors['timesheetEntries']?.[index]?.totalTimeInHours?.message}
											placeholder='Hours'
										/>
									)}
								/>
								</TimesheetEntryTransitionOverlay>
							</Grid>
							<Grid container
								xs={isMobileUiV2Enabled ? 12 : 3}
								md={3}
								className={classes.endRowContainer}>
								<Grid
									item
									xs={isCallBackEntry ? 8 : 10}
									classes={{ root: classes.commentsInput }}
									className={classes.entryCell}
								>
									<InputLabel shrink classes={{ root: classes.inputLabel }}>
										Comments
								</InputLabel>
									<TimesheetEntryTransitionOverlay id={`timesheet-Transition--${index}`} doTransition={isMobileUiV2Enabled && doTransition}>
									<WarningController
										name={`timesheetEntries[${index}].comment`}
										defaultValue={timesheetEntry.comment}
										render={({ onChange, value, ref, warning }) => (
											<TextField
												id={`timesheet-entry--${index}--comments`}
												inputProps={{ maxLength: 255 }}
												className={ (warning && isShowWarning) ? `${classes.formControlOrange} timesheet-entry--cell` : `${classes.formControl} timesheet-entry--cell`}
												value={value}
												inputRef={ref}
												onChange={(e) => {
													clearErrors(`timesheetEntries[${index}].comment`);
													onChange(e);
												}}
												InputProps={{
													classes: {
														input: classes.input,
														root: classes.inputRoot,
														disabled: classes.disabled,
														notchedOutline: classes.notchedOutline,
													},
												}}
												variant='outlined'
												disabled={isReadOnly}
												placeholder='Comments'
												minRows={isMobile && isMobileUiV2Enabled ? 2 : 1}
												multiline={isMobile && isMobileUiV2Enabled}
											/>
										)}
									/>
									</TimesheetEntryTransitionOverlay>
								</Grid>
								<Grid item className={classes.menuGrid}>
									<Hidden
										only={
											isPaidApproved || adminAuth?.isLTAssociateUser
												? ['xs', 'sm', 'md', 'lg', 'xl']
												: ['xs', 'sm']
										}
									>
										<div
											className={`${classes.menuButtonCell} ${isReadOnly ? classes.disabledBackgroundColor : ''
												}`}
										>
											<IconButton
												id={`timesheet-entry--${index}--menu`}
												className={classes.menuButton}
												color='default'
												aria-label='More options'
												onClick={handleClick}
												disabled={isReadOnly ? true : false}
											>
												<MoreVert />
											</IconButton>
											<Menu
												id='timesheetEntry-menu'
												anchorEl={anchorEl}
												keepMounted
												open={Boolean(anchorEl)}
												onClose={handleClose}
											>
												{renderBreakMenuItem()}
												{renderCopyADayMenuItem()}
												{renderRemoveMenuItem()}
												{renderCallBackMenuItem()}
											</Menu>
											{renderCopyADayMenu()}
										</div>
									</Hidden>
								</Grid>
								{renderCallBackConnector()}
							</Grid>
						
					</Grid>
				</Grid>
				{renderBreaks()}
				<Grid item xs={12} hidden={!isMobile || !isMobileUiV2Enabled || !isCallBackEntry || isPaidApproved || isReadOnly}>
					<Button
						variant='outlined'
						color='secondary'
						classes={{ root: classes.callbackDeleteButton }}
						onClick={() => {
							onRemove(getValues().timesheetEntries[index], index);
							handleClose();
						}} >
						Delete Callback
					</Button>
				</Grid>
				<Grid item className={classes.mobileMenuGrid}>
					<div
						className={`${classes.mobileMenuButtonCell} ${isReadOnly ? classes.disabledBackgroundColor : ''}`}
					>
						<Grid item xs={12} hidden={!isMobile || !isMobileUiV2Enabled || isCallWithCallBack || (isCallBackEntry && !isLastCallBackEntry) || isPaidApproved || isReadOnly}>
							<Divider hidden={!isCallBackEntry && !hasBreaks} />
							<Button
								variant='contained'
								classes={{ root: (isCallBackEntry || hasBreaks) ? classes.shiftWithChildActionButton : classes.shiftActionButton }}
								onClick={handleClick} >
								Shift Actions
							</Button>
						</Grid>
						<Menu
							id='timesheetEntryMobile-menu'
							anchorEl={anchorEl}
							anchorOrigin={isMobile && isMobileUiV2Enabled ? { vertical: 'center', horizontal: 'center', } : undefined}
							transformOrigin={isMobile && isMobileUiV2Enabled ? { vertical: 'top', horizontal: 'center', } : undefined}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}
						>
							{renderBreakMenuItem()}
							{renderCopyADayMenuItem()}
							{renderRemoveMenuItem()}
							{renderCallBackMenuItem()}
						</Menu>
						{renderCopyADayMenu()}
					</div>
				</Grid>
				<Grid item xs={12} hidden={!isMobile || !isMobileUiV2Enabled || isCallWithCallBack || (isCallBackEntry && !isLastCallBackEntry) || isPaidApproved || isReadOnly}>
					<Button
						variant='contained'
						classes={{ root: classes.shiftDeleteButton }}
						onClick={() => {
							const entries = getValues().timesheetEntries;
							const shiftIndex = isCallBackEntry ? entries.findIndex(element => element.id === entries[index].relatedEntryId) : index;
							onRemove(entries[shiftIndex], shiftIndex);
							handleClose();
						}} >
						Delete Shift
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
}

TimesheetEntry.propTypes = {
	timesheetEntry: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		timesheetId: PropTypes.number,
		locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		rateTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		relatedEntryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		orderDateId: PropTypes.number,
		units: PropTypes.string,
		startTime: PropTypes.string,
		endTime: PropTypes.string,
		totalTimeInHours: PropTypes.number,
		comment: PropTypes.string,
		onEntryRowDateChange: PropTypes.func,
		timesheetBreaks: PropTypes.array,
	}).isRequired,
};

export default WithContexts(withRouter(withTranslation()(TimesheetEntry)));
