import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import ExpenseDatePicker from './ExpenseDatePicker';
import moment from 'moment';
import { ExpenseStatusValuesEnum } from './ExpenseStatus.enum';

const ExpensePayDate = (props) => {
    const { index, expense, mode, isPosted, isPendingSubmission, isViewOnly} = props;
    const { control, watch, errors, setValue, trigger, clearErrors } = useFormContext();
    const minDate = moment(new Date()).startOf('day');
    const maxDate = moment(new Date()).startOf('day').add(30, 'days');
    const billType = watch(`expenseEntries[${index}].billType`);
    const isClinicianDeduction = billType === 'ClinicianDeduction';
    const [required, setRequired] = useState({ required: true });
    const handleDateChange = (e) =>
        e && e.startOf('day') ? e.startOf('day').format('YYYY-MM-DD') : e;

    useEffect(() => {
        setRequired({
            required: (mode !== 'cc'
                || (mode === 'cc' && isClinicianDeduction && expense?.status === ExpenseStatusValuesEnum.Approved))
        });
    }, [expense?.status]);


    return (
        <Grid item xs={12} md={6}>
            <Controller
                control={control}
                name={`expenseEntries[${index}].payDate`}
                defaultValue={expense.payDate || null}
                rules={isPosted ? {} : {
                    required: required.required ? 'Pay Date is required. Please enter a valid date.' : false,
                    validate: {
                        invalid: (value) => !value || moment(value, 'YYYY-MM-DD', true).isValid() || 'Invalid date',
                        maxDate: (value) => !value || moment(value, 'YYYY-MM-DD', true).isSameOrBefore(maxDate) || 'Max date reached',
                        minDate: (value) => !value || moment(value, 'YYYY-MM-DD', true).isSameOrAfter(minDate) || 'Min date reached',
                    },
                }}
                render={({ onChange, value }) => (
                    <ExpenseDatePicker
                        onChange={(e) => onChange(handleDateChange(e))}
                        value={value}
                        id='pay-date'
                        label='Pay date'
                        {...required}
                        disabled={isViewOnly || isPosted || isPendingSubmission}
                        minDate={minDate}
                        maxDate={maxDate}
                        error={Boolean(errors.expenseEntries?.[index]?.payDate)}
                        helperText={errors.expenseEntries?.[index]?.payDate?.message}
                    />
                )}
            />
        </Grid>
    );
};

export default ExpensePayDate;
