import React, { useState, useEffect } from 'react';
import { COLORS } from '../../../../../utils/Application_Constants';
import { Grid, Box, makeStyles, CircularProgress } from '@material-ui/core';
import { getLicensure } from '../../../../../services/Credentialing';
import moment from 'moment';
import { useForm, useFieldArray } from 'react-hook-form';
import { convertToDateIgnoringTime, stateAcronymToFullName } from '../../../../../utils/helpers';
import _ from 'lodash';
import ViewMoreButton from './ViewMoreButton';

const styles = makeStyles((theme) => ({
	header: {
		// color: COLORS.BLACK_MARLIN,
		// [theme.breakpoints.down('sm')]: {
		//     padding: '15px 0 0 15px',
		// },
		// textAlign: 'left',
		// paddingLeft: '32px',
		// paddingTop: '16px',
		// fontSize: '22px',
		// fontWeight: '700',
	},
	root: {
		padding: '20px 0',
		minHeight: '800px',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '60px',
			marginTop: '25px',
		},
		maxWidth: '100%',
		//backgroundColor: COLORS.BLACK_HAZE,
	},
	helpPanelContainerRoot: {
		padding: '15px',
		display: 'flex',
		flexWrap: 'wrap',
		[theme.breakpoints.down('sm')]: {
			padding: '0px',
		},
	},
	linkHeader: {
		color: COLORS.DENIM,
		textDecoration: 'none',
		display: 'flex',
		fontWeight: '700',
		fontSize: '22px',
		margin: 0,
		textAlign: 'left',
	},
	sectionStyle: {
		color: COLORS.DENIM,
		fontWeight: '700',
		display: 'flex',
	},
	info: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '16px',
		textAlign: 'left',
	},
	columnInfoLeft: {
		// color: COLORS.RAVEN,
		// fontWeight: '500',
		// fontSize: '16px',
		// textAlign: 'left',
		// margin: '20px 0 10px 0',
		// [theme.breakpoints.down('sm')]: {
		//     fontSize: '14px',
		// }
	},
	columnInfoCenter: {
		// color: COLORS.RAVEN,
		// fontWeight: '500',
		// fontSize: '16px',
		// textAlign: 'left',
		// margin: '20px 0 10px 0',
		// [theme.breakpoints.down('sm')]: {
		//     fontSize: '14px',
		// }
	},
	columnInfoRight: {
		// color: COLORS.RAVEN,
		// fontWeight: '500',
		// fontSize: '16px',
		// textAlign: 'right',
		// margin: '20px 0 10px 0',
		// [theme.breakpoints.down('sm')]: {
		//     fontSize: '14px',
		// }
	},
	lifeTime: {
		marginRight: '29px',
	},
	tableHeader: {
		borderBottom: `1px solid ${COLORS.LT_SLATE10}`,
		marginBottom: '1rem',
	},
	columnHeaderLeft: {
		// color: COLORS.BLACK_MARLIN,
		// fontWeight: '900',
		// fontSize: '16px',
		// textAlign: 'left',
		// marginTop: '20px',
		// borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
		// [theme.breakpoints.down('sm')]: {
		//     fontSize: '14px',
		// }
	},
	columnHeaderCenter: {
		color: COLORS.BLACK_MARLIN,
		fontWeight: '900',
		fontSize: '16px',
		textAlign: 'left',
		marginTop: '20px',
		borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
		[theme.breakpoints.down('sm')]: {
			fontSize: '14px',
		},
	},
	columnHeaderRight: {
		color: COLORS.BLACK_MARLIN,
		fontWeight: '900',
		fontSize: '16px',
		textAlign: 'left',
		marginTop: '20px',
		borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
		[theme.breakpoints.down('sm')]: {
			fontSize: '14px',
		},
	},
	noMaxWidth: {
		maxWidth: 'none',
	},
	topTileRoot: {
		marginTop: '30px',
		height: '85%',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	expirationDate: {
		textAlign: 'right',
	},
	expirationDateHeader: {
		fontWeight: '900',
		fontSize: '12px',
		whiteSpace: 'nowrap',
		textTransform: 'uppercase',
		textAlign: 'right',
	},
	iconAlign: {
		paddingLeft: '110px',
		paddingbottom: '20px',
	},
	dateInfoGrid: {
		display: 'grid',
		width: '75px',
		gridTemplateColumns: 'repeat(auto-fit, minmax(30px, 1fr))',
		[theme.breakpoints.down('sm')]: {
			width: '50px',
			gridTemplateColumns: 'repeat(auto-fit, minmax(25px, 1fr))',
		},
	},

	dateItemColumn: {
		textAlign: 'center',
	},
	iconIndicator: {
		textAlign: 'left',
	},
}));

function LicenseOverviewCard({
	t,
	UserContext,
	setCredAppError,
	isLoaded,
	setIsLoaded,
	renderIndicatorColor,
}) {
	const classes = styles();
	const [viewMore, setViewMore] = useState(false);

	const toggleViewMore = () => {
		setViewMore(!viewMore);
	};

	const { control, reset } = useForm({
		mode: 'all',
		defaultValues: {
			id: null,
			state: '',
			licenseType: null,
			dateExpired: null,
		},
	});

	const { fields: licensuresFields, append: licensuresAppend } = useFieldArray({
		control: control,
		name: 'licensures',
		keyName: 'internalId',
	});

	useEffect(() => {
		(async function fetchData() {
			const { externalId } = UserContext;

			await getLicensure(externalId)
				.then(function (response) {
					console.log('license response ', response);
					const { licensures = [] } = response;

					if (licensures.length === 0) {
						licensuresAppend({
							id: null,
							state: '',
							licenseType: false,
							dateExpired: null,
						});
						response['licensures'].push({
							id: null,
							state: '',
							licenseType: false,
							dateExpired: null,
						});
					} else {
						response['licensures'].map((item) => {
							if (item.state === null) {
								item.state = '';
							}

							return item;
						});
					}
					reset(response);
					setIsLoaded(true);
				})
				.catch((e) => {
					setCredAppError(e);
				});
		})();
	}, []);

	const filterLicensures = (licensuresFields) => {
		const filterOldLicensures = licensuresFields.filter((item) => {
			if (item.dateExpired !== null) {
				const today = moment(moment());
				const expirationDate = moment(item.dateExpired);

				if (today.diff(expirationDate, 'years', false) < 5) {
					return item;
				}
			}
		});

		let finalSpecialtiesList = _.orderBy(
			filterOldLicensures,
			[(obj) => new Date(obj.dateExpired)],
			['asc']
		);

		return finalSpecialtiesList;
	};

	const renderLicenseItems = () => {
		let finalLicensuresList = filterLicensures(licensuresFields);

		if (!viewMore) {
			finalLicensuresList = finalLicensuresList.slice(0, 4);
		}

		if (!isLoaded) {
			return (
				<div className={classes.progress}>
					<CircularProgress />
				</div>
			);
		} else {
			return (
				<Grid container space={2} alignItems='center'>
					{finalLicensuresList.map((item) => (
						<>
							<Grid item xs={4} md={4}>
								<p>{stateAcronymToFullName(item.state)}</p>
							</Grid>
							<Grid item xs={4} md={4}>
								<p>{item.licenseType}</p>
							</Grid>
							<Grid item xs={4} md={4}>
								<p>
									{renderIndicatorColor(item.expirationDate)}{' '}
									{convertToDateIgnoringTime(item.dateExpired).format(
										'MM/DD/YYYY'
									)}
								</p>
							</Grid>
						</>
					))}
				</Grid>
			);
		}
	};

	const renderLicenses = () => {
		let finalLicensuresList = filterLicensures(licensuresFields);

		return (
			<Box mb={4}>
				<Grid container>
					<Grid item xs={12}>
						<h3>
							{t('credentials:CRED_OVERVIEW.LICENSES_TITLE')}
						</h3>
						<p>{t('credentials:CRED_OVERVIEW.LICENSES_INFO')}</p>
					</Grid>

					<Grid container direction='row' className={classes.tableHeader}>
						<Grid item xs={4} md={4}>
							<h6 className='cr-mb-0'>
								{t('credentials:CRED_OVERVIEW.LICENSE_STATE')}
							</h6>
						</Grid>
						<Grid item xs={4} md={4}>
							<h6 className='cr-mb-0'>{t('credentials:CRED_OVERVIEW.TYPE')}</h6>
						</Grid>
						<Grid item xs={4} md={4}>
							<h6 className='cr-mb-0'>
								{t('credentials:CRED_OVERVIEW.EXPIRATION_DATE')}
							</h6>
						</Grid>
					</Grid>

					<Grid container direction='row'>
						<Grid item xs={12} md={12}>
							{renderLicenseItems()}
						</Grid>
						{finalLicensuresList.length > 4 ? (
							<ViewMoreButton
								t={t}
								viewMore={viewMore}
								toggleViewMore={toggleViewMore}
							/>
						) : null}
					</Grid>
				</Grid>
			</Box>
		);
	};

	return <>{renderLicenses()}</>;
}

export default LicenseOverviewCard;
