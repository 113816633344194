const COLORS = {
	LT_JOURNEY: '#0077C8',
	LT_JOURNEY75: '#4099d6',
	LT_JOURNEY50: '#7fbae3',
	LT_JOURNEY25: '#BFDDF1',
	LT_JOURNEY10: '#e5f1fa',

	LT_FOUNDATION: '#326295',

	LT_EMERALD: '#009639',
	LT_EMERALD75: '#40b06a',
	LT_EMERALD50: '#7fca9b',
	LT_EMERALD25: '#bfe5cd',
	LT_EMERALD10: '#e5f4eb',

	LT_POMEGRANATE: '#8A1B61',
	LT_POMEGRANATE75: '#a75488',
	LT_POMEGRANATE50: '#c48caf',
	LT_POMEGRANATE25: '#e2c5d7',
	LT_POMEGRANATE10: '#f3e7ef',

	LT_PEPPER: '#ea3219',
	LT_PEPPER75: '#ef6552',
	LT_PEPPER50: '#f4988b',
	LT_PEPPER25: '#facbc5',
	LT_PEPPER10: '#fdeae7',

	LT_SUNFLOWER: '#FFC56E',
	LT_SUNFLOWER75: '#ffd392',
	LT_SUNFLOWER50: '#ffe2b6',
	LT_SUNFLOWER25: '#fff1da',
	LT_SUNFLOWER10: '#fff9f0',

	LT_MIDNIGHT: '#2a3953',
	LT_MIDNIGHT5: '#EEF0F1',
	LT_MIDNIGHT10: '#E9EBED',
	LT_MIDNIGHT25: '#CACDD3',
	LT_MIDNIGHT33: '#ABB0BA',
	LT_MIDNIGHT50: '#959BA7',
	LT_MIDNIGHT75: '#5F6A7E',
	LT_MIDNIGHTBG1: '#F6F7F8',
	LT_MIDNIGHTBG2: '#F9FAFB',
	LT_MIDNIGHTSTEEL: '#D9DFE5',
	
	LT_WARNING: '#ed6c02',

	LT_SLATE: '#596579',
	LT_SLATE10: '#eeeff1',

	LT_STEEL: '#88909f',
	LT_TEST: '#B1FF00',

	DENIM: '#0074C8',
	MARINER: '#3F5F90',
	WHITE: '#FFFFFF',
	BLACK_HAZE: '#F7F7F7',
	ROMANCE: '#FEFEFE',
	CONCRETE: '#F2F2F2',
	WHITE_LILAC: '#E7E7E7',
	SLATE_GRAY: '#767B8F',
	RAVEN: '#6D707E',
	BEAU_BLUE: '#BED3E4',
	NERO: '#242526',
	GRAY_SUIT: '#91939D',
	DARK_RASPBERRY: '#83265E',
	WATERLOO: '#757b91',
	ST_TROPAZ: '#356094',
	TRUE_BLUE: '#0077C8',
	LILY_WHITE: '#eaeaea',
	KELLY_GREEN: '#009639',
	SEASHELL: '#f1f1f1',
	ZIRCON: '#e0e0e0',
	WILD_SAND: '#f4f4f4',
	MILANO_RED: '#EA3219',
	FOLLY: '#ff0156',
	BOMBAY: '#afafaf',
	LIGHT_BLUE: '#B9D4E6',
	LIGHT_BLUE_TRANSPARENT: '#32629527',
	ASTRAL: '#396789',
	MYSTIC: '#dfebf5',
	GLITTER: '#e8f0f9',
	RICH_ELECTRIC_BLUE: '#0089D1',
	AQUA_SPRING: '#e8f5e9',
	PIGNMENT_GREEN: '#00a757',
	BLACK_MARLIN: '#3b3b3e',
	PERSIAN_RED: '#DB3535',
	TUTU: '#F5E3E3',
	STORM_GREY: '#7074B3',
	DESERT_STORM: '#ECEBEA',
	LIGHT_YELLOW: '#FFFDD9',
	BROWN_COLOR: '#98743D',
	LIGHT_GRAY: '#eeeeee',
	DARK_BLUE: '#336195',
	STORMY_GRAY: '#949BA8',
};
const APP_CONFIG = {
	PAGINATION_LIMIT: 6,
};

const MSAL_CONFIG = {
	auth: {
		clientId: process.env.REACT_APP_CLIENT_ID, //'37baed04-e2c1-4903-8e0d-d9e1b6c0cb44', //
		authority: process.env.REACT_APP_AUTHORITY, //'https://jhcloudlocumtenensdotcom.b2clogin.com/tfp/jhcloudlocumtenensdotcom.onmicrosoft.com/B2C_1_SI', //
		validateAuthority: false,
		redirectUri: window.location.origin,
		postLogoutRedirectUri: window.location.origin,
		navigateToLoginRequestUrl: false,
	},
	cache: {
		cacheLocation: 'localStorage',
		storeAuthStateInCookie: false,
	},
	system: {
		tokenRenewalOffsetSeconds: 1440,
		loadFrameTimeout: 12000,
		navigateFrameWait: 1000,
	},
};

const DOCUMENT_TYPES = {
	// COI: 'Certificate Of Insurance', //Old doc type for COI
	COI: 'Insurance/Malpractice Carrier',
	ADDENDUM: 'Provider Addendum',
	W9: 'W9',
	DD: 'Direct Deposit',
	TRAVEL: 'Travel Packets/Information',
	AGREEMENT: 'Provider Agreement',
};

const ACTIVE_PATHNAME_MD = 'MD';
const ACTIVE_PATHNAME_AP = 'AP';

const PROFESSIONS_MD = ['Dentist', 'Physician', 'Podiatrist'];

const PROFESSIONS_AP = ['CNM', 'CNS', 'CRNA', 'NP', 'PA', 'Psychologist', 'SFA', 'Perfusionist'];

const COUNTRIES = [
	'United States',
	'Afghanistan',
	'Aland Islands',
	'Albania',
	'Algeria',
	'Andorra',
	'Angola',
	'Anguilla',
	'Antarctica',
	'Antigua and Barbuda',
	'Argentina',
	'Armenia',
	'Aruba',
	'Australia',
	'Austria',
	'Azerbaijan',
	'Bahamas',
	'Bahrain',
	'Bangladesh',
	'Barbados',
	'Belarus',
	'Belgium',
	'Belize',
	'Benin',
	'Bermuda',
	'Bhutan',
	'Bolivia, Plurinational State of',
	'Bonaire, Sint Eustatius and Saba',
	'Bosnia and Herzegovina',
	'Botswana',
	'Bouvet Island',
	'Brazil',
	'British Indian Ocean Territory',
	'Brunei Darussalam',
	'Bulgaria',
	'Burkina Faso',
	'Burundi',
	'Cambodia',
	'Cameroon',
	'Canada',
	'Cape Verde',
	'Cayman Islands',
	'Central African Republic',
	'Chad',
	'Chile',
	'China',
	'Christmas Island',
	'Cocos (Keeling) Islands',
	'Colombia',
	'Comoros',
	'Congo',
	'Congo, the Democratic Republic of the',
	'Cook Islands',
	'Costa Rica',
	'Cote d’Ivoire',
	'Croatia',
	'Cuba',
	'Curaçao',
	'Cyprus',
	'Czech Republic',
	'Denmark',
	'Djibouti',
	'Dominica',
	'Dominican Republic',
	'Ecuador',
	'Egypt',
	'El Salvador',
	'Equatorial Guinea',
	'Eritrea',
	'Estonia',
	'Ethiopia',
	'Falkland Islands (Malvinas)',
	'Faroe Islands',
	'Fiji',
	'Finland',
	'France',
	'French Guiana',
	'French Polynesia',
	'French Southern Territories',
	'Gabon',
	'Gambia',
	'Georgia',
	'Germany',
	'Ghana',
	'Gibraltar',
	'Greece',
	'Greenland',
	'Grenada',
	'Guadeloupe',
	'Guatemala',
	'Guernsey',
	'Guinea',
	'Guinea-Bissau',
	'Guyana',
	'Haiti',
	'Heard Island and McDonald Islands',
	'Holy See (Vatican City State)',
	'Honduras',
	'Hungary',
	'Iceland',
	'India',
	'Indonesia',
	'Iran, Islamic Republic of',
	'Iraq',
	'Ireland',
	'Isle of Man',
	'Israel',
	'Italy',
	'Jamaica',
	'Japan',
	'Jersey',
	'Jordan',
	'Kazakhstan',
	'Kenya',
	'Kiribati',
	'Korea, Democratic People’s Republic of',
	'Korea, Republic of',
	'Kuwait',
	'Kyrgyzstan',
	'Lao People’s Democratic Republic',
	'Latvia',
	'Lebanon',
	'Lesotho',
	'Liberia',
	'Libyan Arab Jamahiriya',
	'Liechtenstein',
	'Lithuania',
	'Luxembourg',
	'Macao',
	'Macedonia, the former Yugoslav Republic of',
	'Madagascar',
	'Malawi',
	'Malaysia',
	'Maldives',
	'Mali',
	'Malta',
	'Martinique',
	'Mauritania',
	'Mauritius',
	'Mayotte',
	'Mexico',
	'Moldova, Republic of',
	'Monaco',
	'Mongolia',
	'Montenegro',
	'Montserrat',
	'Morocco',
	'Mozambique',
	'Myanmar',
	'Namibia',
	'Nauru',
	'Nepal',
	'Netherlands',
	'New Caledonia',
	'New Zealand',
	'Nicaragua',
	'Niger',
	'Nigeria',
	'Niue',
	'Norfolk Island',
	'Norway',
	'Oman',
	'Pakistan',
	'Palestine',
	'Panama',
	'Papua New Guinea',
	'Paraguay',
	'Peru',
	'Philippines',
	'Pitcairn',
	'Poland',
	'Portugal',
	'Qatar',
	'Reunion',
	'Romania',
	'Russian Federation',
	'Rwanda',
	'Saint Barthélemy',
	'Saint Helena, Ascension and Tristan da Cunha',
	'Saint Kitts and Nevis',
	'Saint Lucia',
	'Saint Martin (French part)',
	'Saint Pierre and Miquelon',
	'Saint Vincent and the Grenadines',
	'Samoa',
	'San Marino',
	'Sao Tome and Principe',
	'Saudi Arabia',
	'Senegal',
	'Serbia',
	'Seychelles',
	'Sierra Leone',
	'Singapore',
	'Sint Maarten (Dutch part)',
	'Slovakia',
	'Slovenia',
	'Solomon Islands',
	'Somalia',
	'South Africa',
	'South Georgia and the South Sandwich Islands',
	'South Sudan',
	'Spain',
	'Sri Lanka',
	'Sudan',
	'Suriname',
	'Svalbard and Jan Mayen',
	'Swaziland',
	'Sweden',
	'Switzerland',
	'Syrian Arab Republic',
	'Taiwan',
	'Tajikistan',
	'Tanzania, United Republic of',
	'Thailand',
	'Timor-Leste',
	'Togo',
	'Tokelau',
	'Tonga',
	'Trinidad and Tobago',
	'Tunisia',
	'Turkey',
	'Turkmenistan',
	'Turks and Caicos Islands',
	'Tuvalu',
	'Uganda',
	'Ukraine',
	'United Arab Emirates',
	'United Kingdom',
	'Uruguay',
	'Uzbekistan',
	'Vanuatu',
	'Venezuela, Bolivarian Republic of',
	'Vietnam',
	'Virgin Islands, British',
	'Wallis and Futuna',
	'Western Sahara',
	'Yemen',
	'Zambia',
	'Zimbabwe',
];

const specialtiesMD = [
	{
		value: 'Abdominal Radiology',
		label: 'Abdominal Radiology',
	},
	{
		value: 'Abdominal Surgery',
		label: 'Abdominal Surgery',
	},
	{
		value: 'Addiction Medicine',
		label: 'Addiction Medicine',
	},
	{
		value: 'Addiction Psychiatry',
		label: 'Addiction Psychiatry',
	},
	{
		value: 'Adolescent Medicine',
		label: 'Adolescent Medicine',
	},
	{
		value: 'Adult Reconstructive Orthopedics',
		label: 'Adult Reconstructive Orthopedics',
	},
	{
		value: 'Aerospace Medicine',
		label: 'Aerospace Medicine',
	},
	{
		value: 'Allergy',
		label: 'Allergy',
	},
	{
		value: 'Allergy/Immunology',
		label: 'Allergy/Immunology',
	},
	{
		value: 'Anatomic/Clinical Pathology',
		label: 'Anatomic/Clinical Pathology',
	},
	{
		value: 'Anatomic Pathology',
		label: 'Anatomic Pathology',
	},
	{
		value: 'Anesthesiology',
		label: 'Anesthesiology',
	},
	{
		value: 'Behavioral and Cognitive Psychology',
		label: 'Behavioral and Cognitive Psychology',
	},
	{
		value: 'Bloodbanking/Transfusion Medicine',
		label: 'Bloodbanking/Transfusion Medicine',
	},
	{
		value: 'Burn Surgery',
		label: 'Burn Surgery',
	},
	{
		value: 'Cardiac Electrophysiology',
		label: 'Cardiac Electrophysiology',
	},
	{
		value: 'Cardiothoracic Radiology',
		label: 'Cardiothoracic Radiology',
	},
	{
		value: 'Cardiovascular Diseases',
		label: 'Cardiovascular Diseases',
	},
	{
		value: 'Chemical Pathology',
		label: 'Chemical Pathology',
	},
	{
		value: 'Child & Adolescent Psychiatry',
		label: 'Child & Adolescent Psychiatry',
	},
	{
		value: 'Child Neurology',
		label: 'Child Neurology',
	},
	{
		value: 'Clinical/Laboratory Immunology',
		label: 'Clinical/Laboratory Immunology',
	},
	{
		value: 'Clinical & Lab Derm Immunology',
		label: 'Clinical & Lab Derm Immunology',
	},
	{
		value: 'Clinical Biochemical Genetics',
		label: 'Clinical Biochemical Genetics',
	},
	{
		value: 'Clinical Child and Adolescent Psychology',
		label: 'Clinical Child and Adolescent Psychology',
	},
	{
		value: 'Clinical Cytogenetics',
		label: 'Clinical Cytogenetics',
	},
	{
		value: 'Clinical Genetics',
		label: 'Clinical Genetics',
	},
	{
		value: 'Clinical Health Psychology',
		label: 'Clinical Health Psychology',
	},
	{
		value: 'Clinical Lab Immunology & Allergy',
		label: 'Clinical Lab Immunology & Allergy',
	},
	{
		value: 'Clinical Molecular Genetics',
		label: 'Clinical Molecular Genetics',
	},
	{
		value: 'Clinical Neurophysiology',
		label: 'Clinical Neurophysiology',
	},
	{
		value: 'Clinical Pathology',
		label: 'Clinical Pathology',
	},
	{
		value: 'Colon & Rectal Surgery',
		label: 'Colon & Rectal Surgery',
	},
	{
		value: 'Cosmetic Surgery',
		label: 'Cosmetic Surgery',
	},
	{
		value: 'Counseling Psychology',
		label: 'Counseling Psychology',
	},
	{
		value: 'Couple and Family Psychology',
		label: 'Couple and Family Psychology',
	},
	{
		value: 'Craniofacial Surgery',
		label: 'Craniofacial Surgery',
	},
	{
		value: 'Critical Care Medicine',
		label: 'Critical Care Medicine',
	},
	{
		value: 'Cytopathology',
		label: 'Cytopathology',
	},
	{
		value: 'Dermatologic Surgery',
		label: 'Dermatologic Surgery',
	},
	{
		value: 'Dermatology',
		label: 'Dermatology',
	},
	{
		value: 'Dermatopathology',
		label: 'Dermatopathology',
	},
	{
		value: 'Developmental-Behavioral Pediatrics',
		label: 'Developmental-Behavioral Pediatrics',
	},
	{
		value: 'Diabetes',
		label: 'Diabetes',
	},
	{
		value: 'Diagnostic Radiology',
		label: 'Diagnostic Radiology',
	},
	{
		value: 'Emergency Medicine',
		label: 'Emergency Medicine',
	},
	{
		value: 'Endocrinology',
		label: 'Endocrinology',
	},
	{
		value: 'Facial Plastic Surgery',
		label: 'Facial Plastic Surgery',
	},
	{
		value: 'Family Practice',
		label: 'Family Practice',
	},
	{
		value: 'Foot & Ankle Orthopedics',
		label: 'Foot & Ankle Orthopedics',
	},
	{
		value: 'Forensic Pathology',
		label: 'Forensic Pathology',
	},
	{
		value: 'Forensic Psychiatry',
		label: 'Forensic Psychiatry',
	},
	{
		value: 'Forensic Psychology',
		label: 'Forensic Psychology',
	},
	{
		value: 'Gastroenterology',
		label: 'Gastroenterology',
	},
	{
		value: 'General Practice',
		label: 'General Practice',
	},
	{
		value: 'General Preventive Medicine',
		label: 'General Preventive Medicine',
	},
	{
		value: 'General Surgery',
		label: 'General Surgery',
	},
	{
		value: 'Geriatric Medicine - FP',
		label: 'Geriatric Medicine - FP',
	},
	{
		value: 'Geriatric Medicine - IM',
		label: 'Geriatric Medicine - IM',
	},
	{
		value: 'Geriatric Psychiatry',
		label: 'Geriatric Psychiatry',
	},
	{
		value: 'Geropsychology',
		label: 'Geropsychology',
	},
	{
		value: 'Gynecological Oncology',
		label: 'Gynecological Oncology',
	},
	{
		value: 'Gynecology',
		label: 'Gynecology',
	},
	{
		value: 'Hand Surgery',
		label: 'Hand Surgery',
	},
	{
		value: 'Head & Neck Surgery',
		label: 'Head & Neck Surgery',
	},
	{
		value: 'Hematology',
		label: 'Hematology',
	},
	{
		value: 'Hematology/Oncology',
		label: 'Hematology/Oncology',
	},
	{
		value: 'Hepatology',
		label: 'Hepatology',
	},
	{
		value: 'Hospice and Palliative Care',
		label: 'Hospice and Palliative Care',
	},
	{
		value: 'Hospitalist',
		label: 'Hospitalist',
	},
	{
		value: 'IM/Pediatrics',
		label: 'IM/Pediatrics',
	},
	{
		value: 'Immunology',
		label: 'Immunology',
	},
	{
		value: 'Infectious Disease',
		label: 'Infectious Disease',
	},
	{
		value: 'Internal Medicine',
		label: 'Internal Medicine',
	},
	{
		value: 'Interventional Cardiology',
		label: 'Interventional Cardiology',
	},
	{
		value: 'Interventional Neurology',
		label: 'Interventional Neurology',
	},
	{
		value: 'Maternal & Fetal Medicine',
		label: 'Maternal & Fetal Medicine',
	},
	{
		value: 'Medical Genetics',
		label: 'Medical Genetics',
	},
	{
		value: 'Medical Microbiology',
		label: 'Medical Microbiology',
	},
	{
		value: 'Medical Oncology',
		label: 'Medical Oncology',
	},
	{
		value: 'Medical Toxicology',
		label: 'Medical Toxicology',
	},
	{
		value: 'Molecular Genetic Pathology',
		label: 'Molecular Genetic Pathology',
	},
	{
		value: 'Musculoskeletal Oncology',
		label: 'Musculoskeletal Oncology',
	},
	{
		value: 'Musculoskeletal Radiology',
		label: 'Musculoskeletal Radiology',
	},
	{
		value: 'Neonatal-Perinatal Medicine',
		label: 'Neonatal-Perinatal Medicine',
	},
	{
		value: 'Nephrology',
		label: 'Nephrology',
	},
	{
		value: 'Neurocritical Care',
		label: 'Neurocritical Care',
	},
	{
		value: 'Neurodevelopmental Disabilities',
		label: 'Neurodevelopmental Disabilities',
	},
	{
		value: 'Neurointerventional Radiology',
		label: 'Neurointerventional Radiology',
	},
	{
		value: 'Neurological Surgery',
		label: 'Neurological Surgery',
	},
	{
		value: 'Neurology',
		label: 'Neurology',
	},
	{
		value: 'Neurology/Diag Rad/Neuroradiology',
		label: 'Neurology/Diag Rad/Neuroradiology',
	},
	{
		value: 'Neuropathology',
		label: 'Neuropathology',
	},
	{
		value: 'Neuroradiology',
		label: 'Neuroradiology',
	},
	{
		value: 'Nuclear Cardiology',
		label: 'Nuclear Cardiology',
	},
	{
		value: 'Nuclear Medicine',
		label: 'Nuclear Medicine',
	},
	{
		value: 'Nuclear Radiology',
		label: 'Nuclear Radiology',
	},
	{
		value: 'Nutrition',
		label: 'Nutrition',
	},
	{
		value: 'Obstetric Critical Care Medicine',
		label: 'Obstetric Critical Care Medicine',
	},
	{
		value: 'Obstetrics',
		label: 'Obstetrics',
	},
	{
		value: 'Obstetrics & Gynecology',
		label: 'Obstetrics & Gynecology',
	},
	{
		value: 'Occupational Medicine',
		label: 'Occupational Medicine',
	},
	{
		value: 'Ophthalmology',
		label: 'Ophthalmology',
	},
	{
		value: 'Oral & Maxillofacial Surgery',
		label: 'Oral & Maxillofacial Surgery',
	},
	{
		value: 'Orthopedic Hand Surgery',
		label: 'Orthopedic Hand Surgery',
	},
	{
		value: 'Orthopedic Surgery',
		label: 'Orthopedic Surgery',
	},
	{
		value: 'Orthopedic Trauma Surgery',
		label: 'Orthopedic Trauma Surgery',
	},
	{
		value: 'Otolaryngology',
		label: 'Otolaryngology',
	},
	{
		value: 'Otology/Neurotology',
		label: 'Otology/Neurotology',
	},
	{
		value: 'Pain Management',
		label: 'Pain Management',
	},
	{
		value: 'Pain Medicine',
		label: 'Pain Medicine',
	},
	{
		value: 'Pediatric Allergy',
		label: 'Pediatric Allergy',
	},
	{
		value: 'Pediatric Anesthesiology',
		label: 'Pediatric Anesthesiology',
	},
	{
		value: 'Pediatric Cardiology',
		label: 'Pediatric Cardiology',
	},
	{
		value: 'Pediatric Cardiothoracic Surgery',
		label: 'Pediatric Cardiothoracic Surgery',
	},
	{
		value: 'Pediatric Clinical & Lab Immunology',
		label: 'Pediatric Clinical & Lab Immunology',
	},
	{
		value: 'Pediatric Critical Care Medicine',
		label: 'Pediatric Critical Care Medicine',
	},
	{
		value: 'Pediatric Dermatology',
		label: 'Pediatric Dermatology',
	},
	{
		value: 'Pediatric Emergency Medicine',
		label: 'Pediatric Emergency Medicine',
	},
	{
		value: 'Pediatric Endocrinology',
		label: 'Pediatric Endocrinology',
	},
	{
		value: 'Pediatric Gastroenterology',
		label: 'Pediatric Gastroenterology',
	},
	{
		value: 'Pediatric Hematology/Oncology',
		label: 'Pediatric Hematology/Oncology',
	},
	{
		value: 'Pediatric Hospitalist',
		label: 'Pediatric Hospitalist',
	},
	{
		value: 'Pediatric Infectious Disease',
		label: 'Pediatric Infectious Disease',
	},
	{
		value: 'Pediatric Medical Toxicology',
		label: 'Pediatric Medical Toxicology',
	},
	{
		value: 'Pediatric Nephrology',
		label: 'Pediatric Nephrology',
	},
	{
		value: 'Pediatric Ophthalmology',
		label: 'Pediatric Ophthalmology',
	},
	{
		value: 'Pediatric Orthopedics',
		label: 'Pediatric Orthopedics',
	},
	{
		value: 'Pediatric Otolaryngology',
		label: 'Pediatric Otolaryngology',
	},
	{
		value: 'Pediatric Pathology',
		label: 'Pediatric Pathology',
	},
	{
		value: 'Pediatric Pulmonology',
		label: 'Pediatric Pulmonology',
	},
	{
		value: 'Pediatric Radiology',
		label: 'Pediatric Radiology',
	},
	{
		value: 'Pediatric Rehabilitation Medicine',
		label: 'Pediatric Rehabilitation Medicine',
	},
	{
		value: 'Pediatric Rheumatology',
		label: 'Pediatric Rheumatology',
	},
	{
		value: 'Pediatrics',
		label: 'Pediatrics',
	},
	{
		value: 'Pediatric Surgery',
		label: 'Pediatric Surgery',
	},
	{
		value: 'Pediatric Surgery - Neurological',
		label: 'Pediatric Surgery - Neurological',
	},
	{
		value: 'Pediatric Urology',
		label: 'Pediatric Urology',
	},
	{
		value: 'Physical Medicine & Rehabilitation',
		label: 'Physical Medicine & Rehabilitation',
	},
	{
		value: 'Plastic Surgery',
		label: 'Plastic Surgery',
	},
	{
		value: 'Plastic Surgery within Head & Neck',
		label: 'Plastic Surgery within Head & Neck',
	},
	{
		value: 'Podiatry',
		label: 'Podiatry',
	},
	{
		value: 'Proctology',
		label: 'Proctology',
	},
	{
		value: 'Psychiatry',
		label: 'Psychiatry',
	},
	{
		value: 'Psychoanalysis',
		label: 'Psychoanalysis',
	},
	{
		value: 'Psychology',
		label: 'Psychology',
	},
	{
		value: 'Public Health & General Prev. Med',
		label: 'Public Health & General Prev. Med',
	},
	{
		value: 'Pulmonary Critical Care Medicine',
		label: 'Pulmonary Critical Care Medicine',
	},
	{
		value: 'Pulmonary Disease',
		label: 'Pulmonary Disease',
	},
	{
		value: 'Radiation Oncology',
		label: 'Radiation Oncology',
	},
	{
		value: 'Radiological Physics',
		label: 'Radiological Physics',
	},
	{
		value: 'Radiology',
		label: 'Radiology',
	},
	{
		value: 'Rehabilitation Psychology',
		label: 'Rehabilitation Psychology',
	},
	{
		value: 'Reproductive Endocrinology',
		label: 'Reproductive Endocrinology',
	},
	{
		value: 'Rheumatology',
		label: 'Rheumatology',
	},
	{
		value: 'School Psychology',
		label: 'School Psychology',
	},
	{
		value: 'Selective Pathology',
		label: 'Selective Pathology',
	},
	{
		value: 'Spinal Cord Injury',
		label: 'Spinal Cord Injury',
	},
	{
		value: 'Spine Surgery',
		label: 'Spine Surgery',
	},
	{
		value: 'Sports Medicine - (PM & R)',
		label: 'Sports Medicine - (PM & R)',
	},
	{
		value: 'Sports Medicine - EM',
		label: 'Sports Medicine - EM',
	},
	{
		value: 'Sports Medicine - FP',
		label: 'Sports Medicine - FP',
	},
	{
		value: 'Sports Medicine-IM',
		label: 'Sports Medicine-IM',
	},
	{
		value: 'Sports Medicine - Orthopedics',
		label: 'Sports Medicine - Orthopedics',
	},
	{
		value: 'Sports Medicine - Pediatric',
		label: 'Sports Medicine - Pediatric',
	},
	{
		value: 'Surgical Critical Care',
		label: 'Surgical Critical Care',
	},
	{
		value: 'Surgical Oncology',
		label: 'Surgical Oncology',
	},
	{
		value: 'Thoracic Surgery',
		label: 'Thoracic Surgery',
	},
	{
		value: 'Transplant Surgery',
		label: 'Transplant Surgery',
	},
	{
		value: 'Trauma Surgery',
		label: 'Trauma Surgery',
	},
	{
		value: 'Undersea & Hyperbaric Medicine',
		label: 'Undersea & Hyperbaric Medicine',
	},
	{
		value: 'Urgent Care',
		label: 'Urgent Care',
	},
	{
		value: 'Urogynecology',
		label: 'Urogynecology',
	},
	{
		value: 'Urology',
		label: 'Urology',
	},
	{
		value: 'Vascular/Interventional Radiology',
		label: 'Vascular/Interventional Radiology',
	},
	{
		value: 'Vascular Medicine',
		label: 'Vascular Medicine',
	},
	{
		value: 'Vascular Neurology',
		label: 'Vascular Neurology',
	},
	{
		value: 'Vascular Surgery',
		label: 'Vascular Surgery',
	},
	{
		value: 'Women’s Imaging',
		label: 'Women’s Imaging',
	},
	{
		value: 'Wound Care',
		label: 'Wound Care',
	},
	{
		value: 'Anesthesiology Critical Care Medicine',
		label: 'Anesthesiology Critical Care Medicine',
	},
	{
		value: 'Advanced Heart Failure and Transplant Cardiology',
		label: 'Advanced Heart Failure and Transplant Cardiology',
	},
	{
		value: 'Pediatric Transplant Hepatology',
		label: 'Pediatric Transplant Hepatology',
	},
	{
		value: 'Adult Congenital Heart Disease',
		label: 'Adult Congenital Heart Disease',
	},
	{
		value: 'Sleep Medicine',
		label: 'Sleep Medicine',
	},
	{
		value: 'Hematopathology',
		label: 'Hematopathology',
	},
	{
		value: 'Cardiothoracic Surgery',
		label: 'Cardiothoracic Surgery',
	},
	{
		value: 'Child Abuse Pediatrics',
		label: 'Child Abuse Pediatrics',
	},
	{
		value: 'Brain Injury Medicine',
		label: 'Brain Injury Medicine',
	},
	{
		value: 'Neuromuscular Medicine',
		label: 'Neuromuscular Medicine',
	},
	{
		value: 'Clinical Informatics',
		label: 'Clinical Informatics',
	},
	{
		value: 'Epilepsy',
		label: 'Epilepsy',
	},
	{
		value: 'Medical Physics (Diagnostic/Nuclear/Therapeutic)',
		label: 'Medical Physics (Diagnostic/Nuclear/Therapeutic)',
	},
	{
		value: 'Interventional Radiology and Diagnostic Radiology',
		label: 'Interventional Radiology and Diagnostic Radiology',
	},
	{
		value: 'Urological Surgery',
		label: 'Urological Surgery',
	},
	{
		value: 'Female Pelvic Medicine and Reconstructive Surgery',
		label: 'Female Pelvic Medicine and Reconstructive Surgery',
	},
];

const specialties = [
	{
		value: 'Abdominal Radiology',
		label: 'Abdominal Radiology',
	},
	{
		value: 'Abdominal Surgery',
		label: 'Abdominal Surgery',
	},
	{
		value: 'Addiction Medicine',
		label: 'Addiction Medicine',
	},
	{
		value: 'Addiction Psychiatry',
		label: 'Addiction Psychiatry',
	},
	{
		value: 'Adolescent Medicine',
		label: 'Adolescent Medicine',
	},
	{
		value: 'Adult Reconstructive Orthopedics',
		label: 'Adult Reconstructive Orthopedics',
	},
	{
		value: 'Aerospace Medicine',
		label: 'Aerospace Medicine',
	},
	{
		value: 'Allergy',
		label: 'Allergy',
	},
	{
		value: 'Allergy/Immunology',
		label: 'Allergy/Immunology',
	},
	{
		value: 'Anatomic/Clinical Pathology',
		label: 'Anatomic/Clinical Pathology',
	},
	{
		value: 'Anatomic Pathology',
		label: 'Anatomic Pathology',
	},
	{
		value: 'Anesthesiology',
		label: 'Anesthesiology',
	},
	{
		value: 'Behavioral and Cognitive Psychology',
		label: 'Behavioral and Cognitive Psychology',
	},
	{
		value: 'Bloodbanking/Transfusion Medicine',
		label: 'Bloodbanking/Transfusion Medicine',
	},
	{
		value: 'Burn Surgery',
		label: 'Burn Surgery',
	},
	{
		value: 'Cardiac Electrophysiology',
		label: 'Cardiac Electrophysiology',
	},
	{
		value: 'Cardiothoracic Radiology',
		label: 'Cardiothoracic Radiology',
	},
	{
		value: 'Cardiovascular Diseases',
		label: 'Cardiovascular Diseases',
	},
	{
		value: 'Chemical Pathology',
		label: 'Chemical Pathology',
	},
	{
		value: 'Child & Adolescent Psychiatry',
		label: 'Child & Adolescent Psychiatry',
	},
	{
		value: 'Child Neurology',
		label: 'Child Neurology',
	},
	{
		value: 'Clinical/Laboratory Immunology',
		label: 'Clinical/Laboratory Immunology',
	},
	{
		value: 'Clinical & Lab Derm Immunology',
		label: 'Clinical & Lab Derm Immunology',
	},
	{
		value: 'Clinical Biochemical Genetics',
		label: 'Clinical Biochemical Genetics',
	},
	{
		value: 'Clinical Child and Adolescent Psychology',
		label: 'Clinical Child and Adolescent Psychology',
	},
	{
		value: 'Clinical Cytogenetics',
		label: 'Clinical Cytogenetics',
	},
	{
		value: 'Clinical Genetics',
		label: 'Clinical Genetics',
	},
	{
		value: 'Clinical Health Psychology',
		label: 'Clinical Health Psychology',
	},
	{
		value: 'Clinical Lab Immunology & Allergy',
		label: 'Clinical Lab Immunology & Allergy',
	},
	{
		value: 'Clinical Molecular Genetics',
		label: 'Clinical Molecular Genetics',
	},
	{
		value: 'Clinical Neurophysiology',
		label: 'Clinical Neurophysiology',
	},
	{
		value: 'Clinical Pathology',
		label: 'Clinical Pathology',
	},
	{
		value: 'Clinical Social Work',
		label: 'Clinical Social Work',
	},
	{
		value: 'Colon & Rectal Surgery',
		label: 'Colon & Rectal Surgery',
	},
	{
		value: 'Cosmetic Surgery',
		label: 'Cosmetic Surgery',
	},
	{
		value: 'Counseling Psychology',
		label: 'Counseling Psychology',
	},
	{
		value: 'Couple and Family Psychology',
		label: 'Couple and Family Psychology',
	},
	{
		value: 'Craniofacial Surgery',
		label: 'Craniofacial Surgery',
	},
	{
		value: 'Critical Care Medicine',
		label: 'Critical Care Medicine',
	},
	{
		value: 'Cytopathology',
		label: 'Cytopathology',
	},
	{
		value: 'Dermatologic Surgery',
		label: 'Dermatologic Surgery',
	},
	{
		value: 'Dermatology',
		label: 'Dermatology',
	},
	{
		value: 'Dermatopathology',
		label: 'Dermatopathology',
	},
	{
		value: 'Developmental-Behavioral Pediatrics',
		label: 'Developmental-Behavioral Pediatrics',
	},
	{
		value: 'Diabetes',
		label: 'Diabetes',
	},
	{
		value: 'Diagnostic Radiology',
		label: 'Diagnostic Radiology',
	},
	{
		value: 'Emergency Medicine',
		label: 'Emergency Medicine',
	},
	{
		value: 'Endocrinology',
		label: 'Endocrinology',
	},
	{
		value: 'Facial Plastic Surgery',
		label: 'Facial Plastic Surgery',
	},
	{
		value: 'Family Practice',
		label: 'Family Practice',
	},
	{
		value: 'Foot & Ankle Orthopedics',
		label: 'Foot & Ankle Orthopedics',
	},
	{
		value: 'Forensic Pathology',
		label: 'Forensic Pathology',
	},
	{
		value: 'Forensic Psychiatry',
		label: 'Forensic Psychiatry',
	},
	{
		value: 'Forensic Psychology',
		label: 'Forensic Psychology',
	},
	{
		value: 'Gastroenterology',
		label: 'Gastroenterology',
	},
	{
		value: 'General Practice',
		label: 'General Practice',
	},
	{
		value: 'General Preventive Medicine',
		label: 'General Preventive Medicine',
	},
	{
		value: 'General Surgery',
		label: 'General Surgery',
	},
	{
		value: 'Geriatric Medicine - FP',
		label: 'Geriatric Medicine - FP',
	},
	{
		value: 'Geriatric Medicine - IM',
		label: 'Geriatric Medicine - IM',
	},
	{
		value: 'Geriatric Psychiatry',
		label: 'Geriatric Psychiatry',
	},
	{
		value: 'Geropsychology',
		label: 'Geropsychology',
	},
	{
		value: 'Gynecological Oncology',
		label: 'Gynecological Oncology',
	},
	{
		value: 'Gynecology',
		label: 'Gynecology',
	},
	{
		value: 'Hand Surgery',
		label: 'Hand Surgery',
	},
	{
		value: 'Head & Neck Surgery',
		label: 'Head & Neck Surgery',
	},
	{
		value: 'Hematology',
		label: 'Hematology',
	},
	{
		value: 'Hematology/Oncology',
		label: 'Hematology/Oncology',
	},
	{
		value: 'Hepatology',
		label: 'Hepatology',
	},
	{
		value: 'Hospice and Palliative Care',
		label: 'Hospice and Palliative Care',
	},
	{
		value: 'Hospitalist',
		label: 'Hospitalist',
	},
	{
		value: 'IM/Pediatrics',
		label: 'IM/Pediatrics',
	},
	{
		value: 'Immunology',
		label: 'Immunology',
	},
	{
		value: 'Infectious Disease',
		label: 'Infectious Disease',
	},
	{
		value: 'Internal Medicine',
		label: 'Internal Medicine',
	},
	{
		value: 'Interventional Cardiology',
		label: 'Interventional Cardiology',
	},
	{
		value: 'Maternal & Fetal Medicine',
		label: 'Maternal & Fetal Medicine',
	},
	{
		value: 'Medical Genetics',
		label: 'Medical Genetics',
	},
	{
		value: 'Medical Microbiology',
		label: 'Medical Microbiology',
	},
	{
		value: 'Medical Oncology',
		label: 'Medical Oncology',
	},
	{
		value: 'Medical Toxicology',
		label: 'Medical Toxicology',
	},
	{
		value: 'Molecular Genetic Pathology',
		label: 'Molecular Genetic Pathology',
	},
	{
		value: 'Musculoskeletal Oncology',
		label: 'Musculoskeletal Oncology',
	},
	{
		value: 'Musculoskeletal Radiology',
		label: 'Musculoskeletal Radiology',
	},
	{
		value: 'Neonatal-Perinatal Medicine',
		label: 'Neonatal-Perinatal Medicine',
	},
	{
		value: 'Nephrology',
		label: 'Nephrology',
	},
	{
		value: 'Neurocritical Care',
		label: 'Neurocritical Care',
	},
	{
		value: 'Neurodevelopmental Disabilities',
		label: 'Neurodevelopmental Disabilities',
	},
	{
		value: 'Neurological Surgery',
		label: 'Neurological Surgery',
	},
	{
		value: 'Neurology',
		label: 'Neurology',
	},
	{
		value: 'Neurology/Diag Rad/Neuroradiology',
		label: 'Neurology/Diag Rad/Neuroradiology',
	},
	{
		value: 'Neuropathology',
		label: 'Neuropathology',
	},
	{
		value: 'Neuroradiology',
		label: 'Neuroradiology',
	},
	{
		value: 'Nuclear Cardiology',
		label: 'Nuclear Cardiology',
	},
	{
		value: 'Nuclear Medicine',
		label: 'Nuclear Medicine',
	},
	{
		value: 'Nuclear Radiology',
		label: 'Nuclear Radiology',
	},
	{
		value: 'Nutrition',
		label: 'Nutrition',
	},
	{
		value: 'Obstetric Critical Care Medicine',
		label: 'Obstetric Critical Care Medicine',
	},
	{
		value: 'Obstetrics',
		label: 'Obstetrics',
	},
	{
		value: 'Obstetrics & Gynecology',
		label: 'Obstetrics & Gynecology',
	},
	{
		value: 'Occupational Medicine',
		label: 'Occupational Medicine',
	},
	{
		value: 'Ophthalmology',
		label: 'Ophthalmology',
	},
	{
		value: 'Oral & Maxillofacial Surgery',
		label: 'Oral & Maxillofacial Surgery',
	},
	{
		value: 'Orthopedic Hand Surgery',
		label: 'Orthopedic Hand Surgery',
	},
	{
		value: 'Orthopedic Surgery',
		label: 'Orthopedic Surgery',
	},
	{
		value: 'Orthopedic Trauma Surgery',
		label: 'Orthopedic Trauma Surgery',
	},
	{
		value: 'Otolaryngology',
		label: 'Otolaryngology',
	},
	{
		value: 'Otology/Neurotology',
		label: 'Otology/Neurotology',
	},
	{
		value: 'Pain Management',
		label: 'Pain Management',
	},
	{
		value: 'Pain Medicine',
		label: 'Pain Medicine',
	},
	{
		value: 'Pediatric Allergy',
		label: 'Pediatric Allergy',
	},
	{
		value: 'Pediatric Anesthesiology',
		label: 'Pediatric Anesthesiology',
	},
	{
		value: 'Pediatric Cardiology',
		label: 'Pediatric Cardiology',
	},
	{
		value: 'Pediatric Cardiothoracic Surgery',
		label: 'Pediatric Cardiothoracic Surgery',
	},
	{
		value: 'Pediatric Clinical & Lab Immunology',
		label: 'Pediatric Clinical & Lab Immunology',
	},
	{
		value: 'Pediatric Critical Care Medicine',
		label: 'Pediatric Critical Care Medicine',
	},
	{
		value: 'Pediatric Dermatology',
		label: 'Pediatric Dermatology',
	},
	{
		value: 'Pediatric Emergency Medicine',
		label: 'Pediatric Emergency Medicine',
	},
	{
		value: 'Pediatric Endocrinology',
		label: 'Pediatric Endocrinology',
	},
	{
		value: 'Pediatric Gastroenterology',
		label: 'Pediatric Gastroenterology',
	},
	{
		value: 'Pediatric Hematology/Oncology',
		label: 'Pediatric Hematology/Oncology',
	},
	{
		value: 'Pediatric Hospitalist',
		label: 'Pediatric Hospitalist',
	},
	{
		value: 'Pediatric Infectious Disease',
		label: 'Pediatric Infectious Disease',
	},
	{
		value: 'Pediatric Medical Toxicology',
		label: 'Pediatric Medical Toxicology',
	},
	{
		value: 'Pediatric Nephrology',
		label: 'Pediatric Nephrology',
	},
	{
		value: 'Pediatric Ophthalmology',
		label: 'Pediatric Ophthalmology',
	},
	{
		value: 'Pediatric Orthopedics',
		label: 'Pediatric Orthopedics',
	},
	{
		value: 'Pediatric Otolaryngology',
		label: 'Pediatric Otolaryngology',
	},
	{
		value: 'Pediatric Pathology',
		label: 'Pediatric Pathology',
	},
	{
		value: 'Pediatric Pulmonology',
		label: 'Pediatric Pulmonology',
	},
	{
		value: 'Pediatric Radiology',
		label: 'Pediatric Radiology',
	},
	{
		value: 'Pediatric Rehabilitation Medicine',
		label: 'Pediatric Rehabilitation Medicine',
	},
	{
		value: 'Pediatric Rheumatology',
		label: 'Pediatric Rheumatology',
	},
	{
		value: 'Pediatrics',
		label: 'Pediatrics',
	},
	{
		value: 'Pediatric Surgery',
		label: 'Pediatric Surgery',
	},
	{
		value: 'Pediatric Surgery - Neurological',
		label: 'Pediatric Surgery - Neurological',
	},
	{
		value: 'Pediatric Urology',
		label: 'Pediatric Urology',
	},
	{
		value: 'Physical Medicine & Rehabilitation',
		label: 'Physical Medicine & Rehabilitation',
	},
	{
		value: 'Plastic Surgery',
		label: 'Plastic Surgery',
	},
	{
		value: 'Plastic Surgery within Head & Neck',
		label: 'Plastic Surgery within Head & Neck',
	},
	{
		value: 'Podiatry',
		label: 'Podiatry',
	},
	{
		value: 'Proctology',
		label: 'Proctology',
	},
	{
		value: 'Psychiatry',
		label: 'Psychiatry',
	},
	{
		value: 'Psychoanalysis',
		label: 'Psychoanalysis',
	},
	{
		value: 'Psychology',
		label: 'Psychology',
	},
	{
		value: 'Public Health & General Prev. Med',
		label: 'Public Health & General Prev. Med',
	},
	{
		value: 'Pulmonary Critical Care Medicine',
		label: 'Pulmonary Critical Care Medicine',
	},
	{
		value: 'Pulmonary Disease',
		label: 'Pulmonary Disease',
	},
	{
		value: 'Radiation Oncology',
		label: 'Radiation Oncology',
	},
	{
		value: 'Radiological Physics',
		label: 'Radiological Physics',
	},
	{
		value: 'Radiology',
		label: 'Radiology',
	},
	{
		value: 'Rehabilitation Psychology',
		label: 'Rehabilitation Psychology',
	},
	{
		value: 'Reproductive Endocrinology',
		label: 'Reproductive Endocrinology',
	},
	{
		value: 'Rheumatology',
		label: 'Rheumatology',
	},
	{
		value: 'School Psychology',
		label: 'School Psychology',
	},
	{
		value: 'Selective Pathology',
		label: 'Selective Pathology',
	},
	{
		value: 'Spinal Cord Injury',
		label: 'Spinal Cord Injury',
	},
	{
		value: 'Spine Surgery',
		label: 'Spine Surgery',
	},
	{
		value: 'Sports Medicine - (PM & R)',
		label: 'Sports Medicine - (PM & R)',
	},
	{
		value: 'Sports Medicine - EM',
		label: 'Sports Medicine - EM',
	},
	{
		value: 'Sports Medicine - FP',
		label: 'Sports Medicine - FP',
	},
	{
		value: 'Sports Medicine-IM',
		label: 'Sports Medicine-IM',
	},
	{
		value: 'Sports Medicine - Orthopedics',
		label: 'Sports Medicine - Orthopedics',
	},
	{
		value: 'Sports Medicine - Pediatric',
		label: 'Sports Medicine - Pediatric',
	},
	{
		value: 'Surgical Critical Care',
		label: 'Surgical Critical Care',
	},
	{
		value: 'Surgical Oncology',
		label: 'Surgical Oncology',
	},
	{
		value: 'Thoracic Surgery',
		label: 'Thoracic Surgery',
	},
	{
		value: 'Transplant Surgery',
		label: 'Transplant Surgery',
	},
	{
		value: 'Trauma Surgery',
		label: 'Trauma Surgery',
	},
	{
		value: 'Undersea & Hyperbaric Medicine',
		label: 'Undersea & Hyperbaric Medicine',
	},
	{
		value: 'Urgent Care',
		label: 'Urgent Care',
	},
	{
		value: 'Urogynecology',
		label: 'Urogynecology',
	},
	{
		value: 'Urology',
		label: 'Urology',
	},
	{
		value: 'Vascular/Interventional Radiology',
		label: 'Vascular/Interventional Radiology',
	},
	{
		value: 'Vascular Medicine',
		label: 'Vascular Medicine',
	},
	{
		value: 'Vascular Neurology',
		label: 'Vascular Neurology',
	},
	{
		value: 'Vascular Surgery',
		label: 'Vascular Surgery',
	},
	{
		value: 'Women’s Imaging',
		label: 'Women’s Imaging',
	},
	{
		value: 'Wound Care',
		label: 'Wound Care',
	},
];

const specialtiesItrnResidency = [
	{
		value: 'Abdominal Radiology',
		label: 'Abdominal Radiology',
	},
	{
		value: 'Abdominal Surgery',
		label: 'Abdominal Surgery',
	},
	{
		value: 'Addiction Medicine',
		label: 'Addiction Medicine',
	},
	{
		value: 'Addiction Psychiatry',
		label: 'Addiction Psychiatry',
	},
	{
		value: 'Adolescent Medicine',
		label: 'Adolescent Medicine',
	},
	{
		value: 'Adult Reconstructive Orthopedics',
		label: 'Adult Reconstructive Orthopedics',
	},
	{
		value: 'Aerospace Medicine',
		label: 'Aerospace Medicine',
	},
	{
		value: 'Allergy',
		label: 'Allergy',
	},
	{
		value: 'Allergy/Immunology',
		label: 'Allergy/Immunology',
	},
	{
		value: 'Anatomic/Clinical Pathology',
		label: 'Anatomic/Clinical Pathology',
	},
	{
		value: 'Anatomic Pathology',
		label: 'Anatomic Pathology',
	},
	{
		value: 'Anesthesiology',
		label: 'Anesthesiology',
	},
	{
		value: 'Behavioral and Cognitive Psychology',
		label: 'Behavioral and Cognitive Psychology',
	},
	{
		value: 'Bloodbanking/Transfusion Medicine',
		label: 'Bloodbanking/Transfusion Medicine',
	},
	//{
	//    "value": "Burn Surgery",
	//    "label": "Burn Surgery"
	//},
	{
		value: 'Cardiac Electrophysiology',
		label: 'Cardiac Electrophysiology',
	},
	{
		value: 'Cardiothoracic Radiology',
		label: 'Cardiothoracic Radiology',
	},
	{
		value: 'Cardiovascular Diseases',
		label: 'Cardiovascular Diseases',
	},
	{
		value: 'Chemical Pathology',
		label: 'Chemical Pathology',
	},
	{
		value: 'Child & Adolescent Psychiatry',
		label: 'Child & Adolescent Psychiatry',
	},
	{
		value: 'Child Neurology',
		label: 'Child Neurology',
	},
	{
		value: 'Clinical/Laboratory Immunology',
		label: 'Clinical/Laboratory Immunology',
	},
	{
		value: 'Clinical & Lab Derm Immunology',
		label: 'Clinical & Lab Derm Immunology',
	},
	{
		value: 'Clinical Biochemical Genetics',
		label: 'Clinical Biochemical Genetics',
	},
	{
		value: 'Clinical Child and Adolescent Psychology',
		label: 'Clinical Child and Adolescent Psychology',
	},
	{
		value: 'Clinical Cytogenetics',
		label: 'Clinical Cytogenetics',
	},
	{
		value: 'Clinical Genetics',
		label: 'Clinical Genetics',
	},
	{
		value: 'Clinical Health Psychology',
		label: 'Clinical Health Psychology',
	},
	{
		value: 'Clinical Lab Immunology & Allergy',
		label: 'Clinical Lab Immunology & Allergy',
	},
	{
		value: 'Clinical Molecular Genetics',
		label: 'Clinical Molecular Genetics',
	},
	{
		value: 'Clinical Neurophysiology',
		label: 'Clinical Neurophysiology',
	},
	{
		value: 'Clinical Pathology',
		label: 'Clinical Pathology',
	},
	{
		value: 'Colon & Rectal Surgery',
		label: 'Colon & Rectal Surgery',
	},
	{
		value: 'Cosmetic Surgery',
		label: 'Cosmetic Surgery',
	},
	{
		value: 'Counseling Psychology',
		label: 'Counseling Psychology',
	},
	{
		value: 'Couple and Family Psychology',
		label: 'Couple and Family Psychology',
	},
	{
		value: 'Craniofacial Surgery',
		label: 'Craniofacial Surgery',
	},
	{
		value: 'Critical Care Medicine',
		label: 'Critical Care Medicine',
	},
	{
		value: 'Cytopathology',
		label: 'Cytopathology',
	},
	{
		value: 'Dermatologic Surgery',
		label: 'Dermatologic Surgery',
	},
	{
		value: 'Dermatology',
		label: 'Dermatology',
	},
	{
		value: 'Dermatopathology',
		label: 'Dermatopathology',
	},
	{
		value: 'Developmental-Behavioral Pediatrics',
		label: 'Developmental-Behavioral Pediatrics',
	},
	{
		value: 'Diabetes',
		label: 'Diabetes',
	},
	{
		value: 'Diagnostic Radiology',
		label: 'Diagnostic Radiology',
	},
	{
		value: 'Emergency Medicine',
		label: 'Emergency Medicine',
	},
	{
		value: 'Endocrinology',
		label: 'Endocrinology',
	},
	{
		value: 'Facial Plastic Surgery',
		label: 'Facial Plastic Surgery',
	},
	{
		value: 'Family Practice',
		label: 'Family Practice',
	},
	{
		value: 'Foot & Ankle Orthopedics',
		label: 'Foot & Ankle Orthopedics',
	},
	{
		value: 'Forensic Pathology',
		label: 'Forensic Pathology',
	},
	{
		value: 'Forensic Psychiatry',
		label: 'Forensic Psychiatry',
	},
	{
		value: 'Forensic Psychology',
		label: 'Forensic Psychology',
	},
	{
		value: 'Gastroenterology',
		label: 'Gastroenterology',
	},
	{
		value: 'General Practice',
		label: 'General Practice',
	},
	{
		value: 'General Preventive Medicine',
		label: 'General Preventive Medicine',
	},
	{
		value: 'General Surgery',
		label: 'General Surgery',
	},
	{
		value: 'Geriatric Medicine - FP',
		label: 'Geriatric Medicine - FP',
	},
	{
		value: 'Geriatric Medicine - IM',
		label: 'Geriatric Medicine - IM',
	},
	{
		value: 'Geriatric Psychiatry',
		label: 'Geriatric Psychiatry',
	},
	{
		value: 'Geropsychology',
		label: 'Geropsychology',
	},
	{
		value: 'Gynecological Oncology',
		label: 'Gynecological Oncology',
	},
	{
		value: 'Gynecology',
		label: 'Gynecology',
	},
	{
		value: 'Hand Surgery',
		label: 'Hand Surgery',
	},
	{
		value: 'Head & Neck Surgery',
		label: 'Head & Neck Surgery',
	},
	{
		value: 'Hematology',
		label: 'Hematology',
	},
	{
		value: 'Hematology/Oncology',
		label: 'Hematology/Oncology',
	},
	{
		value: 'Hepatology',
		label: 'Hepatology',
	},
	//{
	//    "value": "Hospice and Palliative Care",
	//    "label": "Hospice and Palliative Care"
	//},
	//{
	//    "value": "Hospitalist",
	//    "label": "Hospitalist"
	//},
	{
		value: 'IM/Pediatrics',
		label: 'IM/Pediatrics',
	},
	{
		value: 'Immunology',
		label: 'Immunology',
	},
	{
		value: 'Infectious Disease',
		label: 'Infectious Disease',
	},
	{
		value: 'Internal Medicine',
		label: 'Internal Medicine',
	},
	{
		value: 'Interventional Cardiology',
		label: 'Interventional Cardiology',
	},
	{
		value: 'Interventional Neurology',
		label: 'Interventional Neurology',
	},
	{
		value: 'Maternal & Fetal Medicine',
		label: 'Maternal & Fetal Medicine',
	},
	{
		value: 'Medical Genetics',
		label: 'Medical Genetics',
	},
	{
		value: 'Medical Microbiology',
		label: 'Medical Microbiology',
	},
	{
		value: 'Medical Oncology',
		label: 'Medical Oncology',
	},
	{
		value: 'Medical Toxicology',
		label: 'Medical Toxicology',
	},
	{
		value: 'Molecular Genetic Pathology',
		label: 'Molecular Genetic Pathology',
	},
	{
		value: 'Musculoskeletal Oncology',
		label: 'Musculoskeletal Oncology',
	},
	{
		value: 'Musculoskeletal Radiology',
		label: 'Musculoskeletal Radiology',
	},
	{
		value: 'Neonatal-Perinatal Medicine',
		label: 'Neonatal-Perinatal Medicine',
	},
	{
		value: 'Nephrology',
		label: 'Nephrology',
	},
	{
		value: 'Neurodevelopmental Disabilities',
		label: 'Neurodevelopmental Disabilities',
	},
	{
		value: 'Neurointerventional Radiology',
		label: 'Neurointerventional Radiology',
	},
	{
		value: 'Neurological Surgery',
		label: 'Neurological Surgery',
	},
	{
		value: 'Neurology',
		label: 'Neurology',
	},
	{
		value: 'Neurology/Diag Rad/Neuroradiology',
		label: 'Neurology/Diag Rad/Neuroradiology',
	},
	{
		value: 'Neuropathology',
		label: 'Neuropathology',
	},
	{
		value: 'Neuroradiology',
		label: 'Neuroradiology',
	},
	{
		value: 'Nuclear Cardiology',
		label: 'Nuclear Cardiology',
	},
	{
		value: 'Nuclear Medicine',
		label: 'Nuclear Medicine',
	},
	{
		value: 'Nuclear Radiology',
		label: 'Nuclear Radiology',
	},
	{
		value: 'Nutrition',
		label: 'Nutrition',
	},
	{
		value: 'Obstetric Critical Care Medicine',
		label: 'Obstetric Critical Care Medicine',
	},
	{
		value: 'Obstetrics',
		label: 'Obstetrics',
	},
	{
		value: 'Obstetrics & Gynecology',
		label: 'Obstetrics & Gynecology',
	},
	{
		value: 'Occupational Medicine',
		label: 'Occupational Medicine',
	},
	{
		value: 'Ophthalmology',
		label: 'Ophthalmology',
	},
	{
		value: 'Oral & Maxillofacial Surgery',
		label: 'Oral & Maxillofacial Surgery',
	},
	{
		value: 'Orthopedic Hand Surgery',
		label: 'Orthopedic Hand Surgery',
	},
	{
		value: 'Orthopedic Surgery',
		label: 'Orthopedic Surgery',
	},
	{
		value: 'Orthopedic Trauma Surgery',
		label: 'Orthopedic Trauma Surgery',
	},
	{
		value: 'Other',
		label: 'Other',
	},
	{
		value: 'Otolaryngology',
		label: 'Otolaryngology',
	},
	{
		value: 'Otology/Neurotology',
		label: 'Otology/Neurotology',
	},
	{
		value: 'Pain Management',
		label: 'Pain Management',
	},
	{
		value: 'Pain Medicine',
		label: 'Pain Medicine',
	},
	{
		value: 'Pediatric Allergy',
		label: 'Pediatric Allergy',
	},
	{
		value: 'Pediatric Anesthesiology',
		label: 'Pediatric Anesthesiology',
	},
	{
		value: 'Pediatric Cardiology',
		label: 'Pediatric Cardiology',
	},
	{
		value: 'Pediatric Cardiothoracic Surgery',
		label: 'Pediatric Cardiothoracic Surgery',
	},
	{
		value: 'Pediatric Clinical & Lab Immunology',
		label: 'Pediatric Clinical & Lab Immunology',
	},
	{
		value: 'Pediatric Critical Care Medicine',
		label: 'Pediatric Critical Care Medicine',
	},
	{
		value: 'Pediatric Dermatology',
		label: 'Pediatric Dermatology',
	},
	{
		value: 'Pediatric Emergency Medicine',
		label: 'Pediatric Emergency Medicine',
	},
	{
		value: 'Pediatric Endocrinology',
		label: 'Pediatric Endocrinology',
	},
	{
		value: 'Pediatric Gastroenterology',
		label: 'Pediatric Gastroenterology',
	},
	{
		value: 'Pediatric Hematology/Oncology',
		label: 'Pediatric Hematology/Oncology',
	},
	{
		value: 'Pediatric Hospitalist',
		label: 'Pediatric Hospitalist',
	},
	{
		value: 'Pediatric Infectious Disease',
		label: 'Pediatric Infectious Disease',
	},
	{
		value: 'Pediatric Medical Toxicology',
		label: 'Pediatric Medical Toxicology',
	},
	{
		value: 'Pediatric Nephrology',
		label: 'Pediatric Nephrology',
	},
	{
		value: 'Pediatric Ophthalmology',
		label: 'Pediatric Ophthalmology',
	},
	{
		value: 'Pediatric Orthopedics',
		label: 'Pediatric Orthopedics',
	},
	{
		value: 'Pediatric Otolaryngology',
		label: 'Pediatric Otolaryngology',
	},
	{
		value: 'Pediatric Pathology',
		label: 'Pediatric Pathology',
	},
	{
		value: 'Pediatric Pulmonology',
		label: 'Pediatric Pulmonology',
	},
	{
		value: 'Pediatric Radiology',
		label: 'Pediatric Radiology',
	},
	{
		value: 'Pediatric Rehabilitation Medicine',
		label: 'Pediatric Rehabilitation Medicine',
	},
	{
		value: 'Pediatric Rheumatology',
		label: 'Pediatric Rheumatology',
	},
	{
		value: 'Pediatrics',
		label: 'Pediatrics',
	},
	{
		value: 'Pediatric Surgery',
		label: 'Pediatric Surgery',
	},
	{
		value: 'Pediatric Surgery - Neurological',
		label: 'Pediatric Surgery - Neurological',
	},
	{
		value: 'Pediatric Urology',
		label: 'Pediatric Urology',
	},
	{
		value: 'Physical Medicine & Rehabilitation',
		label: 'Physical Medicine & Rehabilitation',
	},
	{
		value: 'Plastic Surgery',
		label: 'Plastic Surgery',
	},
	{
		value: 'Plastic Surgery within Head & Neck',
		label: 'Plastic Surgery within Head & Neck',
	},
	{
		value: 'Podiatry',
		label: 'Podiatry',
	},
	{
		value: 'Proctology',
		label: 'Proctology',
	},
	{
		value: 'Psychiatry',
		label: 'Psychiatry',
	},
	{
		value: 'Psychoanalysis',
		label: 'Psychoanalysis',
	},
	{
		value: 'Psychology',
		label: 'Psychology',
	},
	{
		value: 'Public Health & General Prev. Med',
		label: 'Public Health & General Prev. Med',
	},
	{
		value: 'Pulmonary Critical Care Medicine',
		label: 'Pulmonary Critical Care Medicine',
	},
	{
		value: 'Pulmonary Disease',
		label: 'Pulmonary Disease',
	},
	{
		value: 'Radiation Oncology',
		label: 'Radiation Oncology',
	},
	{
		value: 'Radiological Physics',
		label: 'Radiological Physics',
	},
	{
		value: 'Radiology',
		label: 'Radiology',
	},
	{
		value: 'Rehabilitation Psychology',
		label: 'Rehabilitation Psychology',
	},
	{
		value: 'Reproductive Endocrinology',
		label: 'Reproductive Endocrinology',
	},
	{
		value: 'Rheumatology',
		label: 'Rheumatology',
	},
	{
		value: 'School Psychology',
		label: 'School Psychology',
	},
	{
		value: 'Selective Pathology',
		label: 'Selective Pathology',
	},
	{
		value: 'Spinal Cord Injury',
		label: 'Spinal Cord Injury',
	},
	{
		value: 'Spine Surgery',
		label: 'Spine Surgery',
	},
	{
		value: 'Sports Medicine - (PM & R)',
		label: 'Sports Medicine - (PM & R)',
	},
	{
		value: 'Sports Medicine - EM',
		label: 'Sports Medicine - EM',
	},
	{
		value: 'Sports Medicine - FP',
		label: 'Sports Medicine - FP',
	},
	{
		value: 'Sports Medicine-IM',
		label: 'Sports Medicine-IM',
	},
	{
		value: 'Sports Medicine - Orthopedics',
		label: 'Sports Medicine - Orthopedics',
	},
	{
		value: 'Sports Medicine - Pediatric',
		label: 'Sports Medicine - Pediatric',
	},
	{
		value: 'Surgical Critical Care',
		label: 'Surgical Critical Care',
	},
	{
		value: 'Surgical Oncology',
		label: 'Surgical Oncology',
	},
	{
		value: 'Thoracic Surgery',
		label: 'Thoracic Surgery',
	},
	{
		value: 'Transplant Surgery',
		label: 'Transplant Surgery',
	},
	{
		value: 'Trauma Surgery',
		label: 'Trauma Surgery',
	},
	{
		value: 'Undersea & Hyperbaric Medicine',
		label: 'Undersea & Hyperbaric Medicine',
	},
	//{
	//    "value": "Urgent Care",
	//    "label": "Urgent Care"
	//},
	//{
	//    "value": "Urogynecology",
	//    "label": "Urogynecology"
	//},
	{
		value: 'Urology',
		label: 'Urology',
	},
	{
		value: 'Vascular/Interventional Radiology',
		label: 'Vascular/Interventional Radiology',
	},
	{
		value: 'Vascular Medicine',
		label: 'Vascular Medicine',
	},
	{
		value: 'Vascular Neurology',
		label: 'Vascular Neurology',
	},
	{
		value: 'Vascular Surgery',
		label: 'Vascular Surgery',
	},
	{
		value: 'Anesthesiology Critical Care Medicine',
		label: 'Anesthesiology Critical Care Medicine',
	},
	{
		value: 'Advanced Heart Failure and Transplant Cardiology',
		label: 'Advanced Heart Failure and Transplant Cardiology',
	},
	{
		value: 'Pediatric Transplant Hepatology',
		label: 'Pediatric Transplant Hepatology',
	},
	{
		value: 'Neurocritical Care',
		label: 'Neurocritical Care',
	},
	{
		value: 'Adult Congenital Heart Disease',
		label: 'Adult Congenital Heart Disease',
	},
	{
		value: 'Sleep Medicine',
		label: 'Sleep Medicine',
	},
	{
		value: 'Hematopathology',
		label: 'Hematopathology',
	},
	{
		value: 'Cardiothoracic Surgery',
		label: 'Cardiothoracic Surgery',
	},
	{
		value: 'Child Abuse Pediatrics',
		label: 'Child Abuse Pediatrics',
	},
	{
		value: 'Brain Injury Medicine',
		label: 'Brain Injury Medicine',
	},
	{
		value: 'Neuromuscular Medicine',
		label: 'Neuromuscular Medicine',
	},
	{
		value: 'Clinical Informatics',
		label: 'Clinical Informatics',
	},
	{
		value: 'Epilepsy',
		label: 'Epilepsy',
	},
	{
		value: 'Medical Physics (Diagnostic/Nuclear/Therapeutic)',
		label: 'Medical Physics (Diagnostic/Nuclear/Therapeutic)',
	},
	{
		value: 'Interventional Radiology and Diagnostic Radiology',
		label: 'Interventional Radiology and Diagnostic Radiology',
	},
	{
		value: 'Urological Surgery',
		label: 'Urological Surgery',
	},
	{
		value: 'Female Pelvic Medicine and Reconstructive Surgery',
		label: 'Female Pelvic Medicine and Reconstructive Surgery',
	},
	//{
	//    "value": "Women’s Imaging",
	//    "label": "Women’s Imaging"
	//},
	//{
	//    "value": "Wound Care",
	//    "label": "Wound Care"
	//}
];

const specialtiesFellowship = [
	{
		value: 'Abdominal Radiology',
		label: 'Abdominal Radiology',
	},
	{
		value: 'Abdominal Surgery',
		label: 'Abdominal Surgery',
	},
	{
		value: 'Addiction Medicine',
		label: 'Addiction Medicine',
	},
	{
		value: 'Addiction Psychiatry',
		label: 'Addiction Psychiatry',
	},
	{
		value: 'Adolescent Medicine',
		label: 'Adolescent Medicine',
	},
	{
		value: 'Adult Reconstructive Orthopedics',
		label: 'Adult Reconstructive Orthopedics',
	},
	{
		value: 'Aerospace Medicine',
		label: 'Aerospace Medicine',
	},
	{
		value: 'Allergy',
		label: 'Allergy',
	},
	{
		value: 'Allergy/Immunology',
		label: 'Allergy/Immunology',
	},
	{
		value: 'Anatomic/Clinical Pathology',
		label: 'Anatomic/Clinical Pathology',
	},
	{
		value: 'Anatomic Pathology',
		label: 'Anatomic Pathology',
	},
	{
		value: 'Anesthesiology',
		label: 'Anesthesiology',
	},
	{
		value: 'Behavioral and Cognitive Psychology',
		label: 'Behavioral and Cognitive Psychology',
	},
	{
		value: 'Bloodbanking/Transfusion Medicine',
		label: 'Bloodbanking/Transfusion Medicine',
	},
	//{
	//    "value": "Burn Surgery",
	//    "label": "Burn Surgery"
	//},
	{
		value: 'Cardiac Electrophysiology',
		label: 'Cardiac Electrophysiology',
	},
	{
		value: 'Cardiothoracic Radiology',
		label: 'Cardiothoracic Radiology',
	},
	{
		value: 'Cardiovascular Diseases',
		label: 'Cardiovascular Diseases',
	},
	{
		value: 'Chemical Pathology',
		label: 'Chemical Pathology',
	},
	{
		value: 'Child & Adolescent Psychiatry',
		label: 'Child & Adolescent Psychiatry',
	},
	{
		value: 'Child Neurology',
		label: 'Child Neurology',
	},
	{
		value: 'Clinical/Laboratory Immunology',
		label: 'Clinical/Laboratory Immunology',
	},
	{
		value: 'Clinical & Lab Derm Immunology',
		label: 'Clinical & Lab Derm Immunology',
	},
	{
		value: 'Clinical Biochemical Genetics',
		label: 'Clinical Biochemical Genetics',
	},
	{
		value: 'Clinical Child and Adolescent Psychology',
		label: 'Clinical Child and Adolescent Psychology',
	},
	{
		value: 'Clinical Cytogenetics',
		label: 'Clinical Cytogenetics',
	},
	{
		value: 'Clinical Genetics',
		label: 'Clinical Genetics',
	},
	{
		value: 'Clinical Health Psychology',
		label: 'Clinical Health Psychology',
	},
	{
		value: 'Clinical Lab Immunology & Allergy',
		label: 'Clinical Lab Immunology & Allergy',
	},
	{
		value: 'Clinical Molecular Genetics',
		label: 'Clinical Molecular Genetics',
	},
	{
		value: 'Clinical Neurophysiology',
		label: 'Clinical Neurophysiology',
	},
	{
		value: 'Clinical Pathology',
		label: 'Clinical Pathology',
	},
	{
		value: 'Colon & Rectal Surgery',
		label: 'Colon & Rectal Surgery',
	},
	{
		value: 'Cosmetic Surgery',
		label: 'Cosmetic Surgery',
	},
	{
		value: 'Counseling Psychology',
		label: 'Counseling Psychology',
	},
	{
		value: 'Couple and Family Psychology',
		label: 'Couple and Family Psychology',
	},
	{
		value: 'Craniofacial Surgery',
		label: 'Craniofacial Surgery',
	},
	{
		value: 'Critical Care Medicine',
		label: 'Critical Care Medicine',
	},
	{
		value: 'Cytopathology',
		label: 'Cytopathology',
	},
	{
		value: 'Dermatologic Surgery',
		label: 'Dermatologic Surgery',
	},
	{
		value: 'Dermatology',
		label: 'Dermatology',
	},
	{
		value: 'Dermatopathology',
		label: 'Dermatopathology',
	},
	{
		value: 'Developmental-Behavioral Pediatrics',
		label: 'Developmental-Behavioral Pediatrics',
	},
	{
		value: 'Diabetes',
		label: 'Diabetes',
	},
	{
		value: 'Diagnostic Radiology',
		label: 'Diagnostic Radiology',
	},
	{
		value: 'Emergency Medicine',
		label: 'Emergency Medicine',
	},
	{
		value: 'Endocrinology',
		label: 'Endocrinology',
	},
	{
		value: 'Facial Plastic Surgery',
		label: 'Facial Plastic Surgery',
	},
	{
		value: 'Family Medicine',
		label: 'Family Medicine',
	},
	{
		value: 'Family Practice',
		label: 'Family Practice',
	},
	{
		value: 'Foot & Ankle Orthopedics',
		label: 'Foot & Ankle Orthopedics',
	},
	{
		value: 'Forensic Pathology',
		label: 'Forensic Pathology',
	},
	{
		value: 'Forensic Psychiatry',
		label: 'Forensic Psychiatry',
	},
	{
		value: 'Forensic Psychology',
		label: 'Forensic Psychology',
	},
	{
		value: 'Gastroenterology',
		label: 'Gastroenterology',
	},
	{
		value: 'General Practice',
		label: 'General Practice',
	},
	{
		value: 'General Preventive Medicine',
		label: 'General Preventive Medicine',
	},
	{
		value: 'General Surgery',
		label: 'General Surgery',
	},
	{
		value: 'Geriatric Medicine - FP',
		label: 'Geriatric Medicine - FP',
	},
	{
		value: 'Geriatric Medicine - IM',
		label: 'Geriatric Medicine - IM',
	},
	{
		value: 'Geriatric Psychiatry',
		label: 'Geriatric Psychiatry',
	},
	{
		value: 'Geropsychology',
		label: 'Geropsychology',
	},
	{
		value: 'Gynecological Oncology',
		label: 'Gynecological Oncology',
	},
	{
		value: 'Gynecology',
		label: 'Gynecology',
	},
	{
		value: 'Hand Surgery',
		label: 'Hand Surgery',
	},
	{
		value: 'Head & Neck Surgery',
		label: 'Head & Neck Surgery',
	},
	{
		value: 'Hematology',
		label: 'Hematology',
	},
	{
		value: 'Hematology/Oncology',
		label: 'Hematology/Oncology',
	},
	{
		value: 'Hepatology',
		label: 'Hepatology',
	},
	//{
	//    "value": "Hospice and Palliative Care",
	//    "label": "Hospice and Palliative Care"
	//},
	//{
	//    "value": "Hospitalist",
	//    "label": "Hospitalist"
	//},
	{
		value: 'IM/Pediatrics',
		label: 'IM/Pediatrics',
	},
	{
		value: 'Immunology',
		label: 'Immunology',
	},
	{
		value: 'Infectious Disease',
		label: 'Infectious Disease',
	},
	{
		value: 'Internal Medicine',
		label: 'Internal Medicine',
	},
	{
		value: 'Interventional Cardiology',
		label: 'Interventional Cardiology',
	},
	{
		value: 'Interventional Neurology',
		label: 'Interventional Neurology',
	},
	{
		value: 'Maternal & Fetal Medicine',
		label: 'Maternal & Fetal Medicine',
	},
	{
		value: 'Medical Genetics',
		label: 'Medical Genetics',
	},
	{
		value: 'Medical Genetics and Genomics',
		label: 'Medical Genetics and Genomics',
	},
	{
		value: 'Medical Microbiology',
		label: 'Medical Microbiology',
	},
	{
		value: 'Medical Oncology',
		label: 'Medical Oncology',
	},
	{
		value: 'Medical Toxicology',
		label: 'Medical Toxicology',
	},
	{
		value: 'Molecular Genetic Pathology',
		label: 'Molecular Genetic Pathology',
	},
	{
		value: 'Musculoskeletal Oncology',
		label: 'Musculoskeletal Oncology',
	},
	{
		value: 'Musculoskeletal Radiology',
		label: 'Musculoskeletal Radiology',
	},
	{
		value: 'Neonatal-Perinatal Medicine',
		label: 'Neonatal-Perinatal Medicine',
	},
	{
		value: 'Nephrology',
		label: 'Nephrology',
	},
	{
		value: 'Neurodevelopmental Disabilities',
		label: 'Neurodevelopmental Disabilities',
	},
	{
		value: 'Neurointerventional Radiology',
		label: 'Neurointerventional Radiology',
	},
	{
		value: 'Neurological Surgery',
		label: 'Neurological Surgery',
	},
	{
		value: 'Neurology',
		label: 'Neurology',
	},
	{
		value: 'Neurology/Diag Rad/Neuroradiology',
		label: 'Neurology/Diag Rad/Neuroradiology',
	},
	{
		value: 'Neuropathology',
		label: 'Neuropathology',
	},
	{
		value: 'Neuroradiology',
		label: 'Neuroradiology',
	},
	{
		value: 'Nuclear Cardiology',
		label: 'Nuclear Cardiology',
	},
	{
		value: 'Nuclear Medicine',
		label: 'Nuclear Medicine',
	},
	{
		value: 'Nuclear Radiology',
		label: 'Nuclear Radiology',
	},
	{
		value: 'Nutrition',
		label: 'Nutrition',
	},
	{
		value: 'Obstetric Critical Care Medicine',
		label: 'Obstetric Critical Care Medicine',
	},
	{
		value: 'Obstetrics',
		label: 'Obstetrics',
	},
	{
		value: 'Obstetrics & Gynecology',
		label: 'Obstetrics & Gynecology',
	},
	{
		value: 'Occupational Medicine',
		label: 'Occupational Medicine',
	},
	{
		value: 'Ophthalmology',
		label: 'Ophthalmology',
	},
	{
		value: 'Oral & Maxillofacial Surgery',
		label: 'Oral & Maxillofacial Surgery',
	},
	{
		value: 'Orthopedic Hand Surgery',
		label: 'Orthopedic Hand Surgery',
	},
	{
		value: 'Orthopedic Surgery',
		label: 'Orthopedic Surgery',
	},
	{
		value: 'Orthopedic Trauma Surgery',
		label: 'Orthopedic Trauma Surgery',
	},
	{
		value: 'Osteopathic Neuromusculoskeletal Surgery',
		label: 'Osteopathic Neuromusculoskeletal Surgery',
	},
	{
		value: 'Other',
		label: 'Other',
	},
	{
		value: 'Otolaryngology',
		label: 'Otolaryngology',
	},
	{
		value: 'Otology/Neurotology',
		label: 'Otology/Neurotology',
	},
	{
		value: 'Pain Management',
		label: 'Pain Management',
	},
	{
		value: 'Pain Medicine',
		label: 'Pain Medicine',
	},
	{
		value: 'Pathology',
		label: 'Pathology',
	},
	{
		value: 'Pediatric Allergy',
		label: 'Pediatric Allergy',
	},
	{
		value: 'Pediatric Anesthesiology',
		label: 'Pediatric Anesthesiology',
	},
	{
		value: 'Pediatric Cardiology',
		label: 'Pediatric Cardiology',
	},
	{
		value: 'Pediatric Cardiothoracic Surgery',
		label: 'Pediatric Cardiothoracic Surgery',
	},
	{
		value: 'Pediatric Clinical & Lab Immunology',
		label: 'Pediatric Clinical & Lab Immunology',
	},
	{
		value: 'Pediatric Critical Care Medicine',
		label: 'Pediatric Critical Care Medicine',
	},
	{
		value: 'Pediatric Dermatology',
		label: 'Pediatric Dermatology',
	},
	{
		value: 'Pediatric Emergency Medicine',
		label: 'Pediatric Emergency Medicine',
	},
	{
		value: 'Pediatric Endocrinology',
		label: 'Pediatric Endocrinology',
	},
	{
		value: 'Pediatric Gastroenterology',
		label: 'Pediatric Gastroenterology',
	},
	{
		value: 'Pediatric Hematology/Oncology',
		label: 'Pediatric Hematology/Oncology',
	},
	{
		value: 'Pediatric Hospitalist',
		label: 'Pediatric Hospitalist',
	},
	{
		value: 'Pediatric Infectious Disease',
		label: 'Pediatric Infectious Disease',
	},
	{
		value: 'Pediatric Medical Toxicology',
		label: 'Pediatric Medical Toxicology',
	},
	{
		value: 'Pediatric Nephrology',
		label: 'Pediatric Nephrology',
	},
	{
		value: 'Pediatric Ophthalmology',
		label: 'Pediatric Ophthalmology',
	},
	{
		value: 'Pediatric Orthopedics',
		label: 'Pediatric Orthopedics',
	},
	{
		value: 'Pediatric Otolaryngology',
		label: 'Pediatric Otolaryngology',
	},
	{
		value: 'Pediatric Pathology',
		label: 'Pediatric Pathology',
	},
	{
		value: 'Pediatric Pulmonology',
		label: 'Pediatric Pulmonology',
	},
	{
		value: 'Pediatric Radiology',
		label: 'Pediatric Radiology',
	},
	{
		value: 'Pediatric Rehabilitation Medicine',
		label: 'Pediatric Rehabilitation Medicine',
	},
	{
		value: 'Pediatric Rheumatology',
		label: 'Pediatric Rheumatology',
	},
	{
		value: 'Pediatrics',
		label: 'Pediatrics',
	},
	{
		value: 'Pediatric Surgery',
		label: 'Pediatric Surgery',
	},
	{
		value: 'Pediatric Surgery - Neurological',
		label: 'Pediatric Surgery - Neurological',
	},
	{
		value: 'Pediatric Urology',
		label: 'Pediatric Urology',
	},
	{
		value: 'Physical Medicine & Rehabilitation',
		label: 'Physical Medicine & Rehabilitation',
	},
	{
		value: 'Plastic Surgery',
		label: 'Plastic Surgery',
	},
	{
		value: 'Plastic Surgery within Head & Neck',
		label: 'Plastic Surgery within Head & Neck',
	},
	{
		value: 'Podiatry',
		label: 'Podiatry',
	},
	{
		value: 'Preventative Medicine',
		label: 'Preventative Medicine',
	},
	{
		value: 'Proctology',
		label: 'Proctology',
	},
	{
		value: 'Psychiatry',
		label: 'Psychiatry',
	},
	{
		value: 'Psychoanalysis',
		label: 'Psychoanalysis',
	},
	{
		value: 'Psychology',
		label: 'Psychology',
	},
	{
		value: 'Public Health & General Prev. Med',
		label: 'Public Health & General Prev. Med',
	},
	{
		value: 'Pulmonary Critical Care Medicine',
		label: 'Pulmonary Critical Care Medicine',
	},
	{
		value: 'Pulmonary Disease',
		label: 'Pulmonary Disease',
	},
	{
		value: 'Radiation Oncology',
		label: 'Radiation Oncology',
	},
	{
		value: 'Radiological Physics',
		label: 'Radiological Physics',
	},
	{
		value: 'Radiology',
		label: 'Radiology',
	},
	{
		value: 'Rehabilitation Psychology',
		label: 'Rehabilitation Psychology',
	},
	{
		value: 'Reproductive Endocrinology',
		label: 'Reproductive Endocrinology',
	},
	{
		value: 'Rheumatology',
		label: 'Rheumatology',
	},
	{
		value: 'School Psychology',
		label: 'School Psychology',
	},
	{
		value: 'Selective Pathology',
		label: 'Selective Pathology',
	},
	{
		value: 'Spinal Cord Injury',
		label: 'Spinal Cord Injury',
	},
	{
		value: 'Spine Surgery',
		label: 'Spine Surgery',
	},
	{
		value: 'Sports Medicine - (PM & R)',
		label: 'Sports Medicine - (PM & R)',
	},
	{
		value: 'Sports Medicine - EM',
		label: 'Sports Medicine - EM',
	},
	{
		value: 'Sports Medicine - FP',
		label: 'Sports Medicine - FP',
	},
	{
		value: 'Sports Medicine-IM',
		label: 'Sports Medicine-IM',
	},
	{
		value: 'Sports Medicine - Orthopedics',
		label: 'Sports Medicine - Orthopedics',
	},
	{
		value: 'Sports Medicine - Pediatric',
		label: 'Sports Medicine - Pediatric',
	},
	{
		value: 'Surgery',
		label: 'Surgery',
	},
	{
		value: 'Surgical Critical Care',
		label: 'Surgical Critical Care',
	},
	{
		value: 'Surgical Oncology',
		label: 'Surgical Oncology',
	},
	{
		value: 'Thoracic Surgery',
		label: 'Thoracic Surgery',
	},
	{
		value: 'Transplant Surgery',
		label: 'Transplant Surgery',
	},
	{
		value: 'Trauma Surgery',
		label: 'Trauma Surgery',
	},
	{
		value: 'Undersea & Hyperbaric Medicine',
		label: 'Undersea & Hyperbaric Medicine',
	},
	//{
	//    "value": "Urgent Care",
	//    "label": "Urgent Care"
	//},
	//{
	//    "value": "Urogynecology",
	//    "label": "Urogynecology"
	//},
	{
		value: 'Urology',
		label: 'Urology',
	},
	{
		value: 'Vascular/Interventional Radiology',
		label: 'Vascular/Interventional Radiology',
	},
	{
		value: 'Vascular Medicine',
		label: 'Vascular Medicine',
	},
	{
		value: 'Vascular Neurology',
		label: 'Vascular Neurology',
	},
	{
		value: 'Vascular Surgery',
		label: 'Vascular Surgery',
	},
	{
		value: 'Anesthesiology Critical Care Medicine',
		label: 'Anesthesiology Critical Care Medicine',
	},
	{
		value: 'Advanced Heart Failure and Transplant Cardiology',
		label: 'Advanced Heart Failure and Transplant Cardiology',
	},
	{
		value: 'Pediatric Transplant Hepatology',
		label: 'Pediatric Transplant Hepatology',
	},
	{
		value: 'Neurocritical Care',
		label: 'Neurocritical Care',
	},
	{
		value: 'Adult Congenital Heart Disease',
		label: 'Adult Congenital Heart Disease',
	},
	{
		value: 'Sleep Medicine',
		label: 'Sleep Medicine',
	},
	{
		value: 'Hematopathology',
		label: 'Hematopathology',
	},
	{
		value: 'Cardiothoracic Surgery',
		label: 'Cardiothoracic Surgery',
	},
	{
		value: 'Child Abuse Pediatrics',
		label: 'Child Abuse Pediatrics',
	},
	{
		value: 'Brain Injury Medicine',
		label: 'Brain Injury Medicine',
	},
	{
		value: 'Neuromuscular Medicine',
		label: 'Neuromuscular Medicine',
	},
	{
		value: 'Clinical Informatics',
		label: 'Clinical Informatics',
	},
	{
		value: 'Epilepsy',
		label: 'Epilepsy',
	},
	{
		value: 'Medical Physics (Diagnostic/Nuclear/Therapeutic)',
		label: 'Medical Physics (Diagnostic/Nuclear/Therapeutic)',
	},
	{
		value: 'Interventional Radiology and Diagnostic Radiology',
		label: 'Interventional Radiology and Diagnostic Radiology',
	},
	{
		value: 'Urological Surgery',
		label: 'Urological Surgery',
	},
	{
		value: 'Female Pelvic Medicine and Reconstructive Surgery',
		label: 'Female Pelvic Medicine and Reconstructive Surgery',
	},
	//{
	//    "value": "Women’s Imaging",
	//    "label": "Women’s Imaging"
	//},
	//{
	//    "value": "Wound Care",
	//    "label": "Wound Care"
	//}
];
const programSalutation = [
	{
		value: 'Mr.',
		label: 'Mr.',
	},
	{
		value: 'Ms.',
		label: 'Ms.',
	},
	{
		value: 'Mrs.',
		label: 'Mrs.',
	},
	{
		value: 'Dr.',
		label: 'Dr.',
	},
	{
		value: 'Prof.',
		label: 'Prof.',
	},
];

const locumsPosition = [
	{
		value: 'Yes',
		label: 'Yes',
	},
	{
		value: 'No',
		label: 'No',
	},
];

const branchService = [
	{
		value: 'Army',
		label: 'Army',
	},
	{
		value: 'Air Force',
		label: 'Air Force',
	},
	{
		value: 'Marines',
		label: 'Marines',
	},
	{
		value: 'Navy',
		label: 'Navy',
	},
	{
		value: 'Coast Guard',
		label: 'Coast Guard',
	},
];

const militaryStatus = [
	{
		value: 'Active',
		label: 'Active',
	},
	{
		value: 'Reserve',
		label: 'Reserve',
	},
	{
		value: 'Dishonorable',
		label: 'Dishonorable',
	},
	{
		value: 'Honorable',
		label: 'Honorable',
	},
];
const DOCUMENT_TYPE = {
	AANA: 'AANA Cert Card',
	BirthCertificate: 'Birth Certificate',
	BoardCertificate: 'Board/Specialty Certificate / Award Letter',
	CSR: 'CSR',
	CaseLogsNonMammos: 'Case Logs-non Mammo #s',
	ChildAbuseClearance: 'Child Abuse Clearance',
	Claims: 'Clinician_Claims History',
	ContinuingEducation: 'Continuing Education',
	CourtBoardDocuments: 'Clinician_Court/Board Documents',
	CurriculumVitae: 'Curriculum Vitae',
	DD214: 'DD-214 Military Discharge',
	DeaRegistration: 'DEA Registration',
	ECFMG: 'ECFMG',
	Explanation: 'Clinician_Explanation/Narratives',
	GovtTraining: 'GOVT Training Certificate',
	Health: 'Health History',
	LOQ: 'Letter of Qualification',
	LifeSupportCerts: 'Life Support Certs',
	MalpracticeCarrier: 'Insurance/Malpractice Carrier',
	MammoNumbers: 'Mammo Numbers',
	Medical: 'Medical',
	NonMedical: 'Non-Medical Graduate',
	NpdbSelfQuery: 'NPDB Self Query – from clinician',
	NPI: 'NPI-Enrollment Confirmation',
	NuclearMaterialsLicense: 'Nuclear Materials License',
	Photo: 'Photo',
	PhotoId: 'Photo ID',
	PrescriptionMonitoring: 'Prescription Monitoring Program',
	RadioactiveMaterialsLicense: 'Radioactive Materials License',
	StateLicense: 'State License',
	Testimonials: 'Testimonials',
	Transcripts: 'Transcripts',
	Undergraduate: 'Undergraduate',
	Visa: 'Visa/Naturalization papers',
	XRay: 'X-Ray Supervisor',
	//TDS-3158
	FCVS: 'FCVS Profile',
	FellowshipCertificate: 'Fellowship Certificate',
	FifthPathway: 'Fifth Pathway',
	InternshipCertificate: 'Internship Certificate',
	Professional: 'Professional',
	ResidencyCertificate: 'Residency Certificate',
	Aviation: 'Aviation Md Examiner License',
	AwardCert: 'Award Cert',
	InfectionControlCertificate: 'Infection Control Certificate',
	LicenseBoardExams: 'License Board Exams',
	LicensureNonUS: 'Licensure-non US',
	Oklahoma: 'Oklahoma CAPE Certificate',
	SpecialCertifications: 'Special Certifications',
	MammoLetter: 'Mammo Letter-Attestation',
	MarriageCert: 'Marriage Cert/Name Change',
	Medicare: 'Medicare/Medicaid #s',
	Membership: 'Membership/Association',
	Military: 'Military Paperwork (not DD-214)',
	MQSA: 'MQSA CME-Digital',
	NonLTCOI: 'Non-LT COI',
	Appt: 'Appt Ltr-LT (Booking)',
	CAQHAPP: 'CAQH Application',
	COMPCREDAPP: 'Complete Cred App',
	COMPEHOSPAPP: 'Complete Hospital Application',
	COMPLICAPP: 'Complete License Application',
	DEAAP: 'DEA Application',
	RECREDAPP: 'Recredentialing App',
	RELEASEFORM: 'Release Form',
	DEAPOA: 'DEA Power of Attorney',
	COVID19: 'COVID-19 Vaccination',
};

const DOCUMENT_TYPE_LABEL = {
	AANA: 'AANA Certification Card',
	BirthCertificate: 'Birth Certificate',
	BoardCertificate: 'Board/Specialty Certificate/Award Letter',
	CSR: 'Controlled Substance Registration',
	CaseLogsNonMammos: 'Case Logs-non Mammo Numbers',
	ChildAbuseClearance: 'Child Abuse Clearance',
	Claims: 'Clinician Claims History',
	ContinuingEducation: 'Continuing Education',
	CourtBoardDocuments: 'Clinician Court/Board Documents',
	CurriculumVitae: 'Curriculum Vitae',
	DD214: 'DD-214 Military Discharge',
	DeaRegistration: 'DEA Registration',
	ECFMG: 'ECFMG',
	Explanation: 'Clinician Explanation/Narratives',
	GovtTraining: 'Government Training Certificate',
	Health: 'Health History',
	LOQ: 'Letter of Qualification',
	LifeSupportCerts: 'Life Support Certs',
	MalpracticeCarrier: 'Insurance/Malpractice Carrier',
	MammoNumbers: 'Mammo Numbers',
	Medical: 'Medical Diploma',
	NonMedical: 'Non-Medical Graduate Diploma',
	NpdbSelfQuery: 'NPDB Self Query – from clinician',
	NPI: 'NPI-Enrollment Confirmation',
	NuclearMaterialsLicense: 'Nuclear Materials License',
	Photo: 'Photo',
	PhotoId: 'Photo ID',
	PrescriptionMonitoring: 'Prescription Monitoring Program',
	RadioactiveMaterialsLicense: 'Radioactive Materials License',
	StateLicense: 'State License',
	Testimonials: 'Testimonials',
	Transcripts: 'Transcripts',
	Undergraduate: 'Undergraduate Diploma',
	Visa: 'Visa/Naturalization Papers',
	XRay: 'X-Ray Supervisor License',
	//TDS-3158
	FCVS: 'FCVS Profile',
	FellowshipCertificate: 'Fellowship Certificate',
	FifthPathway: 'Fifth Pathway',
	InternshipCertificate: 'Internship Certificate',
	Professional: 'Professional School Diploma',
	ResidencyCertificate: 'Residency Certificate',
	Aviation: 'Aviation Medical Examiner License',
	AwardCert: 'Award Certificate',
	InfectionControlCertificate: 'Infection Control Certificate',
	LicenseBoardExams: 'License Board Exams',
	LicensureNonUS: 'Licensure (International)',
	Oklahoma: 'Oklahoma CAPE Certificate',
	SpecialCertifications: 'Special Certifications',
	MammoLetter: 'Mammo Letter-Attestation',
	MarriageCert: 'Marriage Certificate/Name Change Forms',
	Medicare: 'Medicare Number/Medicaid Number(s)',
	Membership: 'Membership/Association',
	Military: 'Military Paperwork (not DD-214)',
	MQSA: 'MQSA CME-Digital',
	NonLTCOI: 'Certificate of Insurance',
	Appt: 'Appointment Letter(s)',
	CAQHAPP: 'CAQH Application',
	COMPCREDAPP: 'Complete Cred App',
	COMPEHOSPAPP: 'Complete Hospital Application',
	COMPLICAPP: 'Complete License Application',
	DEAAP: 'DEA Application',
	RECREDAPP: 'Recredentialing App',
	RELEASEFORM: 'Release Form',
	DEAPOA: 'DEA Power of Attorney',
	COVID19: 'COVID-19 Vaccination',
};

const DOCUMENT_FIELD_NAME = {
	Url: 'metaData.url',
	Name: 'metaData.name',
	DownloadUrl: 'metaData.downloadUrl',
	MimeType: 'metaData.mimeType',
	Created: 'metaData.created',
	Modified: 'metaData.additionalData.Modified',
	DocumentType: 'metaData.additionalData.Document_x0020_Type',
	Size: 'metaData.additionalData.FileSizeDisplay',
	Email: 'metaData.additionalData.AdobeSign_x0020_Sender_x0020_Email',
	Agreement_ID: 'metaData.additionalData.AdobeSign_x0020_Agreement_x0020_ID',
	Status: 'metaData.additionalData.AdobeSign_x0020_Document_x0020_Status',
	Date: 'metaData.additionalData.AdobeSign_x0020_Document_x0020_Sent_x0020_Date',
};

const DOCUMENT_HIDE_DELETE_BUTTON = [
	'CAQH Application',
	'Complete Cred App',
	'Complete Hospital Application',
	'Complete License Application',
	'DEA Application',
	'Recredentialing App',
	'Release Form',
	'DEA Power of Attorney',
	'Insurance/Malpractice Carrier',
];

const DOCUMENT_REMOVE_LIST = [
	{
		value: 'CAQH Application',
		label: 'CAQH Application',
	},

	{
		value: 'Complete Credentialing Application',
		label: 'Complete Credentialing Application',
	},
	{
		value: 'Complete Hospital Application',
		label: 'Complete Hospital Application',
	},
	{
		value: 'Complete License Application',
		label: 'Complete License Application',
	},
	{
		value: 'DEA Application',
		label: 'DEA Application',
	},
	{
		value: 'Recredentialing Application',
		label: 'Recredentialing Application',
	},
	{
		value: 'Release Form',
		label: 'Release Form',
	},
	{
		value: 'DEA Power of Attorney',
		label: 'DEA Power of Attorney',
	},
	{
		value: 'Insurance/Malpractice Carrier',
		label: 'Insurance/Malpractice Carrier',
	},
];
const gapsReason = [
	{
		value: 'Illness',
		label: 'Illness',
	},
	{
		value: 'Job Search',
		label: 'Job Search',
	},
	{
		value: 'Maternity Leave',
		label: 'Maternity Leave',
	},
	{
		value: 'Moving',
		label: 'Moving',
	},
	{
		value: 'Other',
		label: 'Other',
	},
	{
		value: 'Pending Credentialing',
		label: 'Pending Credentialing',
	},
	{
		value: 'Pending Locums Assignment',
		label: 'Pending Locums Assignment',
	},
	{
		value: 'Pending Placement in PGT Program',
		label: 'Pending Placement in PGT Program',
	},
	{
		value: 'Preparing for Exams/Boards',
		label: 'Preparing for Exams/Boards',
	},
	{
		value: 'Research',
		label: 'Research',
	},
	{
		value: 'Retired',
		label: 'Retired',
	},
	{
		value: 'Sabbatical',
		label: 'Sabbatical',
	},
	{
		value: 'Treatment',
		label: 'Treatment',
	},
	{
		value: 'Vacation',
		label: 'Vacation',
	},
	{
		value: 'Volunteer Work',
		label: 'Volunteer Work',
	},
	{
		value: 'Working Internationally',
		label: 'Working Internationally',
	},
];

const PHYSICIAN_SPECIALTY_BOARD = [
	{
		value: 'American Board of Allergy and Immunology',
		label: 'American Board of Allergy and Immunology (ABAI)',
	},
	{
		value: 'American Board of Anesthesiology',
		label: 'American Board of Anesthesiology (ABA)',
	},
	{
		value: 'American Board of Colon and Rectal Surgery',
		label: 'American Board of Colon and Rectal Surgery (ABCRS)',
	},
	{
		value: 'American Board of Dental Specialties',
		label: 'American Board of Dental Specialties (ABDS)',
	},
	{
		value: 'American Board of Dermatology',
		label: 'American Board of Dermatology (ABD)',
	},
	{
		value: 'American Board of Emergency Medicine',
		label: 'American Board of Emergency Medicine (ABEM)',
	},
	{
		value: 'American Board of Family Medicine',
		label: 'American Board of Family Medicine (ABFM)',
	},
	{
		value: 'American Board of Foot and Ankle Surgery',
		label: 'American Board of Foot and Ankle Surgery (ABFAS)',
	},
	{
		value: 'American Board of General Dentistry',
		label: 'American Board of General Dentistry (ABGD)',
	},
	{
		value: 'American Board of Internal Medicine',
		label: 'American Board of Internal Medicine (ABIM)',
	},
	{
		value: 'American Board of Medical Genetics',
		label: 'American Board of Medical Genetics (ABMGG)',
	},
	{
		value: 'American Board of Neurological Surgery',
		label: 'American Board of Neurological Surgery (ABNS)',
	},
	{
		value: 'American Board of Nuclear Medicine',
		label: 'American Board of Nuclear Medicine (ABNM)',
	},
	{
		value: 'American Board of Obstetrics and Gynecology',
		label: 'American Board of Obstetrics and Gynecology (ABOG)',
	},
	{
		value: 'American Board of Ophthalmology',
		label: 'American Board of Ophthalmology (ABOP)',
	},
	{
		value: 'American Board of Oral and Maxillofacial Surgery',
		label: 'American Board of Oral and Maxillofacial Surgery (ABOMS)',
	},
	{
		value: 'American Board of Orthopaedic Surgery',
		label: 'American Board of Orthopaedic Surgery (ABOS)',
	},
	{
		value: 'American Board of Otolaryngology',
		label: 'American Board of Otolaryngology (ABOHNS)',
	},
	{
		value: 'American Board of Pathology',
		label: 'American Board of Pathology (ABPath)',
	},
	{
		value: 'American Board of Pediatric Neurological Surgery',
		label: 'American Board of Pediatric Neurological Surgery (ABPNS)',
	},
	{
		value: 'American Board of Pediatrics',
		label: 'American Board of Pediatrics (ABP)',
	},
	{
		value: 'American Board of Physical Medicine and Rehabilitation',
		label: 'American Board of Physical Medicine and Rehabilitation (ABPMR)',
	},
	{
		value: 'American Board of Physician Services (ABPS)',
		label: 'American Board of Physician Services (ABPS)',
	},
	{
		value: 'American Board of Plastic Surgery',
		label: 'American Board of Plastic Surgery (ABPS)',
	},
	{
		value: 'American Board of Podiatric Medicine',
		label: 'American Board of Podiatric Medicine (ABPM)',
	},
	{
		value: 'American Board of Preventive Medicine',
		label: 'American Board of Preventive Medicine (ABPM)',
	},
	{
		value: 'American Board of Professional Psychology',
		label: 'American Board of Professional Psychology (ABPP)',
	},
	{
		value: 'American Board of Psychiatry and Neurology',
		label: 'American Board of Psychiatry and Neurology (ABPN)',
	},
	{
		value: 'American Board of Radiology',
		label: 'American Board of Radiology (ABR)',
	},
	{
		value: 'American Board of Surgery',
		label: 'American Board of Surgery (ABS)',
	},
	{
		value: 'American Board of Thoracic Surgery',
		label: 'American Board of Thoracic Surgery (ABTS)',
	},
	{
		value: 'American Board of Urology',
		label: 'American Board of Urology (ABU)',
	},
	{
		value: 'American Osteopathic Board Dermatology (AOBD)',
		label: 'American Osteopathic Board Dermatology (AOBD)',
	},
	{
		value: 'American Osteopathic Board Emergency Medicine (AOBEM)',
		label: 'American Osteopathic Board Emergency Medicine (AOBEM)',
	},
	{
		value: 'American Osteopathic Board Family Physicians (AOBFP)',
		label: 'American Osteopathic Board Family Physicians (AOBFP)',
	},
	{
		value: 'American Osteopathic Board Internal Medicine (AOBIM)',
		label: 'American Osteopathic Board Internal Medicine (AOBIM)',
	},
	{
		value: 'American Osteopathic Board Neurology & Psychiatry (AOBNP)',
		label: 'American Osteopathic Board Neurology & Psychiatry (AOBNP)',
	},
	{
		value: 'American Osteopathic Board of Neurological Surgery (AOBNS)',
		label: 'American Osteopathic Board of Neurological Surgery (AOBNS)',
	},
	{
		value: 'American Osteopathic Board Neuromusculoskeletal Medicine (AOBNMM)',
		label: 'American Osteopathic Board Neuromusculoskeletal Medicine (AOBNMM)',
	},
	{
		value: 'American Osteopathic Board Nuclear Medicine (AOBNM)',
		label: 'American Osteopathic Board Nuclear Medicine (AOBNM)',
	},
	{
		value: 'American Osteopathic Board Obstetrics & Gynecology (AOBOG)',
		label: 'American Osteopathic Board Obstetrics & Gynecology (AOBOG)',
	},
	{
		value: 'American Osteopathic Board of Anesthesiology (AOBA)',
		label: 'American Osteopathic Board of Anesthesiology (AOBA)',
	},
	{
		value: 'American Osteopathic Board Ophthalmology & Otolaryngology (AOBOO)',
		label: 'American Osteopathic Board Ophthalmology & Otolaryngology (AOBOO)',
	},
	{
		value: 'American Osteopathic Board Orthopedic Surgery (AOBOS)',
		label: 'American Osteopathic Board Orthopedic Surgery (AOBOS)',
	},
	{
		value: 'American Osteopathic Board Pathology (AOBPa)',
		label: 'American Osteopathic Board Pathology (AOBPa)',
	},
	{
		value: 'American Osteopathic Board Pediatrics (AOBP)',
		label: 'American Osteopathic Board Pediatrics (AOBP)',
	},
	{
		value: 'American Osteopathic Board Physical Medicine & Rehabilitation (AOBPMR)',
		label: 'American Osteopathic Board Physical Medicine & Rehabilitation (AOBPMR)',
	},
	{
		value: 'American Osteopathic Board Preventive Medicine (AOBPM)',
		label: 'American Osteopathic Board Preventive Medicine (AOBPM)',
	},
	{
		value: 'American Osteopathic Board Proctology (AOBPR)',
		label: 'American Osteopathic Board Proctology (AOBPR)',
	},
	{
		value: 'American Osteopathic Board Radiology (AOBR)',
		label: 'American Osteopathic Board Radiology (AOBR)',
	},
	{
		value: 'American Osteopathic Board Surgery (AOBS)',
		label: 'American Osteopathic Board Surgery (AOBS)',
	},
	{
		value: 'Other',
		label: 'Other',
	},
	{
		value: 'Royal College of Physicians and Surgeons of Canada',
		label: 'Royal College of Physicians and Surgeons of Canada (RCPSC)',
	},
];

const NURSING_AP_SPECIALTY_BOARD = [
	{
		value: 'American Association of Critical-Care Nurses (AACN)',
		label: 'American Association of Critical-Care Nurses (AACN)',
	},
	{
		value: 'American Association of Nurse Practitioners (AANP)',
		label: 'American Association of Nurse Practitioners (AANP)',
	},
	{
		value: 'American Board of Cardiovascular Perfusion',
		label: 'American Board of Cardiovascular Perfusion (ABCP)',
	},
	{
		value: 'American Board of Professional Psychology',
		label: 'American Board of Professional Psychology (ABPP)',
	},
	{
		value: 'American Board of Surgical Assistants',
		label: 'American Board of Surgical Assistants (ABSA)',
	},
	{
		value: 'American Midwifery Certification Board (AMCB)',
		label: 'American Midwifery Certification Board (AMCB)',
	},
	{
		value: 'American Nurses Credentialing Center (ANCC)',
		label: 'American Nurses Credentialing Center (ANCC)',
	},
	{
		value: 'National Board Certification and Recertification of Nurse Anesthetists (NBCRNA)',
		label: 'National Board Certification and Recertification of Nurse Anesthetists (NBCRNA)',
	},
	{
		value: 'National Board of Surgical Technology and Surgical Assisting',
		label: 'National Board of Surgical Technology and Surgical Assisting (NBSTSA)',
	},
	{
		value: 'National Certification Corporation (NCC)',
		label: 'National Certification Corporation (NCC)',
	},
	{
		value: 'National Commission on Certification of Physician Assistant (NCCPA)',
		label: 'National Commission on Certification of Physician Assistant (NCCPA)',
	},
	{
		value: 'National Surgical Assistant Association',
		label: 'National Surgical Assistant Association (NSAA)',
	},
	{
		value: 'Other',
		label: 'Other',
	},
];

const NP_ISSUING_BOARD = [
	{
		value: 'American Association of Nurse Practitioners (AANP)',
		label: 'American Association of Nurse Practitioners (AANP)',
	},
	{
		value: 'American Nurses Credentialing Center (ANCC)',
		label: 'American Nurses Credentialing Center (ANCC)',
	},
	{
		value: 'Other',
		label: 'Other',
	},
	{
		value: 'Pediatric Nursing Certification Board (PNCB)',
		label: 'Pediatric Nursing Certification Board (PNCB)',
	},
	{
		value: 'National Certification Corporation (NCC)',
		label: 'National Certification Corporation (NCC)',
	},
	{
		value: 'American Association of Critical-Care Nurses (AACN)',
		label: 'American Association of Critical-Care Nurses (AACN)',
	},
];

const DNP_PhD_SFA_RN_ISSUING_BOARD = [
	{
		value: 'American Association of Critical-Care Nurses (AACN)',
		label: 'American Association of Critical-Care Nurses (AACN)',
	},
	{
		value: 'American Association of Nurse Practitioners (AANP)',
		label: 'American Association of Nurse Practitioners (AANP)',
	},
	{
		value: 'American Board of Cardiovascular Perfusion',
		label: 'American Board of Cardiovascular Perfusion (ABCP)',
	},
	{
		value: 'American Board of Professional Psychology',
		label: 'American Board of Professional Psychology (ABPP)',
	},
	{
		value: 'American Board of Surgical Assistants',
		label: 'American Board of Surgical Assistants (ABSA)',
	},
	{
		value: 'American Midwifery Certification Board (AMCB)',
		label: 'American Midwifery Certification Board (AMCB)',
	},
	{
		value: 'American Nurses Credentialing Center (ANCC)',
		label: 'American Nurses Credentialing Center (ANCC)',
	},
	{
		value: 'National Board Certification and Recertification of Nurse Anesthetists (NBCRNA)',
		label: 'National Board Certification and Recertification of Nurse Anesthetists (NBCRNA)',
	},
	{
		value: 'National Board of Surgical Technology and Surgical Assisting',
		label: 'National Board of Surgical Technology and Surgical Assisting (NBSTSA)',
	},
	{
		value: 'National Certification Corporation (NCC)',
		label: 'National Certification Corporation (NCC)',
	},
	{
		value: 'National Commission on Certification of Physician Assistant (NCCPA)',
		label: 'National Commission on Certification of Physician Assistant (NCCPA)',
	},
	{
		value: 'National Surgical Assistant Association',
		label: 'National Surgical Assistant Association (NSAA)',
	},
	{
		value: 'Other',
		label: 'Other',
	},
];

const CNS_ISSUING_BOARD = [
	{
		value: 'American Nurses Credentialing Center (ANCC)',
		label: 'American Nurses Credentialing Center (ANCC)',
	},
	{
		value: 'Other',
		label: 'Other',
	},
	{
		value: 'Pediatric Nursing Certification Board (PNCB)',
		label: 'Pediatric Nursing Certification Board (PNCB)',
	},
	{
		value: 'American Association of Critical-Care Nurses (AACN)',
		label: 'American Association of Critical-Care Nurses (AACN)',
	},
];

const CNM_ISSUING_BOARD = [
	{
		value: 'American Association of Critical-Care Nurses (AACN)',
		label: 'American Association of Critical-Care Nurses (AACN)',
	},
	{
		value: 'American Midwifery Certification Board (AMCB)',
		label: 'American Midwifery Certification Board (AMCB)',
	},
	{
		value: 'American Nurses Credentialing Center (ANCC)',
		label: 'American Nurses Credentialing Center (ANCC)',
	},
	{
		value: 'Other',
		label: 'Other',
	},
];

const CRNA_ISSUING_BOARD = [
	{
		value: 'American Association of Critical-Care Nurses (AACN)',
		label: 'American Association of Critical-Care Nurses (AACN)',
	},
	{
		value: 'National Board Certification and Recertification of Nurse Anesthetists (NBCRNA)',
		label: 'National Board Certification and Recertification of Nurse Anesthetists (NBCRNA)',
	},
	{
		value: 'American Nurses Credentialing Center (ANCC)',
		label: 'American Nurses Credentialing Center (ANCC)',
	},
	{
		value: 'Other',
		label: 'Other',
	},
];

const NURSING_CERTIFICATION_PICKLIST = [
	{
		value: 'Advanced Burn Life Support - ABLS',
		label: 'Advanced Burn Life Support - ABLS',
	},
	{
		value: 'Advanced Cardiac Life Support - ACLS',
		label: 'Advanced Cardiac Life Support - ACLS',
	},
	{
		value: 'Advanced Life Support in Obstetrics - ALSO',
		label: 'Advanced Life Support in Obstetrics - ALSO',
	},
	{
		value: 'Advanced Trauma Life Support - ATLS',
		label: 'Advanced Trauma Life Support - ATLS',
	},
	{
		value: 'Basic Cardiac Life Support - BCLS',
		label: 'Basic Cardiac Life Support - BCLS',
	},
	{
		value: 'Basic Disaster Life Support - BDLS',
		label: 'Basic Disaster Life Support - BDLS',
	},
	{
		value: 'Basic Life Support - BLS',
		label: 'Basic Life Support - BLS',
	},
	{
		value: 'CA Fluroscopy Supervisor and Operator',
		label: 'CA Fluroscopy Supervisor and Operator',
	},
	{
		value: 'Comprehensive Advanced Life Support - CALS',
		label: 'Comprehensive Advanced Life Support - CALS',
	},
	{
		value: 'CPR/AED',
		label: 'CPR/AED',
	},
	{
		value: 'Neonatal Advanced Life Support - NALS',
		label: 'Neonatal Advanced Life Support - NALS',
	},
	{
		value: 'Neonatal Resuscitation Program - NRP',
		label: 'Neonatal Resuscitation Program - NRP',
	},
	{
		value: 'Nuclear Materials License',
		label: 'Nuclear Materials License',
	},
	{
		value: 'Pediatric Advanced Life Support - PALS',
		label: 'Pediatric Advanced Life Support - PALS',
	},
	{
		value: 'Radioactive Materials License',
		label: 'Radioactive Materials License',
	},
	{
		value: 'CSFA',
		label: 'CSFA',
	},
	{
		value: 'Transesophageal Echocardiography - TEE',
		label: 'Transesophageal Echocardiography - TEE',
	},
	{
		value: 'X-RAY Permit/Certificate',
		label: 'X-RAY Permit/Certificate',
	},
	{
		value: 'X-Ray Supervisor and Operator License',
		label: 'X-Ray Supervisor and Operator License',
	},
];

const NP_CERTIFICATION_PICKLIST = [
	{
		value: 'Adult Nurse Practitioner Certification (ANP-BC)',
		label: 'Adult Nurse Practitioner Certification (ANP-BC)',
	},
	{
		value: 'Adult-Gerontology Acute Care Nurse Practitioner Certification (AGACNP-BC)',
		label: 'Adult-Gerontology Acute Care Nurse Practitioner Certification (AGACNP-BC)',
	},
	{
		value: 'Adult-Gerontology Primary Care Nurse Practitioner Certification (AGPCNP-BC)',
		label: 'Adult-Gerontology Primary Care Nurse Practitioner Certification (AGPCNP-BC)',
	},
	{
		value: 'Advanced Diabetes Management Certification (ADM-BC)',
		label: 'Advanced Diabetes Management Certification (ADM-BC)',
	},
	{
		value: 'Emergency Nurse Practitioner Certification (ENP-BC)',
		label: 'Emergency Nurse Practitioner Certification (ENP-BC)',
	},
	{
		value: 'Family Nurse Practitioner Certification (FNP-BC)',
		label: 'Family Nurse Practitioner Certification (FNP-BC)',
	},
	{
		value: 'Gerontological Nurse Practitioner Certification (GNP-BC)',
		label: 'Gerontological Nurse Practitioner Certification (GNP-BC)',
	},
	{
		value: 'Pediatric Primary Care Nurse Practitioner Certification (PPCNP-BC)',
		label: 'Pediatric Primary Care Nurse Practitioner Certification (PPCNP-BC)',
	},
	{
		value: 'Psychiatric-Mental Health Nurse Practitioner (Across the Lifespan) Certification (PMHNP-BC)',
		label: 'Psychiatric-Mental Health Nurse Practitioner (Across the Lifespan) Certification (PMHNP-BC)',
	},
	{
		value: 'School Nurse Practitioner Certification (SNP-BC)',
		label: 'School Nurse Practitioner Certification (SNP-BC)',
	},
	{
		value: 'Family Nurse Practitioner',
		label: 'Family Nurse Practitioner',
	},
	{
		value: 'Adult-Gerontology Primary Care Nurse Practitioner',
		label: 'Adult-Gerontology Primary Care Nurse Practitioner',
	},
	{
		value: 'Adult-Gerontology Acute Care Nurse Practitioner',
		label: 'Adult-Gerontology Acute Care Nurse Practitioner',
	},
	{
		value: 'Psychiatric-Mental Health Nurse Practitioner',
		label: 'Psychiatric-Mental Health Nurse Practitioner',
	},
	{
		value: 'Adult-Gerontology Nurse Practitioner',
		label: 'Adult-Gerontology Nurse Practitioner',
	},
	{
		value: 'Certified Pediatric Nurse Practitioner - Primary Care',
		label: 'Certified Pediatric Nurse Practitioner - Primary Care',
	},
	{
		value: 'Certified Pediatric Nurse Practitioner - Acute Care',
		label: 'Certified Pediatric Nurse Practitioner - Acute Care',
	},
	{
		value: 'Acute Care Nurse Practitioner',
		label: 'Acute Care Nurse Practitioner',
	},
	{
		value: 'Acute Care Nurse Practitioner - Adult-Gerontology ',
		label: 'Acute Care Nurse Practitioner - Adult-Gerontology ',
	},
	{
		value: 'Tele-ICU Acute/Critical Care Nursing',
		label: 'Tele-ICU Acute/Critical Care Nursing',
	},
	{
		value: 'Cardiac Medicine',
		label: 'Cardiac Medicine',
	},
	{
		value: 'Cardiac Surgery',
		label: 'Cardiac Surgery',
	},
	{
		value: 'Progressive Care Nursing',
		label: 'Progressive Care Nursing',
	},
];

const CNS_CERTIFICATION_PICKLIST = [
	{
		value: 'Adult-Gerontology Certified Nurse Specialist',
		label: 'Adult-Gerontology Certified Nurse Specialist',
	},
	{
		value: 'Pediatric Primary Care Mental Health Specialist',
		label: 'Pediatric Primary Care Mental Health Specialist',
	},
	{
		value: 'Tele-ICU Acute/Critical Care Nursing',
		label: 'Tele-ICU Acute/Critical Care Nursing',
	},
	{
		value: 'Cardiac Medicine',
		label: 'Cardiac Medicine',
	},
	{
		value: 'Cardiac Surgery',
		label: 'Cardiac Surgery',
	},
	{
		value: 'Progressive Care Nursing',
		label: 'Progressive Care Nursing',
	},
	{
		value: 'Acute Care Clinical Nurse Specialist - Adult-Gerontology',
		label: 'Acute Care Clinical Nurse Specialist - Adult-Gerontology',
	},
	{
		value: 'Acute Care Clinical Nurse Specialist - Pediatric',
		label: 'Acute Care Clinical Nurse Specialist - Pediatric',
	},
	{
		value: 'Acute Care Clinical Nurse Specialist - Neonatology',
		label: 'Acute Care Clinical Nurse Specialist - Neonatology',
	},
];

const CNM_CERTIFICATION_PICKLIST = [
	{
		value: 'Tele-ICU Acute/Critical Care Nursing',
		label: 'Tele-ICU Acute/Critical Care Nursing',
	},
	{
		value: 'Cardiac Medicine',
		label: 'Cardiac Medicine',
	},
	{
		value: 'Cardiac Surgery',
		label: 'Cardiac Surgery',
	},
	{
		value: 'Progressive Care Nursing',
		label: 'Progressive Care Nursing',
	},
	{
		value: 'Certified Nurse Midwife',
		label: 'Certified Nurse Midwife',
	},
];

const CRNA_CERTIFACTION_PICKLIST = [
	{
		value: 'Tele-ICU Acute/Critical Care Nursing',
		label: 'Tele-ICU Acute/Critical Care Nursing',
	},
	{
		value: 'Cardiac Medicine',
		label: 'Cardiac Medicine',
	},
	{
		value: 'Cardiac Surgery',
		label: 'Cardiac Surgery',
	},
	{
		value: 'Progressive Care Nursing',
		label: 'Progressive Care Nursing',
	},
	{
		value: 'Certified Registered Nurse Anesthetist',
		label: 'Certified Registered Nurse Anesthetist',
	},
];

const DNAP_ISSUING_BOARD = [
	{
		value: 'American Association of Nurse Practitioners (AANP)',
		label: 'American Association of Nurse Practitioners (AANP)',
	},
	{
		value: 'American Board of Professional Psychology',
		label: 'American Board of Professional Psychology (ABPP)',
	},
	{
		value: 'American Midwifery Certification Board (AMCB)',
		label: 'American Midwifery Certification Board (AMCB)',
	},
	{
		value: 'American Nurses Credentialing Center (ANCC)',
		label: 'American Nurses Credentialing Center (ANCC)',
	},
	{
		value: 'American Board of Surgical Assistants',
		label: 'American Board of Surgical Assistants (ABSA)',
	},
	{
		value: 'National Surgical Assistant Association',
		label: 'National Surgical Assistant Association (NSAA)',
	},
	{
		value: 'National Board Certification and Recertification of Nurse Anesthetists (NBCRNA)',
		label: 'National Board Certification and Recertification of Nurse Anesthetists (NBCRNA)',
	},
	{
		value: 'National Board of Surgical Technology and Surgical Assisting',
		label: 'National Board of Surgical Technology and Surgical Assisting (NBSTSA)',
	},
	{
		value: 'National Certification Corporation (NCC)',
		label: 'National Certification Corporation (NCC)',
	},
];

const professionList = [
	{
		value: 'Dentist',
		label: 'Dentist',
	},
	{
		value: 'CNM',
		label: 'CNM',
	},
	{
		value: 'CNS',
		label: 'CNS',
	},
	{
		value: 'CRNA',
		label: 'CRNA',
	},
	{
		value: 'NP',
		label: 'NP',
	},
	{
		value: 'PA',
		label: 'PA',
	},
	{
		value: 'Physician',
		label: 'Physician',
	},
	{
		value: 'Podiatrist',
		label: 'Podiatrist',
	},
	{
		value: 'Psychologist',
		label: 'Psychologist',
	},
	{
		value: 'SFA',
		label: 'Surgical Assistant',
	},
	{
		value: 'Perfusionist',
		label: 'Perfusionist',
	},
	{
		value: 'LCSW',
		label: 'LCSW',
	},
	{
		value: 'LPC',
		label: 'LPC',
	},
	{
		value: 'LMFT',
		label: 'LMFT',
	},
	{
		value: 'CAA',
		label: 'CAA',
	},
];

const DEGREE = {
	MD: 'MD',
	DO: 'DO',
	CRNA: 'CRNA',
	NP: 'NP',
	DNP: 'DNP',
	PA: 'PA',
	MBBS: 'MBBS',
};

//Status from SF
const CRED_STATUS = {
	ApplicationRequested: 'Application Requested',
	ApplicationSent: 'App Sent',
	ApplicationStarted: 'Application Started',
	ApplicationReceived: 'App Received',
	InProcess: 'In Process',
	TempApproved: 'Temp Approved',
	Approved: 'Approved',
	ApprovedConditions: 'Approved Conditions',
	NotApproved: 'Not Approved',
	Incomplete: 'Incomplete',
	Expired: 'Expired',
	Expiring: 'Expiring',
	Hold: 'Hold',
	PermProvider: 'Perm Provider',
	PreCredentialing: 'PreCredentialing',
};

const CRED_TYPE = {
	RECRED: 'Re-cred',
	INITIAL: 'Initial',
};

const CRED_SOURCE = {
	SSO: 'SSO Integration'
};

const CRED_RECIPE_TYPE = {
	EngagedInitial: 'Engaged Initial',
	EngagedReappointment: 'Engaged Reappointment',
};

const CRED_APP_STATUS = {
	NotSigned: 'Not Signed',
	Signed: 'Signed',
	InReview: 'In Review',
	Complete: 'Complete',
	VerificationComplete: 'Verification Complete',
	Incomplete: 'Incomplete',
	Expired: 'Expired',
	Expiring: 'Expiring',
};

const CRED_APP_STATUS_MESSAGE = {
	NotSignedMessage: 'Please provide all required information and sign.',
	SignedMessage:
		'Your application has been submitted and is awaiting verification by a LocumTenens.com Credentialing Coordinator.',
	InReviewMessage:
		'Your application is currently being reviewed and verified by a LocumTenens.com Credentialing Coordinator.',
	CompleteMessage:
		'Your credentials are complete with LocumTenens.com. A member of our Recruiting Team will contact you to provide further details.',
	VerificationCompleteMessage:
		'Your information has been verified. A member of the LocumTenens.com Recruiting Team will contact you to provide further details.',
	IncompleteMessage:
		'Your application is incomplete. Please complete required fields and sign the PDF. Please reach out to a Credentialing Coordinator if you have any questions.',
	ExpiredMessage:
		'Your application is expired. Please contact a Credentialing Coordinator at 800-562-8663 with additional questions.',
	ExpiringMessage:
		'Your application is due to expire soon. Please begin re-credentialing by providing all required information and sign.',
};

const CRED_APP_STATUS_MESSAGE_V2 = {
	NotSignedMessage: 'Please provide all required information and share your application.',
	SignedMessage:
		'Your application has been submitted and is awaiting verification by a LocumTenens.com credentialing associate.',
	InReviewMessage:
		'Your application is currently being reviewed and verified by a Locumtenens.com credentialing associate.',
	CompleteMessage:
		'Your credentials are complete with LocumTenens.com. Your credentials are good through ',
	VerificationCompleteMessage:
		'Your information has been verified. A member of LocumTenens.com recruiting team will contact you to provide further details.',
	IncompleteMessage:
		'Your application is incomplete. Please submit the required information and/or documentation to your  LocumTenens.com credentialing associate to complete your credentialing file.',
	ExpiredMessage:
		'Your application is expired. Please click the "link" to get started on your re-credentialing application.',
	ExpiringMessage:
		'Your credentialing file will expire soon. Please click the "link" to get started on your re-credentialing application.',
};

const CRED_APP_STATUS_COLOR = {
	NotSignedColor: 'Red',
	SignedColor: 'Green',
	InReviewColor: 'Green',
	CompleteColor: 'Green',
	VerificationCompleteColor: 'Green',
	IncompleteColor: 'Yellow',
	ExpiredColor: 'Red',
	ExpiringColor: 'Red',
};

const CRED_STATE = {
	OPEN: 'Open',
	PARTIALLY_OPEN: 'Partially Open',
	CLOSED: 'Closed',
};

const HIDE_CRED_FOR_STATUS_INITIAL = [
	'Perm Provider',
	'App Sent',
	'Application Started',
	'Approved',
	'Approved Conditions',
];
const HIDE_CRED_FOR_STATUS_COMMON = ['Approved', 'Approved Conditions'];
const LENGTH_CONSTANTS = {
	LENGTH_ZIP_CODE: 5,
	LENGTH_CITY: 40,
	LENGTH_NAME: 50,
	LENGTH_ADDRESS_LINE2: 100,
	LENGTH_EMAIL_ADDRESS: 100,
	LENGTH_ADDRESS_LINE1: 155,
};
export {
	COLORS,
	MSAL_CONFIG,
	APP_CONFIG,
	DOCUMENT_TYPES,
	COUNTRIES,
	PROFESSIONS_MD,
	PROFESSIONS_AP,
	ACTIVE_PATHNAME_MD,
	ACTIVE_PATHNAME_AP,
	specialties,
	specialtiesMD,
	specialtiesItrnResidency,
	specialtiesFellowship,
	programSalutation,
	locumsPosition,
	branchService,
	militaryStatus,
	DOCUMENT_TYPE,
	DOCUMENT_FIELD_NAME,
	gapsReason,
	PHYSICIAN_SPECIALTY_BOARD,
	NURSING_AP_SPECIALTY_BOARD,
	NP_ISSUING_BOARD,
	CNS_ISSUING_BOARD,
	CNM_ISSUING_BOARD,
	CRNA_ISSUING_BOARD,
	NURSING_CERTIFICATION_PICKLIST,
	NP_CERTIFICATION_PICKLIST,
	CNS_CERTIFICATION_PICKLIST,
	CNM_CERTIFICATION_PICKLIST,
	CRNA_CERTIFACTION_PICKLIST,
	professionList,
	DEGREE,
	CRED_STATUS,
	CRED_STATE,
	CRED_APP_STATUS,
	CRED_APP_STATUS_MESSAGE,
	CRED_APP_STATUS_MESSAGE_V2,
	CRED_APP_STATUS_COLOR,
	DNP_PhD_SFA_RN_ISSUING_BOARD,
	DOCUMENT_TYPE_LABEL,
	DOCUMENT_HIDE_DELETE_BUTTON,
	DOCUMENT_REMOVE_LIST,
	CRED_TYPE,
	CRED_SOURCE,
	CRED_RECIPE_TYPE,
	DNAP_ISSUING_BOARD,
	HIDE_CRED_FOR_STATUS_INITIAL,
	HIDE_CRED_FOR_STATUS_COMMON,
	LENGTH_CONSTANTS,
};
