import React, { Component } from 'react';
import {
	withStyles,
	Container,
	Grid,
	Paper,
	Hidden,
	Drawer,
	CircularProgress,
} from '@material-ui/core';
import SideNavigation from '../../components/ContentComponents/Credentials/SideNavigationControl/SideNavigation';
import {
	COLORS,
	PROFESSIONS_MD,
	ACTIVE_PATHNAME_AP,
	ACTIVE_PATHNAME_MD,
	CRED_STATE,
	CRED_STATUS,
	CRED_TYPE,
	CRED_SOURCE,
} from '../../utils/Application_Constants';
import { calcCredState } from '../../utils/CredentialingAppUtility';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
	getClinician,
	patchClinician,
	getClinicianCredentialing,
	postClinicianCredentialing,
	patchClinicianCredentialing,
} from '../../services/Credentialing';
import { providerSync } from '../../services/CredentialVerificationService';
import menuListforNavigation from '../../configuration/navigation/credentials/menuItemListForNavigation.json';
import menuItemListForNavigation_AP_path from '../../configuration/navigation/credentials/menuItemListForNavigation_AP_path.json';
import { withRouter } from 'react-router-dom';
import ErrorDialog from '../../components/GlobalComponents/Dialog/ErrorDialog';
import WithContexts from '../../contexts/withContexts';
import { Close } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import CredInProgress from './CredInProgress';
import { appInsights, reactPlugin } from '../../AppInsights';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import Documents from '../../components/ContentComponents/Credentials/PageComponenets/Documents';
import CredentialsOverview from '../../components/ContentComponents/Credentials/PageComponenets/CredentialsOverview';
import StartCredentials from './StartCredentials';
import { Alert } from '@material-ui/lab';
import moment from 'moment';

const styles = (theme) => ({
	root: {
		minHeight: '800px',
		backgroundColor: COLORS.BLACK_HAZE,
	},
	containerStyle: {
		paddingLeft: '0px',
		paddingRight: '0px',
	},
	sidebarRoot: {
		// marginTop: '30px',
		// padding: '30px 0',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		minHeight: '800px',
		boxShadow: 'none',
	},
	contentRoot: {
		// marginTop: '30px',
		//  padding: '30px 0',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		minHeight: '800px',
		[theme.breakpoints.down('sm')]: {
			marginTop: '0px',
		},
	},
	credentialsContent: {
		zIndex: '10',
	},
	sidePanelContainerRoot: {
		//padding: '20px',
		display: 'flex',
		flexWrap: 'wrap',
	},
	mainPanelContainerRoot: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	circularProgressHeader: {
		//marginBottom: '10px',
		paddingTop: '30px',
		background: '#f7f7f7',
		textAlign: 'center',
	},
	helpPanelLinks: {
		textAlign: 'left',
	},
	navigationButtonWrapper: {
		position: 'relative',
		bottom: '0px',
		display: 'flex',
		justifyContent: 'flex-start',
		marginLeft: '20px',
		marginBottom: '30px',
	},
	navigationButton: {
		marginTop: '10px',
		marginRight: '30px',
		width: '300px',
	},
	buttonBlock: {
		display: 'block',
	},
	buttonNone: {
		display: 'none',
	},
	gridControl: {
		textAlign: 'left',
		padding: '20px 0px 0px 0px',
		justifyContent: 'center',
	},
	gridCircularProgress: {
		textAlign: 'end',
		justifyContent: 'center',
		padding: '10px 0px',
	},
	credAnchorTag: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '14px',
		cursor: 'pointer',
		marginBottom: '15px',
		borderBottom: `1px solid ${COLORS.TRUE_BLUE}`,
	},
	credAnchorTagClosure: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '14px',
		cursor: 'pointer',
		marginBottom: '10px',
		marginTop: '15px',
		textAlign: 'left',
		marginLeft: '10px',
	},
	drawerPaper: {
		width: '100%',
		backgroundColor: 'none',
		boxShadow: 'none',
		marginTop: '60px',
		overflow: 'scroll',
	},
	mobileNavigation: {
		color: COLORS.RAVEN,
		height: '80vh',
		overflow: 'scroll',
	},
	progressWrapper: {
		height: '80vh',
	},
	progressRoot: {
		[theme.breakpoints.up('md')]: {
			marginTop: '25vh',
			marginLeft: '30vh',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '20vh',
			marginLeft: '25vh',
		},
	},
	successAlertMessage: {
		border: `1px solid #009639`,
		color: COLORS.LT_EMERALD,
		backgroundColor: COLORS.LT_EMERALD25,
		fontWeight: '500',
		[theme.breakpoints.down('sm')]: {
			margin: '10px 5px',
		},
	},
});

class Credentials extends Component {
	constructor(props) {
		super(props);
		const { setPageName } = this.props.PageContext;
		setPageName('');

		this.state = {
			isLoading: true,
			activePath: null,
			currentSelectedLinkId: 0,
			toggleSideNavDrawer: false,
			credState: CRED_STATE.OPEN,
			// Save related data
			handleSubmit: null,
			childTab: null,
			childName: null,
			flags: {
				missingData: false,
				dataSaved: false,
				isDirty: false,
				showSaveChanges: false,
				isPendingSave: false,
				targetTab: 0,
				validationError: false,
				navDisabled: false,
				isError: false,
			},
			clinician: {},
			redirectFlag: true,
			navigationFlag: false,
			credAppStatus: '',
			hasCredRecord: false,
			navDisabled: false,
			credType: '',
			autoCredRegistrationDate: null
		};
	}

	sideNavdrawerToggle = () => {
		const { toggleSideNavDrawer } = this.state;
		this.setState({ toggleSideNavDrawer: !toggleSideNavDrawer });
	};

	getSelectdLinkIdByPathName = async (activePath) => {
		const menuList =
			activePath === ACTIVE_PATHNAME_AP
				? menuItemListForNavigation_AP_path.data
				: menuListforNavigation.data;
		console.log('this.props.location.pathname ' + this.props.location.pathname);
		let obj = menuList.find((o) => o.parentUrlLink === this.props.location.pathname);

		let currentId;
		if (obj === undefined) {
			currentId = 0;
		} else {
			currentId = obj.id - 1;
		}

		console.log('this.props.location.pathname currentId ' + currentId);
		return currentId;
	};

	getSelectdLinkId = async () => {
		//const activePath = this.state.activePath
		//const menuList = activePath === ACTIVE_PATHNAME_AP ? menuItemListForNavigation_AP_path.data : menuListforNavigation.data
		//let obj = menuList.find(o => o.parentUrlLink === this.props.location.pathname);
		this.setState({ navigationFlag: false });
	};

	componentDidUpdate() {
		if (this.state.currentSelectedLinkId !== 0) {
			if (this.props.location.pathname === '/credentials/personalInformation') {
				this.setState({ currentSelectedLinkId: 0 });
			}
		}
	}

	async componentDidMount() {
		const { externalId } = this.props.UserContext;
		await this.ProviderSync(externalId);
		await this.LoadAppFor(externalId);
	}

	async LoadAppFor(externalId) {
		await Promise.all([getClinicianCredentialing(externalId), getClinician(externalId)])
			.then(async ([credentialing, clinician]) => {
				const status = !!credentialing ? credentialing.status : CRED_STATUS.ApplicationStarted;

				// credentialingType
				const credType = !!credentialing ? credentialing.type : CRED_TYPE.INITIAL;

				const activePath =
					!!clinician &&
						PROFESSIONS_MD.includes(clinician.profession)
						? ACTIVE_PATHNAME_MD
						: ACTIVE_PATHNAME_AP;

				const newState = {};
				newState.hasCredRecord = !!credentialing && !!credentialing.id;
				newState.currentSelectedLinkId = await this.getSelectdLinkIdByPathName(activePath);
				newState.oldLinkId = newState.currentSelectedLinkId;
				newState.activePath = activePath;
				if (!!clinician) {
					newState.clinician = clinician;
				}
				newState.credState = calcCredState(status);
				newState.credAppStatus = credentialing?.status;
				newState.priorCredAppStatus = credentialing?.priorStatus;
				newState.credType = credType;
				newState.shouldHighlightFields = false;
				newState.autoCredRegistrationDate = clinician.autoCredRegistrationDate;
				this.setState({ ...newState });

				const state = { ...this.state };
				if (!!clinician) {
					state.profession = clinician.profession; //this is here to function like isLoaded = true;
				}

				this.setState({
					profession: state.profession,
					isLoading: false,
				});
			})
			.catch((e) => {
				this.setCredAppError(e);
			});
	}

	async ProviderSync(externalId) {
		try {
			const [credentialing, clinician] = await Promise.all([
				getClinicianCredentialing(externalId),
				getClinician(externalId)
			]);

			if (!clinician.npiNumber)
				return;

			if (await this.SkipProviderSync(credentialing))
				return;

			const providerSyncRequest = {
				ExternalId: externalId,
				HasIdentificationVerified: clinician?.credAppIdVerification,
				CredentialingStatus: credentialing.status
			};

			await providerSync(clinician.npiNumber, providerSyncRequest);
		} catch (e) {
			this.setCredAppError(e);
		}
	}

	async SkipProviderSync(credentialing) {
		return !credentialing?.status
			|| ((credentialing.priorStatus === CRED_STATUS.Expired || credentialing.priorStatus === CRED_STATUS.Expiring)
				&& credentialing.status === CRED_STATUS.ApplicationSent);
	}

	//Setting currnet tab state based on selction of left side navigation link from SideNavigation Component
	//Getting menuObject from leftnav and fetching ID for button navigation
	setCurrentTabHandler = (menuItem) => {
		if (menuItem.id !== 0) {
			const { toggleSideNavDrawer, activePath, currentSelectedLinkId } = this.state;

			//This is to check if item is parent element or child element and set id
			const link =
				menuItem.parentUrlLink !== undefined ? menuItem.parentUrlLink : menuItem.link;
			const menuList =
				activePath === ACTIVE_PATHNAME_AP
					? menuItemListForNavigation_AP_path.data
					: menuListforNavigation.data;
			let obj = menuList.find((o) => o.parentUrlLink === link);
			const state = { ...this.state };
			state.flags.validationError = false;
			state.currentSelectedLinkId = obj.id - 1;
			state.toggleSideNavDrawer = !toggleSideNavDrawer;
			state.navigationFlag = true;
			state.oldLinkId = currentSelectedLinkId;
			state.flags.navDisabled = false;
			this.setState({ ...state });
		}
		//Setting state to current selected Tab from left nav
		//this.setState({ currentSelectedLinkId: obj.id - 1, toggleSideNavDrawer: !toggleSideNavDrawer })
	};

	setTabHandlerFromDialog = () => {
		this.setState({ currentSelectedLinkId: 1 });
	};
	setSaveHandler = (child, tabName, handler) => {
		const state = { ...this.state };
		state.childTab = child;
		state.childName = tabName;
		state.handleSubmit = handler;
		// Reset save flags
		state.flags.missingData = false;
		state.flags.dataSaved = false;
		state.flags.isDirty = false;
		state.flags.showSaveChanges = false;
		state.flags.isPendingSave = false;
		state.flags.errorMessage = null;
		state.flags.apiError = null;
		state.backButton = null;
		this.setState({ ...state });
	};

	updateFlags = (flags) => {
		console.log('Updating flags');
		this.setState({ flags: flags });
	};

	updateHighlightFieldsState = async (value, item) => {
		console.log(`Updating shouldHighlightFields: ${value}`);
		await this.setState({ shouldHighlightFields: value });
		if (!!item) {
			this.setCurrentTabHandler(item);
			this.sideNavdrawerToggle();
		}
	};

	getStarted = async (profession, npiNumber) => {
		const { externalId, account } = this.props.UserContext;
		const state = { ...this.state };
		state.clinician.profession = profession;
		state.clinician.npiNumber = npiNumber;
		state.profession = profession;
		const activePath = PROFESSIONS_MD.includes(profession)
			? ACTIVE_PATHNAME_MD
			: ACTIVE_PATHNAME_AP;

		state.activePath = activePath;
		state.redirectFlag = false;
		//this.setState({ ...state })
		this.setState({
			clinician: state.clinician,
			profession: state.profession,
			activePath: state.activePath,
			redirectFlag: state.redirectFlag,
		});

		try {
			const patchClinicianRequest = {
				npiNumber: npiNumber,
				profession: profession
			};
			await patchClinician(patchClinicianRequest, externalId);

			appInsights.trackEvent({
				name: 'CredAppStarted',
				properties: {
					externalId: externalId,
					b2cId: account.accountIdentifier,
				},
			});

			await this.createOrUpdateCredStatus(externalId);
		} catch (e) {
			this.setCredAppError(e);
		}
	};

	async createOrUpdateCredStatus(externalId) {
		const { clinician, credAppStatus, credType } = { ...this.state };

		if (!credAppStatus) {
			const postClinicianCredentialingRequest = {
				clinicianId: parseInt(externalId),
				applicationSource: CRED_SOURCE.SSO,
				sentByName: clinician.portalInviteSentBy,
				sentDate: moment().utc().format('YYYY-MM-DDTHH:mm:ss+00:00'),
				status: CRED_STATUS.ApplicationStarted,
				type: CRED_TYPE.INITIAL
			};

			await postClinicianCredentialing(externalId, postClinicianCredentialingRequest);
		} else if (credAppStatus === CRED_STATUS.ApplicationSent) {
			var patchClinicianCredentialingRequest = {
				clinicianId: externalId,
				status: CRED_STATUS.ApplicationStarted
			};

			if (credType === CRED_TYPE.INITIAL) {
				patchClinicianCredentialingRequest.sentByName = clinician.portalInviteSentBy;
				patchClinicianCredentialingRequest.sentDate = moment().utc().format('YYYY-MM-DDTHH:mm:ss+00:00');
			}

			await patchClinicianCredentialing(externalId, patchClinicianCredentialingRequest);
		}
	};

	setCredAppError = (e) => {
		const { flags } = { ...this.state };
		flags.isError = true;
		if (e.hasOwnProperty('title') && e.hasOwnProperty('message')) {
			flags.apiError = e;
		} else {
			flags.errorMessage = 'Error loading page';
		}
		flags.isLoading = false;
		flags.isPendingSave = false;
	};

	blockedBackNavigation = () => {
		const { backButton, oldLinkId } = this.state;
		if (!!backButton) {
			backButton.blockedBackNavigation();
			this.setState({ backButton: null });
		} else {
			this.setState({ currentSelectedLinkId: oldLinkId, navigationFlag: true });
		}
	};

	pushBackToOverview = () => {
		const { history } = this.props;

		history.push('/credentials/overview');
	};

	handleBackButtonActions = (backButton) => {
		const state = { ...this.state };
		state.flags.validationError = false;
		state.backButton = backButton;
		this.setState({ ...state });
	};

	async onCallSubmit() {
		const state = { ...this.state };
		const { externalId, account } = this.props.UserContext;
		const status = state.credAppStatus;
		const credType = state.credType;

		if (!state.handleSubmit || !state.childTab) {
			state.flags.dataSaved = true; // no save method
			state.flags.validationError = false;
			state.flags.isPendingSave = false;
		} else {
			if (!!state.childName) {
				appInsights.trackEvent({
					name: 'CredAppSave',
					properties: {
						tab: state.childName,
						externalId: externalId,
						b2cId: account.accountIdentifier,
					},
				});
			}
			state.flags.isPendingSave = true;
			this.setState({ flags: state.flags });

			console.log('calling childTab.handleSubmit');
			try {
				await state.handleSubmit.call(state.childTab);
			} catch (error) {
				appInsights.trackException({
					exception: error,
					properties: {
						method: 'Credentials.onCallSubmit',
						severityLevel: SeverityLevel.Error,
					},
				});
				console.log('error=' + JSON.stringify(error));
				// error is an ApiError
				if (error.hasOwnProperty('title') && error.hasOwnProperty('message')) {
					state.flags.apiError = error;
				} else {
					state.flags.errorMessage = error;
				}
			}
			state.flags.isPendingSave = false;
		}
		if (
			credType === 'Re-cred' &&
			status === 'App Sent' &&
			(state.flags.dataSaved === true || state.flags.isPendingSave === false)
		) {
			let dto = { clinicianId: externalId, status: 'Application Started' };
			let result = null;
			result = await patchClinicianCredentialing(externalId, dto).catch((e) => {
				this.setCredAppError(e);
			});
			if (result && result.statusText === 'OK') {
				state.credAppStatus = 'Application Started';
				appInsights.trackEvent({
					name: 'ReCredAppStarted',
					properties: {
						externalId: externalId,
						b2cId: account.accountIdentifier,
					},
				});
				this.setState({
					credAppStatus: state.credAppStatus,
				});
			}
		}

		this.setState({
			flags: state.flags,
		});
		return state.flags.dataSaved;
	}

	closeError = () => {
		const state = { ...this.state };
		state.flags.apiError = null;
		//state.flags.isError = false;
		this.setState({ ...state });
	};

	showHideCredOverviewPage = () => {
		const { credType, credAppStatus, redirectFlag } = this.state;
		if (redirectFlag === false) {
			this.setState({ redirectFlag: true });
		}

		if (
			credType === CRED_TYPE.RECRED ||
			(credType === CRED_TYPE.INITIAL &&
				credAppStatus !== CRED_STATUS.ApplicationSent &&
				credAppStatus !== CRED_STATUS.ApplicationStarted &&
				credAppStatus !== CRED_STATUS.PermProvider)
		) {
			return true;
		} else {
			return false;
		}
	};

	renderBanner = () => {
		const { classes, t } = this.props;

		if (this.state.autoCredRegistrationDate === null) {
			return;
		}

		if (this.hasRecentlyRegistered()) {
			return <Grid item>
				<Alert
					severity='success'
					className={classes.successAlertMessage}
					icon={false}
				>
					{t('credentials:CREDENTIALS.RECENT_REGISTRATION_ALERT')}
				</Alert>
			</Grid>
		}
	}

	hasRecentlyRegistered = () => {
		var today = new Date();
		var registrationDate = new Date(this.state.autoCredRegistrationDate);
		registrationDate.setHours(registrationDate.getHours() + 48);

		return registrationDate.getTime() > today.getTime();
	}

	render() {
		const { classes, t } = this.props;
		const {
			hasCredRecord,
			activePath,
			flags,
			toggleSideNavDrawer,
			profession,
			credState,
			credAppStatus,
			priorCredAppStatus,
		} = { ...this.state };

		if (this.state.isLoading && !flags.apiError) {
			return (
				<div className={classes.progressWrapper}>
					<CircularProgress classes={{ root: classes.progressRoot }} color='primary' />
				</div>
			);
		} else {
			if (
				(!hasCredRecord ||
					profession === null ||
					(priorCredAppStatus !== CRED_STATUS.Expired && priorCredAppStatus !== CRED_STATUS.Expiring && credAppStatus === CRED_STATUS.ApplicationSent)
				)
				&&
				!flags.isError
			) {
				return (
					<>
						<AppInsightsErrorBoundary
							onError={() => (
								<h1>I believe something went wrong with Start Credentials</h1>
							)}
							appInsights={reactPlugin}
						>
							<StartCredentials
								initialProfession={profession}
								getStarted={this.getStarted}
								clinician={this.state.clinician}
								credAppStatus={credAppStatus}
								priorCredAppStatus={priorCredAppStatus}
								userContext={this.props.UserContext}
							/>
						</AppInsightsErrorBoundary>
					</>
				);
			} else if (credAppStatus === 'Application Started') {
				return (
					<CredInProgress
						clinician={this.state.clinician}
						credAppStatus={credAppStatus}
						priorCredAppStatus={priorCredAppStatus}
						userContext={this.props.UserContext}
					/>
				);
			} else {
				return (
					<AppInsightsErrorBoundary
						onError={() => <h1>I believe something went wrong with Credentials</h1>}
						appInsights={reactPlugin}
					>
						{this.renderBanner()}
						<div className={classes.root}>
							<ErrorDialog
								open={!!flags.apiError}
								onClose={this.closeError}
								title={!flags.apiError ? null : flags.apiError.title}
								description={!flags.apiError ? null : flags.apiError.message}
								errorId={!flags.apiError ? null : flags.apiError.errorId}
							/>
							<Container maxWidth='xl' className={classes.containerStyle}>
								<Grid item xs={12}>
									<Grid container justifyContent='space-evenly'>
										<Hidden only={['md', 'lg', 'xl']}>
											<Drawer
												variant='persistent'
												classes={{ paper: classes.drawerPaper }}
												open={toggleSideNavDrawer}
											>
												<span
													className={classes.credAnchorTagClosure}
													onClick={() => this.sideNavdrawerToggle()}
												>
													<Close
														style={{
															position: 'relative',
															top: '6px',
															fontSize: '20px',
														}}
													/>
													{t('credentials:CREDENTIALS.RETURN_TO_APP')}
												</span>
												<SideNavigation
													tabHandler={this.setCurrentTabHandler}
													activePath={activePath}
													showHideCredOverviewPage={
														this.showHideCredOverviewPage
													}
												/>
											</Drawer>
										</Hidden>
										<Grid
											item
											xs={12}
											md={9}
											classes={{ root: classes.credentialsContent }}
										>
											<Paper
												classes={{ root: classes.contentRoot }}
												elevation={2}
												square
											>
												<Grid
													container
													classes={{
														root: classes.mainPanelContainerRoot,
													}}
												>
													<Switch>
														<Route exact path='/credentials'>
															{this.state.redirectFlag && (
																<Redirect
																	to={
																		'/credentials/overview'
																	}
																/>
															)}
														</Route>
													</Switch>
													<Switch>
														<Route
															exact
															path='/credentials/overview'
															render={(props) => (
																<CredentialsOverview
																	key={'Overview'}
																	activePath={activePath}
																	setCredAppError={
																		this.setCredAppError
																	}
																	clinician={this.state.clinician}
																/>
															)}
														></Route>
														<Route
															exact
															path='/credentials/documents'
															render={(props) => (
																<Documents
																	key={'Documents'}
																	activePath={activePath}
																	setSaveHandler={
																		this.setSaveHandler
																	}
																	blockedBackNavigation={
																		this.blockedBackNavigation
																	}
																	flags={flags}
																	credState={credState}
																	updateFlags={this.updateFlags}
																	updateHighlightFieldsState={
																		this
																			.updateHighlightFieldsState
																	}
																	shouldHighlightFields={
																		this.state
																			.shouldHighlightFields
																	}
																/>
															)}
														></Route>
													</Switch>
												</Grid>
											</Paper>
										</Grid>
									</Grid>
								</Grid>
							</Container>
						</div>
					</AppInsightsErrorBoundary>
				);
			}
		}
	}
}

export default WithContexts(withRouter(withTranslation()(withStyles(styles)(Credentials))));
