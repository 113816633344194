import React from 'react';
import { getConfigurationSetting } from '../../../services/AppConfigService';

export default class FeatureFlag extends React.Component {
	static defaultProps = {
		isFlagEnabled: 'true',
		fallbackValue: false
	};

	state = {
		featureState: false,
		processing: true,
	};

	async componentDidMount() {
		await this.checkFlag();

		var [interval = 300000] = [this.props.interval];

		//Poll Feature Flag
		this.intervalID = setInterval(
			function (parent) {
				parent.checkFlag();
			},
			interval,
			this
		);
	}

	async componentWillUnmount() {
		clearInterval(this.intervalID);
	}

	async checkFlag() {
		const isEdge = window.navigator.userAgent.indexOf('Edge') !== -1;
		const isIE = window.navigator.userAgent.indexOf('Trident') !== -1 && !isEdge;

		var messageconfig = false;

		if (isEdge || isIE) {
			messageconfig = true;
		} else {
			messageconfig = await getConfigurationSetting(this.props.name,true,this.props.fallbackValue);
		}

		this.setState({
			featureState: messageconfig,
			processing: false,
		});
	}

	render() {
		const lowerFlag = (this.props.isFlagEnabled ?? '').toLowerCase();
		if (!this.state.processing && this.state.featureState && lowerFlag === 'true') {
			return <div>{this.props.children}</div>;
		}

		if (!this.state.processing && !this.state.featureState && lowerFlag === 'false') {
			return <div>{this.props.children}</div>;
		}

		return null;
	}
}
