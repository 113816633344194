import React, { Component } from 'react';
import { Phone } from '@material-ui/icons';
import { withStyles, Paper } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { COLORS } from '../../../utils/Application_Constants';

const styles = (theme) => ({
	facilityWrapper: {
		paddingTop: '18px',
	},
	widgetHeader: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '18px',
		textAlign: 'center',
	},
	paperRootNotFirstMargin: {
		marginTop: '20px',
	},
	paperRoot: {
		padding: '1.5rem',
		// boxShadow: '0px 3px 6px #00000008',
	},
	contactWrapper: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '20px',
	},
	contactCard: {
		paddingLeft: '20px',
		paddingBottom: '40px',
		width: '86%',
		textAlign: 'center',
	},
	contactIcon: {
		fontSize: '16px',
		marginRight: '5px',
	},
	clickableSpan: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '16px',
		cursor: 'pointer',
		borderBottom: `1px dashed ${COLORS.TRUE_BLUE}`,
		[theme.breakpoints.down('sm')]: {
			marginLeft: '10px',
		},
	},
});

class LocumTenensAfterHourSupportContact extends Component {
	isComponentFirstWidget = () => {
		const { classes, isFirst } = this.props;
		if (isFirst) {
			return '';
		}
		return classes.paperRootNotFirstMargin;
	};

	render() {
		const { classes, t } = this.props;
		return (
			<Paper classes={{ root: `${this.isComponentFirstWidget()} ${classes.paperRoot}` }}>
				<div className={classes.facilityWrapper}>
					<h1 className={classes.widgetHeader}>
						{t('contacts:LT_SUPPORT.URL')}
						<br />
						{t('contacts:LT_SUPPORT.AFTER_HOUR_SUPPORT')}
					</h1>
					<hr />
					<div className={classes.contactWrapper}>
						<div className={classes.contactCard}>
							<Phone classes={{ root: classes.contactIcon }} color='primary' />
							<a href={t('common:INFORMATION.HELP_NUMBER_LINK')}>
								<span className={classes.clickableSpan}>
									{t('common:INFORMATION.HELP_NUMBER')}
								</span>
							</a>
						</div>
					</div>
				</div>
			</Paper>
		);
	}
}

export default withTranslation()(withStyles(styles)(LocumTenensAfterHourSupportContact));
