import { Typography, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import { COLORS } from '../../../utils/Application_Constants';
import { useFormContext } from 'react-hook-form';
import ExpenseReceiptUpload from './ExpenseReceiptUpload';
const useStyles = makeStyles((theme) => ({
    formHeader: {
        borderBottom: `1px solid ${COLORS.LT_MIDNIGHT25}`,
        paddingBottom: 8,
        marginBottom: 16,
    },
	asterisk: {
		color: COLORS.LT_PEPPER,
	},
}));

const ExpenseReceiptUploadForm = (props) => {
    const { index, expense, originalExpenseEntries, mode, isViewOnly } = props;
    const classes = useStyles();
    const { watch } = useFormContext();
    const isRequired = mode === 'clinician-reimbursement' ? true : false;
    // Get the original status from the originalExpenseEntries
    const originalStatus = useMemo(() => {
        if (expense.id && originalExpenseEntries?.length > 0) {
            const originalExpense = originalExpenseEntries.find(entry => entry.id === expense.id);
            return originalExpense ? originalExpense.status : expense.status;
        }
        return expense.status; // Fallback to current status if no original entry is found
    }, [expense.id, originalExpenseEntries, expense.status]);
    // Track the current status being edited
    const currentStatus = watch(`expenseEntries[${index}].status`);
    // Compute isPosted based on original status and current transient state
    const isPosted = useMemo(() => {
        return originalStatus === 'Posted' || (currentStatus === 'OnHoldReimbursement' && originalStatus === 'Posted');
    }, [originalStatus, currentStatus]);
    const isPendingSubmission = expense.status === 'PendingSubmission';
	const isTimesheetDocumentUpload =
		watch('type') &&
		watch('category') &&
		watch('category') === 'Travel' &&
		watch('type') === 'Mileage';
    const sectionLabel = isTimesheetDocumentUpload
		? 'Timesheet & Document Upload'
		: 'Receipt Upload';
    return (
        <div>
            <Typography variant='h5' component='h5' className={classes.formHeader}>
                {sectionLabel}{isRequired && <span className={classes.asterisk}> *</span>}
            </Typography>
            <ExpenseReceiptUpload index={index}
                mode={mode}
                isPosted={isPosted} 
                isPendingSubmission={isPendingSubmission}
                isViewOnly={isViewOnly}/>
        </div>
    );
};

export default ExpenseReceiptUploadForm;
