import { Button, Collapse, Divider, Paper, Typography, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { currencies } from '../../Configurations/ExpenseEntryConfig';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(3),
		marginTop: theme.spacing(4),
	},
	lineItem: {
		display: 'flex',
		justifyContent: 'space-between',

		'&:not(:last-child)': {
			marginBottom: theme.spacing(1),
		},
	},
	divider: {
		margin: theme.spacing(1, 0, 3, 0),
	},
	viewMore: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(1, 0),

		'&:hover': {
			cursor: 'pointer',
		},
	},
	total: {
		marginTop: theme.spacing(2),
	},
	balanceZero: {
		color: 'green',
	},
	balanceBlack: {
		color: '#2a3953',
	},
	balanceOff: {
		color: 'red',
	},
}));

const ExpenseSummaryItem = ({ expense, isReimbursement }) => (
	<>
		<Typography variant='body2'>{expense.status !== 'Abandoned' ? expense.label : null}</Typography>
		<Typography variant='body2'>
			{isReimbursement ? (
				expense.status !== 'Abandoned' ? (expense.reimbursedAmount && expense.reimbursedAmount !== '-') ? '$' + parseFloat(expense.reimbursedAmount).toFixed(2) : '$' + 0 : null
			) : (
				expense.status !== 'Abandoned' ? (expense.amount && expense.amount !== '-') ? '$' + parseFloat(expense.amount).toFixed(2) : '$' + 0 : null
			)}
		</Typography>
	</>
);

const ExpenseSummary = ({mode, setError, clearErrors, setShowBalanceError, showBalanceError, setShowBalanceWarning, isReimbursement, isAnyApproved}) => {
	const classes = useStyles();
	const { watch } = useFormContext();
	const expenseEntries = watch('expenseEntries');
	const limit = 2;
	const [viewMore, setViewMore] = useState(false);

	const entriesTotal = expenseEntries
		.filter(expense =>
			expense.status !== 'Abandoned' &&
			(isReimbursement ? expense.reimbursedAmount !== '-' : expense.amount !== '-') &&
			(isReimbursement ? expense.reimbursedAmount !== '' : expense.amount !== '')
		)
		.reduce((total, expense) => total + Number((isReimbursement ? expense.reimbursedAmount : expense.amount)), 0);
	const balance = Math.round((expenseEntries[0].totalAmount - entriesTotal) * 100) / 100;
	const total = mode == 'cc' ? expenseEntries[0].totalAmount !== 0 && typeof(expenseEntries[0].totalAmount) === 'number' ? parseFloat(expenseEntries[0].totalAmount) : 0 : entriesTotal;
	const { id } = useParams();
	const currency = currencies.find(currency => currency.value === expenseEntries[0].currency);
	const submittedAmount = !isNaN(parseFloat(expenseEntries[0].amount)) ? parseFloat(expenseEntries[0].amount).toFixed(2) : 0;
	useEffect(() => {
		if (mode == 'cc') {
			if (balance !== 0) {
				setError("balance", {
					type: "manual",
					message: "The sum of the expenses does not match the current transaction amount.",
				});
			} else {
				clearErrors("balance");
				setShowBalanceError(false);
			}
		}

		if (isReimbursement) {
			if (entriesTotal > expenseEntries[0].amount && expenseEntries[0].currency == 'USD' ) {
				if (isAnyApproved){
					setError("balance", {
						type: "manual",
						message: "The reimbursed amount exceeds the submitted amount.",
					});
					setShowBalanceError(true);
					setShowBalanceWarning(false);
				}else{
					clearErrors("balance");
					setShowBalanceWarning(true);
					setShowBalanceError(false);
				}

			}else if (entriesTotal <=0 && isAnyApproved) {
				setError("balance", {
					type: "manual",
					message: "The reimbursed amount must be greater than zero to approve the expense.",
				});
				setShowBalanceWarning(false);
			}else {
				//Ensure all Errors/Warnings are cleared
				clearErrors("balance");
				setShowBalanceError(false);
				setShowBalanceWarning(false);
			}
		}


	}, [entriesTotal, isAnyApproved, currency, submittedAmount]);

	return (
		<Paper elevation={1} className={classes.paper}>
			{isReimbursement ? (
				<Typography variant='h5'>Reimbursement Summary</Typography>
			) : (
				<Typography variant='h5'>Expense Summary</Typography>
			)}
			
			<Divider className={classes.divider} />
				{expenseEntries.slice(0, limit).map((expense, index) => (
					<div className={classes.lineItem} key={`${index}-line-item`}>
						<ExpenseSummaryItem expense={expense} isReimbursement={isReimbursement}/>
					</div>
				))}
			<Collapse in={viewMore}>
				{expenseEntries.slice(limit).map((expense, index) => (
					<div className={classes.lineItem} key={`${index}-line-item`}>
						<ExpenseSummaryItem expense={expense} isReimbursement={isReimbursement}/>
					</div>
				))}
			</Collapse>
			{expenseEntries.length > limit && (
				<Typography
					variant='body2'
					onClick={() => setViewMore((prev) => !prev)}
					className={classes.viewMore}
				>
					<b>{viewMore ? 'Show less' : 'View more'}</b>{' '}
					<ExpandMoreIcon
						style={{
							transition: 'transform .25s',
							transform: `${viewMore ? 'rotate(180deg)' : 'rotate(0)'}`,
						}}
						fontSize='small'
					/>
				</Typography>
			)}
			
			{isReimbursement ? (
				<div className={`${classes.lineItem} ${classes.total}`}>
					<Typography className={((entriesTotal > expenseEntries[0].amount && expenseEntries[0].currency == 'USD') || (entriesTotal <=0 && showBalanceError)) ? classes.balanceOff : ((entriesTotal <=0 ) ? classes.balanceBlack : classes.balanceZero)} variant='h5'>Reimbursement Total</Typography>
					<Typography className={((entriesTotal > expenseEntries[0].amount && expenseEntries[0].currency == 'USD') || (entriesTotal <=0 && showBalanceError)) ? classes.balanceOff : ((entriesTotal <=0 ) ? classes.balanceBlack : classes.balanceZero)} variant='h5'>${total !== 0 ? total.toFixed(2) : 0}</Typography>
				</div>
			) : (
				<div className={`${classes.lineItem} ${classes.total}`}>
					<Typography variant='h5'>Total</Typography>
					<Typography variant='h5'>${total !== 0 ? total.toFixed(2) : 0}</Typography>
				</div>
			)}
				
			{isReimbursement && 
				<div className={`${classes.lineItem} ${classes.total}`}>
					<Typography variant='h7'>
						Submitted Expense Amount
					</Typography>
					<Typography variant='h7'>
						{currency.symbol}{submittedAmount} {((currency.symbol == '$' && currency.value != 'USD' )? ' '+ currency.value : '')}
					</Typography>
				</div>
			}
			{mode == 'cc' && 
				<div className={`${classes.lineItem} ${classes.total}`}>
					<Typography variant='h5'
						className={balance !== 0 ? classes.balanceOff : classes.balanceZero}>
						Balance
					</Typography>
					<Typography variant='h5'
						className={balance !== 0 ? classes.balanceOff : classes.balanceZero}>
						${balance !== 0 ? balance.toFixed(2) : 0}
					</Typography>
				</div>
			}
		</Paper>
	);
};

export default ExpenseSummary;
