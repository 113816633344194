import {axiosWCApiInstance} from "../configuration/axios/axiosConfiguration";

export async function sendFormattedNotification(notification, sendAsap = null) {
    let url = 'FormattedNotification';

    if (sendAsap) {
        url += '?sendASAP=true';
    }

    try {
        await axiosWCApiInstance.post(url, notification);
    } catch (error) {
        console.error(`${sendFormattedNotification.name} Failed: ${JSON.stringify(error, null, ' ')}`);
    }
}