const tableColumns = [
    { name: 'Status', key: 'status', size: 2, sortKey: 'status' },
    { name: 'Credit Card Account', key: 'creditCardNumber', size: 2, sortKey: 'creditCard' },
    { name: 'Clinician', key: 'clinicianName', size: 2, sortKey: 'clinicianLastName' },
    { name: 'Booking Number', key: 'bookingId', size: 2, sortKey: 'bookingName' },
    { name: 'Period End', key: 'periodEndDate', size: 2, sortKey: 'periodEnd' },
    {
        name: 'Transaction Date',
        key: 'transactionDate',
        size: 2,
        sortKey: 'transactionDate',
    },
    { name: 'Post Date', key: 'postDate', size: 2, sortKey: 'postDate' },
    { name: 'Merchant Name', key: 'merchantName', size: 2, sortKey: 'merchantName' },
    { name: 'Amount', key: 'amount', size: 2, sortKey: 'amount' },
    { name: 'Merchant Address', key: 'merchantAddress', size: 2, sortKey: 'merchantAddress' },
    { name: 'Merchant City', key: 'merchantCity', size: 2, sortKey: 'merchantCity' },
    { name: 'Merchant State', key: 'merchantState', size: 2, sortKey: 'merchantState' },
    { name: 'Traveler Name', key: 'travelerName', size: 2, sortKey: 'travelerName' },
    { name: 'Ticket Number', key: 'ticketNumber', size: 2, sortKey: 'ticketNumber' },
    {
        name: 'Start Date',
        key: 'startDate',
        size: 2,
        sortKey: 'startDate',
    },
    { name: 'End Date', key: 'endDate', size: 2, sortKey: 'endDate' },
    { name: 'Bill Type', key: 'billType', size: 2, sortKey: 'billType' },
    { name: 'Expense Type', key: 'expenseType', size: 2, sortKey: 'expenseType' },
    { name: 'Division', key: 'division', size: 2, sortKey: 'division' },
    { name: 'Source', key: 'source', size: 2, sortKey: 'source' },
];

const pendingCCTableColumns = [
    { name: 'Status', key: 'status', size: 2, sortKey: 'status' },
    { name: 'Credit Card Account', key: 'creditCardNumber', size: 2, sortKey: 'creditCard' },
    { name: 'Clinician', key: 'clinicianName', size: 2, sortKey: 'clinicianLastName' },
    { name: 'Booking Number', key: 'bookingId', size: 2, sortKey: 'bookingName' },
    {
        name: 'Transaction Date',
        key: 'transactionDate',
        size: 2,
        sortKey: 'transactionDate',
    },
    { name: 'Merchant Name', key: 'merchantName', size: 2, sortKey: 'merchantName' },
    { name: 'Amount', key: 'amount', size: 2, sortKey: 'amount' },
    {
        name: 'Start Date',
        key: 'startDate',
        size: 2,
        sortKey: 'startDate',
    },
    { name: 'End Date', key: 'endDate', size: 2, sortKey: 'endDate' },
    { name: 'Bill Type', key: 'billType', size: 2, sortKey: 'billType' },
    { name: 'Expense Type', key: 'type', size: 2, sortKey: 'type' },
    { name: 'Division', key: 'division', size: 2, sortKey: 'division' },
];

const order =
    'Traveler Name, Ticket Number, Start Date, End Date, Bill Type, Expense Type, Division, Source';

const expenseCategories = [
    {
        category: 'Licensing',
        types: [
            { value: 'Application' },
            { value: 'Renewal' },
            { value: 'Fingerprinting' },
            { value: 'Verification' },
        ],
    },
    {
        category: 'Privileging',
        types: [
            { value: 'Application Fee' },
            { value: 'Background Check' },
            { value: 'Employee Health Requirement' },
            { value: 'Life Support Certification' },
            { value: 'Shipping' },
            { value: 'Medical Staff Dues' },
            { value: 'Verification' },
            { value: 'Reappointment Fees' },
            { value: 'Lab Fees' },
            { value: 'Fingerprinting' },
        ],
    },
    {
        category: 'Credentialing',
        types: [
            { value: 'AMA' },
            { value: 'AOIA' },
            { value: 'ECFMG/INTEALTH' },
            { value: 'NPDB' },
            { value: 'Verification' },
            { value: 'Nursing Boards' },
        ],
    },
    {
        category: 'Travel',
        types: [
            { value: 'Air' },
            { value: 'Auto' },
            { value: 'Baggage Fee' },
            { value: 'Fuel' },
            { value: 'Lodging' },
            { value: 'Mileage' },
            { value: 'Parking' },
            { value: 'Ride Service' },
            { value: 'Toll' },
            { value: 'Utilities' },
            { value: 'Misc' },
        ],
    },
    {
        category: 'Other',
        types: [
            { value: 'Unidentified Licensing' },
            { value: 'Malpractice Comp Fund – State Comp funds' },
            { value: 'PSYC Malpractice Comp Fund' },
            { value: 'Compliance Fees' },
            { value: 'Dues and Subscriptions' },
            { value: 'Incidentals and Rounding' },
            { value: 'Rebate' },
            { value: 'Suspense' },
            { value: 'Disputed' },
            { value: 'Lodging Security Deposit' },
            { value: 'Clinician Gifts and Relations' },
            { value: 'Customer Care Gifts and Flowers' },
            { value: 'Client Gift' },
        ],
    },
];

const source = ['Reimbursement', 'Conferma', 'Corpay', 'Travel Inc'];

const searchKeys = [
    { name: 'Clinician', value: 'clinicianName' },
    { name: 'Booking Number', value: 'bookingName' },
    { name: 'Merchant Name', value: 'merchantName' },
    //{ name: 'Credit Card Account', value: 'CCAccount' }, TDS-7826: Remove Credit Card Account as a search attribute (will be added back later)
];

const mockData = [
    {
        Clinician: 'Dr. John Doe',
        BookingNumber: 'BKG-123456',
        TransactionDate: '01-15-2023',
        PostDate: '01-20-2023',
        Division: 'Anesthesia',
        BillType: 'Billable',
        ExpenseType: 'Verification',
        Amount: '€500',
        Status: 'Submitted',
        StartDate: '01-01-2023',
        EndDate: '01-31-2023',
        Source: 'Reimbursement',
        CreditCardAccount: '1234-Card Name',
    },
    {
        Clinician: 'Dr. Jane Smith',
        BookingNumber: 'BKG-789012',
        TransactionDate: '02-10-2023',
        PostDate: '02-15-2023',
        Division: 'Anesthesia',
        BillType: 'Non-Bill Producer',
        ExpenseType: 'Application Fee',
        Amount: '£750',
        Status: 'Rejected',
        StartDate: '02-01-2023',
        EndDate: '02-28-2023',
        Source: 'Reimbursement',
        CreditCardAccount: '9876-Card Name',
    },
    {
        Clinician: 'Dr. David Brown',
        BookingNumber: 'BKG-246813',
        TransactionDate: '03-20-2023',
        PostDate: '03-25-2023',
        Division: 'Anesthesia',
        BillType: 'Billable',
        ExpenseType: 'Shipping',
        Amount: '₹300',
        Status: 'Approved',
        StartDate: '03-01-2023',
        EndDate: '03-31-2023',
        Source: 'Reimbursement',
        CreditCardAccount: '1357-Card Name',
    },
    {
        Clinician: 'Dr. Sarah Johnson',
        BookingNumber: 'BKG-369258',
        TransactionDate: '04-05-2023',
        PostDate: '04-10-2023',
        Division: 'Anesthesia',
        BillType: 'Billable',
        ExpenseType: 'Lab Fees',
        Amount: '$600',
        Status: 'Rejected',
        StartDate: '04-01-2023',
        EndDate: '04-30-2023',
        Source: 'Reimbursement',
        CreditCardAccount: '2468-Card Name',
    },
    {
        Clinician: 'Dr. Michael Wilson',
        BookingNumber: 'BKG-753951',
        TransactionDate: '05-18-2023',
        PostDate: '05-25-2023',
        Division: 'Anesthesia',
        BillType: 'Non-Bill Team',
        ExpenseType: 'Fingerprinting',
        Amount: '$900',
        Status: 'Approved',
        StartDate: '05-01-2023',
        EndDate: '05-31-2023',
        Source: 'Conferma',
        CreditCardAccount: '3698-Card Name',
    },
    {
        Clinician: 'Dr. Lisa Anderson',
        BookingNumber: 'BKG-852036',
        TransactionDate: '06-12-2023',
        PostDate: '06-20-2023',
        Division: 'Anesthesia',
        BillType: 'Non-Bill Producer',
        ExpenseType: 'Parking',
        Amount: '$1200',
        Status: 'Disputed',
        StartDate: '06-01-2023',
        EndDate: '06-30-2023',
        Source: 'Conferma',
        CreditCardAccount: '9512-Card Name',
    },
    {
        Clinician: 'Dr. Emily Thomas',
        BookingNumber: 'BKG-456789',
        TransactionDate: '07-25-2023',
        PostDate: '07-30-2023',
        Division: 'Anesthesia',
        BillType: 'Billable',
        ExpenseType: 'Auto',
        Amount: '$400',
        Status: 'New',
        StartDate: '07-01-2023',
        EndDate: '07-31-2023',
        Source: 'Conferma',
        CreditCardAccount: '3579-Card Name',
    },
    {
        Clinician: 'Dr. Robert Garcia',
        BookingNumber: 'BKG-159753',
        TransactionDate: '08-08-2023',
        PostDate: '08-15-2023',
        Division: 'Anesthesia',
        BillType: 'Non-Bill Team',
        ExpenseType: 'Compliance Fees',
        Amount: '$1500',
        Status: 'Approved',
        StartDate: '08-01-2023',
        EndDate: '08-31-2023',
        Source: 'Conferma',
        CreditCardAccount: '0246-Card Name',
    },
    {
        Clinician: 'Dr. Laura Martinez',
        BookingNumber: 'BKG-951753',
        TransactionDate: '09-30-2023',
        PostDate: '10-05-2023',
        Division: 'Anesthesia',
        BillType: 'Billable',
        ExpenseType: 'AMA',
        Amount: '$800',
        Status: 'Disputed',
        StartDate: '09-01-2023',
        EndDate: '08-31-2023',
        Source: 'Corpay',
        CreditCardAccount: '0246-Card Name',
    },
];

const states = [
    { name: 'Alabama', value: 'AL' },
    { name: 'Alaska', value: 'AK' },
    { name: 'Arizona', value: 'AZ' },
    { name: 'Arkansas', value: 'AR' },
    { name: 'California', value: 'CA' },
    { name: 'Colorado', value: 'CO' },
    { name: 'Connecticut', value: 'CT' },
    { name: 'District of Columbia', value: 'DC' },
    { name: 'Delaware', value: 'DE' },
    { name: 'Florida', value: 'FL' },
    { name: 'Georgia', value: 'GA' },
    { name: 'Hawaii', value: 'HI' },
    { name: 'Idaho', value: 'ID' },
    { name: 'Illinois', value: 'IL' },
    { name: 'Indiana', value: 'IN' },
    { name: 'Iowa', value: 'IA' },
    { name: 'Kansas', value: 'KS' },
    { name: 'Kentucky', value: 'KY' },
    { name: 'Louisiana', value: 'LA' },
    { name: 'Maine', value: 'ME' },
    { name: 'Maryland', value: 'MD' },
    { name: 'Massachusetts', value: 'MA' },
    { name: 'Michigan', value: 'MI' },
    { name: 'Minnesota', value: 'MN' },
    { name: 'Mississippi', value: 'MS' },
    { name: 'Missouri', value: 'MO' },
    { name: 'Montana', value: 'MT' },
    { name: 'Nebraska', value: 'NE' },
    { name: 'Nevada', value: 'NV' },
    { name: 'New Hampshire', value: 'NH' },
    { name: 'New Jersey', value: 'NJ' },
    { name: 'New Mexico', value: 'NM' },
    { name: 'New York', value: 'NY' },
    { name: 'North Carolina', value: 'NC' },
    { name: 'North Dakota', value: 'ND' },
    { name: 'Ohio', value: 'OH' },
    { name: 'Oklahoma', value: 'OK' },
    { name: 'Oregon', value: 'OR' },
    { name: 'Pennsylvania', value: 'PA' },
    { name: 'Rhode Island', value: 'RI' },
    { name: 'South Carolina', value: 'SC' },
    { name: 'South Dakota', value: 'SD' },
    { name: 'Tennessee', value: 'TN' },
    { name: 'Texas', value: 'TX' },
    { name: 'Utah', value: 'UT' },
    { name: 'Vermont', value: 'VT' },
    { name: 'Virginia', value: 'VA' },
    { name: 'Washington', value: 'WA' },
    { name: 'West Virginia', value: 'WV' },
    { name: 'Wisconsin', value: 'WI' },
    { name: 'Wyoming', value: 'WY' },
];

const needsReviewReasons = [
    { name: 'EXPENSE_COMMENT', value: 'Expense Comment' },
    { name: 'REQUIRES_EXPENSE_ATTACHMENT', value: 'Requires Expense Attachment' },
    { name: 'OLD_TRANSACTION_DATE', value: 'Old Transaction Date' },
    { name: 'OVER_CAP_THRESHOLD', value: 'Over Cap Threshold' },
    { name: 'EXPENSE_NOT_EXPECTED', value: 'Expense Not Expected' },
    { name: 'CONCURRENT_AIR_TRANSACTION', value: 'Concurrent Air Transaction' },
    { name: 'CONCURRENT_AUTO_TRANSACTION', value: 'Concurrent Auto Transaction' },
    { name: 'AUTO_MILEAGE_CONFLICT', value: 'Auto Mileage Conflict' },
    { name: 'CONCURRENT_LODGING_TRANSACTION', value: 'Concurrent Lodging Transaction' },
    { name: 'EXPENSE_EXCEPTION_NOTES', value: 'Expense Exception Notes' },
    { name: 'CONCURRENT_EXPENSE_TRANSACTION', value: 'Concurrent Expense Transaction' },
    { name: 'MISC_EXPENSE', value: 'Misc Expense' },
    { name: 'AUTO_BOOKING_CONFLICT', value: 'Auto Booking Conflict' },
    { name: 'AIR_BOOKING_CONFLICT', value: 'Air Booking Conflict' },
    { name: 'FUTURE_AIR_DATE', value: 'Future Air Date' },
    { name: 'LODGING_BOOKING_CONFLICT', value: 'Lodging Booking Conflict' },
    { name: 'FUTURE_LODGING_DATE', value: 'Future Lodging Date' },
    { name: 'CLINICIAN_ID_CONFLICT', value: 'Clinician ID Conflict' },
    { name: 'BOOKING_CONFLICT', value: 'Booking Conflict' },
    { name: 'OLD_START_DATE', value: 'Old Start Date' },
    { name: 'OLD_END_DATE', value: 'Old End Date' },
    { name: 'FUTURE_AUTO_DATE', value: 'Future Auto Date' },
    { name: 'REQUIRES_MILEAGE_VERIFICATION', value: 'Requires Mileage Verification' },
    { name: 'EXCESSIVE_START_DATE', value: 'Excessive Start Date' },
    { name: 'AB5', value: 'AB5' },
    { name: 'THIRD_PARTY_BOOKING', value: 'Third Party Booking' },
    { name: 'EXPENSE_STIPEND_CONFLICT', value: 'Expense Stipend Conflict' },
    { name: 'EXCESSIVE_MILEAGE', value: 'Excessive Mileage' },
    { name: 'BILL_TYPE_CONFLICT', value: 'Bill Type Conflict' }
];

export const config = {
    tableColumns,
    pendingCCTableColumns,
    expenseCategories,
    hasSearch: true,
    source,
    searchKeys,
    mockData,
    states,
    needsReviewReasons,
};