import React, { useEffect, useState } from 'react';
import {
	COLORS,
	CRED_SOURCE,
	CRED_STATUS,
	CRED_TYPE,
} from '../../../../../utils/Application_Constants';
import { Grid, makeStyles, Paper, Button, CircularProgress } from '@material-ui/core';
import {
	postAzureB2cUserClaims,
	patchClinician,
	getClinicianCredentialing,
} from '../../../../../services/Credentialing';
import { useForm, FormProvider } from 'react-hook-form';
import { getCredAppStatusObjectV2 } from '../../../../../utils/CredentialingAppUtility';
import ToolTipComponent from '../../../../GlobalComponents/ToolTipComponent';
import { Info } from '@material-ui/icons';
import { setProviderRecipe } from "../../../../../services/CredentialVerificationService";
import {
	patchClinicianCredentialing,
	postClinicianCredentialing,
} from "../../../../../services/Credentialing";
import ErrorDialog from "../../../../GlobalComponents/Dialog/ErrorDialog";
import { getConfigurationSetting } from '../../../../../services/AppConfigService';
import moment from 'moment';

const styles = makeStyles((theme) => ({
	iconInfo: {
		height: '16px',
		margin: '0px',
		marginBottom: '-3px',
	},
	statusDesc: {
		borderBottom: '1px solid red',
		background: 'red',
		fontSize: '8px',
	},
	// credStatusExpireHeader: {
	//     color: COLORS.LT_STEEL,
	//     marginTop: theme.spacing(1),
	// },
	test: {
		background: 'red',
	},
	// credStatusApprovedUntil: {
	//     marginBottom: '0px',
	// },
	credStatusPriorDaysLeft: {
		color: COLORS.LT_STEEL,
	},
	mainButtonStyle: {
		marginTop: theme.spacing(1),
		display: 'block',
		width: '100%',
	},

	header: {
		color: COLORS.BLACK_MARLIN,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'center',
		fontSize: '22px',
		fontWeight: '700',
		marginTop: '30px',
		marginLeft: '30px',
	},
	root: {
		padding: '20px 0',
		minHeight: '800px',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '60px',
			marginTop: '25px',
		},
		maxWidth: '100%',
		//backgroundColor: COLORS.BLACK_HAZE,
	},
	// helpPanelContainerRoot: {
	//     //padding: '20px',
	//     display: 'flex',
	//     flexWrap: 'wrap',
	//     [theme.breakpoints.down('sm')]: {
	//         padding:'0px'
	//     },

	// },
	linkHeader: {
		color: COLORS.DENIM,
		textDecoration: 'none',
		display: 'flex',
		fontWeight: '700',
		fontSize: '22px',
		margin: 0,
		textAlign: 'left',
	},
	sectionStyle: {
		color: COLORS.DENIM,
		fontWeight: '700',
		display: 'flex',
	},
	info: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '16px',
		textAlign: 'left',
	},
	columnInfoLeft: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '16px',
		textAlign: 'left',
		margin: '20px 0 10px 0',
		display: 'inline-block',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		width: '350px',
		[theme.breakpoints.down('md')]: {
			width: '195px',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100px',
		},
	},
	columnInfoCenter: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '16px',
		textAlign: 'left',
		margin: '20px 0 10px 0',
		display: 'inline-block',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		width: '350px',
		[theme.breakpoints.down('md')]: {
			width: '195px',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100px',
		},
	},
	columnInfoRight: {
		color: COLORS.RAVEN,
		fontWeight: '700',
		fontSize: '16px',
		textAlign: 'right',
		margin: '20px 0 10px 0',
	},
	indicatorGreen: {
		color: 'green',
		verticalAlign: 'middle',
		marginRight: '20px',
		paddingRight: '5px',
	},
	indicatorYellow: {
		color: 'orange',
		verticalAlign: 'middle',
		marginRight: '20px',
		paddingRight: '5px',
	},
	indicatorRed: {
		color: 'red',
		verticalAlign: 'middle',
		marginRight: '20px',
		paddingRight: '5px',
	},
	lifeTime: {
		marginRight: '29px',
	},
	columnHeaderLeft: {
		color: COLORS.BLACK_MARLIN,
		fontWeight: '900',
		fontSize: '16px',
		textAlign: 'left',
		marginTop: '20px',
		borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
	},
	columnHeaderCenter: {
		color: COLORS.BLACK_MARLIN,
		fontWeight: '900',
		fontSize: '16px',
		textAlign: 'left',
		marginTop: '20px',
		borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
	},
	columnHeaderRight: {
		color: COLORS.BLACK_MARLIN,
		fontWeight: '900',
		fontSize: '16px',
		textAlign: 'right',
		marginTop: '20px',
		borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
	},
	checkStatusIcon: {
		color: COLORS.DENIM,
		fontSize: '22px',
		marginLeft: '4px',
		marginTop: '3px;',
	},
	noMaxWidth: {
		maxWidth: 'none',
	},
	topTileRoot: {
		//marginTop: '30px',
		height: '85%',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	expirationDate: {
		textAlign: 'right',
	},
	expirationDateHeader: {
		fontWeight: '900',
		fontSize: '12px',
		whiteSpace: 'nowrap',
		textTransform: 'uppercase',
		textAlign: 'right',
	},
	statusExpired: {
		borderWidth: '1px',
		borderColor: COLORS.LT_PEPPER,
		color: COLORS.LT_PEPPER,
		borderRadius: '25px',
		background: COLORS.LT_PEPPER10,
		paddingLeft: '5px',
		textAlign: 'center',
	},
	statusExpiresSoon: {
		borderWidth: 'thin',
		borderColor: '#FFB733',
		color: '#FFB733',
		borderRadius: '25px',
		background: '#FEF8C0',
		paddingLeft: '5px',
		textAlign: 'center',
	},
	statusComplete: {
		borderWidth: 'thin',
		borderColor: '#048E17',
		color: '#048E17',
		borderRadius: '25px',
		background: '#7ED589',
		paddingLeft: '5px',
		textAlign: 'center',
	},
	statusInReview: {
		borderWidth: 'thin',
		borderColor: '#0853AE',
		color: '#0853AE',
		borderRadius: '25px',
		background: '#98EDFE',
		paddingLeft: '5px',
		textAlign: 'center',
	},
	dateInReview: {
		borderStyle: 'none',
		color: COLORS.BLACK_MARLIN,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'center',
		paddingLeft: '5px',
		paddingTop: '16px',
		fontSize: '22px',
		fontWeight: '700',
	},
	statusNotApproved: {
		borderWidth: 'thin',
		borderColor: '#FFB733',
		color: '#FFB733',
		borderRadius: '25px',
		background: '#FEF8C0',
		paddingLeft: '5px',
		textAlign: 'center',
	},
	statusIncomplete: {
		borderWidth: 'thin',
		borderColor: '#FF5733',
		color: '#FF5733',
		borderRadius: '25px',
		background: '#FEACAC',
		paddingLeft: '5px',
		textAlign: 'center',
	},
	textLink: {
		color: 'white',
		marginTop: '.5rem',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	darktextLink: {
		color: COLORS.LT_JOURNEY,
		marginTop: '.5rem',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	daysLeft: {
		borderStyle: 'none',
		fontWeight: '700',
		fontSize: '16px',
		textAlign: 'center',
	},
}));

function CredentialingStatusCard({ t, UserContext, setCredAppError, setIsLoaded, clinician, handleButtonClickOverride, buttonTextOverride }) {
	const longEnUSFormatter = new Intl.DateTimeFormat('en-US', {
		timeZone: 'UTC',
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	});
	const [approvedUntilFormatted, setApprovedUntilFormatted] = useState('');
	const [priorApprovedUntilFormatted, setPriorApprovedUntilFormatted] = useState('');
	const [credPackReceivedDateFormatted, setCredPackReceivedDateFormatted] = useState('');
	const [, setDaysLeft] = useState('');
	const [priorDaysLeft, setPriorDaysLeft] = useState('');
	const [credStatusObj, setCredStatusObj] = useState({});
	const [priorCredStatusObj, setPriorCredStatusObj] = useState({});
	const [buttonLoading, setButtonLoading] = useState(false);
	const [buttonClickError, setButtonClickError] = useState(null);

	const classes = styles();
	const { reset, watch } = useForm({
		mode: 'all',
		defaultValues: {
			id: null,
			clinicianId: null,
			status: null,
			credAppFileName: null,
			createdDate: null,
			lastModifiedDate: null,
			credAppPercentComplete: null,
			credPackReceivedDate: null,
			approvedUntil: null,
			haveReferenceInTwoYear: null,
			credentialingType: null,
			priorApprovedUntil: null,
			priorStatus: null,
			priorFileName: null,
		},
	});

	const approvedUntil = watch('approvedUntil');
	const priorApprovedUntil = watch('priorApprovedUntil');
	const status = watch('status');
	const priorStatus = watch('priorStatus');
	const credPackReceivedDate = watch('credPackReceivedDate');

	useEffect(() => {
		(async function fetchData() {
			const { externalId } = UserContext;
			await getClinicianCredentialing(externalId)
				.then(function (response) {
					console.log('credentialing response ', response);
					reset(response);
					setCredStatusObj(getCredAppStatusObjectV2(response.status));
					setPriorCredStatusObj(getCredAppStatusObjectV2(response.priorStatus));
					setIsLoaded(true);
				})
				.catch((e) => {
					setCredAppError(e);
				});
		})();
	}, [UserContext, reset, setCredAppError, setIsLoaded]);

	useEffect(() => {
		if (!!credPackReceivedDate) {
			const credPackReceivedDateSplit = credPackReceivedDate.split('T')[0];
			var date = new Date(credPackReceivedDateSplit);
			setCredPackReceivedDateFormatted(longEnUSFormatter.format(date));
		}
	}, [credPackReceivedDate, longEnUSFormatter]);

	useEffect(() => {
		if (!!priorApprovedUntil) {
			const priorApprovedUntilDate = priorApprovedUntil.split('T')[0];
			var date = new Date(priorApprovedUntilDate);
			setPriorApprovedUntilFormatted(longEnUSFormatter.format(date));
			var today = new Date();
			var days = Math.ceil((date.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
			setPriorDaysLeft(days + ' days');
		}
	}, [priorApprovedUntil, longEnUSFormatter]);

	useEffect(() => {
		if (!!approvedUntil) {
			const approvedUntilDate = approvedUntil.split('T')[0];
			var date = new Date(approvedUntilDate);
			setApprovedUntilFormatted(longEnUSFormatter.format(date));
			var today = new Date();
			var days = Math.ceil((date.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
			setDaysLeft('( ' + days + ' days )');
		}
	}, [approvedUntil, longEnUSFormatter]);

	const handleButtonClick = async () => {
		if (handleButtonClickOverride) {
			handleButtonClickOverride();
			return;
		}

		if (!clinician.npiNumber && !clinician.autoCredRegistrationDate) {
			window.alert(t(`credentials:CRED_OVERVIEW.MISSING_NPI`));
			return;
		}

		setButtonLoading(true);

		try {
			if (status === CRED_STATUS.ApplicationSent)
				await updateCredStatus(CRED_STATUS.ApplicationStarted);
			else if (await requiresNewCredRecord(status))
				await createCredRecord(CRED_STATUS.ApplicationStarted);

			const postAzureB2cUserClaimsRequest = {
				b2cId: UserContext.account.accountIdentifier,
				npiNumber: clinician.npiNumber,
				providerTitles: clinician.providerTitle
			};

			await postAzureB2cUserClaims(postAzureB2cUserClaimsRequest);

			if (clinician.npiNumber && status)
				await setProviderRecipe(clinician.npiNumber, UserContext.degree, status, priorStatus);

			var credRedirectDateFeatureFlag = await getConfigurationSetting('CredentialingRedirectDate', true);
			if (credRedirectDateFeatureFlag) {
				const patchClinicianRequest = {
					lastCredentialingRedirectDate: moment().utc().format('YYYY-MM-DDTHH:mm:ss+00:00')
				};
				await patchClinician(patchClinicianRequest, UserContext.externalId);
			}

			window.open(process.env.REACT_APP_AXUALL_REDIRECTURL, '_blank');
			window.location.reload();
		} catch (error) {
			setButtonClickError(error);
		} finally {
			setButtonLoading(false);
		}
	};

	const requiresNewCredRecord = async (status) => {
		const newCredTriggeringStatuses = [CRED_STATUS.Expired, CRED_STATUS.Incomplete, CRED_STATUS.NotApproved];

		return newCredTriggeringStatuses.includes(status);
	}

	const updateCredStatus = async (status) => {
		const { externalId } = UserContext;
		var patchClinicianCredentialingRequest = {
			clinicianId: externalId,
			status: status
		};

		await patchClinicianCredentialing(externalId, patchClinicianCredentialingRequest);
	}

	const createCredRecord = async (status) => {
		const { externalId } = UserContext;
		const postClinicianCredentialingRequest = {
			clinicianId: parseInt(externalId),
			applicationSource: CRED_SOURCE.SSO,
			status: status,
			type: CRED_TYPE.RECRED
		};

		await postClinicianCredentialing(externalId, postClinicianCredentialingRequest);
	}

	const clearButtonClickError = () => {
		setButtonClickError(null);
	}

	const renderExpired = () => {
		return (
			<div>
				<ErrorDialog
					open={!!buttonClickError}
					onClose={clearButtonClickError}
					title={buttonClickError?.title}
					description={buttonClickError?.message}
					errorId={buttonClickError?.errorId}
				/>
				<Grid container direction='column' align='center'>
					<Grid item xs={12}>
						<Button
							variant='contained'
							color='primary'
							className={classes.mainButtonStyle}
							disableElevation
							onClick={handleButtonClick}
							disabled={buttonLoading}
						>
							{
								buttonTextOverride ||
								(buttonLoading ?
									<CircularProgress color='inherit' size='0.9em' /> :
									t(`credentials:CRED_OVERVIEW.CRED_STATUS_START_RECRED_BUTTON`))
							}
						</Button>
					</Grid>
					<Grid container direction='column' align='center'>
						{priorApprovedUntilFormatted ? renderExpiredTag() : <h1></h1>}
					</Grid>
					<Paper className='cr-bg-pepper--gradient'>
						<Grid container direction='column' spacing={1} align='center'>
							<Grid item xs={12}>
								<h3 className='cr-mb-0 cr-white'>
									{t(`credentials:CRED_OVERVIEW.CRED_STATUS`)}
								</h3>
							</Grid>
							<Grid item xs={12}>
								<span className='cr-white' style={{ marginRight: '8px' }}>
									Status:
								</span>
								<span className='cr-chip cr-chip--statusPepper'>
									{priorCredStatusObj.status}
								</span>
							</Grid>
							<Grid item xs={12}>
								<h6 className='cr-pepper10 cr-mb-1 cr-mt-2'>
									<ToolTipComponent
										disableFocusListener
										enterTouchDelay={150}
										interactive
										placement='right'
										title={<>{<span>{priorCredStatusObj.message}</span>}</>}
									>
										<span className='credStatusDesc'>
											<Info classes={{ root: classes.iconInfo }} />{' '}
											<span className='credStatusDescText'>
												Status Description
											</span>
										</span>
									</ToolTipComponent>
								</h6>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</div>
		);
	};

	const renderExpiredTag = () => {
		return (
			<h2 className='credExpiringTag'>Expired on {priorApprovedUntilFormatted} </h2>
		)
	}

	const renderExpiresSoon = () => {
		return (
			<div>
				<ErrorDialog
					open={!!buttonClickError}
					onClose={clearButtonClickError}
					title={buttonClickError?.title}
					description={buttonClickError?.message}
					errorId={buttonClickError?.errorId}
				/>
				<Grid container direction='column' spacing={1} align='center'>
					<Button
						variant='contained'
						color='primary'
						className={classes.mainButtonStyle}
						disableElevation
						onClick={handleButtonClick}
						disabled={buttonLoading}
					>
						{
							buttonTextOverride ||
							(buttonLoading ?
								<CircularProgress color='inherit' size='0.9em' /> :
								t(`credentials:CRED_OVERVIEW.CRED_STATUS_START_RECRED_BUTTON`))
						}
					</Button>
				</Grid>
				<Grid container direction='column' align='center'>
					<h2 className='credExpiringTag'>Expires in {priorDaysLeft} </h2>
				</Grid>
				<Paper className='cr-bg-sunflower--gradient'>
					<Grid container direction='column' spacing={1} align='center'>
						<Grid item xs={12}>
							<h3 className='cr-mb-0'>
								{t(`credentials:CRED_OVERVIEW.CRED_STATUS`)}
							</h3>
						</Grid>
						<Grid item xs={12}>
							<span style={{ marginRight: '8px' }}>Status:</span>
							<span className='cr-chip cr-chip--statusSunflower'>
								{priorCredStatusObj.status}
							</span>
						</Grid>
						<Grid item xs={12}>
							<h6 className='cr-slate cr-mb-1 cr-mt-2'>
								<ToolTipComponent
									disableFocusListener
									enterTouchDelay={150}
									interactive
									placement='right'
									title={<>{<span>{priorCredStatusObj.message}</span>}</>}
								>
									<span className='credStatusDesc'>
										<Info classes={{ root: classes.iconInfo }} />{' '}
										<span className='credStatusDescText'>
											Status Description
										</span>
									</span>
								</ToolTipComponent>
							</h6>
						</Grid>
					</Grid>
				</Paper>
			</div>
		);
	};

	const renderComplete = () => {
		return (
			<div>
				<ErrorDialog
					open={!!buttonClickError}
					onClose={clearButtonClickError}
					title={buttonClickError?.title}
					description={buttonClickError?.message}
					errorId={buttonClickError?.errorId}
				/>
				<Grid container direction='column' align='center'>
					<Grid item xs={12}>
						<Button
							variant='contained'
							color='primary'
							className={classes.mainButtonStyle}
							disableElevation
							onClick={handleButtonClick}
							disabled={buttonLoading}
						>
							{
								buttonTextOverride ||
								(buttonLoading ? <CircularProgress color='inherit' size='0.9em' /> :
									t(`credentials:CRED_OVERVIEW.CRED_STATUS_VIEW_MY_CRED_APP_BUTTON`))
							}
						</Button>

					</Grid>
				</Grid>
				<Grid container direction='column' align='center' paddingBottom={4} >
					<h1 >{null} </h1>
				</Grid>
				<Paper className='cr-bg-emerald-gradient'>
					<Grid container direction='column' spacing={1} align='center'>
						<Grid item xs={12}>
							<h3 className='cr-mb-0 cr-white'>
								{t(`credentials:CRED_OVERVIEW.CRED_STATUS`)}
							</h3>
						</Grid>
						<Grid item xs={12}>
							<span className='cr-white' style={{ marginRight: '8px' }}>
								Status:
							</span>
							<span className='cr-chip cr-chip--statusEmerald'>
								{credStatusObj.status}
							</span>
						</Grid>
						<Grid item xs={12}>
							<h6 className='cr-emerald10 cr-mb-1 cr-mt-2'>

								<ToolTipComponent
									disableFocusListener
									enterTouchDelay={150}
									interactive
									placement='right'
									title={<>{<span>{credStatusObj.message} {approvedUntilFormatted}</span>}</>}
								>
									<span className='credStatusDesc'>
										<Info classes={{ root: classes.iconInfo }} />{' '}
										<span className='credStatusDescText'>
											Status Description
										</span>
									</span>
								</ToolTipComponent>
							</h6>

						</Grid>
					</Grid>
				</Paper>
			</div>
		);
	};

	const renderInReview = () => {
		return (
			<div>
				<ErrorDialog
					open={!!buttonClickError}
					onClose={clearButtonClickError}
					title={buttonClickError?.title}
					description={buttonClickError?.message}
					errorId={buttonClickError?.errorId}
				/>
				<Grid container direction='column' align='center'>
					<Grid item xs={12}>
						<Button
							variant='contained'
							color='primary'
							className={classes.mainButtonStyle}
							disableElevation
							onClick={handleButtonClick}
							disabled={buttonLoading}
						>
							{
								buttonTextOverride ||
								(buttonLoading ? <CircularProgress color='inherit' size='0.9em' /> :
									t(`credentials:CRED_OVERVIEW.CRED_STATUS_VIEW_MY_CRED_APP_BUTTON`))
							}
						</Button>

					</Grid>
				</Grid>
				<Grid container direction='column' align='center'>
					<h1 >{null} </h1>
				</Grid>
				<Paper className='cr-bg-journey--gradient'>
					<Grid container direction='column' spacing={1} align='center'>
						<Grid item xs={12}>
							<h3 className='cr-mb-0 cr-white'>
								{t(`credentials:CRED_OVERVIEW.CRED_STATUS`)}
							</h3>
						</Grid>
						<Grid item xs={12}>
							<span className='cr-white' style={{ marginRight: '8px' }}>
								Status:
							</span>
							<span className='cr-chip cr-chip--statusJourney'>
								{credStatusObj.status}
							</span>
						</Grid>
						<Grid item xs={12}>
							<h6 className='cr-journey10 cr-mb-1 cr-mt-2'>
								<ToolTipComponent
									disableFocusListener
									enterTouchDelay={150}
									interactive
									placement='right'
									title={<>{<span>{credStatusObj.message}</span>}</>}
								>
									<span className='credStatusDesc'>
										<Info classes={{ root: classes.iconInfo }} />{' '}
										<span className='credStatusDescText'>
											Status Description
										</span>
									</span>
								</ToolTipComponent>
							</h6>
							<h4 className='cr-mb-0 cr-white'>
								{credPackReceivedDateFormatted}
							</h4>
						</Grid>
					</Grid>
				</Paper>
			</div>
		);
	};

	const renderIncomplete = () => {
		return (
			<div>
				<ErrorDialog
					open={!!buttonClickError}
					onClose={clearButtonClickError}
					title={buttonClickError?.title}
					description={buttonClickError?.message}
					errorId={buttonClickError?.errorId}
				/>
				<Grid container direction='column' spacing={4} align='center'>
					<Grid item xs={12}>
						<Button
							variant='contained'
							color='primary'
							className={classes.mainButtonStyle}
							disableElevation
							onClick={handleButtonClick}
							disabled={buttonLoading}
						>
							{
								buttonTextOverride ||
								t(`credentials:CRED_OVERVIEW.CRED_STATUS_VIEW_MY_CRED_APP_BUTTON`)
							}
						</Button>
					</Grid>
				</Grid>
				<Grid container direction='column' align='center'>
					<h1 >{null} </h1>
				</Grid>
				<Paper className='cr-bg-pepper--gradient'>
					<Grid container direction='column' spacing={1} align='center'>
						<Grid item xs={12}>
							<h3 className='cr-mb-0 cr-white'>
								{t(`credentials:CRED_OVERVIEW.CRED_STATUS`)}
							</h3>
						</Grid>
						<Grid item xs={12}>
							<span className='cr-white' style={{ marginRight: '8px' }}>
								Status:
							</span>
							<span className='cr-chip cr-chip--statusPepper'>
								{credStatusObj.status}
							</span>
						</Grid>
						<Grid item xs={12}>
							<h6 className='cr-pepper10 cr-mb-1 cr-mt-2'>
								<ToolTipComponent
									disableFocusListener
									enterTouchDelay={150}
									interactive
									placement='right'
									title={<>{<span>{credStatusObj.message}</span>}</>}
								>
									<span className='credStatusDesc'>
										<Info classes={{ root: classes.iconInfo }} />{' '}
										<span className='credStatusDescText'>
											Status Description
										</span>
									</span>
								</ToolTipComponent>
							</h6>
						</Grid>
					</Grid>
				</Paper>
			</div>
		);
	};

	const renderNotApproved = () => {
		return (
			<div>
				<ErrorDialog
					open={!!buttonClickError}
					onClose={clearButtonClickError}
					title={buttonClickError?.title}
					description={buttonClickError?.message}
					errorId={buttonClickError?.errorId}
				/>
				<Grid container direction='column' spacing={4} align='center'>
					<Grid item xs={12}>
						<Button
							variant='contained'
							color='primary'
							className={classes.mainButtonStyle}
							disableElevation
							onClick={handleButtonClick}
							disabled={buttonLoading}
						>
							{
								buttonTextOverride ||
								(buttonLoading ? <CircularProgress color='inherit' size='0.9em' /> :
									t(`credentials:CRED_OVERVIEW.CRED_STATUS_VIEW_MY_CRED_APP_BUTTON`))
							}
						</Button>
					</Grid>
				</Grid>
				<Grid container direction='column' align='center'>
					<h1 >{null} </h1>
				</Grid>
				<Paper className='cr-bg-slate--gradient'>
					<Grid container direction='column' spacing={1} align='center'>
						<Grid item xs={12}>
							<h3 className='cr-mb-0 cr-white'>
								{t(`credentials:CRED_OVERVIEW.CRED_STATUS`)}
							</h3>
						</Grid>
						<Grid item xs={12} style={{ textAlign: 'center' }}>
							<span className='cr-white' style={{ marginRight: '8px' }}>
								Status:
							</span>
							<span className='cr-chip cr-chip--statusSlate'>
								{credStatusObj.status}
							</span>
							<Grid item xs={12}>
								<h6 className='cr-pepper10 cr-mb-1 cr-mt-2'>
									<ToolTipComponent
										disableFocusListener
										enterTouchDelay={150}
										interactive
										placement='right'
										title={<>{<span>{credStatusObj.message}</span>}</>}
									>
										<span className='credStatusDesc'>
											<Info classes={{ root: classes.iconInfo }} />{' '}
											<span className='credStatusDescText'>
												Status Description
											</span>
										</span>
									</ToolTipComponent>
								</h6>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</div>
		);
	};

	return (
		<div>
			<FormProvider>
				<form>
					{(status === 'App Sent' && priorStatus === 'Expiring') &&
						renderExpiresSoon()}
					{(status === 'Expired' || (status === 'App Sent' && priorStatus === 'Expired')) &&
						renderExpired()}
					{(status === 'Incomplete' || status === 'Perm Provider') &&
						renderIncomplete()}
					{(status === 'Approved' || status === 'Approved Conditions') &&
						renderComplete()}
					{(status === 'In Process' ||
						status === 'App Received' ||
						status === 'Temp Approved' ||
						status === 'Hold') &&
						renderInReview()}
					{status === 'Not Approved' && renderNotApproved()}
				</form>
			</FormProvider>
		</div>
	);
}

export default CredentialingStatusCard;
