import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import AssignmentDetailCard from '../AssignmentDetailCard/AssignmentDetailCard';
import AssignmentDocumentComponentV2 from '../AssignmentDocumentComponent/AssignmentDocumentComponentV2';
import FeatureFlag from '../../GlobalComponents/FeatureFlagComponent/FeatureFlag';
import TravelV2 from '../Travel/TravelV2';
import TravelV3 from '../Travel/TravelV3';
const styles = (theme) => ({});

class PastAssignmentDetailView extends Component {
	render() {
		const { assignment, externalId } = this.props;
		return !assignment ? (
			<p>Error, Assignment not found</p>
		) : (
			<>
				<AssignmentDetailCard assignment={assignment} type='past' />
				<AssignmentDocumentComponentV2 assignmentName={assignment.name} />
				<FeatureFlag name='TravelV3' isFlagEnabled='false' fallbackValue={false}>
					<TravelV2 assignment={assignment} externalId={externalId} filter='active' />
				</FeatureFlag>
				<FeatureFlag name='TravelV3' isFlagEnabled='true' fallbackValue={false}>
					<TravelV3 assignment={assignment} externalId={externalId} filter='active' />
				</FeatureFlag>
			</>
		);
	}
}

export default withStyles(styles)(PastAssignmentDetailView);
