import React, { useMemo, useContext } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { COLORS } from '../../../utils/Application_Constants';
import ExpenseTextField from './ExpenseTextField';
import ExpensePayDate from './ExpensePayDate';
import ExpenseTransactionDate from './ExpenseTransactionDate';
import ExpenseDateRange from './ExpenseDateRange';
import ExpenseCategoryType from './ExpenseCategoryType';
import ExpenseBillType from './ExpenseBillType';
import ExpenseAmount from './ExpenseAmount';
import ExpenseReimbursedAmount from './ExpenseReimbursedAmount';
import ExpenseComments from './ExpenseComments';
import { valueAsMileage } from '../../Utils/ValueAsUtils';

const styles = (theme) => ({
    formHeader: {
        borderBottom: `1px solid ${COLORS.LT_MIDNIGHT25}`,
        paddingBottom: 8,
        marginBottom: 16,
    },
});

const ClinicianExpenseDetailsForm = (props) => {
    const { classes, index, expense, expenseCategories, originalExpenseEntries, isViewOnly } = props;
    const { control, watch, errors } = useFormContext();
    const expenseCategory = watch(`expenseEntries[${index}].category`);
    const expenseType = watch(`expenseEntries[${index}].type`);
    const isExpenseTravel = expenseCategory && expenseCategory === 'Travel';
    const canRemoveTravelDateFields = expenseType === 'BaggageFee' || expenseType === 'Fuel' || expenseType === '';
    const isClinicianReimbursement = true;
    const hasTransactionDate = expenseCategory && expenseCategory !== '';
    const hasMileage =
        expenseType && expenseCategory && expenseCategory === 'Travel' && expenseType === 'Mileage';
    // Get the original status from the originalExpenseEntries
    const originalStatus = useMemo(() => {
        if (expense.id && originalExpenseEntries?.length > 0) {
            const originalExpense = originalExpenseEntries.find(entry => entry.id === expense.id);
            return originalExpense ? originalExpense.status : expense.status;
        }
        return expense.status; // Fallback to current status if no original entry is found
    }, [expense.id, originalExpenseEntries, expense.status]);
    // Track the current status being edited
    const currentStatus = watch(`expenseEntries[${index}].status`);
    // Compute isPosted based on original status and current transient state
    const isPosted = useMemo(() => {
        return originalStatus === 'Posted' || (currentStatus === 'OnHoldReimbursement' && originalStatus === 'Posted');
    }, [originalStatus, currentStatus]);
    const isPendingSubmission = expense.status === 'PendingSubmission';
    const expenseTypes = useMemo(() => {
        if (!expenseCategory) return [];
        if (!expenseCategories) return [];
        let { types } = expenseCategories.find((option) => option.category === expenseCategory);
        return types ? types : [];
    }, [expenseCategory, expenseCategories]);
    const originalExpense = (function() {//If my tab original db expense is posted disable
		if (expense.id && expense.id > 0 && originalExpenseEntries && originalExpenseEntries.length > 0){
			return originalExpenseEntries.find(entry => entry.id === expense.id);
		}
		return null;
	})();
    const isAnyExpensePosted = (function() {//Check if anything is Posted
		if (originalExpenseEntries && originalExpenseEntries.length > 0){
			return originalExpenseEntries.some(entry => entry.status === 'Posted');
		}
		return false;
	})();

    return (
        <>
            <Typography variant='h5' component='h5' className={classes.formHeader}>
                Expense Details
            </Typography>
            <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name={`expenseEntries[${index}].merchantName`}
                        defaultValue={expense.merchantName}
                        rules={{
                            required: 'Merchant name is required',
                        }}
                        render={({ onChange, value }) => {
                            return (
                                <ExpenseTextField
                                    id={'merchant-name'}
                                    onChange={onChange}
                                    inputProps={{ maxLength: 50 }}
                                    value={value}
                                    placeholder='Merchant name'
                                    label={'Merchant name'}
                                    required={true}
                                    disabled={isPosted || isPendingSubmission || isViewOnly}
                                    error={Boolean(errors.expenseEntries?.[index]?.merchantName)}
                                    helperText={errors.expenseEntries?.[index]?.merchantName?.message}
                                ></ExpenseTextField>
                            );
                        }}
                    />
                </Grid>
                <ExpenseBillType index={index} expense={expense} isClinicianReimbursement={isClinicianReimbursement} isSGA={[]} isPosted={isPosted} isPendingSubmission={isPendingSubmission} isViewOnly={isViewOnly}/>
                <ExpensePayDate index={index} expense={expense} mode={'reimbursement'} isPosted={isPosted} isPendingSubmission={isPendingSubmission} isViewOnly={isViewOnly}/>
                {hasTransactionDate && (
                    <ExpenseTransactionDate index={index} expense={expense} isReimbursement isPosted={isPosted} isPendingSubmission={isPendingSubmission} isViewOnly={isViewOnly}/>
                )}
                <ExpenseCategoryType expenseTypes={expenseTypes} index={index} expense={expense} expenseCategories={expenseCategories} isSGA={[]} isPosted={isPosted} isPendingSubmission={isPendingSubmission} isViewOnly={isViewOnly}/>
                {isExpenseTravel && !canRemoveTravelDateFields && <ExpenseDateRange index={index} expense={expense} isPosted={isPosted} isPendingSubmission={isPendingSubmission} isViewOnly={isViewOnly}/>}
                <ExpenseAmount index={index} expense={expense} originalExpense={originalExpense} originalExpenseEntries={originalExpenseEntries} isReimbursement isPosted={isPosted} isPendingSubmission={isPendingSubmission} isAnyExpensePosted={isAnyExpensePosted} isViewOnly={isViewOnly}/>
                <ExpenseReimbursedAmount index={index} expense={expense} isReimbursement isPendingSubmission={isPendingSubmission} isPosted={isPosted} isViewOnly={isViewOnly}/>
                {hasMileage && (
                    <Grid item xs={12} sm={6}>
                        <Controller
                            control={control}
                            name={`expenseEntries[${index}].mileage`}
                            defaultValue={expense.mileage}
                            rules={{
                                required: 'Miles driven is required',
                            }}
                            render={({ onChange, value }) => (
                                <ExpenseTextField
                                    id={'miles-driven'}
                                    onChange={(e) => onChange(valueAsMileage(e.target.value))}
                                    value={value}
                                    placeholder={'Enter number of miles'}
                                    label={'Miles driven'}
                                    required
                                    disabled={isPosted || isPendingSubmission || isViewOnly}
                                    error={Boolean(errors.expenseEntries?.[index]?.mileage)}
                                    helperText={errors.expenseEntries?.[index]?.mileage?.message}
                                />
                            )}
                        />
                    </Grid>
                )}
                <ExpenseComments index={index} expense={expense} isPosted={isPosted} isPendingSubmission={isPendingSubmission} isViewOnly={isViewOnly}/>
            </Grid>
        </>
    );
};

export default withStyles(styles)(ClinicianExpenseDetailsForm);