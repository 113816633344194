import React, { useState } from 'react';
import { Container, Paper } from '@material-ui/core';
import { COLORS } from '../../../../../utils/Application_Constants';
import { withTranslation } from 'react-i18next';
import { Grid, Box, makeStyles } from '@material-ui/core';
import WithContexts from '../../../../../contexts/withContexts';
import moment from 'moment';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CredentialingStatusCard from './CredentialingStatusCard';
import LicenseOverviewCard from './LicenseOverviewCard';
import BoardCertsCard from './BoardCertsCard';
import OtherCertsCard from './OtherCertsCard';
import DocumentsTile from './DocumentsTile';
import NeedHelpCard from './NeedHelpCard';
import ToolTipComponent from '../../../../GlobalComponents/ToolTipComponent';
import PoweredByAxuall from '../../../../../assets/images/PoweredbyAxuall--vertical.png';

const styles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(4),
	},
	header: {
		color: COLORS.BLACK_MARLIN,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		//paddingLeft: '32px',
		//paddingTop: '16px',
		fontSize: '22px',
		fontWeight: '700',
	},
	headerRight: {
		color: COLORS.BLACK_MARLIN,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		fontSize: '22px',
		fontWeight: '700',
		padding: '30px',
	},
	headerTitle: {
		color: COLORS.BLACK_MARLIN,
		[theme.breakpoints.down('sm')]: {
			padding: '15px 0 0 15px',
		},
		textAlign: 'left',
		paddingTop: '60px',
		paddingBottom: '30px',
		fontSize: '22px',
		fontWeight: '700',
	},
	gridRoot: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginLeft: '32px',
			marginBottom: '10px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '5px 10px',
		},
	},
	paperRoot: {
		paddingBottom: '60px',
		paddingLeft: '30px',
	},
	ContainerRoot: {
		paddingLeft: '30px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '16px',
			paddingRight: '16px',
		},
	},
	root: {
		padding: '20px 0',
		minHeight: '800px',
		[theme.breakpoints.down('sm')]: {
			// paddingTop: '60px',
			// marginTop: '25px',
		},
		maxWidth: '100%',
		//backgroundColor: COLORS.BLACK_HAZE,
	},
	helpPanelRoot: {
		marginTop: '30px',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	// helpPanelContainerRoot: {
	//     paddingLeft:'15px',
	//     display: 'flex',
	//     flexWrap: 'wrap',
	//     [theme.breakpoints.down('sm')]: {
	//         padding:'0px'
	//     },
	// },
	helpPanelContainerRootRight: {
		paddingLeft: '30px',
		display: 'flex',
		flexWrap: 'wrap',
		paddingBottom: '30px',
		[theme.breakpoints.down('sm')]: {
			padding: '0px',
		},
	},
	helpPanelHeader: {
		marginBottom: '10px',
	},
	helpPanelLinks: {
		textAlign: 'left',
		marginBottom: '10px',
	},
	linkHeader: {
		color: COLORS.DENIM,
		textDecoration: 'none',
		display: 'flex',
		fontWeight: '700',
		fontSize: '22px',
		margin: 0,
		textAlign: 'left',
	},
	noMaxWidth: {
		maxWidth: 'none',
	},
	link: {
		color: COLORS.TRUE_BLUE,
		marginBottom: '10px',
		textDecoration: `underline dotted ${COLORS.LIGHT_BLUE}`,
		fontWeight: '400',
		fontSize: '16px',
		cursor: 'pointer',
		display: 'block',
	},
	topTileRoot: {
		//marginTop: '30px',
		height: '85%',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		marginLeft: '30px',
	},
	indicatorGreen: {
		color: COLORS.LT_EMERALD,
		verticalAlign: 'middle',
		// marginRight: '20px',
		// marginLeft:'80px',
		// marginTop:'15px',
		paddingRight: '5px',
		[theme.breakpoints.down('sm')]: {
			marginLeft: '1px',
			paddingBottom: '5px',
		},
	},
	indicatorYellow: {
		color: COLORS.LT_SUNFLOWER,
		verticalAlign: 'middle',
		// marginRight: '20px',
		// marginLeft:'80px',
		// marginTop:'15px',
		paddingRight: '5px',
		[theme.breakpoints.down('sm')]: {
			marginLeft: '1px',
			paddingBottom: '5px',
		},
	},
	indicatorRed: {
		color: COLORS.LT_PEPPER,
		verticalAlign: 'middle',
		// marginRight: '20px',
		// marginLeft:'80px',
		// marginTop:'15px',
		paddingRight: '5px',
		[theme.breakpoints.down('sm')]: {
			marginLeft: '1px',
			paddingBottom: '5px',
		},
	},
	containerLeft: {
		paddingLeft: '30px',
	},
	PoweredByAxuallImg: {
		maxWidth: '100px',
		marginTop: '1rem',
		height: 'fit-content',
	},
	startCredButton: {
		width: '100%',
	},
}));

function CredentialsOverviewForm({
	t,
	UserContext,
	activePath,
	setCredAppError,
	clinician
}) {
	const classes = styles();
	const [isLoaded, setIsLoaded] = useState(true);

	const renderIndicatorColor = (expirationDate) => {
		const daysToExpiration = !!expirationDate
			? (moment.utc(expirationDate) - moment.utc(moment())) / (1000 * 3600 * 24)
			: 100;

		if (daysToExpiration < 0) {
			return (
				<ToolTipComponent title='Expired' placement='right'>
					<FiberManualRecordIcon className={classes.indicatorRed} size='small' />
				</ToolTipComponent>
			);
		} else if (daysToExpiration >= 0 && daysToExpiration < 90) {
			return (
				<ToolTipComponent title='Expires Soon' placement='right'>
					<FiberManualRecordIcon className={classes.indicatorYellow} size='small' />
				</ToolTipComponent>
			);
		} else {
			return (
				<ToolTipComponent title='Active' placement='right'>
					<FiberManualRecordIcon className={classes.indicatorGreen} size='small' />
				</ToolTipComponent>
			);
		}
	};

	return (
		<>
			<div>
				<Container maxWidth='xl' className={classes.ContainerRoot} justify='space-evenly'>
					<Box mb={2}>
						<Grid container direction='row' spacing={4}>
							{/* LEFT COLUMN (header, licenses, etc.) */}
							<Grid item md={8} xs={12}>
								<Grid container direction='column' spacing={4}>
									<Grid item>
										<Box display='flex'>
											<Box flexGrow={1}>
												<h1 className='pageHeader'>
													{t('credentials:CRED_OVERVIEW.TITLE')}
												</h1>
											</Box>
											<img
												src={PoweredByAxuall}
												className={classes.PoweredByAxuallImg}
												alt=''
											/>
										</Box>
									</Grid>
									<Grid item>
										<Paper className={classes.paper}>
											<LicenseOverviewCard
												t={t}
												setCredAppError={setCredAppError}
												UserContext={UserContext}
												isLoaded={isLoaded}
												setIsLoaded={setIsLoaded}
												renderIndicatorColor={renderIndicatorColor}
											/>
										</Paper>
									</Grid>
									<Grid item>
										<Paper className={classes.paper}>
											<BoardCertsCard
												t={t}
												setCredAppError={setCredAppError}
												UserContext={UserContext}
												isLoaded={isLoaded}
												setIsLoaded={setIsLoaded}
												renderIndicatorColor={renderIndicatorColor}
												activePath={activePath}
											/>
										</Paper>
									</Grid>
									<Grid item>
										<Paper className={classes.paper}>
											<OtherCertsCard
												t={t}
												setCredAppError={setCredAppError}
												UserContext={UserContext}
												isLoaded={isLoaded}
												setIsLoaded={setIsLoaded}
												renderIndicatorColor={renderIndicatorColor}
											/>
										</Paper>
									</Grid>
								</Grid>
							</Grid>

							{/* RIGHT COLUMN (cred status, documents, etc.) */}
							<Grid item md={4} xs={12}>
								<Box mt={2}>
									<Grid container direction='column' spacing={4}>
										<Grid item xs={12}>
											<CredentialingStatusCard
												t={t}
												setCredAppError={setCredAppError}
												UserContext={UserContext}
												isLoaded={isLoaded}
												setIsLoaded={setIsLoaded}
												clinician={clinician}
											/>
										</Grid>
										<Grid item xs={12}>
											<DocumentsTile t={t} />
										</Grid>
										<Grid item xs={12}>
											<NeedHelpCard t={t} />
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</div>
		</>
	);
}
export default WithContexts(withTranslation()(CredentialsOverviewForm));
