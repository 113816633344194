import { getDocumentsForTypes } from '../services/DocumentService';
import {
	DOCUMENT_TYPE,
	DOCUMENT_FIELD_NAME,
	CRED_STATE,
	CRED_STATUS,
	CRED_APP_STATUS,
	CRED_APP_STATUS_MESSAGE,
	CRED_APP_STATUS_MESSAGE_V2,
	CRED_APP_STATUS_COLOR,
	CRED_RECIPE_TYPE,
	DEGREE,
} from './Application_Constants';
import { getNestedProperty } from './helpers';
import moment from 'moment';


//malpracticeClaimsHistorySectionCheck
function reAttestMalpracticeClaimsHistorySectionCheck(
	malpracticeClaimHistory,
	activePath,
	updateRequired
) {
	let status = {
		status: 'incomplete',
		missingFields: [],
	};

	if (!malpracticeClaimHistory) return status;

	if (
		malpracticeClaimHistory.qa1Denied === 'Yes' ||
		malpracticeClaimHistory.qa2MalpracticeClaim === 'Yes' ||
		malpracticeClaimHistory.qa3PendingClaim === 'Yes'
	) {
		if (
			!!malpracticeClaimHistory &&
			malpracticeClaimHistory.malpracticeClaimHistories.length > 0
		) {
			for (
				var obj = 0;
				obj < malpracticeClaimHistory.malpracticeClaimHistories.length;
				obj++
			) {
				if (!malpracticeClaimHistory.malpracticeClaimHistories[obj].plaintiffLastName) {
					status.missingFields.push(
						`malpracticeClaimHistories[${obj}].plaintiffLastName`
					);
				}
				if (!malpracticeClaimHistory.malpracticeClaimHistories[obj].insuranceCarrier) {
					status.missingFields.push(`malpracticeClaimHistories[${obj}].insuranceCarrier`);
				}

				if (!malpracticeClaimHistory.malpracticeClaimHistories[obj].state) {
					status.missingFields.push(`malpracticeClaimHistories[${obj}].state`);
				}

				if (!malpracticeClaimHistory.malpracticeClaimHistories[obj].status) {
					status.missingFields.push(`malpracticeClaimHistories[${obj}].status`);
				}

				if (!malpracticeClaimHistory.malpracticeClaimHistories[obj].incidentDate) {
					status.missingFields.push(`malpracticeClaimHistories[${obj}].incidentDate`);
				}
			}

			if (status.missingFields.length === 0) {
				status.status = 'complete';
			} else {
				status.status = 'incomplete';
			}
		} else {
			status.status = 'incomplete';
		}
	} else {
		status.status = 'complete';
	}

	if (
		!malpracticeClaimHistory.qa1Denied ||
		!malpracticeClaimHistory.qa2MalpracticeClaim ||
		!malpracticeClaimHistory.qa3PendingClaim
	) {
		if (!!updateRequired) {
			if (!malpracticeClaimHistory.qa1Denied && updateRequired.updateQa1Denied === true) {
				status.missingFields.push('qa1Denied');
			}

			if (
				!malpracticeClaimHistory.qa2MalpracticeClaim &&
				updateRequired.updateQa2MalpracticeClaim === true
			) {
				status.missingFields.push('qa2MalpracticeClaim');
			}

			if (
				!malpracticeClaimHistory.qa3PendingClaim &&
				updateRequired.qa3PendingClaim === true
			) {
				status.missingFields.push('qa3PendingClaim');
			}
		}
		status.status = 'incomplete';
	}

	if (
		malpracticeClaimHistory.qa1Denied === 'No' &&
		malpracticeClaimHistory.qa2MalpracticeClaim === 'No' &&
		malpracticeClaimHistory.qa3PendingClaim === 'No'
	) {
		status.status = 'complete';
	}
	return status;
}
function documentDateValidation(date) {
	if (date !== null) {
		var dateTo = moment(new Date(date));
		var today = moment();
		var sixMonthsAgoToday = today.subtract(6, 'month');
		//TDS-3908 ‘Document Date': It should be from the past 6 momnths from current date
		if (sixMonthsAgoToday.isSameOrBefore(dateTo, 'day')) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
}

// documentLibraryStatusCheck - This function checks all required documents based on document types
async function documentLibraryStatusCheck(externalId) {
	let status = {
		status: '',
		missingFields: [],
	};
	//all required document types
	let docTypes = [
		DOCUMENT_TYPE.CurriculumVitae,
		DOCUMENT_TYPE.ContinuingEducation,
		DOCUMENT_TYPE.LifeSupportCerts,
		DOCUMENT_TYPE.PhotoId,
		DOCUMENT_TYPE.Photo,
	];

	const docList = {};

	await Promise.all([getDocumentsForTypes(externalId, docTypes)]).then(([docs]) => {
		if (!!docs) {
			docs.forEach((d) => {
				const docType = getNestedProperty(d, DOCUMENT_FIELD_NAME.DocumentType);
				if (!docList[docType]) docList[docType] = [d];
				else docList[docType].push(d);
			});
		}
	});

	if (Object.keys(docList).length > 0) {
		let docListArray = [];

		docTypes.forEach((t) => (docListArray = docListArray.concat(docList[t] ?? [])));

		let curriculumVitae = 0;
		let cmrORLifeSupportCerts = 0;
		let licenseOrPassport = 0;
		let headshotPhoto = 0;

		docListArray.forEach((doc) => {
			const docType = getNestedProperty(doc, DOCUMENT_FIELD_NAME.DocumentType);
			const dateModified = getNestedProperty(doc, DOCUMENT_FIELD_NAME.Modified);

			if (docType === DOCUMENT_TYPE.CurriculumVitae && documentDateValidation(dateModified)) {
				curriculumVitae = curriculumVitae + 1;
			} else if (
				(docType === DOCUMENT_TYPE.ContinuingEducation ||
					docType === DOCUMENT_TYPE.LifeSupportCerts) &&
				documentDateValidation(dateModified)
			) {
				cmrORLifeSupportCerts = cmrORLifeSupportCerts + 1;
			} else if (docType === DOCUMENT_TYPE.PhotoId && documentDateValidation(dateModified)) {
				licenseOrPassport = licenseOrPassport + 1;
			} else if (docType === DOCUMENT_TYPE.Photo && documentDateValidation(dateModified)) {
				headshotPhoto = headshotPhoto + 1;
			}
		});
		if (curriculumVitae === 0) {
			status.missingFields.push(`Current CV (in Month/Year format)`);
		}
		if (cmrORLifeSupportCerts === 0) {
			status.missingFields.push(`CME or Life Support Certifications for the past two years`);
		}
		if (licenseOrPassport === 0) {
			status.missingFields.push(`Color copy of your current Driver’s License or Passport`);
		}
		if (headshotPhoto === 0) {
			status.missingFields.push(`Current color headshot photo`);
		}

		if (status.missingFields.length === 0) {
			status.status = 'complete';
		} else {
			status.status = 'incomplete';
		}
	} else {
		status.missingFields.push(`Current CV (in Month/Year format)`);
		status.missingFields.push(`CME or Life Support Certifications for the past two years`);
		status.missingFields.push(`Color copy of your current Driver’s License or Passport`);
		status.missingFields.push(`Current color headshot photo`);
		status.status = 'incomplete';
	}

	return status;
}

function calcCredState(credStatus) {
	let credState = CRED_STATE.OPEN;
	switch (credStatus) {
		case CRED_STATUS.ApplicationStarted:
		case CRED_STATUS.Incomplete:
		case CRED_STATUS.PermProvider:
		case CRED_STATUS.Expiring:
			credState = CRED_STATE.OPEN;
			break;
		case CRED_STATUS.ApplicationReceived:
		case CRED_STATUS.InProcess:
		case CRED_STATUS.TempApproved:
		case CRED_STATUS.Hold:
			credState = CRED_STATE.CLOSED;
			break;
		case CRED_STATUS.Approved:
		case CRED_STATUS.ApprovedConditions:
		case CRED_STATUS.NotApproved:
		case CRED_STATUS.Expired:
			credState = CRED_STATE.PARTIALLY_OPEN;
			break;
		default:
			credState = CRED_STATE.OPEN;
	}
	return credState;
}

function getCredAppStatusObject(credStatus) {
	let credState = {};
	switch (credStatus) {
		case CRED_STATUS.ApplicationStarted:
			credState.status = CRED_APP_STATUS.NotSigned;
			credState.message = CRED_APP_STATUS_MESSAGE.NotSignedMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.NotSignedColor;
			break;
		case CRED_STATUS.ApplicationSent:
			credState.status = CRED_APP_STATUS.NotSigned;
			credState.message = CRED_APP_STATUS_MESSAGE.NotSignedMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.NotSignedColor;
			break;
		case CRED_STATUS.ApplicationReceived:
			credState.status = CRED_APP_STATUS.Signed;
			credState.message = CRED_APP_STATUS_MESSAGE.SignedMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.SignedColor;
			break;
		case CRED_STATUS.InProcess:
			credState.status = CRED_APP_STATUS.InReview;
			credState.message = CRED_APP_STATUS_MESSAGE.InReviewMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.InReviewColor;
			break;
		case CRED_STATUS.Approved:
			credState.status = CRED_APP_STATUS.Complete;
			credState.message = CRED_APP_STATUS_MESSAGE.CompleteMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.CompleteColor;
			break;
		case CRED_STATUS.ApprovedConditions:
			credState.status = CRED_APP_STATUS.Complete;
			credState.message = CRED_APP_STATUS_MESSAGE.CompleteMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.CompleteColor;
			break;
		case CRED_STATUS.TempApproved:
			credState.status = CRED_APP_STATUS.InReview;
			credState.message = CRED_APP_STATUS_MESSAGE.InReviewMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.InReviewColor;
			break;
		case CRED_STATUS.NotApproved:
			credState.status = CRED_APP_STATUS.VerificationComplete;
			credState.message = CRED_APP_STATUS_MESSAGE.VerificationCompleteMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.VerificationCompleteColor;
			break;
		case CRED_STATUS.Incomplete:
			credState.status = CRED_APP_STATUS.Incomplete;
			credState.message = CRED_APP_STATUS_MESSAGE.IncompleteMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.IncompleteColor;
			break;
		case CRED_STATUS.Hold:
			credState.status = CRED_APP_STATUS.InReview;
			credState.message = CRED_APP_STATUS_MESSAGE.InReviewMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.InReviewColor;
			break;
		case CRED_STATUS.Expired:
			credState.status = CRED_APP_STATUS.Expired;
			credState.message = CRED_APP_STATUS_MESSAGE.ExpiredMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.ExpiredColor;
			break;
		case CRED_STATUS.Expiring:
			credState.status = CRED_APP_STATUS.Expiring;
			credState.message = CRED_APP_STATUS_MESSAGE.ExpiringMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.ExpiringColor;
			break;
		default:
			credState.status = CRED_APP_STATUS.NotSigned;
			credState.message = CRED_APP_STATUS_MESSAGE.NotSignedMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.NotSignedColor;
	}
	return credState;
}
function getCredAppStatusObjectV2(credStatus) {
	let credState = {};
	switch (credStatus) {
		case CRED_STATUS.ApplicationStarted:
			credState.status = CRED_APP_STATUS.NotSigned;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.NotSignedMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.NotSignedColor;
			break;
		case CRED_STATUS.ApplicationSent:
			credState.status = CRED_APP_STATUS.NotSigned;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.NotSignedMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.NotSignedColor;
			break;
		case CRED_STATUS.ApplicationReceived:
			credState.status = CRED_APP_STATUS.Signed;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.SignedMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.SignedColor;
			break;
		case CRED_STATUS.InProcess:
			credState.status = CRED_APP_STATUS.InReview;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.InReviewMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.InReviewColor;
			break;
		case CRED_STATUS.Approved:
			credState.status = CRED_APP_STATUS.Complete;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.CompleteMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.CompleteColor;
			break;
		case CRED_STATUS.ApprovedConditions:
			credState.status = CRED_APP_STATUS.Complete;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.CompleteMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.CompleteColor;
			break;
		case CRED_STATUS.TempApproved:
			credState.status = CRED_APP_STATUS.InReview;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.InReviewMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.InReviewColor;
			break;
		case CRED_STATUS.NotApproved:
			credState.status = CRED_APP_STATUS.VerificationComplete;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.VerificationCompleteMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.VerificationCompleteColor;
			break;
		case CRED_STATUS.PermProvider:
		case CRED_STATUS.Incomplete:
			credState.status = CRED_APP_STATUS.Incomplete;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.IncompleteMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.IncompleteColor;
			break;
		case CRED_STATUS.Hold:
			credState.status = CRED_APP_STATUS.InReview;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.InReviewMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.InReviewColor;
			break;
		case CRED_STATUS.Expired:
			credState.status = CRED_APP_STATUS.Expired;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.ExpiredMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.ExpiredColor;
			break;
		case CRED_STATUS.Expiring:
			credState.status = CRED_APP_STATUS.Expiring;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.ExpiringMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.ExpiringColor;
			break;
		default:
			credState.status = CRED_APP_STATUS.NotSigned;
			credState.message = CRED_APP_STATUS_MESSAGE_V2.NotSignedMessage;
			credState.colorCode = CRED_APP_STATUS_COLOR.NotSignedColor;
	}
	return credState;
}

 function validateNpi(str) {
	const pattern = /^[0-9]{10}$/;

	if (!pattern.test(str)) {
		return false;
	}

	const digits = str.split('').map(Number);
	const npiSum = calculateNpiSum(digits.slice(0, 9));

	return (npiSum + digits[9]) % 10 === 0;
}

function calculateNpiSum(digits) {
	return digits.reduce(processNpiDigit, 24);
}

function processNpiDigit(currentSum, currentDigit, currentIndex) {
	if (currentIndex % 2 === 1) {
		return currentSum + currentDigit;
	}

	return currentSum + sumDigits(currentDigit * 2);
}

function sumDigits(num) {
	const digits = num.toString().split('').map(Number);

	return digits.reduce((currentSum, currentDigit) => currentSum + currentDigit, 0);
}

export {
	documentLibraryStatusCheck,
	reAttestMalpracticeClaimsHistorySectionCheck,
	calcCredState,
	getCredAppStatusObject,
	getCredAppStatusObjectV2,
	documentDateValidation,
	validateNpi,
};
