import React, {useEffect, useState} from 'react';
import {
  Controller,
  useFormContext
} from "react-hook-form";
import { getConfigurationSetting } from '../../../services/AppConfigService';


  function containsAny(str, substrings) {
    
    var rtnStrings = [];
    for (var i = 0; i != substrings.length; i++) {
       var substring = substrings[i];
       let re = new RegExp(String.raw`\b${substring}\b`, "gi");
       if (str.search(re) >= 0) {
         rtnStrings.push(substring);
       }
    }

    if (rtnStrings.length > 0){
      const formatter = new Intl.ListFormat('en', {
        style: 'long',
        type: 'conjunction',
      });
      return 'We detected you may have entered ' + formatter.format(rtnStrings) + ' information in a comment. To help reduce potential delays in timesheet processing, please enter the information directly in the timesheet. Review the Help information for additional information.';
    }else{
      return '';
    }
    
}

export const WarningController = (props) => {
    const { formState } = useFormContext();
    const isDirty = !!formState.dirtyFields[props.name];
    const isTouched = !!formState.touched[props.name];
    const [wordList, setWordList] = useState(null); 

    useEffect(() => {
        let isMounted = true;               // note mutable flag
        getConfigurationSettings();
        return () => { isMounted = false }; // cleanup toggles value, if unmounted
        async function getConfigurationSettings() {
            let fallbackValue = {"words":["break", "lunch", "call"]};
            const aWordlist = await getConfigurationSetting('Timesheets:Comments:WordList', false, fallbackValue);
            if (isMounted)  setWordList(aWordlist);
              else console.log("aborted setWordList on unmounted component")
          }
      }
    , []);

    return (
      <Controller
        control={props.control}
        name={props.name}
        defaultValue={props.defaultValue}
        render={(innerProps) => {
          return props.render({
            ...innerProps,
            isDirty,
            isTouched,
            warning: (wordList && wordList.words && innerProps.value.length > 0) ? containsAny(innerProps.value, wordList.words) : ''
          });
        }}
      />
    );
  };

  
