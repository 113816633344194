/**
 * @file Desktop Navigation component is rendered on medium and large devices.
 * @TODO integrate with react router, links currently lead to nowhere.
 *
 */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Grow, Paper, Popper, MenuItem, MenuList, Grid } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { menuNavigationProps } from '../../../types/centralizedProps';
import Logo from '../../../assets/logo/locumtenens_com_logo.svg';
import { COLORS } from '../../../utils/Application_Constants';
import { withTranslation } from 'react-i18next';
import ProfileNavComponent from '../../ContentComponents/ProfileNavigation/ProfileNavComponent';
import NavigationService from '../../../services/NavigationService';
import UserContext from '../../../contexts/userContext';
import Notifications from '../Notifications/Notifications';
const styles = () => ({
	menuListRoot: {
		display: 'flex',
	},
	logoWrapper: {
		maxWidth: '270px',
	},
	supportNumberMenuListRoot: {
		display: 'flex',
		paddingLeft: '5px',
		fontSize: '14px',
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '10px 0px 18px 0px',
		marginTop: '15px',
		flexGrow: '1',
	},
	navbarWrapper: {
		padding: '0 15px',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		color: `${COLORS.DENIM}`,
		position: 'absolute',
	},
	fontBoldHeader: {
		fontWeight: '700',
		fontSize: '14px',
		padding: '12px 10px',
		transition: '0.3s all',
		'&:hover': {
			background: 'none',
		},
	},

	paperPopper: {
		zIndex: '1302',
	},
	subNavRoot: {
		padding: '32px 49px 30px 30px',
	},
	gridFlexWrapper: {
		display: 'flex',
		justifyContent: 'flex-start',
	},
	supportNavNumber: {
		fontWeight: '700',
		color: COLORS.TRUE_BLUE,
		fontSize: '14px',
		cursor: 'pointer',
		padding: '12px 10px',
		transition: '0.3s all',
		'&:hover': {
			background: 'none',
			color: '#336195',
		},
	},
	subNavMenuItem: {
		fontWeight: '700',
		color: `${COLORS.TRUE_BLUE}`,
		fontSize: '14px',
		'&:hover': {
			background: COLORS.TRUE_BLUE,
			color: '#ffffff',
		},
	},
});

class DesktopNavigation extends Component {
	constructor(props, context) {
		super(props);
		this.state = {
			childMenuOpen: null,
			profileNavigation: [],
			anchorEl: null,
			isAdminApp: process.env.REACT_APP_TYPE === 'Admin',
			isClientApp: process.env.REACT_APP_TYPE === 'Client',
		};
		this.anchorRef = React.createRef(null);
		this.context = context;
	}

	componentDidMount() {
		const profileNavigation = NavigationService.getProfileNavigation();
		this.setState({ profileNavigation: profileNavigation.profile });
	}

	handleClick = (event) => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	renderSupportNumber = () => {
		const { t, classes } = this.props;
		return (
			<a href={t('common:INFORMATION.HELP_NUMBER_LINK')} className={classes.supportNavNumber}>
					{' '}
					{t('common:INFORMATION.HELP_NUMBER')}
			</a>
		);
	};

	/**
	 * Method renders a single item as part of navigation.
	 * @param {any} navigationItem
	 * @returns {JSX}
	 */
	renderNavigationItem = (navigationItem) => {
		const { classes } = this.props;
		const { id, name, parentUrlLink } = navigationItem;

		return (
			<NavLink
				exact
				key={id.toString()}
				to={{ pathname: parentUrlLink }}
				className='desk-link'
			>
				<MenuItem className={classes.fontBoldHeader}>{name}</MenuItem>
			</NavLink>
		);
	};

	/**
	 * Main navigation method called in the render method. This method decides
	 * how to render the navigation based on subnav properties.
	 */
	renderNavigation = () => {
		const { menuNavigation } = this.props;
		return menuNavigation.map((item) => {
			if (item.children.length > 0) {
				return this.renderNavItemWithSubMenu(item);
			}
			return this.renderNavigationItem(item);
		});
	};

	renderNavItemWithSubMenu(navigationItem) {
		const { classes } = this.props;
		const { id, name, parentUrlLink } = navigationItem;

		return (
			<div key={'div-' + id.toString()}>
				<NavLink
					exact
					to={{ pathname: parentUrlLink }}
					className='desk-link'
				>
					<MenuItem
						className={classes.fontBoldHeader}
						onClick={this.handleNavItemWithSubMenuClick}
						onClose={(event) => {
							this.anchorRef = event.target;
						}}
						onBlur={(event) => {
							this.anchorRef = event.target;
							this.setState({childMenuOpen: null});
						}}
					>
						{name}
						<span className='caret'></span>
					</MenuItem>
				</NavLink>
				<Popper
					open={
						this.state.childMenuOpen?.toUpperCase().trim() ===
						navigationItem.name?.toUpperCase().trim()
					}
					anchorEl={this.anchorRef}
					transition
					className={classes.paperPopper}
					placement='bottom-start'
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin:
									placement === 'bottom' ? 'center top' : 'center bottom',
							}}
						>
							<Paper>
								<MenuList>
									{navigationItem.children.map((item, index) => {
										if (item.hasLtDotComBaseUrl) {
											return (
												<a
													key={'a-' + item.id.toString()}
													href={`${process.env.REACT_APP_LOGIN_URL}${item.link}`}
													className='desk-link'
												>
													<MenuItem
														classes={{ root: classes.subNavMenuItem }}
													>
														{item.name}
													</MenuItem>
												</a>
											);
										} else {
											return (
												<NavLink
													key={'NavLink-' + item.id.toString()}
													exact
													to={{ pathname: item.link }}
													className='desk-link'
												>
													<MenuItem
														classes={{ root: classes.subNavMenuItem }}
													>
														{item.name}
													</MenuItem>
												</NavLink>
											);
										}
									})}
								</MenuList>
							</Paper>
						</Grow>
					)}
				</Popper>
			</div>
		);
	}

	handleNavItemWithSubMenuClick = (event) => {
		this.anchorRef = event.target;
		if (this.state.childMenuOpen === event.target.innerText) {
			this.setState({childMenuOpen: null});
		} else {
			this.setState({ childMenuOpen: event.target.innerText });
		}
	};

	render() {
		const { classes,refreshNotifications } = this.props;
		const { profileNavigation, isAdminApp, isClientApp } = this.state;
		const { account } = this.context;

		return (
			<Grid
				container
				justifyContent='flex-start'
				alignContent='center'
				className={classes.navbarWrapper}
			>
				<Grid item classes={{ root: classes.logoWrapper }} md={3}>
					<a href='/'>
						<img alt='LocumTenens Logo' src={Logo} />
					</a>
				</Grid>
				<Grid item md={6}>
					<MenuList classes={{ root: classes.menuListRoot }}>
						{this.renderNavigation()}
					</MenuList>
				</Grid>

				<Grid item md='auto' classes={{ root: classes.buttonWrapper }}>
					<MenuList classes={{ root: classes.supportNumberMenuListRoot }}>
						{this.renderSupportNumber()}
					</MenuList>

					{!isClientApp && !isAdminApp && (
						<span>
							<MenuList>
								<Notifications refreshNotifications = {refreshNotifications} device='desktop' />
							</MenuList>
						</span>
					)}

					<span>
						<ProfileNavComponent profileNavigation={account ? profileNavigation : []} />
					</span>
				</Grid>
				{/* Removed this logout button and added this function in profileNavigation  */}
				{/*
        < Grid item container justify="flex-end" alignContent="center" md={1} >
          <MenuList classes={{ root: classes.menuListRoot }}>
            <LoginComponent />
          </MenuList>
        </Grid > */}
			</Grid>
		);
	}
}

DesktopNavigation.propTypes = {
	classes: PropTypes.shape({
		menuListRoot: PropTypes.string.isRequired,
		logoWrapper: PropTypes.string.isRequired,
		navbarWrapper: PropTypes.string.isRequired,
		fontBoldHeader: PropTypes.string.isRequired,
		paperPopper: PropTypes.string.isRequired,
		subNavRoot: PropTypes.string.isRequired,
		gridFlexWrapper: PropTypes.string.isRequired,
	}).isRequired,
	menuNavigation: menuNavigationProps.isRequired,
};

DesktopNavigation.contextType = UserContext;
export default withTranslation()(withStyles(styles)(DesktopNavigation));
