/**
 * @file The NavigationService class will transform the navbar information from a .json format
 * to a parsable format for the applications app bar. This exists to allow for future implementation
 * of a CMS platform to be called here and transformed into the expected format of the header/footer
 * componenents.
 */
import headerNavigation from '../configuration/navigation/header';
import level1HeaderNavigation from '../configuration/navigation/headerlevel1';
import footerNavigation from '../configuration/navigation/footer';
import profileNavigation from '../configuration/navigation/profile';
import timeAndPayNavigation from '../configuration/navigation/timeandpay';

function replaceAll(string, search, replace) {
	return string.split(search).join(replace);
}

class NavigationService {
	/**
	 * Method currently just retrieves from header json, change here to retrieve header information
	 * from CMS/API.
	 */
	getHeaderLevelNavigation(userLevel) {
		console.log('userLevel received in Navigation service is ' + JSON.stringify(userLevel));
		if (userLevel === 1) {
			return level1HeaderNavigation;
		}

		return headerNavigation;
	}

	/**
	 * Method currently just retrieves from header json, change here to retrieve header information
	 * from CMS/API.
	 */
	getHeaderNavigation() {
		return headerNavigation;
	}

	/**
	 * Method currently just retrieves from footer json, change here to retrieve footer information
	 * from CMS/API.
	 */
	getFooterNavigation() {
		var jobboard = process.env.REACT_APP_LOGIN_URL.split('//')[1];

		Object.keys(footerNavigation.footer).forEach(function (key) {
			Object.keys(footerNavigation.footer[key].children).forEach(function (key2) {
				var origLink = footerNavigation.footer[key].children[key2].link;

				var newLink = replaceAll(origLink, 'www.locumtenens.com', jobboard);

				footerNavigation.footer[key].children[key2].link = newLink;
			});
		});

		return footerNavigation;
	}

	/**
	 * Method currently just retrieves from footer json, change here to retrieve profile information
	 * from CMS/API.
	 */
	getProfileNavigation() {
		return profileNavigation;
	}

	/**
	 *
	 * Time and Pay Navigation links
	 */
	getTimeAndPayNavigationLinks() {
		var jobboard = process.env.REACT_APP_LT_API_URI.split('//')[1];

		Object.keys(timeAndPayNavigation.timeandpay).forEach(function (key) {
			var origLink = timeAndPayNavigation.timeandpay[key].link;
			var newLink = replaceAll(origLink, 'www.locumtenens.com', jobboard);
			timeAndPayNavigation.timeandpay[key].link = newLink;
		});

		return timeAndPayNavigation;
	}
}

export default new NavigationService();
