import React, { useEffect, useRef, useState, useContext } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import { TextField, makeStyles } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { COLORS } from '../../../../utils/Application_Constants';

const useStyles = makeStyles((theme) => ({
	tabLabel: {
		display: 'flex',
		gap: 8,
		alignItems: 'center',
	},
	textFieldRoot: {
		marginBottom: 0,
	},
	textFieldError: {
		'& .MuiInput-input': {
			color: COLORS.LT_PEPPER,
		},
	},
	tabLabelInput: {
		cursor: 'pointer',
		fontSize: 14,
	},
}));

const ExpenseTabLabel = (props) => {
	const { handleRemoveTab, index, isActive, setValue, tab,mode, isViewOnly, originalPrimaryExpense, isAnyExpensePosted } = props;
	const classes = useStyles();
	const [isEditing, setIsEditing] = useState(false);
	const ref = useRef();
	const { errors, watch} = useFormContext();
	const expenseEntries = watch('expenseEntries');
	const [hideDelete, setHideDelete] = useState(false);

	const handleBlur = () => {
		setIsEditing(false);
	};

	const handleEdit = () => {
		ref.current.focus();
		ref.current.select();
		setIsEditing((prevState) => !prevState);
	};

	useEffect(() => {
		if (mode === 'pending-cc' && (expenseEntries[index].id > 0)) {
			setHideDelete(true);
		}
    }, []);

	return (
		<div className={classes.tabLabel}>
			<Controller
				defaultValue={tab.label}
				name={`expenseEntries[${index}].label`}
				render={({ onChange, value }) => (
					<TextField
						onClick={() => setValue(index)}
						error={Boolean(errors.expenseEntries?.[index])}
						onChange={onChange}
						onBlur={handleBlur}
						classes={{ root: classes.textFieldRoot }}
						value={value}
						InputProps={{
							readOnly: !isEditing,
							classes: { error: classes.textFieldError },
						}}
						inputProps={{ className: classes.tabLabelInput }}
						inputRef={ref}
					/>
				)}
			/>
			{isActive && !isViewOnly && <EditIcon fontSize='small' color='primary' onClick={handleEdit} />}
			{(index !== 0 && expenseEntries[index].status !== 'Posted' && !hideDelete) && !isViewOnly && !(mode!=='pending-cc' && isAnyExpensePosted) && <CloseIcon fontSize='small' onClick={() => handleRemoveTab(index)} />}
		</div>
	);
};

export default ExpenseTabLabel;
