import React, { useState, useEffect } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import ExpenseTextField from './ExpenseTextField';
import { DivisionEnum, TypeEnum } from './Division.enum';
import { ExpenseStatusValuesEnum } from './ExpenseStatus.enum';

const ExpenseCategoryType = (props) => {
    const { expenseTypes, index, expense, expenseCategories, mode, isSGA, setIsDivisionDisabled, isPosted, isPendingSubmission, isViewOnly} = props;
    const { control, errors, watch, setValue } = useFormContext();
    const expenseCategory = watch(`expenseEntries[${index}].category`);
    const expenseBillType = watch(`expenseEntries[${index}].billType`);
    const isClinicianDeduction = expenseBillType === 'ClinicianDeduction';
    const [required, setRequired] = useState({ required: true });

    useEffect(() => {
        setRequired({ required: (mode !== 'cc' && !isClinicianDeduction) || (mode === 'cc' && expense?.status === ExpenseStatusValuesEnum.Approved)});
    }, [expense?.status]);

    return (
        <Grid item xs={12}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name={`expenseEntries[${index}].category`}
                        defaultValue={!expense.category ? '' : expense.category}
                        rules={{
                            required: required.required ? 'Expense category is required ' + expenseBillType : false,
                        }}
                        render={({ onChange, value }) => (
                            <ExpenseTextField
                                id={'expense-category'}
                                select
                                onChange={(e) => {
                                    if(isSGA[index]){
                                        setValue(`expenseEntries[${index}].sgaType`, '');
                                        setValue(`expenseEntries[${index}].sgaCategory`, e.target.value);
                                    }else{
                                        setValue(`expenseEntries[${index}].bookingType`, '');
                                        setValue(`expenseEntries[${index}].bookingCategory`, e.target.value);
                                    }
                                    setValue(`expenseEntries[${index}].type`, '');
                                    onChange(e);
                                }}
                                value={!value ? '' : value}
                                label={'Expense category'}
                                {...required}
                                disabled={isViewOnly || isPosted || isPendingSubmission}
                                error={Boolean(errors.expenseEntries?.[index]?.category)}
                                helperText={errors.expenseEntries?.[index]?.category?.message}
                            >
                                <MenuItem value='' disabled>
                                    Select an expense category
                                </MenuItem>
                                {!expenseCategories ? null : expenseCategories.map((option, index) => (
                                    <MenuItem
                                        key={option.category}
                                        value={option.category}
                                        id={`expense-category-menu-option-${index}`}
                                    >
                                        {option.category}
                                    </MenuItem>
                                ))}
                            </ExpenseTextField>
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name={`expenseEntries[${index}].type`}
                        defaultValue={!expense.type ? '' :expense.type }
                        rules={{
                            required: expenseCategory && required.required ? 'Expense type is required' : false,
                        }}
                        render={({ onChange, value }) => (
                            <ExpenseTextField
                                id={'expense-type'}
                                select
                                onChange={(e) => {
                                    if(isSGA[index]){
                                        setValue(`expenseEntries[${index}].sgaType`, e.target.value);
                                        if (e.target.value===TypeEnum.CustomerCareGiftsFlowers){
                                            //set division to Customer Care and disable
                                            setValue(`expenseEntries[${index}].sgaDivision`, DivisionEnum.CustomerCare);
                                            setValue(`expenseEntries[${index}].division`, DivisionEnum.CustomerCare);
                                            setIsDivisionDisabled(true);
                                        }else if (e.target.value===TypeEnum.MalpracticeCompFundStateCompfunds ||
                                            e.target.value===TypeEnum.PSYCMalpracticeCompFund
                                        ){
                                            //set division to Malpractice and disable
                                            setValue(`expenseEntries[${index}].sgaDivision`, DivisionEnum.CustomerCare);
                                            setValue(`expenseEntries[${index}].division`, DivisionEnum.Malpractice);
                                            setIsDivisionDisabled(true);
                                        }else if (e.target.value===TypeEnum.UnidentifiedLicensing
                                        ){
                                            //set division to CVO and disable
                                            setValue(`expenseEntries[${index}].sgaDivision`, DivisionEnum.CustomerCare);
                                            setValue(`expenseEntries[${index}].division`, DivisionEnum.CVO);
                                            setIsDivisionDisabled(true);
                                        }else if (e.target.value===TypeEnum.LodgingSecurityDeposit ||
                                            e.target.value===TypeEnum.AssociateTravelPrepaid ||
                                            e.target.value===TypeEnum.AssociateTravelAccrued ||
                                            e.target.value===TypeEnum.Disputed ||
                                            e.target.value===TypeEnum.IncidentalsRounding ||
                                            e.target.value===TypeEnum.Rebate 
                                        ){
                                            //set division to Corporate and disable
                                            setValue(`expenseEntries[${index}].sgaDivision`, DivisionEnum.CustomerCare);
                                            setValue(`expenseEntries[${index}].division`, DivisionEnum.Corporate);
                                            setIsDivisionDisabled(true);
                                        }else{
                                            setIsDivisionDisabled(false);
                                        }
                                    }else{
                                        setValue(`expenseEntries[${index}].bookingType`, e.target.value);
                                    }
                                    onChange(e);
                                }}
                                value={!value ? '' : value}
                                label={'Expense type'}
                                {...required}
                                disabled={isViewOnly || !expenseCategory || isPosted || isPendingSubmission}
                                error={Boolean(errors.expenseEntries?.[index]?.type)}
                                helperText={errors.expenseEntries?.[index]?.type?.message}
                            >
                                <MenuItem value='' disabled>
                                    Select an expense type
                                </MenuItem>
                                {expenseTypes.map((type, index) => (
                                    <MenuItem
                                        key={type.name}
                                        value={type.name}
                                        id={`expense-type-menu-option-${index}`}
                                    >
                                        {type.description}
                                    </MenuItem>
                                ))}
                            </ExpenseTextField>
                        )}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ExpenseCategoryType;
