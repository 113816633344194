import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Grid, withStyles, CircularProgress, Typography } from '@material-ui/core';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import ExpenseButtons from '../Components/Expense/ExpenseButtons';
import ExpenseEntryHeader from '../Components/Expense/ExpenseEntryHeader';
import ExpenseReceiptUploadForm from '../Components/Expense/ExpenseReceiptUploadForm';
import {
    createPendingCCExpense,
    updateExpense,
    updatePendingCCExpense,
    createExpense,
    createExpenseImage,
    getExpenseById,
    getDivisions,
    getPendingCCExpenseById,
    getAllCreditCards, 
    getExpenseCategories,
    getStatusesAllowed
} from '../Services/ExpenseService';
import { formatErrorMessage } from '../Utils/ErrorMessage';
import { uploadImages } from '../Utils/UploadImages';
import AddExpenseTab from '../Components/Expense/Tabs/AddExpenseTab';
import ExpenseTabs from '../Components/Expense/Tabs/ExpenseTabs';
import ExpenseTab from '../Components/Expense/Tabs/ExpenseTab';
import ExpenseTabLabel from '../Components/Expense/Tabs/ExpenseTabLabel';
import ExpenseTabPanel from '../Components/Expense/Tabs/ExpenseTabPanel';
import AddIcon from '@material-ui/icons/Add';
import BookingDetailsForm from '../Components/Expense/BookingDetailsForm';
import PreCodedExpenseDetailsForm from '../Components/Expense/PreCodedExpenseDetailsForm';
import Alert from '../Components/Alert';
import ExpenseSummary from '../Components/Expense/ExpenseSummary';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import InternalNotesForm from '../Components/Expense/InternalNotesForm';
import ReasonForSplittingForm from '../Components/Expense/ReasonForSplittingForm';
import AdditionalExpenseDetails from '../Components/Expense/AdditionalExpenseDetails';
import { AdminAuthContext } from '../../Admin/Contexts/AdminAuthContext';
import { PreCodedStatusEnum } from '../Components/Expense/PreCodedStatus.enum';
import { ExpenseStatusEnum } from '../Components/Expense/ExpenseStatus.enum';
import { ExpenseClassEnum } from '../Components/Expense/ExpenseClass.enum';
import ExpenseNeedsReviewAlert from '../Components/Expense/ExpenseNeedsReviewAlert';
import _ from 'lodash';

const styles = (theme) => ({
    ContainerRoot: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '16px',
            paddingRight: '16px',
        },
        paddingTop: '1.5rem',
        paddingBottom: '5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
    },
    formGridContainer: {
        gap: 32,
    },
    summary: {
        position: "sticky",
        top: "5rem",
        paddingTop: '.5rem',
    },
});

const defaultPendingExpense = {
    label: 'Expense',
    amount: '',
    billType: '',
    bookingId: null,
    category: '',
    clientId: '',
    clinicianFirstName: '',
    clinicianId: '',
    clinicianLastName: '',
    comments: '',
    creditCardId: '',
    currency: 'USD',
    division: '',
    merchantName: '',
    serviceFee: '',
    state: '',
    totalExpense: '',
    type: '',
    status: 'Unassigned',
    salesperson: '',
    recruiter: '',
    payDate: null,
    expenseClass: 'Normal',
    splitTransactionComment: '',
    bookingType: '',
    bookingDivision: '',
    bookingCategory: '',
    sgaType: '',
    sgaDivision: '',
    sgaCategory: '',
};

const defaultCCExpense = {
    label: 'Expense',
    amount: '',
    billType: '',
    bookingId: null,
    category: '',
    clientId: '',
    clinicianFirstName: '',
    clinicianId: '',
    clinicianLastName: '',
    comments: '',
    creditCardId: '',
    currency: 'USD',
    division: '',
    merchantName: '',
    serviceFee: '',
    state: '',
    totalExpense: '',
    type: '',
    status: 'New',
    salesperson: '',
    recruiter: '',
    payDate: null,
    expenseClass: 'IngestedCCTransaction',
    splitTransactionComment: '',
    bookingType: '',
    bookingDivision: '',
    bookingCategory: '',
    sgaType: '',
    sgaDivision: '',
    sgaCategory: '',
};

const mergeFields = {
    creditCardId: 'Ingested',
    transactionDate: 'Ingested',
    amount: 'Ingested',
    merchantName: 'Ingested',
    division: 'Pending CC/Pre-Code',
    category: 'Pending CC/Pre-Code',
    type: 'Pending CC/Pre-Code',
    startDate: 'Pending CC/Pre-Code',
    endDate: 'Pending CC/Pre-Code',
    bookingId: 'Pending CC/Pre-Code',
    billType: 'Pending CC/Pre-Code',
    clinicianId: 'Pending CC/Pre-Code',
    clinicianFirstName: 'Pending CC/Pre-Code',
    clinicianLastName: 'Pending CC/Pre-Code',
    state: 'Pending CC/Pre-Code',
};

const PreCoded = (props) => {
    const { classes, mode } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [divisions, setDivisions] = useState([]);
    const [divisionsSGA, setDivisionsSGA] = useState([]);
    const [statusesAllowed, setStatusesAllowed] = useState([]);
    const { id } = useParams();
    const [alert, setAlert] = useState();
    const history = useHistory();
    const defaultValues = (mode == 'cc') ? { fileUploads: [{ newFiles: [], existingFiles: [] }], expenseEntries: [defaultCCExpense] } : { fileUploads: [{ newFiles: [], existingFiles: [] }], expenseEntries: [defaultPendingExpense] };
    const methods = useForm({ shouldUnregister: false, defaultValues: defaultValues });
    const { handleSubmit, reset, control, getValues, watch, setValue, setError, clearErrors, formState: { errors } } = methods;
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const backLink = id ? 'All Expenses' : 'Expense Types';
    const title = mode === 'cc' ? 'Credit Card Transaction' : 'Pending CC Expense Entry';
    const expensesPath = id ? (mode === 'pending-cc' ? '/expenses/pending-cc/review' : '/expenses/review') : '/expenses';
    const { isExpenseSystemAdministratorUser, isExpenseAdminUser } = useContext(AdminAuthContext);
    const isViewOnly = !(isExpenseSystemAdministratorUser || isExpenseAdminUser);
    const watchExpenseEntries = watch(`expenseEntries`);
    const [originalExpenseEntries, setOriginalExpenseEntries] = useState([]);
    const [originalPrimaryExpense, setOriginalPrimaryExpense] = useState();
    const isAnyExpensePosted = useMemo(() => {
        if (mode === 'cc' && originalExpenseEntries?.length > 0) {
            return originalExpenseEntries.some(entry => entry.status === 'Posted');
        }
        return false;
    }, [originalExpenseEntries]);
    const isAllExpensePosted = useMemo(() => {
        if (mode === 'cc' && originalExpenseEntries?.length > 0) {
            return !originalExpenseEntries.some(entry => entry.status !== 'Posted');
        }
        return false;
    }, [originalExpenseEntries]);
    const [isSGA, setIsSGA] = useState([false,false,false,false,false]);
    const [removeTabs, setRemoveTabs] = useState(false);
    const [expenseCategoriesSGA, setExpenseCategoriesSGA] = useState(null);
    const [expenseCategoriesNonSGA, setExpenseCategoriesNonSGA] = useState(null);
    const [allCreditCards, setallCreditCards] = useState([]);
    const [showBalanceError, setShowBalanceError] = useState(false);
    const {
        fields: expenseEntries,
        append,
        remove,
    } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: 'expenseEntries', // unique name for your Field Array
        keyName: 'expenseEntryId',
    });

    useEffect(() => {
        Promise.all([getDivisions(true, true), getStatusesAllowed('IngestedCCTransaction' /*Anything other than Reimbursement*/), getAllCreditCards(), getExpenseCategories('NonSGA'), getExpenseCategories('SGA')])
            .then(async ([divsSGA, statuses, cc, catsNonSGA,catsSGA]) => {
                setDivisions(divsSGA.filter(val => val.isSGA === false));
                setDivisionsSGA(divsSGA);
                setallCreditCards(cc);
                setExpenseCategoriesNonSGA(catsNonSGA);
                setExpenseCategoriesSGA(catsSGA);
                setStatusesAllowed(statuses);
            })
            .catch((error) => {
                console.error(error);
            });
        if (id) {
            fetchExpenseById();
        }

    }, []);

    const resetImages = (index) => {
        //Resort/Reset Images
        const fileUploads = getValues(`fileUploads`);
        fileUploads.splice(index, 1);
        setValue(`fileUploads`,fileUploads)
    };

    //This was added to address a rendering issue
    //It turns off the TabPanel Display removes the item and then turns back on the display
    useEffect(() => {
        if (removeTabs){
            resetIsSGA(activeTabIndex);
            resetImages(activeTabIndex);
            remove(activeTabIndex);
            setActiveTabIndex(activeTabIndex - 1);
            setRemoveTabs(false);
        }
	}, [removeTabs])

    const fetchExpenseById = async () => {
        try {
            setIsLoading(true);
            // TODO: Get all split/duplicate expense entires instead of singular once API is complete
            const res = mode === 'cc' ? await getExpenseById(id) : await getPendingCCExpenseById(id);
            let listExpenses = []
            let aOriginalExpenseEntries = [];
            let aFileUploads = [];
            if (mode === 'cc'){

                if (res.primaryExpenseId && res.primaryExpense){//child split
                    let childIndex = 0
                    listExpenses.push(res.primaryExpense);
                    for (let i = 0; i < res.primaryExpense.splitExpenses.length; i++) {
                        let listExpensesIndex = listExpenses.push(res.primaryExpense.splitExpenses[i]);
                        if (res.primaryExpense.splitExpenses[i].id == id){
                            childIndex = listExpensesIndex - 1;
                        }
                    }

                    setActiveTabIndex(childIndex);
    
                }else{
                    listExpenses.push(res);
                    for (let i = 0; i < res.splitExpenses.length; i++) {
                        listExpenses.push(res.splitExpenses[i]);
                    }
                }

                listExpenses[0].splitExpenses=[];
            }else{

                if (res.primaryPreCodedExpenseId && res.primaryPreCodedExpense){//child split
                    let childIndex = 0
                    listExpenses.push(res.primaryPreCodedExpense);
                    for (let i = 0; i < res.primaryPreCodedExpense.splitPreCodedExpenses.length; i++) {
                        let listExpensesIndex = listExpenses.push(res.primaryPreCodedExpense.splitPreCodedExpenses[i]);
                        if (res.primaryPreCodedExpense.splitPreCodedExpenses[i].id == id){
                            childIndex = listExpensesIndex - 1;
                        }
                    }
                    setActiveTabIndex(childIndex);
                }else{
                    listExpenses.push(res);
                    for (let i = 0; i < res.splitPreCodedExpenses.length; i++) {
                        listExpenses.push(res.splitPreCodedExpenses[i]);
                    }
                }

                listExpenses[0].splitPreCodedExpenses=[];
            }

            for (let i = 0; i < listExpenses.length; i++) {
              
                //Sets expenseEntries[0] backup defaults
                if (listExpenses[i].expenseClass === ExpenseClassEnum.SalesGeneralAndAdmin){
                    listExpenses[i].bookingType = '';
                    listExpenses[i].bookingDivision = '';
                    listExpenses[i].bookingCategory = '';
                    listExpenses[i].bookingBillType = '';
                    listExpenses[i].sgaType = listExpenses[i].type;
                    listExpenses[i].sgaDivision = listExpenses[i].division;
                    listExpenses[i].sgaCategory = listExpenses[i].category;
                    listExpenses[i].sgaBillType = listExpenses[i].billType;
                    isSGA[i] = true;
                }else{
                    listExpenses[i].bookingType = listExpenses[i].type;
                    listExpenses[i].bookingDivision = listExpenses[i].division;
                    listExpenses[i].bookingCategory = listExpenses[i].category;
                    listExpenses[i].bookingBillType = listExpenses[i].billType;
                    listExpenses[i].sgaType = '';
                    listExpenses[i].sgaDivision = '';
                    listExpenses[i].sgaCategory = '';
                    listExpenses[i].sgaBillType = '';
                    isSGA[i] = false;  
                }
                let formattedValues;

                if (listExpenses[i].matchedId) {
                    const matchedExpense = mode === 'cc' ? await getExpenseById(listExpenses[i].matchedId) : await getPendingCCExpenseById(listExpenses[i].matchedId);
                    const matchedValues = mergeMatchedValues(matchedExpense, listExpenses[i]);
                    formattedValues = formatResponse(matchedValues);
                } else {
                    formattedValues = formatResponse(listExpenses[i]);
                }
                //Reset back to Null as empty string on the as the default was causing issues
                formattedValues.payDate = (_.isString(formattedValues.payDate) && formattedValues.payDate.length === 0) ? null : formattedValues.payDate.substring(0, 10);

                aOriginalExpenseEntries.push({ ...formattedValues, label: listExpenses[i].label ? listExpenses[i].label : `Expense ${i}` });

                if (mode === 'cc' ){
                    if (listExpenses[i] && listExpenses[i].expenseImages && listExpenses[i].expenseImages.length >= 0){
                        aFileUploads.push({ newFiles: [], existingFiles: listExpenses[i].expenseImages });
                    }else{
                        aFileUploads.push({ newFiles: [], existingFiles: [] });
                    }
                }else{
                    if (listExpenses[i] && listExpenses[i].preCodedExpenseImages && listExpenses[i].preCodedExpenseImages.length >= 0){
                        aFileUploads.push({ newFiles: [], existingFiles: listExpenses[i].preCodedExpenseImages });
                    }else{
                        aFileUploads.push({ newFiles: [], existingFiles: [] });
                    }
                }
            }

            setIsSGA(isSGA);
            setOriginalExpenseEntries(aOriginalExpenseEntries);
            setOriginalPrimaryExpense(aOriginalExpenseEntries[0]);
            const defaultValues = { fileUploads: aFileUploads, expenseEntries: aOriginalExpenseEntries };
            reset(defaultValues);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const mergeMatchedValues = (matchedExpense, defaultExpense) =>
        Object.fromEntries(
            Object.entries(mergeFields).map(([key, value]) => {
                let mergedValue = [key];
                let sourceOfTruth = value === 'Ingested' ? matchedExpense : defaultExpense;

                if (
                    matchedExpense[key] &&
                    defaultExpense[key] &&
                    matchedExpense[key] !== defaultExpense[key]
                ) {
                    mergedValue = [...mergedValue, sourceOfTruth[key]];
                } else {
                    mergedValue = [...mergedValue, defaultExpense[key]];
                }

                return mergedValue;
            })
        );

    const formatResponse = (res) =>
        Object.fromEntries(
            Object.entries(res).map(([key, value]) => [key, value === null ? '' : value])
        );

    const clearAllHiddenErrors = () => {
        const aExpenseEntries = getValues('expenseEntries')
        aExpenseEntries.map((element, index) => {
            clearExpenseHiddenErrors(element, index);
            });
    };

    const clearExpenseHiddenErrors = (preCodedexpense, index) => {
        //Field 1: Transaction Date  transactionDate
        if (!preCodedexpense.category)
        {
            clearErrors(`expenseEntries[${index}].transactionDate`);
        }

        //Field 2: Start Date  startDate
        //Field 3: End Date  endDate
        if (!(preCodedexpense.category === 'Travel' && !(preCodedexpense.type === 'BaggageFee' || preCodedexpense.type === 'Fuel' || !preCodedexpense.type)))
        {
            clearErrors(`expenseEntries[${index}].startDate`);
            clearErrors(`expenseEntries[${index}].endDate`);
        }
    };

    const validateSubmit = async () => {
        if (mode === 'cc' ){
            clearAllHiddenErrors();
        }
        
        handleSubmit(onSubmit)();
    };

    const validateSave = async () => {
        if (mode === 'cc' ){
            clearAllHiddenErrors();
        }
        if (errors.balance) {
            setError("balance", {
                type: "manual",
                message: "The sum of the expenses does not match the current transaction amount.",
            });
            setShowBalanceError(true);
            return;
        }
        clearErrors("balance");
        setShowBalanceError(false);
        handleSubmit(onSave)();
    };

    const getStatusKey = (value) => {
        if (mode === 'cc') {
            return Object.keys(ExpenseStatusEnum).find((key) => ExpenseStatusEnum[key].value === value);
        } else {
            return Object.keys(PreCodedStatusEnum).find((key) => PreCodedStatusEnum[key].value === value);
        }
    };

    const prepareExpense = (origExpense) => {
        // Do not modify the existing expense object, clone it
        const expense = JSON.parse(JSON.stringify(origExpense));
        expense.bookingId = parseInt(expense.bookingId, 10);
        expense.clientId = expense.clientId !== '' ? expense.clientId : null;
        expense.serviceFee = expense.serviceFee !== '' ? expense.serviceFee : null;
        expense.creditCardId = parseInt(expense.creditCardId, 10);
        if (expense.billType == 'ClinicianDeduction') {
            if (expense.category == "") { expense.category = null; }
            if (expense.type == "") { expense.type = null; }
            const date = new Date;
            if (expense.transactionDate == "" || expense.transactionDate == null) { expense.transactionDate = date.toISOString(); }
        }
        Object.keys(expense).forEach((i) => {
            if (_.isString(expense[i]) && expense[i].length === 0) {
                expense[i] = null;
            }
        }
        );

        return expense;
    };

    const onSave = async (data) => {
        try {
            setIsLoading(true);
            const expenses = data.expenseEntries.map(prepareExpense);
            let transformExpense = transformToExpenseAPI(expenses);
            const expenseResponse = mode === 'cc' ? await updateExpense(transformExpense.id, transformExpense) : await updatePendingCCExpense(transformExpense.id, transformExpense);
            const fileUploads = getValues(`fileUploads`);
            await uploadImages(fileUploads, expenseResponse, mode);

            history.push({
                pathname: '/expenses/submit',
                state: { prevPage: mode === 'cc' ? '/expenses/review' : '/expenses/pending-cc/review' }
            });

        } catch (error) {
            console.error('Error onSaveCC Pending CC Expense Entry');
            reset({ expenseEntries: data.expenseEntries, fileUploads: data.fileUploads });
            const message = formatErrorMessage(error);
            setAlert({
                message,
                type: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const transformToExpenseAPI = (expenses) => {
        let rtnExpense = expenses[0];

        if (mode === 'cc'){
            rtnExpense.splitExpenses = [];
            rtnExpense.expenseImages = [];
            // Copy to splitExpenses
            for (let i = 1; i < expenses.length; i++) {
                expenses[i].expenseImages = [];
                rtnExpense.splitExpenses.push(expenses[i]);
            }
        }else{
            rtnExpense.splitPreCodedExpenses = [];
            rtnExpense.preCodedExpenseImages = [];
            
            // Copy to splitPreCodedExpenses
            for (let i = 1; i < expenses.length; i++) {
                expenses[i].preCodedExpenseImages = [];
                rtnExpense.splitPreCodedExpenses.push(expenses[i]);
            }
        }
        return rtnExpense;
    };

    //Submit will only be used for mode pending 
    const onSubmit = async (data) => {
        try {
            setIsLoading(true);
            const expenses = data.expenseEntries.map(prepareExpense);
            let transformExpense = transformToExpenseAPI(expenses);
            const expenseResponse = await createPendingCCExpense(transformExpense);
            const fileUploads = getValues(`fileUploads`);
            await uploadImages(fileUploads, expenseResponse, mode);
            
            history.push({
                pathname: '/expenses/submit',
                state: { prevPage: '/expenses/pending-cc' }
            });
        } catch (error) {
            console.error('Error onSubmit Pending CC Expense Entry');
            reset({ expenseEntries: data.expenseEntries, fileUploads: data.fileUploads });
            const message = formatErrorMessage(error);
            setAlert({
                message,
                type: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddExpense = () => {
        const newExpense = {
            ...getValues('expenseEntries[0]'),
            label: 'New Expense',
            amount: '',
            billType: '',
            id: null,
            created: null,
            lastModified: null,
            status: (mode == 'cc') ? 'New' : 'Unassigned',
        };

        const newFileUpload = {
            ...getValues('fileUploads[0]')
        };

        if (newFileUpload ){
            if (newFileUpload.existingFiles && newFileUpload.existingFiles.length >0){
                if (mode === 'cc'){
                    newFileUpload.existingFiles.forEach(file => {
                        // Clear out the Existing ExpenseId
                        file.expenseId=null;
                    });
                }else{
                    newFileUpload.existingFiles.forEach(file => {
                        // Clear out the Existing PreCodedExpenseId
                        file.preCodedExpenseId=null;
                    });
                }
            }

            const cloneNewFiles = [];
            if (newFileUpload.newFiles && newFileUpload.newFiles.length >0){
                newFileUpload.newFiles.forEach(file => {
                    let cloneNewFile = new File([file], file.name, {type: file.type});
                    // Regenerate the preview to make unique for the tab                   
                    Object.assign(cloneNewFile, {
                        preview: URL.createObjectURL(file),
                    });
                    cloneNewFiles.push(cloneNewFile);
                });
            }
            newFileUpload.newFiles = cloneNewFiles;

            const fileUploads = getValues(`fileUploads`);
            fileUploads.push(newFileUpload);
            setValue(`fileUploads`,fileUploads)
        }

        if (newExpense.hasOwnProperty('errors')) {
            delete newExpense.errors;
        }
        if (newExpense.hasOwnProperty('relatedErrors')) {
            delete newExpense.relatedErrors;
        }
        if (newExpense.hasOwnProperty('importedData')) {
            delete newExpense.importedData;
        }
        if (!newExpense.splitTransactionComment) {
            newExpense.splitTransactionComment = '';
        }
        if (isSGA[0]){
            newExpense.sgaType = newExpense.type;
            newExpense.sgaDivision = newExpense.division;
            newExpense.sgaCategory = newExpense.category;
            newExpense.sgaBillType = newExpense.billType;
            newExpense.sgaExpenseClass = newExpense.expenseClass;
        }else{
            newExpense.bookingType = newExpense.type;
            newExpense.bookingDivision = newExpense.division;
            newExpense.bookingCategory = newExpense.category;
            newExpense.bookingBillType = newExpense.billType;
            newExpense.bookingExpenseClass = newExpense.expenseClass;
        }
        isSGA[expenseEntries.length] = isSGA[0];
        setIsSGA(isSGA);
        append(newExpense);
        setActiveTabIndex(expenseEntries.length);
    };

    const resetIsSGA = (index) => {
        //Resort/Reset isSGA flags
        if (index == 4){
            isSGA[index] = false;
        }else{
            for (let indexLoop = index; indexLoop < isSGA.length; ++indexLoop) {
                if (isSGA[indexLoop+1] !== undefined) {
                    if (isSGA[indexLoop] !== isSGA[indexLoop+1]){
                        isSGA[indexLoop] = isSGA[indexLoop+1];
                    }
                }
            }
        }
        setIsSGA(isSGA);
    };

    const handleRemoveExpense = (index) => {
        //This was added to address a rendering issue
        //It turns off the TabPanel Display removes the item and then turns back on the display
        setActiveTabIndex(index);
        setRemoveTabs(true);
    };

    return (
        <Container maxWidth='xl' className={classes.ContainerRoot}>
            <FormProvider {...methods}>
                <Grid container direction='column' spacing={2}>
                    <ExpenseEntryHeader
                        title={title}
                        backLink={backLink}
                        expensesPath={expensesPath}
                    />
                    {isLoading ? (
                        <CircularProgress color='primary' />
                    ) : (
                        <>
                            <Grid item xs={12}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container spacing={4}>
                                        <Grid item sm={7} md={8} lg={9} xs={12}>
                                            <ExpenseTabs value={activeTabIndex}>
                                                {watchExpenseEntries.map((expense, index) => (
                                                    <ExpenseTab
                                                        key={expense.expenseEntryId}
                                                        id={`expense-tab-${index}`}
                                                        label={
                                                            <ExpenseTabLabel
                                                                isActive={activeTabIndex === index}
                                                                index={index}
                                                                handleRemoveTab={() =>
                                                                    handleRemoveExpense(index)
                                                                }
                                                                setValue={setActiveTabIndex}
                                                                tab={expense}
                                                                mode={mode}
                                                                isViewOnly={isViewOnly}
                                                                originalPrimaryExpense={originalPrimaryExpense}
                                                                isAnyExpensePosted={isAnyExpensePosted}
                                                            />
                                                        }
                                                    />
                                                ))}
                                                {!isViewOnly && !(mode==='cc' && isAnyExpensePosted) && (
                                                    <AddExpenseTab
                                                    label={<AddIcon color='primary' />}
                                                    disabled={watchExpenseEntries.length === 5}
                                                    onClick={handleAddExpense}
                                                    id='expense-tab-add'
                                                    />
                                                )}
                                            </ExpenseTabs>
                                            {(!removeTabs) && (
                                                <div>
                                                    {watchExpenseEntries.map((expense, index) => (
                                                        <ExpenseTabPanel
                                                            className={classes.tabPanel}
                                                            key={`${expense.expenseEntryId}-panel`}
                                                            value={activeTabIndex}
                                                            index={index}
                                                            id={`expense-panel-${index}`}
                                                        >
                                                            {expense.errors && expense.errors.length > 0 && (
                                                                <ExpenseNeedsReviewAlert expense={expense} />
                                                            )}
                                                            <Grid
                                                                container
                                                                direction='column'
                                                                className={classes.formGridContainer}
                                                            >

                                                                <Grid item xs={12}>
                                                                    { !isSGA[index] && 
                                                                    <BookingDetailsForm
                                                                    index={index}
                                                                    expense={expense}
                                                                    divisions={divisions}
                                                                    mode={mode}
                                                                    setIsSGA={setIsSGA}
                                                                    originalExpenseEntries={originalExpenseEntries}
                                                                    isSGA={isSGA}
                                                                    isViewOnly={isViewOnly}
                                                                    />}
                                                                    
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <PreCodedExpenseDetailsForm
                                                                        index={index}
                                                                        expense={expense}
                                                                        divisions={isSGA[index] ? divisionsSGA : divisions}
                                                                        mode={mode}
                                                                        setIsSGA={setIsSGA}
                                                                        isSGA={isSGA}
                                                                        expenseCategoriesSGA={expenseCategoriesSGA}
                                                                        expenseCategoriesNonSGA={expenseCategoriesNonSGA}
                                                                        allCreditCards={allCreditCards}
                                                                        isViewOnly={isViewOnly}
                                                                    />
                                                                </Grid>
                                                                {id &&
                                                                    (isExpenseSystemAdministratorUser ||
                                                                        isExpenseAdminUser) &&
                                                                    expense.importedData && (
                                                                        <Grid item xs={12}>
                                                                            <AdditionalExpenseDetails
                                                                                index={index}
                                                                                expense={expense}
                                                                                mode={mode}
                                                                                isViewOnly={isViewOnly}
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                <Grid item xs={12}>
                                                                    <ExpenseReceiptUploadForm
                                                                        index={index}
                                                                        expense={expense}
                                                                        originalExpenseEntries={originalExpenseEntries}
                                                                        mode={mode}
                                                                        isViewOnly={isViewOnly}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <InternalNotesForm
                                                                        index={index}
                                                                        expense={expense}
                                                                        mode={mode}
                                                                        originalExpenseEntries={originalExpenseEntries}
                                                                        statusesAllowed={statusesAllowed}
                                                                        isReimbursement={false}
                                                                        isViewOnly={isViewOnly}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <ReasonForSplittingForm
                                                                        index={index}
                                                                        expense={expense}
                                                                        originalExpenseEntries={originalExpenseEntries}
                                                                        mode={mode}
                                                                        isViewOnly={isViewOnly}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </ExpenseTabPanel>
                                                    ))}
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item md={4} sm={5} lg={3} xs={12}>
                                            <div className={classes.summary}>
                                                    <ExpenseSummary mode={mode} setError={setError} clearErrors={clearErrors} setShowBalanceError={setShowBalanceError} />
                                                    {showBalanceError && (
                                                        <Typography color="error">{errors.balance.message}</Typography>
                                                    )}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                            {(!isViewOnly && (mode !== 'cc' || !(mode === 'cc' && isAllExpensePosted))) && (
                            <Grid item xs={12}>
                                <ExpenseButtons
                                    validateSubmit={validateSubmit}
                                    validateSave={validateSave}
                                    defaultValues={defaultValues}
                                    setActiveTabIndex={setActiveTabIndex}
                                    expensesPath={id ? (mode === 'pending-cc' ? '/expenses/pending-cc/review' : '/expenses/review') : '/expenses'}
                                />
                            </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </FormProvider>
            <Alert alert={alert} setAlert={setAlert} />
        </Container>
    );
};

export default withStyles(styles)(PreCoded);
