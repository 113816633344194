import React from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import RecallIcon from '@material-ui/icons/ReportProblem';
import DidNotWorkIcon from '@material-ui/icons/HighlightOffOutlined';
import { ReactComponent as DownloadIcon } from '/public/favicons/file-arrow-down-solid.svg'
import SvgIcon from '@material-ui/core/SvgIcon';
import { COLORS } from '../../utils/Application_Constants';
import { TimesheetStatusEnumApi } from '../../components/ContentComponents/Timesheets/Status.enum';
import { Mail } from '@material-ui/icons';

const tableColumns = [
	{ name: 'Clinician', key: 'name', size: 2, sortKey: 'clinicianLastName' },
	{ name: 'Assignment/Location', key: 'location', size: 2, sortKey: 'location' },
	{ name: 'Division', key: 'divisionName', size: 1, sortKey: 'divisionName' },
	{ name: 'Hours', key: 'hours', size: 1 },
	{ name: 'Units', key: 'units', size: 1 },
	{ name: 'Mileage', key: 'miles', size: 1 },
	{ name: 'Clinician Comments', key: 'clinicianComments', size: 1, sortKey: 'comments' },
	{ name: 'Approver', key: 'approver', size: 1, sortKey: 'approver' },
	{ name: 'Status', key: 'status', size: 1, sortKey: 'status' },
	{ name: 'Actions', key: 'actions', size: 1 },
];

const availableStatuses = [
	TimesheetStatusEnumApi.Approved,
	TimesheetStatusEnumApi.Rejected,
	TimesheetStatusEnumApi.Submitted,
	TimesheetStatusEnumApi.NotStarted,
	TimesheetStatusEnumApi.PendingSubmission,
	TimesheetStatusEnumApi.Paid,
	TimesheetStatusEnumApi.DidNotWork,
	TimesheetStatusEnumApi.TimeNotEntered,
];

const filters = ['Status', 'Divisions', 'Week Ending'];

const availableDivisions = [
	'Anesthesia',
	'Emergency Medicine',
	'Government',
	'Hospital Medicine',
	'Internal Medicine Sub-Specialties',
	'Neurology',
	'Oncology',
	'Primary Care',
	'Psychiatry',
	'Radiology',
	'Surgery - Clinic-Based',
	'Surgery - Hospital-Based',
];

const actions = [
	{ name: 'View', requestedStatus: 'View', icon: <VisibilityIcon fontSize='small' /> },
	{
		name: 'Approve',
		requestedStatus: TimesheetStatusEnumApi.Approved,
		icon: <DoneIcon fontSize='small' style={{ color: COLORS.LT_EMERALD }} />,
	},
	{
		name: 'Reject',
		requestedStatus: TimesheetStatusEnumApi.Rejected,
		icon: <ClearIcon fontSize='small' style={{ color: COLORS.LT_PEPPER }} />,
	},
	{
		name: 'Recall',
		requestedStatus: TimesheetStatusEnumApi.PendingSubmission,
		icon: <RecallIcon fontSize='small' />,
	},
	{
		name: 'Email Client',
		requestedStatus: 'EmailClient',
		icon: <Mail fontSize='small' color='primary' />,
	},
	{
		name: 'Email Clinician',
		requestedStatus: 'EmailClinician',
		icon: <Mail fontSize='small' color='primary' />,
	},
	{
		name: 'Did Not Work',
		requestedStatus: TimesheetStatusEnumApi.DidNotWork,
		icon: <DidNotWorkIcon fontSize='small' />,
	},
	{
		name: 'Download Timesheet',
		requestedStatus: 'DownloadTimesheet',
		icon: 	  <SvgIcon component={DownloadIcon} color='primary' fontSize='small' viewBox="-100 50 600 476.6" />,
	},
];

const errorCodes = {
	'00013': 'Approved',
	'00014': 'Rejected',
	'00015': 'Recalled',
};

const getViewUrl = ({ status, id, weekEnding, locationId, clinicianId, dueDate, bookingId }) => {
	let baseUrl;
	switch (status) {
		case TimesheetStatusEnumApi.NotStarted:
		case TimesheetStatusEnumApi.PendingSubmission:
		case TimesheetStatusEnumApi.Rejected:
		case TimesheetStatusEnumApi.DidNotWork:
			baseUrl = `timesheet/${id}/edit`;
			break;
		case TimesheetStatusEnumApi.Approved:
		case TimesheetStatusEnumApi.Paid:
		case TimesheetStatusEnumApi.Submitted:
		case TimesheetStatusEnumApi.TimeNotEntered:
			baseUrl = `/clinician/${clinicianId}/timesheet/${id}/review/${locationId}`;
			break;
		default:
			break;
	}
	if (status === TimesheetStatusEnumApi.NotStarted) {
		return `${baseUrl}?weekEnding=${weekEnding}&dueDate=${dueDate}&bookingId=${bookingId}&clinicianId=${clinicianId}`;
	} else if (
		status === TimesheetStatusEnumApi.Submitted ||
		status === TimesheetStatusEnumApi.TimeNotEntered
	) {
		return baseUrl;
	} else {
		return `${baseUrl}?weekEnding=${weekEnding}&clinicianId=${clinicianId}`;
	}
};

const searchKeys = [
	{ name: 'Clinician', value: 'clinicianName' },
	{ name: 'Location', value: 'locationName' },
	{ name: 'Booking ID', value: 'bookingName' },
	{ name: 'Approver', value: ['assignedApproverName', 'actualApproverName'] },
	{ name: 'Recruiter', value: 'recruiterName' },
	{ name: 'Salesperson', value: 'salespersonName' }
];

export const config = {
	tableColumns,
	availableStatuses,
	actions,
	filters,
	hasSearch: true,
	errorCodes,
	availableDivisions,
	getViewUrl,
	searchKeys,
};
