import React from 'react';
import { InputAdornment, MenuItem, TextField, makeStyles } from '@material-ui/core';
import { COLORS } from '../../../utils/Application_Constants';
import { ExpandMore } from '@material-ui/icons';
import { Controller, useFormContext } from 'react-hook-form';
import { currencies, textFieldStyles } from '../../Configurations/ExpenseEntryConfig';

const styles = makeStyles((theme) => {
	const baseStyles = textFieldStyles(theme);
	return {
		...baseStyles,
		selectIcon: {
			...baseStyles.selectIcon,
			color: 'inherit',
			'&.Mui-disabled': {
				display: 'none',
			},
		},
		inputAdornmentRoot: {
			border: 'none',
			borderRadius: 0,
		},
		inputAdornmentSelect: {
			paddingRight: '32px !important',
			background: COLORS.LT_MIDNIGHT5,
			fontSize: '14px',
		},
		inputAdornmentDisabled: {
			paddingRight: '24px !important',
		},
		positionEnd: {
			marginLeft: 0,
		},
	};
});

export const AmountCurrencyInputAdornment = (props) => {
	const classes = styles();
	const { disabled, index, currencyType } = props;
	const { control } = useFormContext();

	return (
		<InputAdornment position='end' classes={{ positionEnd: classes.positionEnd }}>
			<Controller
				control={control}
				name={`expenseEntries[${index}].currency`}
				defaultValue={currencyType}
				render={({ onChange, value }) => (
					<TextField
						id='expense-currency'
						classes={{ root: classes.root }}
						select
						InputProps={{
							classes: {
								root: classes.inputAdornmentRoot,
								input: classes.input,
							},
						}}
						onChange={onChange}
						value={value}
						disabled={disabled}
						SelectProps={{
							classes: {
								select: classes.inputAdornmentSelect,
								icon: classes.selectIcon,
								disabled: classes.inputAdornmentDisabled,
							},
							IconComponent: ExpandMore,
							MenuProps: {
								MenuListProps: { id: 'expense-currency-menu' },
							},
						}}
					>
						{currencies.map(({ name, value }, index) => (
							<MenuItem
								key={value}
								value={value}
								id={`expense-currency-menu-option-${index}`}
							>
								{name}
							</MenuItem>
						))}
					</TextField>
				)}
			/>
		</InputAdornment>
	);
};

export default AmountCurrencyInputAdornment;
