import React, { Component } from 'react';
import { withStyles, Grid, CircularProgress } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import UpcomingAssignmentList from '../UpcomingAssignmentList/UpcomingAssignmentList';
import UpcomingAssignmentDetailView from '../UpcomingAssignmentDetailView/UpcomingAssignmentDetailView';
import { getPresents } from '../../../services/PresentsService';
import UserContext from '../../../contexts/userContext';
import PresentsWidgetComponent from '../PresentsWidgetComponent/PresentsWidgetComponent';
import FacilityContacts from '../FacilityContacts/FacilityContacts';
import LocumTenensAfterHourSupportContact from '../LocumTenensContacts/LocumTenensAfterHourSupportContact';
import { getAssignments } from '../../../services/AssignmentsService';
import AssignmentDocumentComponentV2 from '../AssignmentDocumentComponent/AssignmentDocumentComponentV2';
import FeatureFlag from '../../GlobalComponents/FeatureFlagComponent/FeatureFlag';
import TravelV2 from '../Travel/TravelV2';
import TravelV3 from '../Travel/TravelV3';
const styles = () => ({
	contactsWidget: {
		marginTop: '20px',
	},
});

class UpcomingAssignmentsYourTeam extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listView: true,
			selectedAssignmentId: '',
			assignments: [],
			url: {
				location: 'upcoming',
				id: null,
			},
			isLoading: true,
			presents: [],
		};
	}

	async componentDidMount() {
		await this.getUrlParams();
		const state = { ...this.state };
		const { externalId } = this.context;
		const { setAssignmentPageError } = this.props;
		state.presents = await getPresents(externalId).catch((e) => {
			setAssignmentPageError(e);
		});
		state.assignments = this.filterUpcomingAssignments(
			await getAssignments(externalId, 'upcoming', true)
		);
		state.isLoading = false;
		this.setState({ ...state });
	}

	async componentDidUpdate(prevProps, prevState) {
		const { match } = this.props;
		if (prevState.url.id !== match.params.id) {
			await this.getUrlParams();
		}
	}

	getUrlParams() {
		const { match } = this.props;
		const state = { ...this.state };
		state.url = { ...match.params };
		this.setState({ ...state });
	}

	updateUpcomingAssignments = async () => {
		const state = { ...this.state };
		state.isLoading = true;
		await this.setState({ isLoading: state.isLoading });
		const { externalId } = this.context;
		const { setAssignmentPageError } = this.props;
		state.assignments = this.filterUpcomingAssignments(
			await getAssignments(externalId, 'upcoming').catch((e) => {
				setAssignmentPageError(e);
			}),
			true
		);
		state.isLoading = false;
		//this.setState({ ...state });
		this.setState({
			assignments: state.assignments,
			isLoading: state.isLoading,
		});
	};

	toggleAssignmentView = (assignmentId) => {
		const state = { ...this.state };
		const { history } = this.props;
		state.listView = !state.listView;
		state.url.id = assignmentId;
		history.push(`/assignments/upcoming/${assignmentId}`);
		this.setState({ ...state });
	};

	getAssignmentBasedOnId = () => {
		const { url, assignments } = this.state;
		const assignment = assignments.filter((item) => item.id.toString() === url.id.toString());
		return assignment[0];
	};

	filterUpcomingAssignments(assignments) {
		if (!assignments) {
			return null;
		}
		const filteredAssignments = [];
		for (const assignment of assignments) {
			if (
				assignment.location.addressLine1 ||
				(assignment.location.city && assignment.location.state)
			) {
				filteredAssignments.push(assignment);
			}
		}
		return filteredAssignments;
	}

	renderAssignmentView = () => {
		const { url, assignments } = this.state;
		const { externalId } = this.context;
		if (!url.id) {
			return (
				<UpcomingAssignmentList
					assignments={assignments}
					toggleAssignmentView={this.toggleAssignmentView}
				/>
			);
		}
		const currentAssignment = this.getAssignmentBasedOnId();
		return !currentAssignment ? (
			<p>Error, Assignment not found</p>
		) : (
			<>
				<UpcomingAssignmentDetailView
					assignment={currentAssignment}
					externalId={externalId}
					type='upcoming'
					assignmentId={url.id}
				/>
				<Grid container spacing={4}>
					<Grid item xs={12} md={8}>
						<AssignmentDocumentComponentV2 assignmentName={currentAssignment.name} />
						<FeatureFlag name='TravelV3' isFlagEnabled='false' fallbackValue={false}>
							<TravelV2 assignment={currentAssignment} externalId={externalId} filter='active' />
						</FeatureFlag>
						<FeatureFlag name='TravelV3' isFlagEnabled='true' fallbackValue={false}>
							<TravelV3 assignment={currentAssignment} externalId={externalId} filter='active' />
						</FeatureFlag>
					</Grid>
					<Grid item xs={12} md={4}>
						{this.renderWidgetsBasedOnView()}
					</Grid>
				</Grid>
			</>
		);
	};

	renderDetailViewWidgets = () => {
		const { classes } = this.props;
		const currentAssignment = this.getAssignmentBasedOnId();
		if (!currentAssignment) return null;
		return (
			<>
				<div className={classes.contactsWidget}>
					{this.renderFacilityContact(
						currentAssignment.facilityId,
						currentAssignment.facilityContacts,
						currentAssignment.displayName
					)}
					{this.renderLTAfterHourSupportContact()}
				</div>
			</>
		);
	};

	renderFacilityContact = (facilityId, contacts, primaryWorkLocation) => {
		if (!!facilityId && !!contacts && Array.isArray(contacts) && contacts.length > 0) {
			return (
				<FacilityContacts
					facilityId={facilityId}
					contacts={contacts}
					primaryWorkLocation={primaryWorkLocation}
					updateAssignments={this.updateUpcomingAssignments}
				/>
			);
		} else {
			return null;
		}
	};

	renderLTAfterHourSupportContact = () => {
		return <LocumTenensAfterHourSupportContact />;
	};

	renderWidgetsBasedOnView = () => {
		const { url, presents } = this.state;
		if (url.id) {
			return this.renderDetailViewWidgets();
		}
		return <PresentsWidgetComponent isFirst presents={presents} />;
	};

	render() {
		const { isLoading } = this.state;
		if (isLoading) {
			return (
				<div>
					<CircularProgress color='primary' />
				</div>
			);
		}
		return <>{this.renderAssignmentView()}</>;
	}
}
UpcomingAssignmentsYourTeam.contextType = UserContext;

UpcomingAssignmentsYourTeam.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string,
			status: PropTypes.string.isRequired,
		}),
	}).isRequired,
};

export default withRouter(withStyles(styles)(UpcomingAssignmentsYourTeam));
