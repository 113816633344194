import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import ExpenseTextField from './ExpenseTextField';

const ExpenseComments = (props) => {
    const { index, expense, isPosted, isPendingSubmission, isViewOnly } = props;
    const { control, watch, errors } = useFormContext();
    const expenseType = watch(`expenseEntries[${index}].type`);
    const isRequiredType = (expenseType === 'Misc' || expenseType === 'ComplianceFees');

    return (
        <Grid item xs={12}>
            <Controller
                control={control}
                name={`expenseEntries[${index}].comments`}
                defaultValue={expense.comments}
                rules={{
                    required: isRequiredType ? 'Expense comments are required' : false,
                }}
                render={({ onChange, value }) => (
                    <ExpenseTextField
                        required={isRequiredType}
                        multiline
                        inputProps={{ maxLength: 1000 }}
                        minRows={5}
                        id='comments'
                        onChange={onChange}
                        value={value}
                        label='Notes for Accounting Processing'
                        error={Boolean(errors.expenseEntries?.[index]?.comments)}
                        disabled={isPosted || isPendingSubmission || isViewOnly}
                        helperText={errors.expenseEntries?.[index]?.comments?.message ? errors.expenseEntries?.[index]?.comments?.message : (value && `${value.length}/1000`)}
                        placeholder='Enter notes for the Accounting System'
                    />
                )}
            />
        </Grid>
    );
};

export default ExpenseComments;
